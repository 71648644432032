import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { EPrefix } from '../../../../../model/EPrefix';
import { IContact } from '../../../../../model/contacts/IContact';
import { IStoreDocument } from '../../../../../model/store/IStoreDocument';
import { EntityLinkService } from '../../../../../services/entityLink.service';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../models/FieldBase';
import { IContactsListFieldParams } from '../../../models/IContactsListFieldParams';
import { FormsService } from '../../../services/forms.service';

/** Champs contenant une liste de contacts. */
@Component({
	templateUrl: './contactsListField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListFieldComponent extends FieldBase<Array<IContact> | Array<string> | string | IContact> implements OnInit {

	//#region FIELDS

	/** Indique si c'est le premier changement de modèle opéré ou non, permettant de déterminer s'il faut marquer le champ comme dirty ou non. */
	private mbIsFirstModelChanged = true;

	//#endregion

	//#region PROPERTIES

	/** Objet contenant les différents paramètres que le composant peut gérer. */
	public params: IContactsListFieldParams;

	public get isDefined(): boolean {
		return (this.fieldValue instanceof Array && ArrayHelper.hasElements(this.fieldValue)) || !!this.fieldValue;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.initParams();

		if (this.params.readContactsFromLinks) {
			this.isvcEntityLink.getLinkedEntities(this.isvcEntityLink.currentEntity.id, this.params.contactsSelectorParams?.prefix ?? EPrefix.contact).pipe(
				tap((paLinkedEntities: IStoreDocument[]) => {
					let laValues: IContact[] | string[];

					if (this.fieldValue instanceof Array)
						laValues = [...this.fieldValue] as IContact[] | string[];
					else // On transforme en tableau si besoin.
						laValues = this.fieldValue ? (typeof this.fieldValue === "string" ? [this.fieldValue as string] : [this.fieldValue as IContact]) : [];

					if (ArrayHelper.hasElements(laValues) && typeof laValues[0] !== "string") {
						paLinkedEntities.forEach((poLinkedContact: IContact) => {
							if ((laValues as IContact[]).every((poContact: IContact) => poContact._id !== poLinkedContact._id))
								(laValues as IContact[]).push(poLinkedContact);
						});
					}
					else {
						paLinkedEntities.forEach((poLinkedContact: IContact) => {
							if ((laValues as string[]).every((psContactId: string) => psContactId !== poLinkedContact._id))
								(laValues as string[]).push(poLinkedContact._id);
						});
					}

					this.fieldValue = laValues;
					this.detectChanges();
				}),
				secure(this)
			).subscribe();
		}
	}

	private initParams(): void {
		this.params = this.to.data;

		if (this.params.readOnly === undefined)
			this.params.readOnly = this.to.readonly;

		this.params.contactsContainer = this.model;

		if (!this.isvcEntityLink.currentEntity)
			this.params.readContactsFromLinks = false;
	}

	public onModelChanged(paValues: string[] | IContact[]): void {
		if (this.canSetModelAfterChange(paValues)) {
			// Si le modèle a des éléments et que c'est le premier changement, il s'agit du remplissage du composant de formulaire, pas une modification.
			if (this.isDefined && this.mbIsFirstModelChanged)
				this.mbIsFirstModelChanged = false;
			else
				this.markAsDirty();

			this.fieldValue = paValues;
			this.detectChanges();
		}
	}

	private canSetModelAfterChange(paValues: Array<IContact> | Array<string>): boolean {
		return (typeof ArrayHelper.getFirstElement(paValues as string[]) === "string" && !ArrayHelper.areArraysEqual(paValues as string[], this.fieldValue as string[])) ||
			!ArrayHelper.areArraysFromDatabaseEqual(paValues as IContact[], this.fieldValue as IContact[]);
	}

	//#endregion
}