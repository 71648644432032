import { IStoreDocument } from '../model/store/IStoreDocument';
import { ArrayHelper } from './arrayHelper';
import { StringHelper } from './stringHelper';

/** Helper relié aux documents du store. */
export abstract class StoreDocumentHelper {

	//#region FIELDS

	public static readonly C_COUCHDB_USER_PREFIX = "org.couchdb.user:";

	//#endregion

	//#region METHODS

	/** Récupère l'objet ayant la révision la plus haute depuis un tableau.
	 * @param paArray Tableau dont il faut récupérer l'objet ayant la révision la plus haute.
	 */
	public static getObjectWithMaxRevisionFromArray<T extends IStoreDocument>(paArray: Array<T>): T {
		let loContactWithMaxRevision: T = paArray[0];

		if (loContactWithMaxRevision) {
			let lnMaxRevisionNumber: number = this.getRevisionNumber(loContactWithMaxRevision);

			for (let lnIndex = paArray.length - 1; lnIndex >= 0; --lnIndex) {
				const loElement: T = paArray[lnIndex];
				const lnElementRevisionNumber: number = this.getRevisionNumber(loElement);

				if (lnElementRevisionNumber > lnMaxRevisionNumber) {
					loContactWithMaxRevision = loElement;
					lnMaxRevisionNumber = lnElementRevisionNumber;
				}
			}
		}

		return loContactWithMaxRevision;
	}

	/** Compare deux objets et récupère celui ayant la plus haute révision.
	 * @param poObject1 Objet qu'il faut comparer pour récupérer celui ayant la révision la plus haute.
	 * @param poObject2 Objet qu'il faut comparer pour récupérer celui ayant la révision la plus haute.
	 */
	public static getObjectWithMaxRevisionFromObjects<T extends IStoreDocument>(poObject1: T, poObject2: T): T {
		return this.getRevisionNumber(poObject1) >= this.getRevisionNumber(poObject2) ? poObject1 : poObject2;
	}

	/** Récupère le numéro de révision d'une révision, 0 s'il n'en a pas.
	 * @param psRevision Révision dont il faut récupérer le numéro de révision.
	 */
	public static getRevisionNumber<T extends IStoreDocument>(psRevision?: string): number;
	/** Récupère le numéro de révision d'un objet, 0 s'il n'en a pas.
	 * @param poObject Objet dont il faut récupérer le numéro de révision.
	 */
	public static getRevisionNumber<T extends IStoreDocument>(poObject?: T): number;
	public static getRevisionNumber<T extends IStoreDocument>(poData?: string | T): number {
		let lsRev: string | undefined;
		if (!poData)
			return 0;

		if (typeof poData === "string")
			lsRev = poData;
		else if (poData && poData._rev)
			lsRev = poData._rev;

		return !StringHelper.isBlank(lsRev) ? +(lsRev.replace(/-.+/, '')) : 0;
	}

	/** Récupère le guid de révision d'une révision, "" s'il n'en a pas.
	 * @param psRevision Révision dont il faut récupérer le guid de révision.
	 */
	public static getRevisionGuid<T extends IStoreDocument>(psRevision?: string): string;
	/** Récupère le guid de révision d'un objet, "" s'il n'en a pas.
	 * @param poObject Objet dont il faut récupérer le guid de révision.
	 */
	public static getRevisionGuid<T extends IStoreDocument>(poObject?: T): string;
	public static getRevisionGuid<T extends IStoreDocument>(poData?: string | T): string {
		let lsRev: string | undefined;

		if (typeof poData === "string")
			lsRev = poData;
		else if (poData && poData._rev)
			lsRev = poData._rev;

		return !StringHelper.isBlank(lsRev) ? ArrayHelper.getLastElement(lsRev.split("-")) : "";
	}

	/** Indique si la révision de 2 documents sont identiques.
	 * @param poDocumentA
	 * @param poDocumentB
	 */
	public static areDocumentRevisionsEqual<T extends IStoreDocument>(poDocumentA: T, poDocumentB: T): boolean {
		return poDocumentA && poDocumentB && poDocumentA._rev === poDocumentB._rev;
	}

	/** Retourne `true` si le document testé est valide et a une révision, `false` sinon.
	 * @param poDocument Document dont il faut vérifier la présence de `_rev`.
	 */
	public static hasRevision<T extends IStoreDocument>(poDocument?: T): boolean {
		// Une révision est composée d'un numéro suivi d'un tiret puis d'un guid (sans séparateurs) ou d'un nombre (dans le cas des documents locaux).
		// On ajoute le marqueur 'i' pour ignorer la casse.
		return !!poDocument && !StringHelper.isBlank(poDocument._rev) && /^[0-9]+-(([0-9a-f]{32})|([0-9]+))$/i.test(poDocument._rev);
	}

	/** Vérifie si les documents passés en paramètre sont égaux (même _id et _rev).
	 * @param paDocuments
	 */
	public static areDocumentsEquals<T extends IStoreDocument>(...paDocuments: T[]): boolean {
		return paDocuments.every((poDocument: T, pnIndex: number) => {
			if (pnIndex === 0)
				return true;
			else {
				const loPreviousDocument: T = paDocuments[pnIndex - 1];
				return loPreviousDocument?._id === poDocument?._id && loPreviousDocument?._rev === poDocument?._rev;
			}
		});
	}

	/** Supprime le préfixe couchDB de l'identifiant pour ne laisser que `usr_[id]`.
	 * @param psId Identifiant du document dont il faut supprimer le préfixe mis automatiquement par couchDB.
	 */
	public static removeUserCouchDBPrefix(psId: string): string;
	/** Supprime le préfixe couchDB du document pour ne laisser que `usr_[id]`.
	 * @param poDocument Document dont il faut supprimer le préfixe mis automatiquement par couchDB.
	 */
	public static removeUserCouchDBPrefix<T extends IStoreDocument>(poDocument: T): string;
	public static removeUserCouchDBPrefix<T extends IStoreDocument>(poData: T | string): string {
		const lsId: string = typeof poData === "string" ? poData : poData._id;

		return lsId.replace(StoreDocumentHelper.C_COUCHDB_USER_PREFIX, "");
	}

	/** Indique si le document est considéré comme nouveau (revision 1 ou pas de revision).
	 * !!! Completement bug (rev 1 n'est pas new)
	 * @param poDocument
	 * @returns
	 */
	public static isNew(poDocument: IStoreDocument): boolean {
		return poDocument._rev?.startsWith("1-") ?? true;
	}

	/** Permet de retourner un objet composé uniquement des champs `_id` et `_rev`.
	 * @param poDocument
	 * @returns
	 */
	public static excludeDocContent(poDocument: IStoreDocument): IStoreDocument | undefined {
		return poDocument ? { _id: poDocument._id, _rev: poDocument._rev } : undefined;
	}

	/** Permet de retourner tableau d'objets composés uniquement des champs `_id` et `_rev`.
	 * @param poDocument
	 * @returns
	 */
	public static excludeDocsContent(paDocuments: IStoreDocument[]): IStoreDocument[] {
		return ArrayHelper.getValidValues(paDocuments.map((poDocument: IStoreDocument) => this.excludeDocContent(poDocument)));
	}

	//#endregion
}