import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '../helpers/stringHelper';
import { IAddress } from '../model/navigation/IAddress';

@Pipe({ name: "contactAddress" })
export class ContactAddressPipe<T extends IAddress> implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Renvoie l'adresse d'un contact, `Aucune adresse` ou `chaîne vide` si aucune adresse renseignée.
	 * @param poValue Entité dont il faut récupérer le nom complet.
	 * @param pbHasDefaultAddress Indique s'il faut retourner une adresse par défaut, `true` par défaut.
	 */
	public transform(poValue?: T, pbHasDefaultAddress: boolean = true): string {
		let lsAddress = pbHasDefaultAddress ? "Aucune adresse" : ""; // Par défaut : si pas de contact, ou aucun champ d'adresse renseigné.

		if (poValue) {
			const lbHasStreet = !StringHelper.isBlank(poValue.street);
			const lbHasZipCode = !StringHelper.isBlank(poValue.zipCode);
			const lbHasCity = !StringHelper.isBlank(poValue.city);

			if (lbHasStreet && lbHasZipCode && lbHasCity)
				lsAddress = `${poValue.street}, ${poValue.zipCode}, ${poValue.city}`;
			else if (lbHasStreet && lbHasZipCode)
				lsAddress = `${poValue.street}, ${poValue.zipCode}`;
			else if (lbHasStreet && lbHasCity)
				lsAddress = `${poValue.street}, ${poValue.city}`;
			else if (lbHasZipCode && lbHasCity)
				lsAddress = `${poValue.zipCode}, ${poValue.city}`;
			else if (lbHasStreet)
				lsAddress = poValue.street;
			else if (lbHasZipCode)
				lsAddress = poValue.zipCode;
			else if (lbHasCity)
				lsAddress = poValue.city;
		}

		return lsAddress;
	}

	//#endregion
}