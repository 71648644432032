import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyConfig } from '@calaosoft/osapp/components/forms/form/fields.config';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { BatteryModule } from '@calaosoft/osapp/modules/battery/battery.module';
import { IDatabaseGroupingConfiguration } from '@calaosoft/osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from '@calaosoft/osapp/modules/database-synchro/services/database-synchro.service';
import { PatchModule } from '@calaosoft/osapp/modules/patch/patch.module';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from '@calaosoft/osapp/modules/patch/patches/Sqlite2ToSqliteEvcoreExtbuildFreePatch';
import { RoutingModule } from '@calaosoft/osapp/modules/routing/routing.module';
import { OsappModule } from '@calaosoft/osapp/osapp.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { ComDmsModule } from '../modules/com-dms/com-dms.module';
import { CustomModule } from '../modules/customElements.module';
import { JoinReportSelectorModalModule } from '../modules/report/join-report-selector-modal/join-report-selector-modal.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomersModule } from './customers/customers.module';

const databasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
	{
		roles: [EDatabaseRole.formsEntries, EDatabaseRole.workspace, EDatabaseRole.userContext],
		title: "Espace de travail",
		description: "clients, rapports"
	},
	{
		roles: [EDatabaseRole.conversations],
		title: "Conversations"
	}
];

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		IonicModule.forRoot(),
		OsappModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		CustomModule,
		ComDmsModule,
		PatchModule.forRoot([Sqlite2ToSqliteEvcoreExtbuildFreePatch]),
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: true, registrationStrategy: "registerImmediately" }),
		CustomersModule,
		BatteryModule,
		RoutingModule,
		FormlyModule.forRoot(FormlyConfig),
		JoinReportSelectorModalModule
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: DATABASES_GROUPING_CONFIG, useValue: databasesGroupingConfigs },
		{ provide: DMS_SYNC_CONFIG, useValue: { title: "Documents", description: "photos, ..." } },
	]
})
export class AppModule { }