import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LinkModule } from '../../components/link/link.module';
import { DatabaseSynchroModule } from '../database-synchro/database-synchro.module';
import { HeaderEndButtonsEndComponent } from './components/header-end-buttons-end/header-end-buttons-end.component';
import { HeaderEndButtonsStartComponent } from './components/header-end-buttons-start/header-end-buttons-start.component';
import { SubHeaderButtonComponent } from './components/sub-header-button/sub-header-button.component';
import { SubHeaderEndButtonsComponent } from './components/sub-header-end-buttons/sub-header-end-buttons.component';
import { SubHeaderTitleComponent } from './components/sub-header-title/sub-header-title.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { HeaderComponent } from './header.component';
import { HeaderDirective } from './header.directive';

@NgModule({
	declarations: [
		HeaderComponent,
		HeaderDirective,
		HeaderEndButtonsEndComponent,
		HeaderEndButtonsStartComponent,
		SubHeaderComponent,
		SubHeaderEndButtonsComponent,
		SubHeaderTitleComponent,
		SubHeaderButtonComponent
	],
	exports: [
		HeaderDirective,
		HeaderComponent,
		HeaderEndButtonsEndComponent,
		HeaderEndButtonsStartComponent,
		SubHeaderComponent,
		SubHeaderEndButtonsComponent,
		SubHeaderTitleComponent,
		SubHeaderButtonComponent
	],
	imports: [CommonModule, IonicModule, RouterModule, DatabaseSynchroModule, LinkModule]
})
export class HeaderModule { }