import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TransfertService } from './services/transfert.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [TransfertService]
})
export class TransfertModule { }