import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IConversationActivity } from '../model/conversation/IConversationActivity';
import { ConversationService } from './conversation.service';

@Injectable()
export class ArchivingService {

	//#region METHODS

	constructor(private readonly isvcConversation: ConversationService) { }

	/** Archive une conversation.
	 * @param poConversationActivity Le document d'activité à modifier.
	 */
	public archiveConversation(poConversationActivity: IConversationActivity): Observable<IConversationActivity> {
		poConversationActivity.archive = new Date();
		return this.isvcConversation.updateActivity(poConversationActivity);
	}

	/** Restaure une conversation.
	 * @param poConversationActivity Le document d'activité à modifier.
	 */
	public restoreConversation(poConversationActivity: IConversationActivity): Observable<IConversationActivity> {
		delete poConversationActivity.archive;
		return this.isvcConversation.updateActivity(poConversationActivity);
	}

	//#endregion METHODS

}