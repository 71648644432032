import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { ITaskParams } from '../../model/backgroundTask/taskParams/ITaskParams';
import { ConfigData } from '../../model/config/ConfigData';
import { ITask } from './ITask';
import { TaskDescriptor } from './TaskDescriptor';

export abstract class TaskBase<T extends ITaskParams = ITaskParams> implements ITask {

	//#region PROPERTIES

	/** @implements */
	public descriptor: TaskDescriptor<T>;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		this.descriptor = poDescriptor;
	}

	/** @implements */
	public abstract execTask(): Observable<any>;

	/** @implements */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$ // Observable qui fournit chaque erreur de la tâche.
			.pipe(
				mergeMap((poError: any, pnNbError: number) => {
					// Si le nombre d'échecs est égal au nombre max de répétitions accordées et que ce n'est pas une tâche cyclique.
					if (pnNbError >= ConfigData.backgroundTask.maxRepeatTask && !this.descriptor.intervalRepetition)
						return throwError(poError);
					else {
						const lnIntervalRepetition: number | undefined =
							this.descriptor.intervalRepetition instanceof Array ?
								ArrayHelper.getFirstElement(this.descriptor.intervalRepetition) :
								this.descriptor.intervalRepetition;
						const lbHasValidIntervalRepetition: boolean = NumberHelper.isValidStrictPositive(lnIntervalRepetition);

						if (!lbHasValidIntervalRepetition)
							this.descriptor.retryInterval *= this.descriptor.intervalMultiplicator;

						const lnIntervalTime: number =
							lbHasValidIntervalRepetition ? lnIntervalRepetition : this.descriptor.retryInterval;

						this.descriptor.lastExec = new Date();

						return timer(lnIntervalTime);
					}
				})
			);
	}

	//#endregion
}