<osapp-header-modal [title]="title ? title : 'Appliquer le planning'"></osapp-header-modal>

<ion-content>
	<div class="range-picker">
		<ion-row>
			<ion-col size="3">Dates</ion-col>
			<ion-col size="9">
				<calao-date-range-picker [model]="dateRange" (dateRangeChanged)="onDateRangeChanged($event)" class="txt-right">
				</calao-date-range-picker>
			</ion-col>
		</ion-row>
	</div>

	<div class="buttons">
		<ion-button (click)="close()" fill="clear" color="danger">
			Annuler
		</ion-button>
		<ion-button (click)="submitDates()" color="primary" fill="clear">
			Valider
		</ion-button>
	</div>
</ion-content>