import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../../model/application/IApplicationEvent';
import { ConfigData } from '../../../model/config/ConfigData';
import { ApplicationService } from '../../../services/application.service';
import { InjectorService } from '../../../services/injector.service';
import { IDmsData } from '../model/IDmsData';
import { IDmsEvent } from '../model/IDmsEvent';

export abstract class DmsFileHelper {

	//#region METHODS

	/** Permet d'attendre qu'une action soit menée sur fichier du dms.
	 * @param psId Id du fichier.
	 */
	public static waitForFile(psId: string): Observable<IDmsData> {
		const lsvcApplication: ApplicationService = InjectorService.instance.get<ApplicationService>(ApplicationService);

		return lsvcApplication.appEvent$
			.pipe(
				filter((poEvent: IApplicationEvent) => this.filterApplicationEvent(poEvent, psId)),
				take(1),
				map((poEvent: IDmsEvent) => {
					if (poEvent.data.dmsFile && poEvent.data.dmsMeta)
						return { file: poEvent.data.dmsFile, meta: poEvent.data.dmsMeta } as IDmsData;
					else
						throw new Error(`Fichier "${poEvent.data.fileId}" non récupéré.`);
				})
			);
	}

	/** Filtre l'événement d'application reçu pour vérifier si c'est celui qu'on attend ou non.
	 * @param poEvent Événement à filtrer.
	 * @param psId Identifiant permettant de filtrer.
	 */
	private static filterApplicationEvent(poEvent: IApplicationEvent, psId: string): boolean {
		return poEvent.type === EApplicationEventType.DmsEvent && (poEvent as IDmsEvent).data.fileId && (poEvent as IDmsEvent).data.fileId === psId;
	}

	public static getDefaultDocumentType(): string {
		return `${ConfigData.appInfo.appId}Doc`;
	}

	public static getDefaultDocumentSubType(): string {
		return `none`;
	}

	public static getDefaultDocumentPath(): string {
		return ``;
	}

	//#endregion
}