import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IStoreTaskParams } from '../../model/backgroundTask/taskParams/IStoreTaskParams';
import { ConfigData } from '../../model/config/ConfigData';
import { ESecurityFlag } from '../../model/security/ESecurityFlag';
import { Database } from '../../model/store/Database';
import { IDatabaseConfig } from '../../model/store/IDatabaseConfig';
import { StoreTask } from './StoreTask';
import { TaskDescriptor } from './TaskDescriptor';

/** Tâche qui lance la réplication d'une base de donnée.
 * La base doit être initialisée quand la tâche est appelée.
 */
export class DatabaseReplicateTask<T extends IStoreTaskParams> extends StoreTask<T> {

	//#region FIELDS

	private readonly msDbId: string;
	private readonly msLogin: string;
	private readonly msPassword: string;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msDbId = this.descriptor.params.dbId;
		this.msLogin = this.descriptor.params.login;
		this.msPassword = this.descriptor.params.password;
	}

	public override execTask(): Observable<Database> {
		const laFlagsToWait: string[] = [ENetworkFlag.isOnlineReliable];
		if (this.msDbId !== ConfigData.environment.coreRoleAppConfig)
			laFlagsToWait.push(ESecurityFlag.authenticated);

		return this.msvcFlag.waitForFlags(laFlagsToWait, true)
			.pipe(
				mergeMap(_ => this.msvcStore.initReplication(ConfigData.databases.find((poDatabaseConfig: IDatabaseConfig) => poDatabaseConfig.id === this.msDbId), this.msLogin, this.msPassword))
			);
	}

	//#endregion
}