import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { FieldBase } from '../../../models/FieldBase';
import { ISliderFieldParams } from '../../../models/ISliderFieldParams';
import { FormsService } from '../../../services/forms.service';

/** Champs contenant un slider. */
@Component({
	templateUrl: './sliderField.component.html',
	styleUrls: ['./sliderField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFieldComponent extends FieldBase<number> implements OnInit {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<ISliderFieldParams>();
	public readonly observableBubbleColor = new ObservableProperty<string>("background-color: var(--ion-color-primary)");

	//#endregion PROPERTIES

	/** Paramètres possibles pour le composant de formulaire Slider. */
	public params: ISliderFieldParams;

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		this.observableParams.value = this.props.data;

		// Initialisation de la valeur de retour du slider (à la valeur minimale, soit 0 par défaut)
		if (!this.fieldValue)
			this.fieldValue = this.observableParams.value.defaultValue ?? this.observableParams.value.minValue;

		if (this.observableParams.value.readOnly)
			this.observableBubbleColor.bind(this.getBubbleColor$(), this);
	}

	public onModelChanged(poEvent: Event): void {
		const lnValue: number = (poEvent as CustomEvent).detail.value;
		if (lnValue !== this.fieldValue) {
			this.fieldValue = lnValue;
			this.markAsDirty();
		}
	}

	private getBubbleColor$(): Observable<string> {
		return this.observableFieldValue.value$.pipe(
			mergeMap((pnValue: number) => {
				if (this.observableParams.value?.colors) {
					const lsColor: string | undefined = this.observableParams.value?.colors[pnValue];
					if (lsColor)
						return of(`background-color: var(${lsColor}); color: var(${lsColor}-contrast);`);
				}

				return of("background-color: var(--ion-color-primary); color: var(--ion-color-primary-contrast);");
			})
		);
	}

	//#endregion
}