import { AfterViewInit, ChangeDetectionStrategy, Component, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { secure } from '../../../utils/rxjs/operators/secure';
import { ILayoutParamsComponent } from '../../models/ilayout-params-component';
import { IStackLayoutParams } from '../../models/istack-layout-params';
import { LayoutBase } from '../../models/layout-base';

@Component({
	selector: 'calao-stack-layout',
	templateUrl: './stack-layout.component.html',
	styleUrls: ['./stack-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackLayoutComponent extends LayoutBase<IStackLayoutParams> implements AfterViewInit {

	//#region FIELDS

	@ViewChildren("wrapper", { read: ViewContainerRef }) private moTabContainers?: QueryList<ViewContainerRef>;

	//#endregion

	//#region METHODS

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		combineLatest([
			this.observableParams.value$,
			this.moTabContainers.changes.pipe(startWith({}))
		]).pipe(
			tap(([poParams]: [IStackLayoutParams | undefined, any]) => {
				this.moTabContainers.forEach((poItem: ViewContainerRef, pnIndex: number) => {
					this.clearLayout(poItem);
					if (ArrayHelper.hasElements(poParams?.components)) {
						const loComp: ILayoutParamsComponent | undefined = poParams.components[pnIndex];

						if (loComp)
							this.addComponent(loComp, poItem);
					}
				});
			}),
			secure(this)
		).subscribe();
	}

	//#endregion

}
