import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { IActionButtonFieldNavigationParams } from '../../../../../model/forms/actionButtonFields/IActionButtonFieldNavigationParams';
import { ENavigationType } from '../../../../../model/navigation/ENavigationType';
import { IAddress } from '../../../../../model/navigation/IAddress';
import { ContactAddressPipe } from '../../../../../pipes/contactAddress.pipe';
import { FormsService } from '../../../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { NavigationService } from '../../../../../services/navigation.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

@Component({
	templateUrl: './actionButtonFieldBase.component.html'
})
export class ActionButtonFieldNavigationComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldNavigationParams> {

	//#region FIELDS

	/** Latitude fournie par le modèle pour afficher la carte. */
	private mnLatitude: string | number;
	/** Longitude fournie par le modèle pour afficher la carte. */
	private mnLongitude: string | number;
	/** Indique si les coordonnées GPS sont correctes ou non. */
	private mbAreCorrectCoordinates: boolean;
	/** Adresse formattée du modèle. */
	private msAddress: string;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcNavigation: NavigationService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioContactAddressPipe: ContactAddressPipe<IAddress>,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	private initCoordinates(): void {
		this.mnLatitude = this.model[this.params.specParams.latitudeField];
		this.mnLongitude = this.model[this.params.specParams.longitudeField];

		// Les coordonnées sont correctes si les deux sont renseignées ; peuvent être un nombre ou un nombre en chaîne de caractères.
		this.mbAreCorrectCoordinates = (NumberHelper.isValid(this.mnLatitude) || NumberHelper.isStringNumber(this.mnLatitude)) &&
			(NumberHelper.isValid(this.mnLongitude) || NumberHelper.isStringNumber(this.mnLongitude));
	}

	private initAddress(): void {
		const loAddress: IAddress = {
			city: this.model[this.params.specParams?.cityField],
			zipCode: this.model[this.params.specParams?.postalCodeField],
			street: this.model[this.params.specParams?.streetField]
		};

		this.msAddress = this.ioContactAddressPipe.transform(loAddress, false);
	}

	/** Lance la navigation GPS sur l'application aux coordonnées présentes dans le modèle. */
	public override action(): void {
		this.initCoordinates();
		this.initAddress();
		this.isvcNavigation.resetConfig(ENavigationType.waze);

		if (this.mbAreCorrectCoordinates) { // Si les coordonnées GPS sont ernseignées.
			this.isvcNavigation.navigateToCoordinates(this.model[this.params.specParams.latitudeField], this.model[this.params.specParams.longitudeField])
				.pipe(takeUntil(this.fieldDestroyed$))
				.subscribe(
					() => { },
					poError => console.error("ABFNAV::", poError)
				);
		}
		else if (!StringHelper.isBlank(this.msAddress)) // Si une adresse est renseignée.
			this.isvcNavigation.navigateByAddress(this.msAddress);
		else { // Pas de coordonnées GPS ni d'adresse.
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({ header: "Naviguer", message: ActionButtonFieldBaseComponent.C_UNDEFINED_COORDINATES_AND_ADDRESS_TEXT })
			);
		}
	}

	//#endregion
}