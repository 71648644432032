import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatChip, MatChipSelectionChange } from '@angular/material/chips';
import { takeUntil } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../../../helpers/objectHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { ESelectorDisplayMode } from '../../../../selector/selector/ESelectorDisplayMode';
import { FieldBase } from '../../../models/FieldBase';
import { ISelectFieldParams } from '../../../models/ISelectFieldParams';
import { ISelectTag } from '../../../models/ISelectTag';
import { ITemplateOptions } from '../../../models/fieldComponent/ITemplateOptions';
import { ISelectField } from '../../../models/fieldComponent/specifications/ISelectField';
import { FormsService } from '../../../services/forms.service';

@Component({
	selector: 'formly-field-ion-select',
	templateUrl: './select-field.component.html',
	styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent extends FieldBase<Array<any> | any> implements OnInit, OnDestroy, ISelectField {

	//#region FIELDS

	/** Texte par défaut à afficher pour le bouton d'annulation. */
	private static readonly C_DEFAULT_CANCEL_TEXT: string = "Annuler";
	private static readonly C_LOG_ID = "SLCT.FLD.C::";

	//#endregion

	//#region PROPERTIES

	/** Valeur(s) sélectionnée(s) dans le select. */
	public values: Array<any>;
	/** Libellé (valeur textuelle) de la valeur sélectionnée. Si plusieurs valeurs sont sélectionnées, les libellés sont concaténés et séparés par une virgule. */
	public label: string;
	/** Libellés, sous forme de array (contrairement à label qui concatène les valeurs s'il en existe plusieurs de sélectionnées). */
	public selectLabels: Array<string> = [];
	/** Variable qui contient l'icone à afficher en RO SI !multiselect */
	public iconName: string;
	/** Nom du champ indiquant la valeur du label. Utilisé pour la rétrocompatibilité. */
	public labelFieldName = "label";
	/** Tags à manipuler dans l'UI. */
	public selectedTags: MatChip[] | MatChip;
	/** Texte à afficher si on souhaite annuler une sélection de Tag. */
	public cancelText: string = SelectFieldComponent.C_DEFAULT_CANCEL_TEXT;
	/** Capacité à afficher une valeur dans le select, même si elle n'est pas présente dans le form descripteur, `true` par défaut. */
	public keepUnknownData = true;

	public params: ISelectFieldParams;
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<ISelectFieldParams>;

	//#endregion

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		/** Service de rafraîchissement de la vue. */
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		if (this.params.displayStyle === "icon")
			this.iconName = this.params.options.find((poOption: ISelectTag) => poOption.value === this.value)?.icon;

		this.stateChanges
			.pipe(takeUntil(this.fieldDestroyed$))
			.subscribe(_ => this.field.focus = this.params.displayStyle !== "tags");

		this.initValue();

		this.detectChanges();
	}

	private initValue(): void {
		if (!this.params.unknownValue)
			this.params.unknownValue = {};

		// Si un paramètre est défini pour la gestion des valeurs inconnues, alors on l'applique.
		if (this.params.unknownValue.keep !== undefined)
			this.keepUnknownData = this.params.unknownValue.keep;

		if (this.fieldValue) { // Si modèle renseigné.
			if (this.fieldValue instanceof Array)
				this.values = this.fieldValue;
			else if (this.params.keepData)
				this.values = [this.fieldValue.value];
			else
				this.values = [this.fieldValue];
		}
		else if (this.params.defaultValue !== undefined) { // Si modèle non renseigné et valeur par défaut renseignée.
			this.fieldValue = this.params.defaultValue;

			if (this.params.keepData)
				this.values = [this.params.defaultValue.value];
			else
				this.values = [this.params.defaultValue];
		}
		else { // Initialisation des tableaux pour qu'ils ne soient pas undefined.
			this.values = [];
			this.fieldValue = [];
		}

		if (this.params.labelFieldName) // Ancien nom des champs, permet la rétrocompatibilité.
			this.labelFieldName = this.params.labelFieldName;
		else if (this.params.labelName) // Nom actuel des champs.
			this.labelFieldName = this.params.labelName;

		// Force les attributs "value" à être au format `string`.
		if (this.params.forceStringValue)
			this.handleForceString();

		// Si on doit gérer les valeurs inconnues, et que la valeur n'est pas dans le tableau des options, ni est un objet vide.
		this.handleUnknownOptions();

		this.ignoreAccentInOptions();

		if (this.params.multiple)
			this.selectLabels = this.getSelectedTagsLabels();

		if (this.params.readOnly)
			this.fillLabelInsteadValues();

		if (!StringHelper.isBlank(this.params.cancelText))
			this.cancelText = this.params.cancelText;
	}

	/** Une modification de sélection du modèle a été réalisée.
	 * @param poNewData Nouvelle valeur / Tableau des nouvelles valeur sélectionnée(s).
	 */
	public change(poNewData: any | any[]): void {
		const laNewValues: any[] = poNewData instanceof Array ? poNewData : [poNewData];

		if (!ArrayHelper.areArraysEqual(this.values, laNewValues)) {
			this.values = laNewValues;

			if (this.params.keepData) {
				const laSelectOptions: Array<any> = this.getSelectOptionsFromValues();
				this.fieldValue = laSelectOptions.length === 1 ? ArrayHelper.getFirstElement(laSelectOptions) : laSelectOptions;
			}
			else
				this.fieldValue = this.values.length === 1 ? ArrayHelper.getFirstElement(this.values) : this.values;

			this.markAsDirty();
			this.detectChanges();
		}
	}

	public onCheckedChange(poEvent: Event, poNewValue: string | number): void {
		const loEvent = poEvent as CustomEvent;
		if (loEvent.detail.checked) {
			if (!this.params.multiple) {
				this.fieldValue = poNewValue;
				this.values = [poNewValue];
			} else {
				if (!ArrayHelper.hasElements(this.fieldValue)) {
					this.fieldValue = [poNewValue];
				}
				else {
					if (!this.fieldValue.some((poValue: any) => poValue === poNewValue))
						this.fieldValue.push(poNewValue);
				}
			}
		}
		else {
			if (!this.params.multiple) {
				this.fieldValue = undefined;
				this.values = [];
			} else {
				if (this.fieldValue.some((poValue: any) => poValue === poNewValue))
					ArrayHelper.removeElementsByFinder(this.fieldValue, (poValue: any) => poValue === poNewValue);
			}

			if (!ArrayHelper.hasElements(this.fieldValue))
				this.fieldValue = undefined;
		}

		this.markAsDirty();
		this.detectChanges();
	}

	private getSelectOptionsFromValues(): any[] {
		return (this.params.options as any[]).filter((poItem: any) => this.values.some((poValue: any) => poItem.value === poValue));
	}

	/** Remplit le label avec le label du select au lieu de la valeur du select, pour la lisibilité de l'utilisateur. */
	private fillLabelInsteadValues(): void {
		if (this.fieldValue instanceof Array) { // Si on a un tableau d'options de select dans le modèle (multiselect).
			const laTmpOptions: Array<string> = [];

			// Remplit le tableau temporaire des différents labels nécessaires.
			for (let lnIndex = 0, lnLength = (this.params.options as Array<any>).length; lnIndex < lnLength; ++lnIndex) {
				const loOption = this.params.options[lnIndex];

				for (let lnModelIndex = 0, lnModelLength = this.fieldValue.length; lnModelIndex < lnModelLength; ++lnModelIndex) {
					const loModelValue: any = this.fieldValue[lnModelIndex]; // nombre ou string.

					if (loOption.value === loModelValue) {
						laTmpOptions.push(loOption[this.labelFieldName]);
					}
				}
			}

			if (ArrayHelper.hasElements(laTmpOptions)) { // Si au moins un label est présent.
				this.label = ArrayHelper.getFirstElement(laTmpOptions);

				for (let lnIndex = 1, lnLength = laTmpOptions.length; lnIndex < lnLength; ++lnIndex) {
					this.label += `, ${laTmpOptions[lnIndex]}`; // On construit une chaîne de caractères sous la forme "label1, label2, [...]".
				}
			}
		}
		else if (this.fieldValue) { // Si on a une unique option de select dans le modèle.
			for (let lnIndex = (this.params.options as Array<any>).length - 1; lnIndex >= 0; --lnIndex) {
				const loOption: any = this.params.options[lnIndex]; // Nombre ou string.

				if ((this.params.keepData && loOption.value === this.fieldValue.value) || loOption.value === this.fieldValue) {
					this.label = loOption[this.labelFieldName];
					this.iconName = loOption.icon;
				}
			}
		}
	}

	/** Fais les tests nécessaires et ajoute les valeurs inconnues si besoin. */
	private handleUnknownOptions(): void {
		// Si on ne doit pas garder les valeurs inconnues, ou si l'objet est vide, on ne fait rien.
		if (!this.keepUnknownData || ObjectHelper.isEmpty(this.fieldValue))
			return;

		if (this.params.multiple) {
			this.values.forEach((poElement: any) => {
				if (!this.isKeyInOptions(poElement))
					this.addUnknownOption(poElement);
			});
		}
		else {
			if (this.fieldValue instanceof Array ? ArrayHelper.hasElements(this.fieldValue) : true) {
				const lnOptionsIndex: number = this.getOptionsIndexFromValue(this.fieldValue);

				// Si la donnée du modèle correspond à une valeur des options, on modifie la donnée du modèle avec la clé des options pour normaliser.
				if (lnOptionsIndex > -1)
					this.change([this.params.options[lnOptionsIndex].value]);
				else if (!this.isKeyInOptions(this.fieldValue)) // Si la donnée du modèle n'est pas une clé des options, il faut ajouter une option inconnue.
					this.addUnknownOption(this.fieldValue);
			}
		}
	}

	/** Force à lire en tant que string tous les attributs "value".
	* La ou les valeurs sélectionnées, et les options.
	*/
	private handleForceString(): void {
		// Dans tous les cas, on ne stringifie pas, si c'est déjà un string.

		// Stringifie this.value.
		if (this.params.multiple) {
			if (this.params.keepData) {
				// Values correspond à des objets complexes.
				this.values.forEach((poValue: any) => {
					if (typeof poValue !== "string" && typeof poValue.value !== "string")
						poValue.value = JSON.stringify(poValue.value);
				});
			}
			else
				this.fieldValue = this.fieldValue.map((poValue: any) => typeof poValue !== "string" ? JSON.stringify(poValue) : poValue);
		}
		else {
			if (this.params.keepData && typeof this.fieldValue.value !== "string")
				this.fieldValue.value = JSON.stringify(this.fieldValue.value); // Values correspond à des objets complexes.
			else if (!this.params.keepData && typeof this.fieldValue !== "string")
				this.fieldValue = JSON.stringify(this.fieldValue);
		}

		// Stringifie this.values.
		this.values = this.values.map((poValue: any) => {
			if (this.params.multiple && typeof poValue !== "string" && typeof poValue.value !== "string")
				poValue.value = JSON.stringify(poValue.value);
			else if (!this.params.multiple && typeof poValue !== "string")
				poValue = JSON.stringify(poValue);

			return poValue;
		});

		// Stringifie this.options.
		this.params.options = this.params.options.map((poValue: any) => {
			if (typeof poValue !== "string" && typeof poValue.value !== "string")
				poValue.value = JSON.stringify(poValue.value);
			return poValue;
		});
	}

	/** Si la valeur du select n'est pas dans les `params.options`, on l'y ajoute. */
	private addUnknownOption(poUnknownValue: any): void {
		const loNewUnknwonValue: ISelectTag = ObjectHelper.isPrimitive(poUnknownValue) ? { label: poUnknownValue } : poUnknownValue;

		if (this.params.unknownValue.params) {
			for (const loKeyParam in this.params.unknownValue.params) {
				loNewUnknwonValue[loKeyParam] = this.params.unknownValue.params[loKeyParam];
			}
		}

		// Dans le cas où on n'a pas de clé pour la valeur inconnue, on lui assigne une clé égale à la valeur par défaut.
		if (loNewUnknwonValue.value === undefined || loNewUnknwonValue === null)
			loNewUnknwonValue.value = loNewUnknwonValue.label;

		this.params.options.push(loNewUnknwonValue);
	}

	/** Retourne `true` si la clé de la donnée du modèle est une clé des options disponibles, `false` sinon. */
	private isKeyInOptions(poKey: string | number | { value: string | number }): boolean {
		// Si la clé est une chaîne de caractères ou un nombre, on la compare directement aux autres options.
		if (typeof poKey === "string" || typeof poKey === "number")
			return this.params.options.some((poTag: ISelectTag) => poTag.value === poKey);
		else // Sinon le champ value est un type complexe, et on le compare à son attribut "value" (qui est un string ou un number).
			return this.params.options.some((poTag: ISelectTag) => poTag.value === poKey.value);
	}

	/** Retourne l'index de l'option associée à la valeur du modèle, `-1` si non trouvé.
	 * @param poValue Valeur dont il faut vérifier si elle est présente dans les options.
	 */
	private getOptionsIndexFromValue(poValue: string | number | { [labelFieldName: string]: string }): number {
		// Si la valeur de la donnée du modèle est une chaîne de caractères ou un nombre, on la compare directement aux options.
		if (typeof poValue === "string" || typeof poValue === "number") { // Cas `!keepData`.
			const lsValue: string = poValue.toString().toLowerCase().trim();
			return this.params.options.findIndex((poTag: ISelectTag) => poTag[this.labelFieldName]?.toLowerCase().trim() === lsValue);
		}
		else { // Sinon c'est un champs complexe, cas `keepData === true`, on le compare à son attribut "label".
			if (poValue[this.labelFieldName]) {
				const lsValue: string = poValue[this.labelFieldName].toLowerCase().trim();
				return this.params.options.findIndex((poTag: ISelectTag) => poTag[this.labelFieldName]?.toLowerCase().trim() === lsValue);
			}
			else {
				console.error(`${SelectFieldComponent.C_LOG_ID}Can not read value of "${JSON.stringify(poValue)}" because field "${this.labelFieldName}" not exist !`);
				return -1;
			}
		}
	}

	/** Événement qui survient lorsqu'un tag sélectionné.
	 * @param poEvent Evénement de changement d'état de la sélection.
	 * @param psTagLabel Label du tag cliqué.
	 */
	public onTagSelectionChange(poEvent: MatChipSelectionChange, psTagLabel: string, poValue: any): void {
		/** Index du tag cliqué, dans le tableau du modèle. */
		let lnTagIndex = -1;

		if (!this.fieldValue)
			this.fieldValue = [];

		if (this.params.keepData) {
			for (let lnIndex = 0; lnIndex < this.fieldValue.length; ++lnIndex) {
				if (this.fieldValue[lnIndex][this.labelFieldName] === psTagLabel)
					lnTagIndex = lnIndex;
			}
		}
		else
			lnTagIndex = this.fieldValue.indexOf(poValue);

		// Suppression du tag cliqué si déjà présent dans le modèle, sinon on l'ajoute.
		if (poEvent.selected && lnTagIndex < 0) {
			// Si keepData, alors on enregistre un objet, sinon la valeur uniquement.
			if (this.params.keepData)
				this.fieldValue.push({ [this.labelFieldName]: psTagLabel, value: poValue });
			else
				this.fieldValue.push(poValue);
		}
		else if (!poEvent.selected)
			this.fieldValue.splice(lnTagIndex, 1);

		// On met à jour les labels sélectionnés (pour l'UI).
		const laNewSelectedLabels: string[] = this.getSelectedTagsLabels();
		if (!ArrayHelper.areArraysEqual(this.selectLabels, laNewSelectedLabels)) {
			this.selectLabels = laNewSelectedLabels;
			this.markAsDirty();
		}

		this.detectChanges();
	}

	/** Tableau des labels des tags sélectionnés. */
	private getSelectedTagsLabels(): string[] {
		/** Tableau de toutes les options possibles. */
		const laOptions: Array<ISelectTag> = this.params.options as Array<ISelectTag>;
		/** Liste des labels sélectionnés. */
		const laLabelSelected: string[] = [];

		if (this.params.keepData) {
			// Si c'est une modification et si keepData, on récupère les labels stockés dans le modèle, car il contient des objets complexes.
			if (this.fieldValue) {
				for (let lnIndex = 0; lnIndex < this.fieldValue.length; ++lnIndex) {
					laLabelSelected.push(this.fieldValue[lnIndex][this.labelFieldName]);
				}
			}
		}
		else {
			// Sinon, on compare avec la valeur, qui est un entier.
			laOptions.forEach((tag: ISelectTag) => {
				if (this.fieldValue.includes(tag.value))
					laLabelSelected.push(tag[this.labelFieldName]);
			});
		}

		return laLabelSelected;
	}

	/** Met à jour les options, en ajoutant/supprimant les accents pour correspondre aux valeurs sélectionnées.
	 * ex: Par exemple, si une valeur sélectionnée est "Région", mais que les options contiennent l'attribut "Region". Alors un accent sera rajouté à l'option.
	 */
	private ignoreAccentInOptions(): void {
		if (this.params.keepData) {
			if (this.params.multiple) {
				for (let lnIndex = 0; lnIndex < this.fieldValue.length; ++lnIndex) {
					this.params.options = this.params.options.map((poOption: ISelectTag): ISelectTag => {

						if ((poOption[this.labelFieldName] as string).localeCompare(this.fieldValue[lnIndex][this.labelFieldName] as string, "fr", { sensitivity: "base" }) === 0)
							return this.fieldValue[lnIndex];
						else
							return poOption;
					});
				}
			}
			else {
				// TODO à implémenter quand ça sera nécessaire et qu'on aura des T.U.
			}
		}
	}

	public hasValue(): boolean {
		if (this.fieldValue instanceof Array)
			return ArrayHelper.hasElements(this.fieldValue);
		else
			return this.fieldValue;
	}

	//#endregion

}