<ng-container *ngIf="fieldValue$ | async as value">
	<ion-item *ngIf="!(observableIsListMode.value$ | async) && !params.divider" style="--background: transparent;"
		lines="none" [class]="params.cssClass? 'id-client' : 'ion-no-padding just-flex'">
		<ion-label *ngIf="to.label" style="text-align: left;"
			[class]="params.labelCssClass ? params.labelCssClass : 'label-group txt10px margin-b min-w width50pc ion-text-wrap'"
			position="stacked">
			{{ to.label }}
		</ion-label>
		<ion-icon *ngIf="to.icon" class="margelect" [name]="to.icon"></ion-icon>
		<div *ngIf="!isBlank(value)" class="just-flex marg-infos">
			<ng-container [ngSwitch]="true">
				<a *ngSwitchCase="observableIsPhone.value$ | async" href="tel:{{ value }}">{{ value }}</a>
				<a *ngSwitchCase="observableIsMail.value$ | async" href="mailto:{{ value }}">{{ value }}</a>
				<a *ngSwitchCase="observableIsUrl.value$ | async" href="{{ value }}" target="_blank">{{ value }}</a>
				<span *ngSwitchDefault style="white-space: pre-wrap;" [class]="to.className">{{value}}</span>
			</ng-container>
		</div>
	</ion-item>

	<!-- Label avec divider -->
	<ion-item *ngIf="!(observableIsListMode.value$ | async) && params.divider" style="--background: transparent;"
		lines="none" [class]="params.cssClass? 'id-client' : 'ion-no-padding just-flex'">
		<div class="label-with-divider">
			<div class="label">
				<ion-label *ngIf="to.label" style="text-align: left;"
					[class]="params.labelCssClass ? params.labelCssClass : 'label-group txt10px margin-b min-w width50pc ion-text-wrap'"
					position="stacked">
					{{ to.label }}
				</ion-label>
			</div>
			<div class="divider"></div>
		</div>
		<ion-icon *ngIf="to.icon" class="margelect" [name]="to.icon"></ion-icon>
		<div *ngIf="!isBlank(value)" class="just-flex marg-infos">
			<ng-container [ngSwitch]="true">
				<a *ngSwitchCase="observableIsPhone.value$ | async" href="tel:{{ value }}">{{ value }}</a>
				<a *ngSwitchCase="observableIsMail.value$ | async" href="mailto:{{ value }}">{{ value }}</a>
				<a *ngSwitchCase="observableIsUrl.value$ | async" href="{{ value }}" target="_blank">{{ value }}</a>
				<span *ngSwitchDefault style="white-space: pre-wrap;" [class]="to.className">{{value}}</span>
			</ng-container>
		</div>
	</ion-item>

	<div *ngIf="observableIsListMode.value$ | async" class="dis-flex">
		<div *ngIf="to.label" class="txt10px min-w width50pc">{{to.label}}</div>
		<div *ngFor="let item of value" [class]="cssClass">
			<span>{{item}}</span>
		</div>
	</div>
</ng-container>