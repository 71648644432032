import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ISectorsListFieldParams } from '../../../../model/forms/ISectorsListFieldParams';
import { ISector } from '../../../../modules/sectors/models/isector';
import { SitesService } from '../../../../modules/sites/services/sites.service';
import { FormsService } from '../../../../services/forms.service';
import { Store } from '../../../../services/store.service';

@Component({
	templateUrl: './sectors-list-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorsListFieldComponent extends FieldBase<ISector[]> implements OnInit {

	//#region FIELDS

	private maSiteSectors: ISector[];
	private maEditedSectors: ISector[];

	//#endregion

	//#region PROPERTIES

	/** Objet contenant les différents paramètres que le composant peut gérer. */
	public params: ISectorsListFieldParams;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSites: SitesService,
		private readonly isvcStore: Store,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;
		this.params.siteId = this.model._id;

		if (this.params.readOnly === undefined)
			this.params.readOnly = this.to.readonly;

		this.isvcSites.getSiteSectors(this.model._id, true)
			.pipe(
				tap((paResults: ISector[]) => {
					this.maSiteSectors = Array.from(paResults);
					this.fieldValue = Array.from(paResults);
					this.detectChanges();
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				tap(() => this.saveSectors()),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	public onSectorsChanged(paSectors: ISector[]): void {
		this.maEditedSectors = paSectors;
		this.markAsDirty();
		this.detectChanges();
	}

	public saveSectors(): void {
		if (this.maEditedSectors) {
			const laRemovedSectors: ISector[] = ArrayHelper.getDifferences(this.maSiteSectors, this.maEditedSectors) ?? [];
			const laNewSectors: ISector[] = ArrayHelper.getDifferences(this.maEditedSectors, this.maSiteSectors) ?? [];

			laRemovedSectors.forEach((poSector: ISector) => delete poSector.siteId);
			laNewSectors.forEach((poSector: ISector) => poSector.siteId = this.model._id);

			this.isvcStore.putMultipleDocuments([...laRemovedSectors, ...laNewSectors])
				.toPromise()
				.catch((poError) => console.error(poError));
		};
	}

	//#endregion
}