import { Component, OnInit } from '@angular/core';
import { IdHelper } from '../../../../../../helpers/idHelper';
import { EPrefix } from '../../../../../../model/EPrefix';
import { FieldBase } from '../../../../../../model/forms/FieldBase';
import { FormsService } from '../../../../../../services/forms.service';
import { IRole } from '../../../../../permissions/models/irole';

@Component({
	selector: 'calao-roles-selector-field',
	templateUrl: './roles-selector-field.component.html',
	styleUrls: ['./roles-selector-field.component.scss'],
})
export class RolesSelectorFieldComponent extends FieldBase<string[]> implements OnInit {

	//#region METHODS

	constructor(protected override readonly isvcForms: FormsService) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
	}

	public onRolesSelectionChanged(paSelection: IRole[]) {
		this.fieldValue = paSelection.map((poRole: IRole) => IdHelper.extractIdWithoutPrefix(poRole._id, EPrefix.role));
	}

	//#endregion METHODS

}
