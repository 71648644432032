import { Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { IFormDescriptor } from '../../../../../../model/forms/IFormDescriptor';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

@Component({
	selector: "preview-page",
	templateUrl: './preview-page.component.html',
	styleUrls: ['./preview-page.component.scss']
})

/** Permet de voir le rendu du FormDefinition grâce au calaoForm.
 * Le form-data-simulator permet de voir les données saisies dans le rendu.
 * Le form-preview-json permet de voir le json générant le rendu.
 */
export class PreviewPageComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public designableDescriptor: IDesignableDescriptor;
	public formDescriptor: IFormDescriptor;

	//#endregion

	//#region METHODS

	constructor(private isvcDesign: DesignService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poDesignableDescriptor: IDesignableDescriptor) => {
					this.designableDescriptor = poDesignableDescriptor;
					this.formDescriptor = this.isvcDesign.convertDesignableDescriptorToFormDescriptor(this.designableDescriptor);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}