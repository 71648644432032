<calao-filter-bar #filterBar [filterValues]="observableFilterValues.value$ | async"
	(onFilterValuesChange)="onFilterValuesChange($any($event))"
	(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))"
	[nbTmpResults]="observableNbTmpResults.value$ | async">
	<calao-filter-bar-main-content>
		<calao-filter-bar-item key="fullName">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un contact" [value]="observableValue.value$ | async"
					(onValueChange)="observableValue.value = $event" [filterBadgeLabel]="nbFiltersLabel$ | async">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-tags>
			<calao-filter-bar-tags-tag [label]="groupsFiltersLabel$ | async">
			</calao-filter-bar-tags-tag>
		</calao-filter-bar-tags>
	</calao-filter-bar-main-content>
	<calao-filter-bar-modal-content #modal>
		<calao-filter-bar-item key="fullName">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un contact" [value]="observableValue.value$ | async"
					(onValueChange)="observableValue.value = $event" [hasModal]="false">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="groups" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Groupes</calao-filter-bar-item-title>
				<calao-groups-checklist *ngIf="modal.observableIsModalOpen.value$ | async" loadAllGroups [hideTitle]="true"
					(onSelectionChanged)="observableValue.value = $event" [groupSelections]="observableValue.value$ | async"
					[displayMode]="selectorDisplayMode.alert" hideCreateButton ignoreRoles></calao-groups-checklist>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-modal-content>
</calao-filter-bar>