import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ConflictListMarkerComponent } from './components/conflict-list-marker/conflict-list-marker.component';
import { ConflictsService } from './services/conflicts.service';

@NgModule({
	declarations: [ConflictListMarkerComponent],
	exports: [ConflictListMarkerComponent],
	imports: [
		CommonModule,
		IonicModule
	],
	providers: [ConflictsService]
})
export class ConflictsModule { }