export abstract class TelephoneHelper {

	//#region FIELDS



	//#endregion

	//#region PROPERTIES

	public static readonly C_PHONE_NUMBER_PATTERN = /^\s*0?\s*[1-9](\s*\d){8}\s*$/;
	public static readonly C_MOBILE_PHONE_NUMBER_PATTERN = /^\s*0?\s*(6|7)(\s*\d){8}\s*$/;
	public static readonly C_E164_PHONE_NUMBER_PATTERN = /^\s*\+(\s*\d){2}(\s*\d){9}\s*$/;

	//#endregion

	//#region METHODS

	/** Formate le numéro de téléphone au format E.164.
	 * @param psPhoneNumber 
	 */
	public static formatPhoneNumberE164(psPhoneNumber: string): string {
		if (psPhoneNumber.includes("+"))
			return psPhoneNumber;
		else {
			let lsPhoneNumber: string = psPhoneNumber;
			if (!psPhoneNumber.startsWith("0"))
				lsPhoneNumber = `0${lsPhoneNumber}`;
			return `+33${lsPhoneNumber.replace(/\s/g, "").substring(1)}`;
		}
	}

	/** Formate le numéro de téléphone au format `0601020304`.
	 * @param psPhoneNumber 
	 */
	public static formatPhoneNumber10Digits(psPhoneNumber: string): string {
		const lsFormattedPhoneNumber: string = psPhoneNumber.replace(/\s/g, "");

		if (this.C_E164_PHONE_NUMBER_PATTERN.test(lsFormattedPhoneNumber) || this.C_PHONE_NUMBER_PATTERN.test(lsFormattedPhoneNumber)) {
			if (lsFormattedPhoneNumber.startsWith("+33"))
				return lsFormattedPhoneNumber.replace("+33", "0");
			else if (lsFormattedPhoneNumber.startsWith("0"))
				return lsFormattedPhoneNumber;
			else
				return `0${lsFormattedPhoneNumber}`;
		}
		else
			throw new Error(`Invalid format ${psPhoneNumber}`);
	}

	//#endregion

}
