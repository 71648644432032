import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { VirtualScrollComponent } from './components/virtual-scroll/virtual-scroll.component';
import { AutoCloseSlideItemDirective } from './directives/auto-close-slide-item.directive';
import { AutoResizeDirective } from './directives/auto-resize.directive';
import { VariableSizeDirective } from './directives/variable-size.directive';

@NgModule({
	declarations: [AutoCloseSlideItemDirective, AutoResizeDirective, VirtualScrollComponent, VariableSizeDirective],
	exports: [AutoCloseSlideItemDirective, AutoResizeDirective, VirtualScrollComponent, VariableSizeDirective],
	imports: [
		CommonModule,
		ScrollingModule,
		IonicModule
	]
})
export class VirtualScrollModule { }