<ion-item-sliding #itemSliding>

	<!-- Le ion-item ne peut pas être dans le composant parent, sinon le ion-button ne peut pas être slotté. -->
	<ion-item lines="full">
		<!-- Le ng-content le peut pas être wrappé dans un calao-sliding-item-content sinon le style du ion-item ne sera pas appliqué (composant intermédiaire). -->
		<ng-content></ng-content>
		<ion-button *ngIf="observableContextualSlot.value$ | async" calaoSlidingItemOptionsToggle color="medium"
			fill="clear" slot="end" class="contextual-slot">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>
	</ion-item>

	<!-- Zone des boutons cachée derrière le slide de l'item. -->
	<ion-item-options side="end" (ionSwipe)="onSwipingEvent(itemSliding)">
		<ng-content select="calao-sliding-list-item-options"></ng-content>
	</ion-item-options>

</ion-item-sliding>