import { Injectable, OnDestroy } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { IDestroyable } from "../../utils/lifecycle/models/IDestroyable";

@Injectable()
export class DestroyableServiceBase implements IDestroyable, OnDestroy {

	//#region FIELDS

	private readonly moDestroySubject = new ReplaySubject<void>(1);

	//#endregion

	//#region PROPERTIES

	/** Permet de s'abonner à l'event disant que le service est détruit */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** Indique si le service est détruit. */
	public get destroyed(): boolean { return this.mbDestroyed; }

	//#endregion PROPERTIES

	//#region METHODS

	public ngOnDestroy(): void {
		this.mbDestroyed = true;
		this.moDestroySubject.next();
		this.moDestroySubject.complete();
	}

	//#endregion METHODS

}