import { Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { IFieldComponent } from '../../../../../../model/forms/fieldComponent/IFieldComponent';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

@Component({
	selector: "widget-adder",
	templateUrl: './widget-adder.component.html',
	styleUrls: ['./widget-adder.component.scss']
})
/** Permet d'ajouter un champ à la définition sélectionnée.
 * Il sera visible dans le FormEditor.
 */
export class WidgetAdderComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public allFields: ReadonlyArray<IFieldComponent<any, any>> = DesignService.C_AVAILABLE_FIELD_COMPONENTS;
	public designableDescriptor: IDesignableDescriptor;

	//#endregion

	//#region METHODS

	constructor(private isvcDesignService: DesignService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcDesignService.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poForm: IDesignableDescriptor) => this.designableDescriptor = poForm),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet d'ajouter un champ à la definition.
	 * @param psType Type de champ à ajouter.
	 */
	public onAddFieldToSelectedDefinition(psType: string): void {
		this.isvcDesignService.addFieldToSelectedDefinition(this.designableDescriptor, psType);
	}

	//#endregion

}