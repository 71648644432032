import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SlidingItemOptionsToggleDirective } from './directives/sliding-item-options-toggle.directive';
import { SlidingListItemOptionsComponent } from './sliding-list-item-options/sliding-list-item-options.component';
import { SlidingListItemComponent } from './sliding-list-item/sliding-list-item.component';

const components = [
	SlidingListItemComponent,
	SlidingListItemOptionsComponent
];

const directives = [
	SlidingItemOptionsToggleDirective
];

@NgModule({
	declarations: [...components, ...directives],
	imports: [
		CommonModule,
		IonicModule
	],
	exports: [...components, ...directives]
})
export class SlidingListModule { }