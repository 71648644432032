<div class="general-contain">
	<ng-container *ngIf="!designableDescriptor.id">
		<h3>FormDescriptor: </h3>
	</ng-container>
	<ng-container *ngIf="designableDescriptor.id as id">
		<h3>FormDescriptor: {{id}}</h3>
	</ng-container>
	<button (click)="onPrepareDescriptor()">Open Descriptor</button>
	<ng-container *ngIf="designableDescriptor.id as id">

		<button class="btn-success" (click)="onPrepareSaveDescriptor()">Save Descriptor</button>

		<div>
			<button (click)="onPrepareDefinition()">Open Definition</button>
			<button (click)="onPrepareListDef()" class="btn-alert">Edit ListDefinitions</button>
		</div>

	</ng-container>
</div>