import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringHelper } from '../../helpers/stringHelper';
import { UserData } from '../../model/application/UserData';
import { IContact } from '../../model/contacts/IContact';
import { NoCurrentUserDataError } from '../../model/errors/NoCurrentUserDataError';
import { ContactsService } from '../../services/contacts.service';

@Injectable({ providedIn: 'root' })
export class ContactResolver implements Resolve<IContact> {

	//#region METHODS

	constructor(private readonly isvcContacts: ContactsService, private readonly ioRouter: Router) { }

	/** @implements */
	public resolve(poRoute: ActivatedRouteSnapshot): Promise<IContact | undefined> {
		// On peut résoudre la route 'edit' ou ':contactId', cas normal, mais aussi 'new' dans le cas où on a un modèle à passer en paramètre.
		return StringHelper.isBlank(poRoute.params.contactId) ? Promise.resolve(this.getNewContact()) : this.getEditContactAsync(poRoute);
	}

	/** Récupère le contact à éditer, `undefined` si non trouvé.
	 * @param poSnapshot Prise de la route utilisée pour naviguer.
	 */
	private getEditContactAsync(poSnapshot: ActivatedRouteSnapshot): Promise<IContact | undefined> {
		const lsContactId: string = poSnapshot.params.contactId;
		let loGet$: Observable<IContact>;

		if (lsContactId === ContactsService.C_CURRENT_USER_ID_FOR_ROUTE) {
			if (UserData.current) {
				loGet$ = this.isvcContacts.getContactFromUserId(UserData.current.name)
					.pipe(
						map((poUserContact: IContact) => {
							if (!poUserContact && UserData.current?.name) { // Si le contact utilisateur n'existe pas dans la base de données, c'est une création, sinon c'est une édition.
								poUserContact = {
									_id: ContactsService.getContactIdFromUserId(UserData.current.name),
									email: UserData.current.email,
									firstName: UserData.current.firstName,
									lastName: UserData.current.lastName
								};
							}

							return poUserContact;
						})
					);
			}
			else
				loGet$ = throwError(new NoCurrentUserDataError());
		}
		else
			loGet$ = this.isvcContacts.getContact(lsContactId);

		return loGet$.toPromise();
	}

	/** Récupère le possible contact à créer (si on a un modèle à pré-remplir comme pour l'import de contact depuis l'appareil par exemple). */
	private getNewContact(): IContact | undefined {
		// Si un modèle est présent dans les paramètres de navigation alors création d'un contact avec un modèle pré-rempli.
		return this.ioRouter.getCurrentNavigation()?.extras?.state?.model;
	}

	//#endregion

}