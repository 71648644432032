import { IAuthentication } from '@calaosoft/osapp/model/IAuthentication';
import { IAppInfo } from '@calaosoft/osapp/model/application/IAppInfo';
import { EAuthenticationAction } from '@calaosoft/osapp/model/authenticator/EAuthenticationAction';
import { IBackgroundTask } from '@calaosoft/osapp/model/backgroundTask/IBackgroundTask';
import { IConfigOneSignal } from '@calaosoft/osapp/model/config/IConfigOneSignal';
import { IConversationConfig } from '@calaosoft/osapp/model/config/IConversationConfig';
import { EContactSelectionTab } from '@calaosoft/osapp/model/contacts/econtact-selection-tab';
import { ContactEntityBuilder } from '@calaosoft/osapp/model/entities/ContactEntityBuilder';
import { ConversationEntityBuilder } from '@calaosoft/osapp/model/entities/ConversationEntityBuilder';
import { GroupEntityBuilder } from '@calaosoft/osapp/model/entities/GroupEntityBuilder';
import { EFormEngine } from '@calaosoft/osapp/model/forms/EFormEngine';
import { Credentials } from '@calaosoft/osapp/model/security/Credentials';
import { EUpdateProvider } from '@calaosoft/osapp/model/update/EUpdateProvider';
import { IUpdateConfig } from '@calaosoft/osapp/model/update/IUpdateConfig';
import { C_ADMINISTRATORS_ROLE_ID, C_WS_ADMINISTRATORS_ROLE_ID } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { EntityBuilder } from '@calaosoft/osapp/services/EntityBuilder';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { DomainService } from '@calaosoft/osapp/services/domain.service';
import { ISecurityConfig } from '@calaosoft/osapp/services/security/ISecurityConfig';
import { ConfigOption } from '@ngx-formly/core';
import { COMMERCIAL_ROLE_ID, CONVOYEUR_ROLE_ID, MERCHANDISEUR_ROLE_ID } from './app/app.constants';
import { APP_CORE_DATABASE_PREFIX, APP_ID, environment } from './environments/environment';
import { FORMLY_COMPONENT_REGISTRY } from './model/ComComponentRegister';
import { ReportEntityBuilder } from './model/ReportEntityBuilder';
import { CustomerEntityBuilder } from './model/customer-entity-builder';
import { JoinReportConversationFormConfig } from './model/reports/join-report-conversation-form-config';

const defaultHeartbeatIntervalMilliseconds = 60000;

export const appInfo: IAppInfo = {
	appId: APP_ID,
	formEngine: EFormEngine.formly,
	deviceId: "123",
	appName: "TexCom",
	supportEmail: "support.texcom@calaosoft.fr",
	firstName: "",
	lastName: "",
	login: "",
	applicationDatabaseId: `${APP_CORE_DATABASE_PREFIX}local_application_storage`,
	applicationServiceProvider: { provide: ApplicationService },
	domainServiceProvider: { provide: DomainService },
	useLinks: true,
	unlockRequired: environment.unlockRequired,
	coreDatabasePrefix: APP_CORE_DATABASE_PREFIX,
	changelogsUrl: "https://install.mobile.calaosoft.fr/public/texcom/changelog.html"
};

export const authentication: IAuthentication = {
	type: "disable",
	hearbeatIntervalMilliseconds: defaultHeartbeatIntervalMilliseconds,
	appCredentials: environment.appCredentials,
	credentials: new Credentials(),
	authenticatorParams: {
		_id: "",
		loginEditEnabled: true,
		newAccountEnabled: false,
		passwordForgottenEnabled: false,
		authenticationAction: EAuthenticationAction.route,
		imgEnabled: true,
		backgroundStyles: "auth",
		login: environment.credentials ? environment.credentials.login : '',
		password: environment.credentials ? environment.credentials.password : '',
		thumbnailPicture: environment.thumbnailLogo,
		thumbnailIcon: environment.thumbnailLogo,
		pinEnabled: true,
		initUserContactAfterAuth: false,
		destinationPathAfterAuth: "home"
	}
};

export * from './environments/environment';

export const backgroundTask: IBackgroundTask = {
	execTasksIntervalMs: 30000,
	maxRepeatTask: 10
};

export const oneSignal: IConfigOneSignal = {
	appId: "76559034-6792-406d-8d14-160ea045ab06",
	googleProjectNumber: "829959417149"
};

export const update: IUpdateConfig = {
	android: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`
	},
	ios: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`,
	}
};

export const conversation: IConversationConfig = {
	conversationActivityExpirationDelaySeconds: 120,
	forms: [new JoinReportConversationFormConfig().getConfig()],
	disableManualsLinks: true,
	hideAllSelectionButton: true,
	defaultConversationTab: EContactSelectionTab.groups
};

export const entityBuilders: EntityBuilder<any>[] = [
	new ContactEntityBuilder(),
	new ConversationEntityBuilder(),
	new GroupEntityBuilder(),
	new CustomerEntityBuilder(),
	new ReportEntityBuilder()
];

/** Cette config doit être incluse en forChild lors de l'inclusion du module FormlyModule.\
 * Elle permet d'ajouter des composants de formulaire customs pour Texcom.
 */
export const comFormlyConfig: ConfigOption = FORMLY_COMPONENT_REGISTRY;

export const security: ISecurityConfig =
{
	builtInRoles: {
		administrators: {
			id: C_ADMINISTRATORS_ROLE_ID,
			label: "Administrateur"
		},
		workspaceAdministrators: {
			id: C_WS_ADMINISTRATORS_ROLE_ID,
			label: "Administrateur de l'espace de travail"
		},
		[COMMERCIAL_ROLE_ID]: {
			id: COMMERCIAL_ROLE_ID,
			label: "Commercial",
			attributors: [C_WS_ADMINISTRATORS_ROLE_ID]
		},
		[CONVOYEUR_ROLE_ID]: {
			id: CONVOYEUR_ROLE_ID,
			label: "Convoyeur",
			attributors: [C_WS_ADMINISTRATORS_ROLE_ID]
		},
		[MERCHANDISEUR_ROLE_ID]: {
			id: MERCHANDISEUR_ROLE_ID,
			label: "Merchandiseur",
			attributors: [C_WS_ADMINISTRATORS_ROLE_ID]
		},
	},
	permissions: {
		default: {
			contacts: {
				create: false,
				edit: false,
				delete: false,
				read: true,
				groups: {
					create: false,
					edit: true,
					delete: false,
					read: true
				}
			},
			conversations: {
				create: true,
				edit: true,
				delete: false,
				read: true
			}
		},
		[C_ADMINISTRATORS_ROLE_ID]: {
			contacts: {
				create: true,
				edit: true,
				delete: true,
				read: true,
				groups: {
					create: true,
					edit: true,
					delete: true,
					read: true
				}
			},
			conversations: {
				create: true,
				edit: true,
				delete: true,
				read: true
			},
			users: {
				create: false,
				edit: true,
				delete: false,
				read: true
			}
		},
		[C_WS_ADMINISTRATORS_ROLE_ID]: {
			contacts: {
				create: true,
				edit: true,
				delete: true,
				read: true,
				groups: {
					create: true,
					edit: true,
					delete: true,
					read: true
				}
			},
			conversations: {
				create: true,
				edit: true,
				delete: true,
				read: true
			},
			users: {
				create: false,
				edit: true,
				delete: false,
				read: true
			}
		}
	}
};