import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LinkModule } from '../link/link.module';
import { CurveComponent } from './curve.component';

@NgModule({
	imports: [CommonModule,
		IonicModule,
		LinkModule,
		NgxChartsModule,
	],
	exports: [CurveComponent],
	declarations: [CurveComponent],
	providers: []
})
export class CurveModule { }