import { Exclude } from "class-transformer";
import { ILastChange } from "../../hooks/models/ilast-change";
import { ObservableProperty } from "../../observable/models/observable-property";
import { StoreDocument } from "../../store/model/store-document";
import { IEntity } from "./ientity";

export abstract class Entity extends StoreDocument implements IEntity {

	//#region PROPERTIES

	@Exclude()
	public observableIsValid?: ObservableProperty<boolean> = new ObservableProperty<boolean>();

	public lastChange?: ILastChange | undefined;

	//#endregion PROPERTIES

}
