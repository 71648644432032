import { EApplicationEventType } from "../../../model/application/EApplicationEventType";
import { EventParticipantEventBase } from "./event-participant-event-base";
import { IEventParticipantEventEndedData } from "./ievent-participant-event-ended-data";

export class EventParticipantAddEndEvent extends EventParticipantEventBase<IEventParticipantEventEndedData> {

	//#region PROPERTIES

	public readonly type = EApplicationEventType.eventParticipantAddEndEvent;

	//#endregion

}
