import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';

interface ICheckboxFieldComponentParams {
	label: string;
	icon: string;
}

@Component({
	selector: 'calao-checkbox-field',
	templateUrl: './checkbox-field.component.html',
	styleUrls: ['./checkbox-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFieldComponent extends FieldBase<boolean> implements OnInit {

	//#region PROPERTIES

	/** `true` si le checkbox est coché, sinon `false`. */
	@Input() public checked?: boolean;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "checked" })
	public readonly observableChecked = new ObservableProperty<boolean>();

	/** Libellé affiché à coté du checkbox. */
	public readonly observableLabel = new ObservableProperty<string>();

	/** Icône affiché à coté du libellé. */
	public readonly observableIcon = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(protected override readonly isvcForms: FormsService) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		const loParams: ICheckboxFieldComponentParams = this.props.params;
		this.observableChecked.value = !!this.model[this.fieldKey];
		this.observableLabel.value = loParams.label;
		this.observableIcon.value = loParams.icon;
	}

	public onChanged(poEvent: Event): void {
		this.observableFieldValue.value = (poEvent as CustomEvent).detail.checked;
	}

	//#endregion METHODS

}
