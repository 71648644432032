<ng-container *ngIf="!!display">
	<ng-container [ngSwitch]="displayMode">
		<!-- Mode tags -->
		<ng-container *ngSwitchCase="'tags'">
			<ion-label *ngIf="label" class="tags-list-label">{{label}}</ion-label>
			<ng-container *ngIf="scrollWrapper; then scrollTemplate else defaultTemplate"></ng-container>
		</ng-container>

		<!-- Mode liste -->
		<div *ngSwitchCase="'list'" class="list-view">
			<div *ngIf="searchOptions" class="search-box txt-center width100pc">
				<search [data]="$any(values)" [options]="$any(searchOptions)"
					(filteredEntriesChanged)="onFilteredValuesChanged($event)">
				</search>
			</div>

			<ion-radio-group *ngIf="!(searchOptions?.hasPreFillData === false && filteredOptions.length === 0)"
				[value]="defaultSingleValue" [allowEmptySelection]="min === 0">
				<ion-item-divider *ngIf="label">
					<ion-label>{{label}}</ion-label>
				</ion-item-divider>
				<ng-container *ngIf="filteredOptions?.length > 0; else emptyScreen">
					<ng-container *ngFor="let option of filteredOptions">
						<ion-item class="radio-item" *ngIf="multiple; else radio" (click)="onListValueSelected(option.value)"
							[disabled]="isDisabled(option)" [matTooltip]="option.tooltip">
							<ion-label>{{option.label}}</ion-label>
							<ion-checkbox slot="start" [checked]="(selectedValues.changes$ | async)?.includes(option.value)"
								class="ion-margin-top ion-margin-bottom">
							</ion-checkbox>
						</ion-item>
						<ng-template #radio>
							<ion-item lines="none" class="radio-item" (click)="onListValueSelected(option.value)"
								[matTooltip]="option.tooltip">
								<ion-label>{{option.label}}</ion-label>
								<ion-radio mode="md" slot="start" [value]="option.value"></ion-radio>
							</ion-item>
						</ng-template>
					</ng-container>
				</ng-container>
			</ion-radio-group>
		</div>

		<!-- Mode par défaut (ion-select) -->
		<div *ngSwitchDefault lines="none" class="selector-space">
			<ion-label *ngIf="label" class="label-selector">{{label}}</ion-label>
			<ng-container *ngIf="!multiple; else resultAsTags">
				<ion-select *ngIf="options?.length > 0"
					[value]="!!multiple ? (selectedValues.changes$ | async) : getFirstElement(selectedValues.changes$ | async)"
					[multiple]="!!multiple" (ionChange)="onPopupValueSelected($event)" [class]="selectCss"
					[interface]="displayMode" cancelText="Annuler" okText="Valider"
					[placeholder]="observablePlaceholder.value$ | async">
					<ion-select-option *ngFor="let option of options" [value]="option.value" [disabled]="isDisabled(option)"
						[matTooltip]="option.tooltip">
						{{option.label}}
					</ion-select-option>
				</ion-select>
			</ng-container>

			<ng-template #resultAsTags>
				<ion-item *ngIf="options?.length > 0" class="selector-item" detail="true" lines="none"
					detail-icon="chevron-down-outline" (click)="select.open($event)">
					<ion-label color="medium">{{ observablePlaceholder.value$ | async }}</ion-label>
				</ion-item>

				<ion-select #select class="hidden"
					[value]="!!multiple ? (selectedValues.changes$ | async) : getFirstElement(selectedValues.changes$ | async)"
					[multiple]="!!multiple" (ionChange)="onPopupValueSelected($event)" [class]="selectCss"
					[interface]="displayMode" cancelText="Annuler" okText="Valider"
					[placeholder]="observablePlaceholder.value$ | async">
					<ion-select-option *ngFor="let option of options" [value]="option.value" [disabled]="isDisabled(option)"
						[matTooltip]="option.tooltip">
						{{option.label}}
					</ion-select-option>
				</ion-select>

				<div class="selector-tags-wrapper">
					<ng-container *ngFor="let selectedValue of selectedValues.changes$ | async">
						<ion-button *ngIf="getValueOption(selectedValue) as option" (click)="unselectValue(selectedValue)"
							shape="round" mode="md" size="small" class="selector-tag-button">
							<ion-icon *ngIf="option.icon" slot="start" [name]="option.icon"></ion-icon>
							{{ option.label }}
							<ion-icon slot="end" name="close"></ion-icon>
						</ion-button>
					</ng-container>
				</div>
			</ng-template>
		</div>

	</ng-container>

	<ng-template #scrollTemplate>
		<div class="tags-list-container">
			<ion-button class="left-button" fill="clear" size="big" (click)="moveLeft()" [hidden]="hidLeftBtn">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
			<mat-chip-list multiple>
				<drag-scroll #dragScroll [ngClass]="isMobile ? '' : 'web-drag-scroll'"
					(reachesLeftBound)="leftBoundStat($event)" (reachesRightBound)="rightBoundStat($event)">
					<div drag-scroll-item *ngFor="let option of options">
						<mat-chip #chip="matChip" *ngIf="!option.hidden" class="scroll-item"
							[ngClass]="!canUnselect ? 'full-opacity' : ''"
							(selectionChange)="onTagValueSelected(option.value, $event.isUserInput, $event.selected, $event.source)"
							(click)="toggleChip(chip)" color="primary" [value]="option.value"
							[selected]="(selectedValues.changes$ | async)?.includes(option.value)" [disabled]="isDisabled(option)"
							[matTooltip]="option.tooltip">
							<ion-icon *ngIf="option.icon" [name]="option.icon"></ion-icon>
							<span>{{option.label}}</span>
						</mat-chip>
					</div>

				</drag-scroll>
			</mat-chip-list>
			<ion-button class="right-button" fill="clear" size="big" (click)="moveRight()" [hidden]="hidRightBtn">
				<ion-icon name="chevron-forward-outline"></ion-icon>
			</ion-button>
		</div>
	</ng-template>

	<ng-template #defaultTemplate>
		<mat-chip-list multiple>
			<ng-container *ngFor="let option of options">
				<mat-chip #chip="matChip" *ngIf="!option.hidden"
					(selectionChange)="onTagValueSelected(option.value, $event.isUserInput, $event.selected, $event.source)"
					(click)="toggleChip(chip)" color="primary" [value]="option.value"
					[ngClass]="!canUnselect ? 'full-opacity' : ''"
					[selected]="(selectedValues.changes$ | async)?.includes(option.value)" [disabled]="isDisabled(option)"
					[matTooltip]="option.tooltip">
					<ion-icon *ngIf="option.icon" [name]="option.icon"></ion-icon>
					<ion-label *ngIf="option.label">{{option.label}}</ion-label>
					<ion-icon *ngIf="chip.selected" name="checkmark"></ion-icon>
				</mat-chip>
			</ng-container>
		</mat-chip-list>
	</ng-template>

	<ng-template #emptyScreen>
		<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
		<div class="empty-screen flex-col">
			<span class="txt-message">
				<ion-icon class="no-result-icon" name="information-circle"></ion-icon>
				Aucun résultat.
			</span>
		</div>
	</ng-template>
</ng-container>