import { ObjectHelper } from "../../../helpers/objectHelper";
import { ConfigData } from "../../../model/config/ConfigData";


export class MaximumFileSizeError extends Error {

	constructor(psFileName: string) {
		super(`Ce document ${psFileName} dépasse la taille maximale autorisée qui est de ${ConfigData.environment.dms.maxDocumentSizeKb} Kb.`);
		// Nécessaire pour que le 'instanceof MaximumFileSizeError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, MaximumFileSizeError);
	}

}