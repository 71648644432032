import { Injectable } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IEntity } from '../../../model/entities/IEntity';
import { WorkspaceService } from '../../../services/workspace.service';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsMeta } from './IDmsMeta';
import { IMetaEntityBuilderBase } from './IMetaEntityBuilderBase';

@Injectable()
export class MetaEntityBuilderBase implements IMetaEntityBuilderBase {

	//#region FIELDS

	protected static readonly C_WS_ID = "wsId";
	protected static readonly C_ENTITY_ID = "entityId";
	protected static readonly C_SUBTYPE = "SUBTYPE";
	protected static readonly C_DOCUMENT_PATHS = "paths";
	protected static readonly C_DIV = "DIV";

	//#endregion FIELDS

	//#region METHODS

	constructor(protected readonly isvcWorkspace: WorkspaceService) { }

	/** @implements */
	public match(psDocumentId: string): boolean {
		return true; // Pour le builder de base, tous les documents correspondent.
	}

	/** @implements */
	public async prepareMeta(poEntity: IEntity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		if (!ArrayHelper.hasElements(poMeta.attributes)) {
			let lsWorkspaceId: string;

			try {
				lsWorkspaceId = this.getEntityContainerId(poEntity); // /!\ renvoie l'ID du WS avec préfixe "ws_"
			} catch (error) {
				lsWorkspaceId = EPrefix.workspace + this.isvcWorkspace.getCurrentWorkspaceId(); // /!\ renvoie l'ID du WS sans préfixe "ws_"
			}

			poMeta.attributes = [
				{ name: MetaEntityBuilderBase.C_WS_ID, value: lsWorkspaceId },
				{ name: MetaEntityBuilderBase.C_ENTITY_ID, value: await this.getEntityIdAsync(poEntity) },
				{ name: `${IdHelper.getPrefixFromId(poEntity.id).replace("_", "")}Id`, value: poEntity.id }, // Le nom de l'attribut est généré à partir du préfix (ex. : cont_ => contId, conv_ => convId)
				{ name: MetaEntityBuilderBase.C_SUBTYPE, value: poMeta.documentSubType ?? this.getEntityDocumentSubType(poEntity) }
			];

			if (!poMeta.paths) {
				const laDocumentPaths = this.getEntityDocumentPaths(poEntity, poMeta.documentSubType);

				if (ArrayHelper.hasElements(laDocumentPaths)) {
					poMeta.attributes.push({ name: MetaEntityBuilderBase.C_DOCUMENT_PATHS, value: laDocumentPaths.join("|") });
					poMeta.paths = laDocumentPaths;
				}
			}
		}

		if (StringHelper.isBlank(poMeta.documentType))
			poMeta.documentType = this.getEntityDocumentType(poEntity);

		return poMeta;
	}

	/** Permet de récupérer l'identifiant de l'entité qui sera considérée comme propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityIdAsync(poEntity: IEntity): Promise<string> {
		return Promise.resolve(poEntity.id);
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentType(poEntity: IEntity): string {
		return DmsFileHelper.getDefaultDocumentType();
	}

	/** Permet de récupérer le chemin de classification du document DMS parmi les documents applicatifs.
	 * @param poEntity
	 * @returns null si aucun path n'est défini pour l'entité, array de chemin sinon.
	 */
	protected getEntityDocumentPaths(poEntity: IEntity, psDocumentSubType?: string): string[] {
		return poEntity.documentsPaths?.map(
			psPath => StringHelper.isBlank(psDocumentSubType) ? psPath : (`${psPath}\\${psDocumentSubType}`)
		);
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentSubType(poEntity: IEntity): string {
		return MetaEntityBuilderBase.C_DIV;
	}

	/** Permet de récupérer l'identifiant du conteneur de l'entité propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityContainerId(poEntity: IEntity): string {
		return this.isvcWorkspace.getWorkspaceIdFromModel(poEntity.model) ??
			ArrayHelper.getFirstElement(StoreHelper.getDatabaseRoles(poEntity.databaseName));
	}

	//#endregion

}