import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, defer, from, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IIndexedArray } from '../../../model/IIndexedArray';
import { IIndexedObject } from '../../../model/IIndexedObject';
import { IDescriptorVersionRange, Version } from '../../../model/application/Version';
import { ConfigData } from '../../../model/config/ConfigData';
import { GalleryFile } from '../../../model/gallery/gallery-file';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { Picture } from '../../../model/picture/picture';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { EntityLinkService } from '../../../services/entityLink.service';
import { GalleryService } from '../../../services/gallery.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DmsFile } from '../../dms/model/DmsFile';
import { IDmsMeta } from '../../dms/model/IDmsMeta';
import { DmsService } from '../../dms/services/dms.service';
import { IFormDefinition } from '../../forms/models/IFormDefinition';
import { IListDefinition } from '../../forms/models/IListDefinition';
import { HooksService } from '../../hooks/services/hooks.service';
import { EPermission } from '../../permissions/models/EPermission';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { ModelResolver } from '../../utils/models/model-resolver';
import { Entity } from '../models/entity';
import { IEntity } from '../models/ientity';
import { IEntityDescriptor } from '../models/ientity-descriptor';

@Injectable({
	providedIn: "any"
})
export class EntitiesService {

	//#region FIELDS

	/** URL de base pour accéder aux descripteurs de formulaires locaux.  */
	private readonly C_LOCAL_DESCRIPTORS_BASE_URL = "/entities/descriptors/";
	/** Extension des descripteurs de formulaires locaux.  */
	private readonly C_LOCAL_DESCRIPTORS_EXTENSION = ".entityDesc.json";
	private static readonly C_DEFAULT_DELETE_TITLE = "Suppression";
	private static readonly C_DEFAULT_DELETE_MESSAGE = "Voulez-vous vraiment supprimer cette entrée ?";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly ioHttpClient: HttpClient,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcEntityLinks: EntityLinkService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioRouter: Router,
		private readonly isvcHooks: HooksService,
		private readonly isvcDms: DmsService
	) { }

	/** Récupère la description dont l'id est en paramètre sur la base de données associée aux descriptions d'entités (descriptionsDb).
 * @param psEntityDescGuid id du descripteur d'entité.
 */
	public getDescriptor$(
		psEntityDescGuid: string,
		psEntityGuid?: string,
		poActivePageManager?: ActivePageManager,
		poContext?: IIndexedObject
	): Observable<IEntityDescriptor | undefined> {
		if (StringHelper.isBlank(ConfigData.appInfo.appVersion)) {
			console.error(`FORM.S::Form descriptor retrieval failed : AppVersion is missing.`);
			return of(undefined);
		}

		const lsEntityDescId: string = IdHelper.buildId(EPrefix.entityDesc, psEntityDescGuid);
		const loRange: IDescriptorVersionRange = Version.getDescriptorVersionRange(lsEntityDescId, ConfigData.appInfo.appVersion);
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsDefinitions),
			viewParams: { include_docs: true }
		};

		if (loRange.minVersion === loRange.maxVersion) // Si pas de version dans le formDescId.
			loParams.viewParams!.key = loRange.minVersion;
		else {
			loParams.viewParams!.startkey = loRange.minVersion;
			loParams.viewParams!.endkey = loRange.maxVersion;
		}

		return combineLatest([
			this.isvcStore.get(loParams),
			this.getLocalDescriptor(psEntityDescGuid)
		]).pipe(
			map(([paDescriptors, poLocalDescriptor]: [IEntityDescriptor[], IEntityDescriptor | undefined]) => {
				const laWsDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace);
				const laConfigDescriptors: IEntityDescriptor[] = [];
				const loWsDescriptors: IEntityDescriptor[] = [];

				paDescriptors.forEach((poDescriptor: IEntityDescriptor) => {
					if (laWsDatabaseIds.includes(StoreHelper.getDocumentCacheData(poDescriptor)?.databaseId ?? ""))
						loWsDescriptors.push(poDescriptor);
					else
						laConfigDescriptors.push(poDescriptor);
				});

				const loDescriptor: IEntityDescriptor | undefined = this.getHighestPriorityFormDescriptor(
					ArrayHelper.getLastElement(laConfigDescriptors),
					ArrayHelper.getLastElement(loWsDescriptors),
					poLocalDescriptor
				);

				if (loDescriptor) {
					console.debug(`FORM.S::Form descriptor ${loDescriptor._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
					return loDescriptor;
				}
				else
					throw new Error(`Aucun formulaire trouvé pour l'identifiant ${lsEntityDescId}`);
			}),
			switchMap((poDesc: IEntityDescriptor) =>
				this.hydrateEntityDescriptor(poDesc, poContext, psEntityGuid, poActivePageManager)
			),
			catchError(poError => {
				console.error("FORM.S:: Erreur récupération des données :", poError);
				return throwError(poError);
			})
		);
	}

	private hydrateEntityDescriptor(
		poDescriptor: IEntityDescriptor,
		poContext?: IIndexedArray<any>,
		psEntityGuid?: string,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityDescriptor> {
		const lsEntityId: string = this.isvcPatternResolver.resolveContextualPattern(poDescriptor.idPattern, { guid: psEntityGuid ?? GuidHelper.newGuid() });
		return defer(() => {
			if (!StringHelper.isBlank(psEntityGuid)) {
				return this.getModel$(
					lsEntityId,
					poActivePageManager
				);
			}
			return of(undefined);
		}).pipe(
			map((poModel?: Entity) => {
				const loDesc: IEntityDescriptor = ObjectHelper.clone(poDescriptor);

				loDesc.entry = ModelResolver.toClass(
					Entity,
					ObjectHelper.assign(loDesc.entry, ModelResolver.toPlain(poModel ?? { _id: lsEntityId }))
				);

				return this.isvcPatternResolver.resolveContextualPatterns(
					loDesc,
					{
						...(poContext ?? {}),
						entry: loDesc.entry,
						entityDescriptor: loDesc,
						guid: psEntityGuid ?? GuidHelper.newGuid(),
						permissions: {
							canCreate: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "create"),
							canEdit: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "edit"),
							canDelete: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "delete")
						}
					}
				);
			})
		);
	}

	/** Récupère la description dont l'id est en paramètre dans les fichiers locaux.
	 * @param psFormDescId id du formDescriptor.
	 */
	private getLocalDescriptor(psFormDescId: string): Observable<IEntityDescriptor | undefined> {
		const lsPath = `${this.C_LOCAL_DESCRIPTORS_BASE_URL}${IdHelper.buildId(EPrefix.entityDesc, psFormDescId)}${this.C_LOCAL_DESCRIPTORS_EXTENSION}`;
		return defer(() => this.checkIfLocalDescriptorExistsAsync(lsPath)).pipe( // Évite de retourner l'index.html à la place d'une 404.
			mergeMap((pbExists: boolean) => {
				if (!pbExists)
					return of(undefined);

				return this.ioHttpClient.get<IEntityDescriptor>(lsPath).pipe(
					catchError(poError => {
						if (poError.status === 404)
							return of(undefined);
						else
							return throwError(poError);
					})
				);
			})
		);
	}

	private checkIfLocalDescriptorExistsAsync(psJsonFilePath: string): Promise<boolean> {
		return this.ioHttpClient.head(psJsonFilePath, { headers: new HttpHeaders({ accept: "application/json" }), observe: "response" }).pipe(
			map((poResponse: HttpResponse<any>) => poResponse.status === 200),
			catchError(poError => of(poError.status === 200))
		).toPromise();
	}

	/** Retourne la dernière version du descripteur voulu en fonction de la priorisation.
	 * @param poConfigDescriptor Descripteur de la base de config.
	 * @param poWsDescriptor Descripteur de la base de workspace.
	 * @param poLocalFormDescriptor Descripteur des fichiers locaux.
	 */
	private getHighestPriorityFormDescriptor(
		poConfigDescriptor?: IEntityDescriptor,
		poWsDescriptor?: IEntityDescriptor,
		poLocalFormDescriptor?: IEntityDescriptor
	): IEntityDescriptor | undefined {
		console.debug(`FORM.S::ConfigDescriptor: "${poConfigDescriptor?._id}", WsDescriptor: "${poWsDescriptor?._id}, LocalFormDescriptor: "${poLocalFormDescriptor?._id}".`);

		if (poLocalFormDescriptor)
			poLocalFormDescriptor._id = `${poLocalFormDescriptor._id}_v${Version.fromString(ConfigData.appInfo.appVersion).toFormattedString()}`;

		const laDescriptorsByPriority: (IEntityDescriptor | undefined)[] = [poWsDescriptor, poConfigDescriptor, poLocalFormDescriptor]; // Tri des descripteurs en fonction de la priorisation.
		const laDefineDescriptors: IEntityDescriptor[] = laDescriptorsByPriority.filter((poDescripteur?: IEntityDescriptor) => ObjectHelper.isDefined(poDescripteur)) as IEntityDescriptor[];

		const laSortedDescriptors: IEntityDescriptor[] = laDefineDescriptors.sort((poDescriptorA: IEntityDescriptor, poDescriptorB: IEntityDescriptor) => {
			const lnVersionA: Version = Version.fromDescriptorId(poDescriptorA._id);
			const lnVersionB: Version = Version.fromDescriptorId(poDescriptorB._id);

			return lnVersionB.compareTo(lnVersionA);
		});

		return ArrayHelper.getFirstElement(laSortedDescriptors);
	}

	public getModel$(
		psModelId?: string,
		poActivePageManager?: ActivePageManager
	): Observable<Entity | undefined> {
		if (StringHelper.isBlank(psModelId))
			return of(undefined);

		return this.isvcStore.getOne(
			{
				role: EDatabaseRole.workspace,
				viewParams: {
					key: psModelId,
					include_docs: true
				},
				live: true,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				baseClass: Entity
			},
			false
		);
	}

	/** Retourne la définition de formulaire correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDefinition(poDescriptor: IEntityDescriptor, psDefinitionId: string): IFormDefinition | undefined {
		return poDescriptor.forms[psDefinitionId];
	}

	/** Retourne la définition de liste correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getListDefinition(poDescriptor: IEntityDescriptor, psDefinitionId: string): IListDefinition | undefined {
		return poDescriptor.lists[psDefinitionId];
	}

	/** Retourne la source de données correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDataSource(poDescriptor: IEntityDescriptor, psDefinitionId: string): IDataSource | undefined {
		if (poDescriptor.dataSources)
			return poDescriptor.dataSources[psDefinitionId];
		return undefined;
	}

	/** Supprime de la base de données l'entrée.
 * @param poModel Entrée à supprimer de la base de données.
 * @param psDatabaseId Nom de la base de données sur laquelle effectuer la requête.
 */
	public deleteEntity(poModel: IEntity, poDescriptor: IEntityDescriptor, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return defer(() => this.isvcEntityLinks.ensureIsDeletableEntity(poModel).pipe(catchError(() => of(true)))) // TODO TB supprimer le catchError lors de l'US sur la gestion des entités custom
			.pipe(
				filter((pbDelete: boolean) => pbDelete),
				mergeMap(() => this.showAskingForDeletePopupAsync(poDescriptor.deleteMessage)),
				filter((pbDelete: boolean) => pbDelete),
				tap(() => this.updateLastChangeIfNeeded(poModel, poDescriptor)),
				mergeMap(() => this.deleteGalleryFiles(poModel)),
				mergeMap(() => this.isvcStore.delete(poModel, psDatabaseId)),
				mergeMap((poResponse: IStoreDataResponse) => {
					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLinks.deleteEntityLinksById(poModel._id).pipe(mapTo(poResponse));
					else
						return of(poResponse);
				}),
				catchError(poError => {
					console.error(`FORM.S:: Erreur suppression document ${poModel} de la base ${psDatabaseId}`);

					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ message: `Erreur lors de la suppression.` })
					);

					return throwError(poError);
				})
			);
	}

	public updateLastChangeIfNeeded(poEntity: IEntity, poDescriptor: IEntityDescriptor): void {
		if (poDescriptor.trackLastChangeClient)
			poEntity.lastChange = this.isvcHooks.getLastChange(ConfigData.appInfo.appId);
	}

	protected showAskingForDeletePopupAsync(
		psMessage: string = EntitiesService.C_DEFAULT_DELETE_MESSAGE,
		psTitle: string = EntitiesService.C_DEFAULT_DELETE_TITLE
	): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: psTitle,
			message: psMessage,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, supprimer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }],
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
			)
			.toPromise();
	}

	private deleteGalleryFiles(poModel: IStoreDocument): Observable<boolean> {
		const laGalleryFiles: GalleryFile[] = [];

		Object.keys(poModel).forEach((psKey: string) => {
			const loProp: any = poModel[psKey];
			if (loProp instanceof GalleryFile)
				laGalleryFiles.push(loProp);
			else if (loProp instanceof Array) {
				loProp.forEach((poItem: any) => {
					if (poItem instanceof GalleryFile)
						laGalleryFiles.push(poItem);
				});
			}
		});

		return this.isvcGallery.save$([], laGalleryFiles);
	}

	/** Enregistre le modèle sur la bdd.
 * @param poModel Modèle à enregistrer.
 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
 */
	public saveModel(poModel: IEntity, poDescriptor: IEntityDescriptor, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return this.saveGalleryFiles(poModel)
			.pipe(
				mergeMap(() => this.savePictures(poModel)),
				tap(() => this.updateLastChangeIfNeeded(poModel, poDescriptor)),
				mergeMap(() =>
					this.isvcStore.put(poModel, psDatabaseId)
						.pipe(catchError(poError => { console.error(`FORM.S:: Erreur lors du put du modèle : `, poError); return throwError(poError); }))
				),
				mergeMap((poStoreDataResponse: IStoreDataResponse) => {
					let loResponse$: Observable<IStoreDataResponse> = of(poStoreDataResponse);
					if (ConfigData.appInfo.useLinks) {
						try {
							loResponse$ = this.isvcEntityLinks.saveEntityLinks(poModel).pipe(mapTo(poStoreDataResponse));
						}
						catch (e) { }
					}
					return loResponse$;
				})
			);
	}

	private savePictures(poModel: IStoreDocument): Observable<boolean> {
		const laPictures: Picture[] = [];

		Object.keys(poModel).forEach((psKey: string) => {
			const loProp: any = poModel[psKey];
			if (loProp instanceof Picture)
				laPictures.push(loProp);
		});

		return from(laPictures).pipe(
			mergeMap((poPicture: Picture) => {
				let loDmsFile: DmsFile | undefined;
				let loDmsMeta: IDmsMeta | undefined;
				if (poPicture.file && poPicture.alt) {
					loDmsFile = new DmsFile(poPicture.file, poPicture.alt);
					loDmsMeta = loDmsFile.createDmsMeta(poPicture.guid ?? GuidHelper.newGuid());
				}
				return loDmsFile && loDmsMeta ? this.isvcDms.save(loDmsFile, loDmsMeta).pipe(map((poDmsMeta: IDmsMeta) => !!poDmsMeta)) : of(true);
			}),
			defaultIfEmpty(true)
		);
	}

	private saveGalleryFiles(poModel: IStoreDocument): Observable<boolean> {
		const laGalleryFiles: GalleryFile[] = [];

		Object.keys(poModel).forEach((psKey: string) => {
			const loProp: any = poModel[psKey];
			if (loProp instanceof GalleryFile)
				laGalleryFiles.push(loProp);
			else if (loProp instanceof Array) {
				loProp.forEach((poItem: any) => {
					if (poItem instanceof GalleryFile)
						laGalleryFiles.push(poItem);
				});
			}
		});

		return this.isvcGallery.save$(laGalleryFiles);
	}

	public navigateToEntityViewAsync(psEntityGuid: string, psEntityDescGuid: string, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([
			"entities",
			psEntityDescGuid,
			psEntityGuid
		],
			{ relativeTo: poActivatedRoute }
		);
	}

	public navigateToEntityEditAsync(psEntityGuid: string, psEntityDescGuid: string, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([
			"entities",
			psEntityDescGuid,
			psEntityGuid,
			ERouteUrlPart.edit
		],
			{ relativeTo: poActivatedRoute }
		);
	}

	//#endregion

}
