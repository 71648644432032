import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { DateHelper } from 'libs/osapp/src/helpers/dateHelper';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ModalComponentBase } from '../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../services/platform.service';

enum EInputMode {
	hour = "hour",
	minute = "minute"
}

@Component({
	selector: 'calao-browser-time-picker',
	templateUrl: './browser-time-picker.component.html',
	styleUrls: ['./browser-time-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserTimePickerComponent extends ModalComponentBase<Date> implements OnInit {

	//#region FIELDS

	private static readonly C_HOURS_VALUES: ReadonlyArray<number> = DateHelper.getHourValues();
	private static readonly C_MINUTES_VALUES: ReadonlyArray<number> = DateHelper.getMinuteValues();
	private mnPrevHourValue: number;
	private mnPrevMinuteValue: number;
	private mdEditedDate: Date;

	private mnMaxMinutes: number;
	private mnMinMinutes: number;
	private mnMaxHours: number;
	private mnMinHours: number;

	//#endregion

	//#region PROPERTIES

	@Input() public date: Date;

	private moMax: Date;
	public get max(): Date { return this.moMax; }
	@Input() public set max(poMax: Date) {
		const ldMax: Date | undefined = poMax instanceof Date ? poMax : DateHelper.isDate(poMax) ? new Date(poMax) : undefined;

		if (ldMax) {
			if (DateHelper.compareTwoDates(ldMax, this.moMax) !== 0) {
				this.moMax = ldMax;

				if (DateHelper.diffDays(new Date, this.max) === 0) {
					if (this.max && DateHelper.diffDays(this.date, this.max) === 0) {
						this.mnMaxHours = this.max.getHours();
						this.mnMaxMinutes = this.max.getMinutes();
					}
					else {
						this.mnMaxHours = undefined;
						this.mnMaxMinutes = undefined;
					}
				}
				this.detectChanges();
			}
		}
	}

	private moMin: Date;
	public get min(): Date { return this.moMin; }
	@Input() public set min(poMin: Date) {
		const ldMin: Date | undefined = poMin instanceof Date ? poMin : DateHelper.isDate(poMin) ? new Date(poMin) : undefined;

		if (ldMin) {
			if (DateHelper.compareTwoDates(ldMin, this.moMax) !== 0) {
				this.moMin = ldMin;

				if (this.min && DateHelper.diffDays(this.date, this.min) === 0) {
					this.mnMinHours = this.min.getHours();
					this.mnMinMinutes = this.min.getMinutes();
				}
				else {
					this.mnMinHours = undefined;
					this.mnMinMinutes = undefined;
				}
				this.detectChanges();
			}
		}

	}
	public get maxMinutes(): number { return ArrayHelper.getLastElement(BrowserTimePickerComponent.C_MINUTES_VALUES); }
	public get minMinutes(): number { return ArrayHelper.getFirstElement(BrowserTimePickerComponent.C_MINUTES_VALUES); }
	public get maxHours(): number { return ArrayHelper.getLastElement(BrowserTimePickerComponent.C_HOURS_VALUES); }
	public get minHours(): number { return ArrayHelper.getFirstElement(BrowserTimePickerComponent.C_HOURS_VALUES); }

	public hourValue: number;
	public minuteValue: number;

	@ViewChild("hourInput") public set hourInput(poHourInput: IonInput) {
		if (poHourInput) {
			setTimeout(() => {
				poHourInput.setFocus();
				poHourInput.getInputElement().then((poHTMLElement: HTMLInputElement) => poHTMLElement.select());
			}, 500);
		}
	};

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		if (!DateHelper.isDate(this.date))
			this.date = new Date();

		this.mdEditedDate = new Date(this.date);

		this.initDate();

		this.detectChanges();
	}

	private initDate(): void {
		const lsHoursAndMinutes: string = DateHelper.getHoursAndMinutes(this.date);
		this.hourValue = +lsHoursAndMinutes.split(":")[0];
		this.minuteValue = +lsHoursAndMinutes.split(":")[1];
	}

	/** Ajoute un 0 si la valeur de l'input est inférieure à 10.
	 * @param poInput L'input.
	 */
	public fillInput(poInput: IonInput): void {
		poInput.value = +poInput.value;

		if (!isNaN(poInput.value) && poInput.value < 10)
			poInput.value = `0${poInput.value}`;
	}

	/** Affecte les valeurs saisies par l'utilisateur.
	 * @param poInput L'input.
	 * @param peInputMode Indique si l'input est celui des heures ou des minutes.
	 */
	public setValue(poMinuteInput: IonInput, poHourInput: IonInput, peInputMode: EInputMode | string): void {
		let lnMinuteInputValue: number = +poMinuteInput.value;
		let lnHourInputValue: number = +poHourInput.value;
		this.mnPrevMinuteValue = this.mdEditedDate.getMinutes();
		this.mnPrevHourValue = this.mdEditedDate.getHours();

		if (lnMinuteInputValue < this.minMinutes)
			lnMinuteInputValue = this.minMinutes;
		else if (lnMinuteInputValue > this.maxMinutes)
			lnMinuteInputValue = this.maxMinutes;

		if (lnHourInputValue < this.minHours)
			lnHourInputValue = this.minHours;
		else if (lnHourInputValue > this.maxHours)
			lnHourInputValue = this.maxHours;

		if (peInputMode === EInputMode.hour) {
			if (!isNaN(lnHourInputValue) && lnHourInputValue !== this.mnPrevHourValue)
				this.mdEditedDate.setHours(lnHourInputValue);
		}
		else if (peInputMode === EInputMode.minute) {
			if (!isNaN(lnMinuteInputValue) && lnMinuteInputValue !== this.mnPrevMinuteValue)
				this.mdEditedDate.setMinutes(lnMinuteInputValue);
		}

		if (DateHelper.compareTwoDates(this.mdEditedDate, this.min ?? this.mdEditedDate) < 0) {
			this.mdEditedDate.setMinutes(this.mnMinMinutes ?? this.mnPrevMinuteValue);
			this.mdEditedDate.setHours(this.mnMinHours ?? this.mnPrevHourValue);
		}
		else if (DateHelper.compareTwoDates(this.mdEditedDate, this.max ?? this.mdEditedDate) > 0) {
			this.mdEditedDate.setMinutes(this.mnMaxMinutes ?? this.mnPrevMinuteValue);
			this.mdEditedDate.setHours(this.mnMaxHours ?? this.mnPrevHourValue);
		}

		poHourInput.value = this.mdEditedDate.getHours();
		poMinuteInput.value = this.mdEditedDate.getMinutes();

		this.fillInput(poHourInput);
		this.fillInput(poMinuteInput);

		this.detectChanges();
	}

	public validate(): void {
		const ldNewDate = new Date(this.date);
		ldNewDate.setHours(this.hourValue);
		ldNewDate.setMinutes(this.minuteValue);
		this.close(ldNewDate);
	}

	public cancel(): void {
		this.close();
	}

	//#endregion

}