import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupDetailsModel } from '../../../model/contacts/IGroupDetailsModel';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { C_SECTORS_ROLE_ID } from '../../../modules/permissions/services/permissions.service';
import { tapError } from '../../../modules/utils/rxjs/operators/tap-error';
import { GroupsService } from '../../../services/groups.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../services/uiMessage.service';
import { FormComponent } from '../../forms/form/form.component';

@Component({
	selector: "group-details",
	templateUrl: './group-details.component.html'
})
export class GroupDetailsComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Modèle du groupe  */
	@Input() public model: IGroupDetailsModel;

	/** Identifiant du descripteur de formulaire des groupes. */
	public formDescriptorId: string;
	/** Identifiant de la définition de formulaire des groupes à utiliser. */
	public formDefinitionId: string;

	public customSubmit: (poModel: IGroupDetailsModel, poForm: FormComponent<IGroupDetailsModel>) => Observable<any>;

	public get isReadOnly(): boolean {
		const loLastUrlSegment: UrlSegment = ArrayHelper.getLastElement(this.ioRoute.snapshot.url);
		return loLastUrlSegment.path !== ERouteUrlPart.edit && loLastUrlSegment.path !== ERouteUrlPart.new;
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Service des groupes. */
		private isvcGroups: GroupsService,
		/** Service permettant l'affichage de popups et toasts. */
		private isvcUiMessage: UiMessageService,
		private ioRoute: ActivatedRoute,
		poRouter: Router
	) {
		super();

		this.model = this.ioRoute.snapshot.data.model ?? poRouter.getCurrentNavigation()?.extras.state;
	}

	public ngOnInit(): void {
		if (!this.model) // Si pas de paramètre alors mode création.
			this.model = { _id: "", name: "", contacts: [] };

		this.formDescriptorId = this.model.roles?.includes(C_SECTORS_ROLE_ID) ? GroupsService.C_DEFAULT_SECTORS_FORMDESC_ID : GroupsService.C_DEFAULT_GROUPES_FORMDESC_ID;

		if (!this.model.contacts)
			this.model.contacts = [];

		this.customSubmit = (poModel: IGroupDetailsModel, poForm: FormComponent<IGroupDetailsModel>) => this.save();

		this.formDefinitionId = this.isReadOnly ? "group_visu" : "group_edit";
	}

	/** Vérifie qu'on peut enregistrer : le nom du groupe au minimum est renseigné. */
	private canSave(): boolean {
		if (StringHelper.isBlank(this.model.name)) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Le nom du groupe est obligatoire !", buttons: [{ text: "OK" }] }));
			this.model.name = "";
			return false;
		}
		else
			return true;
	}

	/** Crée un groupe.
	 * @param poGroup Groupe à créer (modèle).
	 */
	private createGroup(poGroup: IGroup): Observable<boolean> {
		return this.isvcGroups.addGroup(poGroup)
			.pipe(
				tapError(
					(poError: any) => {
						console.error(`GRPEDIT.C:: Erreur création groupe : `, poError);
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: `La création du groupe ${poGroup.name} a échoué. Veuillez réessayez ultérieurement.` })
						);
					}
				)
			);
	}

	/** Enregistre un groupe (création ou mise à jour). */
	private save(): Observable<boolean> {

		if (this.canSave()) {
			const laContacts: Array<IContact> | undefined = this.model.contacts;
			delete this.model.contacts;
			let loSave$: Observable<boolean>;

			if (StringHelper.isBlank(this.model._rev)) { // Si pas de révision alors mode création.

				if (!this.model._id.startsWith(EPrefix.group))
					this.model._id = IdHelper.buildId(EPrefix.group);

				loSave$ = this.createGroup(this.model);
			}
			else // Sinon, mode édition.
				loSave$ = this.updateGroup(this.model);

			return loSave$
				.pipe(
					tap(
						_ => this.model.contacts = laContacts,
						poError => console.error(`GRPEDIT.C:: Erreur lors de l'enregistrement du groupe '${this.model._id}'`, poError)
					),
					takeUntil(this.destroyed$)
				);
		}
		else
			return EMPTY;
	}

	/** Met à jour le groupe.
	 * @param poGroup Groupe qu'on veut mettre à jour.
	 */
	private updateGroup(poGroup: IGroup): Observable<boolean> {
		return this.isvcGroups.updateGroup(poGroup)
			.pipe(
				tapError(
					(poError: any) => {
						console.error(`GRPEDIT.C:: Erreur mise à jour du groupe '${poGroup._id}' : `, poError);
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: `La mise à jour du groupe ${poGroup.name} a échoué. Veuillez réessayez ultérieurement.` })
						);
					}
				)
			);
	}

	//#endregion
}