import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImageModule } from '../../components/image/image.module';
import { DmsImageComponent } from './components/dms-image/dms-image.component';
import { IMetaEntityBuilderBase } from './model/IMetaEntityBuilderBase';
import { MetaConversationBuilder } from './model/MetaConversationBuilder';
import { MetaEntityBuilderBase } from './model/MetaEntityBuilderBase';
import { DmsExternalLinkerService } from './services/dms-external-linker.service';
import { DMS_META_CONFIG, DmsMetaService } from './services/dms-meta.service';
import { DmsService } from './services/dms.service';

const laDmsModuleProviders: Array<Provider> = [
	DmsMetaService,
	MetaEntityBuilderBase,
	MetaConversationBuilder,
	DmsService,
	DmsExternalLinkerService
];

@NgModule({
	declarations: [
		DmsImageComponent
	],
	imports: [
		CommonModule,
		ImageModule,
		IonicModule
	],
	exports: [
		DmsImageComponent
	]
})
export class DmsModule {

	public static forRoot(poDmsMetaConfig: Type<IMetaEntityBuilderBase>[]): ModuleWithProviders<DmsModule> {
		return {
			ngModule: DmsModule,
			providers: [
				...laDmsModuleProviders,
				{ provide: DMS_META_CONFIG, useValue: poDmsMetaConfig }
			]
		};
	}

}
