import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { EEventOccurrenceActionType } from "./eevent-occurrence-action-type";
import { EventOccurrenceAction } from "./event-occurrence-action";
import { IConstraintLabelParams } from "./iconstraint-label-params";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceAction } from "./ievent-occurrence-action";
import { IEventOccurrencePlaceAction } from "./ievent-occurrence-place-action";

const C_TYPE = EEventOccurrenceActionType.place;

@ModelMatch((poData: IEventOccurrenceAction) => poData?.type === C_TYPE, EventOccurrenceAction)
export class EventOccurrencePlaceAction extends EventOccurrenceAction {

	//#region PROPERTIES

	public override readonly type: string = C_TYPE;

	/** @implements */
	public place?: string;

	//#endregion

	//#region METHODS

	constructor(poAction?: Partial<IEventOccurrencePlaceAction>) {
		super(poAction);
	}

	public override action(poEventOccurrence: IEventOccurrence): void {
		poEventOccurrence.place = this.place;
	}

	public override buildlabel(poParams?: IConstraintLabelParams): string {
		throw new Error("Method not implemented.");
	}


	//#endregion

}
