import { Injectable } from '@angular/core';
import { UserData } from '../../../model/application/UserData';
import { ConfigData } from '../../../model/config/ConfigData';
import { PlatformService } from '../../../services/platform.service';
import { ILastChange } from '../models/ilast-change';

@Injectable()
export class HooksService {

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService
	) { }

	/** Retourne les données utilisateur de la dernière modification.
	 * @param psAppName L'identifiant de l'application (ex: merchapp).
	 */
	public getLastChange(psAppName: string): ILastChange {
		return {
			agent: {
				id: psAppName,
				version: ConfigData.appInfo.appVersion as string,
				type: this.isvcPlatform.platform
			},
			user: {
				id: UserData.current?._id as string
			},
			date: new Date().toISOString(),
			browser: this.isvcPlatform.isMobileApp || this.isvcPlatform.isVirtual ? undefined : {
				id: this.isvcPlatform.model
			},
			device: ConfigData.appInfo.deviceId !== "123" ? {
				id: ConfigData.appInfo.deviceId
			} : undefined
		};
	}

	//#endregion

}