import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyTabsModule } from 'ngx-formly-tabs';
import { DynamicPageModule } from '../../../components/dynamicPage/dynamicPage.module';
import { FormModule } from '../../../components/forms/form/form.module';
import { SharedComponentsModule } from '../../../components/sharedComponents.module';
import { HeaderModule } from '../../header/header.module';
import { EditorPageComponent } from './components/editor/editor-page/editor-page.component';
import { FormEditorComponent } from './components/editor/form-editor/form-editor.component';
import { WidgetAdderComponent } from './components/editor/widget-adder/widget-adder.component';
import { WidgetSettingComponent } from './components/editor/widget-setting/widget-setting.component';
import { GeneralSettingComponent } from './components/general-setting/general-setting.component';
import { FormDataSimulatorComponent } from './components/preview/form-data-simulator/form-data-simulator.component';
import { FormPreviewJsonComponent } from './components/preview/form-preview-json/form-preview-json.component';
import { PreviewPageComponent } from './components/preview/preview-page/preview-page.component';
import { DesignService } from './services/design.service';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		FormModule,
		FormlyModule.forChild(),
		SharedComponentsModule,
		ReactiveFormsModule,
		FormlyTabsModule,
		HttpClientModule,
		HeaderModule,
		DynamicPageModule,
	],
	declarations: [
		WidgetAdderComponent,
		WidgetSettingComponent,
		FormEditorComponent,
		FormDataSimulatorComponent,
		FormPreviewJsonComponent,
		GeneralSettingComponent,
		EditorPageComponent,
		PreviewPageComponent
	],
	exports: [],
	providers: [DesignService],
	bootstrap: []
})
export class DesignerModule { }