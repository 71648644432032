import { EUpdateStatus } from "./eupdate-status";

export class UpdateEvent {

	//#region PROPERTIES

	public state: EUpdateStatus;
	public progression: number;

	//#endregion

	//#region METHODS

	constructor(peState: EUpdateStatus, pnProgression?: number) {
		this.state = peState;
		this.progression = pnProgression ?? 0;
	}

	//#endregion

}
