<div *ngIf="dataTemplate" class="slidebox">
	<!-- onglets -->
	<div #sliderTabBar class="slidebox-nav">
		<ion-button #sliderTabs class="border-l slider-button" *ngFor="let slide of dataTemplate.slideBoxes"
			(click)="slideTo(slide.id)" color="slidebox-button"
			[ngClass]="slide.id === currentSlideId ? 'slidebox-active-tab' : ''"><span>{{slide.title}}</span>
		</ion-button>
	</div>

	<!-- slides -->
	<ion-slides #sliderContainer (ionSlideWillChange)="slideChanging()" (ionSlidesDidLoad)="onSlideboxLoaded()"
		(ionSlideDidChange)="manageSwipeFromBlockedSlideIds()" class="calao-slides" [options]="options">
		<ion-slide *ngFor="let slide of dataTemplate.slideBoxes" class="swipe textalignleft calao-slides">
			<ng-template dyn-host></ng-template>
		</ion-slide>
	</ion-slides>
</div>