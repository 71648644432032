import { Observable, of } from 'rxjs';
import { UserHelper } from '../../helpers/user.helper';
import { IContact } from '../contacts/IContact';
import { DefaultEntityGuard } from './DefaultEntityGuard';
import { IGuardResult } from "./IEntityGuard";
import { IEntityLink } from "./IEntityLink";

export class ContactEntityGuard extends DefaultEntityGuard {

	constructor() {
		super("Ce contact est lié");
	}

	/** Un contact peut être supprimé :
	 * - s'il ne s'agit pas d'un contact Utilisateur
	 * - s'il n'est lié à aucune autre entité (comportement standard). */
	public override isDeletable(poContact: IContact, paLinks: IEntityLink[]): Observable<IGuardResult> {
		if (UserHelper.isUser(poContact)) // il s'agit d'un utilisateur de l'application.
			return of({ result: false, message: "Ce contact est un utilisateur de l'application et ne peut pas être supprimé." });
		else
			return super.isDeletable(poContact, paLinks); // comportement standard
	}

}