import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConversationHelper } from '../../../../../../helpers/ConversationHelper';
import { UserData } from '../../../../../../model/application/UserData';
import { IContact } from '../../../../../../model/contacts/IContact';
import { IActionButtonFieldConvParams } from '../../../../models/actionButtonFields/IActionButtonFieldConvParams';
import { FormsService } from '../../../../services/forms.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

@Component({
	templateUrl: './actionButtonFieldConversation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonFieldConversationComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldConvParams> implements OnInit {

	//#region PROPERTIES

	/** Indique si l'utilisateur peut cliquer sur le bouton ou non. */
	public canClick: boolean;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(psvcForms, poChangeDetectorRef);
		/** On stocke cet état dans window pour y avoir accès dans les expressions du formDescriptor. */
		(window as any).userId = UserData.current.name;
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.setCanClick();
	}

	private setCanClick(): void {
		// On peut cliquer si le bouton n'est pas désactivé et
		// si le modèle est éligible en tant que participant ou si le modèle va être lié à la conversation (pas participant donc pas besoin d'être éligible).
		this.canClick = this.params.disabled ?
			false : (ConversationHelper.isParticipantEligible(this.model as IContact) || this.params.specParams.linkEntityToConversation);
	}

	/** Ne fait rien (l'action est gérée par le composant interne). */
	public override action(): void { }

	//#endregion
}