import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ToggleChangeEventDetail } from '@ionic/core';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { EDateTimePickerMode } from '../../../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../../../model/date/IDateTimePickerParams';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { IOptionalDateTimePickerProps } from './models/ioptional-date-time-picker-props';

@Component({
	selector: 'calao-optional-date-time-picker-field',
	templateUrl: './optional-date-time-picker-field.component.html',
	styleUrls: ['./optional-date-time-picker-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionalDateTimePickerFieldComponent extends FieldBase<Date> implements OnInit {

	//#region PROPERTIES

	/** Date choisie via la sélecteur. */
	public readonly observableDate = new ObservableProperty<Date | undefined>();

	/** Libellé du champs. */
	public readonly observableFieldLabel = new ObservableProperty<string>();

	/** Libellé affiché à coté du toggle */
	public readonly observableToggleLabel = new ObservableProperty<String>("Désactivée");

	/** `true` pour afficher le mode visu, sinon `false`. */
	public readonly observableReadonly = new ObservableProperty<boolean>();

	/** Libellé de la date formatée pour le mode readonly. */
	public readonly observableReadonlyDateLabel = new ObservableProperty<string>();

	public observableDatePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public observableTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	/** `true` pour afficher le sélecteur de date sinon `false`. */
	public readonly observableActivation = new ObservableProperty<boolean>()
		.bind(
			this.observableDate.value$.pipe(map((pdDate?: Date) => !!pdDate)),
			this
		);

	//#endregion PROPERTIES

	//#region METHODS

	public override ngOnInit(): void {
		super.ngOnInit();

		this.initReadonly();
		this.initDate()
		this.initPadding();
		this.initFieldLabel();
		this.initDate();

		this.observableActivation.value$.pipe(
			tap((pbToggleisActive?: boolean) => {
				this.observableToggleLabel.value = !!pbToggleisActive ? "Activée" : "Désactivée";
			}),
			secure(this)
		).subscribe();

		this.observableDate.value$.pipe(
			secure(this),
			tap((pdDate: Date) => this.value = pdDate)
		).subscribe();

		this.observableReadonly.value$.pipe(
			secure(this),
			switchMap((pbIsReadonly: boolean) => pbIsReadonly ? this.setReadonlyDateLabel$() : EMPTY)
		).subscribe();

	}

	private setReadonlyDateLabel$(): Observable<void> {
		return this.observableFieldValue.value$.pipe(
			map((poValue: Date) => {
				this.observableReadonlyDateLabel.value = `${DateHelper.transform(poValue, ETimetablePattern.dd_MMMM_yyyy_comma_HH_mm)}`;
			})
		);
	}

	public onActivationChanged(poEvent: Event): void {
		const loEvent: ToggleChangeEventDetail = (poEvent as CustomEvent).detail;
		if (!loEvent.checked) {
			if (this.observableDate.value)
				this.observableDate.value = undefined;
		}
		else
			this.observableDate.value = new Date();
	}

	public onDateChanged(pdDate: Date): void {
		this.observableDate.value = pdDate;
	}

	private initReadonly(): void {
		this.observableReadonly.value = !!(this.props as IOptionalDateTimePickerProps).readonly;
	}

	private initDate(): void {
		if (this.fieldValue)
			this.observableDate.value = this.fieldValue;
	}

	private initPadding(): void {
		const lsPadding: string = (this.props as IOptionalDateTimePickerProps).padding ?? "initial";
		if (lsPadding)
			this.observablePadding.value = lsPadding;
	}

	private initFieldLabel(): void {
		this.observableFieldLabel.value = (this.props as IOptionalDateTimePickerProps).label ?? "Date";
	}

	//#endregion METHODS

}
