/** Enumération des désignation pour la console.
 */
export enum ENameConsole {
	editorPage = "EDT.C::",
	formEditor = "FEDT.C::",
	widgetAdder = "WADD.C::",
	widgetSetting = "WSTG.C::",
	generalSetting = "GSTG.C::",
	formDataSimulator = "FDS.C::",
	formPreviewJson = "FPVW.C::",
	previewPage = "PVW.C::",
	designer = "DSG.P::",
	designService = "DSG.S::"
};