import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { TabsTabContainerComponent } from '../tabs-tab-container/tabs-tab-container.component';

@Component({
	selector: 'calao-tabs-tab',
	templateUrl: './tabs-tab.component.html',
	styleUrls: ['./tabs-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsTabComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	/** Index de la tab. */
	@Input() public index?: number;
	@ObserveProperty<TabsTabComponent>({ sourcePropertyKey: "index" })
	public readonly observableIndex = new ObservableProperty<number>();

	public readonly isActive$: Observable<boolean>;

	//#endregion

	//#region METHODS

	constructor(@Optional() private ioTabsTabContainer?: TabsTabContainerComponent) {
		super();

		if (this.ioTabsTabContainer) {
			this.isActive$ = combineLatest([
				this.ioTabsTabContainer.observableActiveTabIndex.value$,
				this.observableIndex.value$
			]).pipe(
				map(([pnActiveIndex, pnTabIndex]: [number, number]) => pnActiveIndex === pnTabIndex),
				secure(this)
			);
		}
		else
			this.isActive$ = EMPTY;
	}

	//#endregion

}
