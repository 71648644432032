import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { PathHelper } from '../../../helpers/path-helper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IEntity } from '../../../model/entities/IEntity';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsDocument } from '../model/IDmsDocument';
import { IDmsDocumentAttribute } from '../model/IDmsDocumentAttribute';
import { IDmsDocumentData } from '../model/IDmsDocumentData';
import { IDmsMeta } from '../model/IDmsMeta';
import { IMetaEntityBuilderBase } from '../model/IMetaEntityBuilderBase';
import { MetaConversationBuilder } from '../model/MetaConversationBuilder';
import { MetaEntityBuilderBase } from '../model/MetaEntityBuilderBase';
import { DmsDocument } from '../model/dms-document';

export const DMS_META_CONFIG = new InjectionToken<Type<IMetaEntityBuilderBase>[]>("DMS_META_CONFIG");

@Injectable()
export class DmsMetaService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DMS-M.S::";
	private maMetaEntityInfosBuilders: IMetaEntityBuilderBase[] = [];

	//#endregion

	//#region PROPERTIES



	//#endregion

	//#region METHODS

	constructor(
		private readonly ioMetaEntityBuilderBase: MetaEntityBuilderBase,
		private readonly ioMetaConversationBuilder: MetaConversationBuilder,
		private readonly ioInjector: Injector,
		private readonly isvcWorkspace: WorkspaceService,
		@Inject(DMS_META_CONFIG) @Optional() private readonly iaMetaEntityInfosBuildersTypes: Type<IMetaEntityBuilderBase>[],
		private isvcStore: Store,
		private isvcUiMessage: UiMessageService
	) { }

	private initBuilders(): void {
		if (ArrayHelper.hasElements(this.iaMetaEntityInfosBuildersTypes)) {
			this.maMetaEntityInfosBuilders = this.iaMetaEntityInfosBuildersTypes.map((poType: Type<IMetaEntityBuilderBase>) => {
				try {
					return this.ioInjector.get(poType);
				}
				catch (poError) {
					console.error("DMS.META.S::Erreur lors de l'initialisation du DmsMetaService. Des providers sont manquants pour ce builder.", poType, poError);
					return undefined;
				}
			});
		}

		// On supprime les valeurs undefined du tableau pour fonctionner même si il manque des providers.
		this.maMetaEntityInfosBuilders = this.maMetaEntityInfosBuilders.filter((poBuilder: MetaEntityBuilderBase) => !!poBuilder);
		this.maMetaEntityInfosBuilders.push( // On ajoute les builders par défaut de la lib.
			this.ioMetaConversationBuilder,
			this.ioMetaEntityBuilderBase // On l'ajoute à la fin pour être sûr d'avoir un builder qui match.
		);
	}

	/** Prépare les meta du DMS en ajoutant les données relatives à l'entité concernée.
	 * @param poMeta
	 * @param poEntity
	 */
	public prepareEntityMeta(poMeta: IDmsMeta, poEntity: IEntity): Promise<IDmsMeta> {
		if (ArrayHelper.hasElements(poMeta.attributes) ||
			(!StringHelper.isBlank(poMeta.documentType) && DmsFileHelper.getDefaultDocumentType() !== poMeta.documentType))
			return Promise.resolve(poMeta);

		return this.getBuilderForEntity(poEntity).prepareMeta(poEntity, poMeta);
	}

	public prepareMeta(poDmsmeta: IDmsMeta): IDmsMeta {
		const loWorkspaceAttribute: IDmsDocumentAttribute = {
			name: "wsId",
			value: this.isvcWorkspace.getCurrentWorkspaceId(true)
		};
		const loPathAttibute: IDmsDocumentAttribute = {
			name: "paths",
			value: poDmsmeta.paths?.map((psPath: string) => PathHelper.preparePath(psPath)).join(";") ?? ""
		};
		if (ArrayHelper.hasElements(poDmsmeta.attributes))
			poDmsmeta.attributes.push([loWorkspaceAttribute, loPathAttibute]);
		else
			poDmsmeta.attributes = [loWorkspaceAttribute, loPathAttibute];
		return poDmsmeta;
	}

	private getBuilderForEntity(poEntity: IEntity): IMetaEntityBuilderBase {
		if (!ArrayHelper.hasElements(this.maMetaEntityInfosBuilders))
			this.initBuilders();

		return this.maMetaEntityInfosBuilders.find((poBuilder: MetaEntityBuilderBase) => poBuilder.match(poEntity.id));
	}

	/** Enregistre en base de données les métadonnées locales d'un document DMS.
	 * @param poDmsMeta Objet en réponse à l'enregistrement du fichier.
	 * @param psDatabaseId Base de données de destination.
	 */
	public saveLocalDocumentMeta(poDmsMeta: IDmsMeta, psDatabaseId: string): Observable<IDmsMeta> {
		poDmsMeta.lastAccess = poDmsMeta.modifyDate = new Date();

		return this.save(poDmsMeta, psDatabaseId, `Erreur lors de l'enregistrement en base de données des métadonnées du fichier ${poDmsMeta.name}.`);
	}

	private save<T extends IStoreDocument>(poDocument: T, psDatabaseId: string, psErrorMessage: string): Observable<T> {
		return this.isvcStore.put(poDocument, psDatabaseId, true)
			.pipe(
				catchError(poError => {
					console.error(`${DmsMetaService.C_LOG_ID}${psErrorMessage}`, poDocument, "Erreur : ", poError);
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({
							header: "Erreur",
							message: psErrorMessage
						})
					);
					return throwError(poError);
				}),
				mapTo(poDocument)
			);
	}

	/** Enregistre en base de données les métadonnées partagées d'un document DMS.
	 * @param poSharedDocument Objet en réponse à l'enregistrement du fichier.
	 * @param psDatabaseId Base de données de destination.
	 */
	public saveSharedDocumentMeta$<T extends IDmsDocument>(poSharedDocument: T, psDatabaseId: string): Observable<T> {
		return this.save(poSharedDocument, psDatabaseId, `Erreur lors de l'enregistrement en base de données des métadonnées partagées du fichier ${poSharedDocument.name}.`);
	}

	public generateDmsDocumentFromData(poFile: IDmsDocumentData): DmsDocument {
		return DmsDocument.FromIDmsDocument(({ _id: this.generateDmsDocumentId(poFile.guid), ...poFile }));
	}

	private generateDmsDocumentFromMeta(poMeta: IDmsMeta): DmsDocument {
		const loDocument: DmsDocument = DmsDocument.FromIDmsMeta(poMeta);

		// Remplace l'ID de métadonnée par celui d'un document partagé.
		loDocument._id = this.generateDmsDocumentId(GuidHelper.extractGuid(poMeta._id));

		return loDocument;
	}

	private generateDmsDocumentId(psDocId: string): string {
		return IdHelper.buildId(EPrefix.dmsDoc, psDocId);
	}

	public generatePrefix(psType?: string): EPrefix {
		return `${EPrefix.dmsDoc}${!StringHelper.isBlank(psType) ? `${psType}_` : ""}` as EPrefix;
	}

	public shareDocument(poMeta: IDmsMeta): Observable<IDmsDocument> {
		const laDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace, true); // Lève une exception en l'absence de résultat.

		if (laDatabaseIds.length > 1)
			console.warn(`${DmsMetaService.C_LOG_ID}Mutliple workspace databases, sharing DMS meta in the first one ${laDatabaseIds[0]}.`);

		const lsWorkspaceDatabaseId: string = laDatabaseIds[0];
		const loSharedMetaDocument: DmsDocument = this.generateDmsDocumentFromMeta(poMeta);

		return this.saveSharedDocumentMeta$(loSharedMetaDocument, lsWorkspaceDatabaseId);
	}

	public deleteSharedDocument$(psId: string): Observable<boolean> {
		return this.isvcStore.delete(this.generateDmsDocumentId(psId))
			.pipe(mergeMap((poResponse: IStoreDataResponse) => of(poResponse.ok)));
	}

	/** Renvoie le document de métadonnées partagé du document dont l'ID est indiqué.
	 * @param psGuid Guid du document.
	 */
	public getSharedDocument$(psGuid: string): Observable<IDmsMeta> {
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
			viewParams: {
				include_docs: true,
				key: `${this.generateDmsDocumentId(psGuid)}`
			}
		};

		return this.isvcStore.getOne<IDmsMeta>(loDataSource);
	}

	//#endregion

}
