<div class="content-wrapper" [style.backgroundColor]="observableBackgroundColor.value$ | async">
	<div class="prefix-note">
		<ng-content select="calao-list-item-content-prefix"></ng-content>
	</div>
	<ng-content></ng-content>
	<ng-content select="calao-list-item-content-suffix"></ng-content>
</div>
<ng-content select="calao-list-item-end"></ng-content>
<div *ngIf="observableDetail.value$ | async" [style.backgroundColor]="observableBackgroundColor.value$ | async"
	class="detail-wrapper" (click)="onDetailClicked($event)" calaoSlidingItemOptionsToggle>
	<ion-icon [name]="observableDetailIcon.value$ | async"></ion-icon>
</div>