import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { ModalModule } from "@calaosoft/osapp/modules/modal";
import { VirtualScrollModule } from "@calaosoft/osapp/modules/virtual-scroll/virtual-scroll.module";
import { PipeModule } from "@calaosoft/osapp/pipes/pipes.module";
import { IonicModule } from "@ionic/angular";
import { JoinReportSelectorModalComponent } from "./join-report-selector-modal.component";
import { JoinReportSelectorModalService } from "./services/join-report-selector-modal.service";

@NgModule({
	declarations: [JoinReportSelectorModalComponent],
	imports: [
		CommonModule,
		IonicModule,
		ModalModule,
		PipeModule,
		VirtualScrollModule,
		MatRadioModule,
		MatDividerModule,
		MatListModule,
		FormsModule
	],
	exports: [JoinReportSelectorModalComponent],
	providers: [JoinReportSelectorModalService]
})
export class JoinReportSelectorModalModule { }