<div class="content-wrapper">
	<ng-container *ngIf="observableModel.value$ | async as model; else noContent">
		<calao-formly-wrapper *ngIf="observableFormDefinition.value$ | async as formDefinition"
			[formDefinition]="formDefinition" [model]="model" (onValidityChanged)="onValidityChanged($event)"
			[readOnly]="observableReadonly.value$ | async">
		</calao-formly-wrapper>
	</ng-container>
</div>

<ng-template #noContent>
	<ng-container *ngIf="observableIsLoading.value$ | async; else blankInfo;">
		<osapp-spinner></osapp-spinner>
	</ng-container>

	<ng-template #blankInfo>
		<calao-blank-information class="middle-align" title="Donnée introuvable"
			subtitle="La donnée n'a pas pu être trouvée." icon="alert-circle">
		</calao-blank-information>
	</ng-template>
</ng-template>