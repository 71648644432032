import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskBase } from '../../../services/backgroundTask/TaskBase';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { SyncDmsService } from '../services/syncDms.service';

export abstract class DmsTaskBase<T extends IDmsTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcSyncDms: SyncDmsService;
	/** Service permettant de manipuler les flags. */
	protected msvcFlag: FlagService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcSyncDms = InjectorService.instance.get(SyncDmsService);
		this.msvcFlag = InjectorService.instance.get(FlagService);
	}

	public override retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return super.retryStrategy(poErrors$.pipe( // Observable qui fournit chaque erreur de la tâche.
			mergeMap((poError: any) => {
				if (this.msvcSyncDms.isPermanentError(poError))
					return throwError(poError);
				return of(poError);
			})
		));
	}

	//#endregion
}