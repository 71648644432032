import { Observable } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";
import { IDestroyable } from "../../lifecycle/models/IDestroyable";

/** Évite les fuites mémoires en coupant le flux lors de la destruction du composant, et rejoue la dernière valeur obtenue. */
export function secure<T>(poDestroyable: IDestroyable): (poSource: Observable<T>) => Observable<T> {
	return (poSource: Observable<T>) => {
		return poSource.pipe(
			takeUntil(poDestroyable.destroyed$),
			shareReplay(1)
		);
	};
}