<div class="streams">
	<div id="localStream">

	</div>

	<div *ngIf="isCustomerView; else admin" id="remoteStream" class="remote">
		<ng-container *ngIf="isCallStarted; else waitingMessageTemplate">
			<img *ngIf="defaultRemoteImageSrc" [src]="defaultRemoteImageSrc" id="defaultImage" />
		</ng-container>
		<ng-template #waitingMessageTemplate>
			<div class="waiting-message">
				<p *ngIf="waitingMessage; else defaultWaitingMessage">{{ waitingMessage }}</p>
				<ng-template #defaultWaitingMessage>
					<p>Merci de rester dans la salle d'attente, votre correspondant va vous rejoindre.</p>
				</ng-template>
			</div>
		</ng-template>
	</div>

	<ng-template #admin>
		<div id="remoteStream" class="remote">

		</div>
	</ng-template>


	<div [class]="isCustomerView ? 'timer-bloc timer-bloc-customer' : 'timer-bloc timer-bloc-admin'">
		<p *ngIf="!!counter" class="timer">{{counter.min}} : {{counter.sec | number: "2.0-0" }}</p>
	</div>
</div>

<div class="footer flex">
	<!-- <ion-fab-button (click)="toggleMuteVideo()">
		<ion-icon name="videocam"></ion-icon>
	</ion-fab-button> -->
	<div class="btn-call">
		<ion-fab-button color="medium" (click)="toggleMuteAudio()">
			<ion-icon [name]="isAudioMuted ? 'mic-off' : 'mic'"></ion-icon>
		</ion-fab-button>

		<ion-fab-button color="danger" (click)="hangupCall(true)">
			<ion-icon name="close"></ion-icon>
		</ion-fab-button>
	</div>

</div>