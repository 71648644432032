import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IGeolocData } from '../../../../model/navigation/IGeolocData';
import { NavigationService } from '../../../../services/navigation.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-filter-bar-geographical-search-input',
	templateUrl: './filter-bar-geographical-search-input.component.html',
	styleUrls: ['./filter-bar-geographical-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarGeographicalSearchInputComponent extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors eventDoc. */
	@Output("onLocationChanged") private readonly moLocationChangedEvent = new EventEmitter<IGeolocData>();

	//#endregion

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public currentPosition?: IGeolocData | null;
	@ObserveProperty<FilterBarGeographicalSearchInputComponent>({ sourcePropertyKey: "currentPosition" })
	public readonly observableCurrentPosition = new ObservableProperty<IGeolocData>();

	public observableCurrentPositionLabel = new ObservableProperty<string>();

	//#endregion

	//#region METHODS

	constructor(private readonly isvcNavigation: NavigationService) {
		super();

		this.observableCurrentPositionLabel.bind(
			this.observableCurrentPosition.value$.pipe(
				map((poData?: IGeolocData) =>
					poData ? `${poData.street} ${poData.zipCode} ${poData.city}` : undefined
				)
			),
			this
		);
	}

	public async locate(): Promise<void> {
		this.moLocationChangedEvent.emit(
			this.observableCurrentPosition.value = ArrayHelper.getFirstElement(await this.isvcNavigation.getCurrentGeolocData().toPromise())
		);
	}

	public reset(): void {
		this.moLocationChangedEvent.emit(
			this.observableCurrentPosition.value = undefined
		);
	}

	//#endregion

}
