<osapp-header-modal [title]="title"></osapp-header-modal>

<mat-list>
	<mat-radio-group class="liste-wrapper" [(ngModel)]="selectedRadioValue">
		<mat-list-item>
			<mat-radio-button [value]="newConversationKey">
				<div>Créer une nouvelle conversation</div>
				<mat-divider></mat-divider>
			</mat-radio-button>
		</mat-list-item>
		<calao-virtual-scroll autosize [templateCacheSize]="0" [itemSize]="50" [items]="conversations"
			class="create-conversation-list">
			<ng-template let-conversation="$implicit">
				<mat-list-item>
					<mat-radio-button class="mat-radio-button" [value]="conversation._id">
						<div>{{ conversation.title ? conversation.title : conversation.defaultTitle }}</div>
						<div class="txt10px date-label">{{ conversation.createDate | dateWithLocale : 'fr-FR' : 'dd/MM/yyyy' }}
						</div>
					</mat-radio-button>
				</mat-list-item>
				<mat-divider></mat-divider>
			</ng-template>
		</calao-virtual-scroll>
	</mat-radio-group>
</mat-list>

<ion-toolbar>
	<ion-buttons slot="end">
		<ion-button (click)="close()">Annuler</ion-button>
		<ion-button (click)="close(selectedRadioValue)" [disabled]="!selectedRadioValue" color="primary">Ok</ion-button>
	</ion-buttons>
</ion-toolbar>