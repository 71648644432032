import { EApplicationEventType } from "../../../model/application/EApplicationEventType";
import { IApplicationEvent } from "../../../model/application/IApplicationEvent";

export abstract class EventParticipantEventBase<T> implements IApplicationEvent {

	//#region PROPERTIES

	public abstract readonly type: EApplicationEventType;
	public readonly createDate = new Date;

	//#endregion

	//#region METHODS

	constructor(public data: T) { }

	//#endregion

}