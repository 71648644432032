import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { AiGenerationService } from '../../../../../services/ai-generation.service';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { NetworkService } from '../../../../../services/network.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { FieldBase } from '../../../models/FieldBase';
import { IAiGeneratedTextFieldParams } from '../../../models/IAiGeneratedTextFieldParams';
import { FormsService } from '../../../services/forms.service';

/** Permet d'ajouter la fonctionnalité de création de rapport à un champ dans un formulaire */
@Component({
	templateUrl: './ai-generate-text-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiGenerateTextFieldComponent extends FieldBase implements OnInit {

	//#region FIELDS

	private readonly C_LOG_ID = "FIELD.IA.C::";

	/** Représente le champ de formulaire où l'IA sera utilisée */
	private moTargetField: AbstractControl<any, any>;

	/** Indique si c'est la première fois que l'IA est activée */
	private mbIsFirstOpen = true;

	//#endregion

	//#region PROPERTIES

	/** Paramètres de configuration passés via le formulaire  */
	public params: IAiGeneratedTextFieldParams;

	/** Indique si le champ IA est ouvert */
	public readonly observableIsOpen = new ObservableProperty<boolean>(false);

	/** Contient la proposition de l'IA à afficher */
	public generatedTextPosition = 0;

	/** Contient les différentes générations de l'IA */
	public generatedTexts: string[] = [];

	/** Indique si un appel à l'api est en cours */
	public readonly observableIsLoading = new ObservableProperty<boolean>(false);

	/** Reprèsente le texte initial de l'utilisateur */
	public userText: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly poChangeDetectorRef: ChangeDetectorRef,
		private readonly isvcAiGeneration: AiGenerationService,
		private readonly isvcNetwork: NetworkService,
		private readonly isvcUiMessage: UiMessageService,
		psvcForms: FormsService,
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		this.moTargetField = this.form.get(this.params.targetKey);
	}

	/** Permet de générer un rapport */
	private async generateReport(): Promise<boolean> {
		let lsResponse: string;

		if (StringHelper.isBlank(this.moTargetField.value)) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Il est impossible de générer un rapport sans texte.", header: "Erreur" }));
			return false;
		}

		// La détection de l'absence d'internet ne fonctionne pas sur navigateur
		if (!await this.isvcNetwork.hasNetworkAsync()) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Le service IA a besoin d'une connexion internet.", header: "Erreur" }));
			return false;
		}

		this.observableIsLoading.value = true;
		try {
			lsResponse = await this.isvcAiGeneration.generateReport(this.moTargetField.value);
		} catch (loError) {
			console.error(this.C_LOG_ID, "Erreur lors de l'appel à l'API", loError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Erreur lors de communication avec l'IA. Vous devez être connecté à internet pour utiliser ce service. Si vous êtes connecté à internet, contactez le support technique.", header: "Erreur" }));
			this.observableIsLoading.value = false;
			this.poChangeDetectorRef.detectChanges();
			return false;
		}

		if (StringHelper.isBlank(this.generatedTexts[this.generatedTextPosition]))
			this.generatedTexts[this.generatedTextPosition] = lsResponse;
		else {
			this.generatedTexts.push(lsResponse);
			this.generatedTextPosition = this.generatedTexts.length - 1;
		}

		this.observableIsLoading.value = false;
		this.poChangeDetectorRef.detectChanges();
		return true;
	}

	/** Permet d'ouvrir le champs de proposition IA et de faire disparaître le bouton */
	public openProposalField(): void {
		if (this.mbIsFirstOpen) {
			this.generateReport().then((lbIsGenerate) => {
				if (lbIsGenerate) {
					this.mbIsFirstOpen = false;
					this.field.parent.className = "dis-flex flex-col";
					this.observableIsOpen.value = true;
					this.poChangeDetectorRef.detectChanges();
				}
			});
		} else {
			this.field.parent.className = "dis-flex flex-col";
			this.observableIsOpen.value = true;
		}
	}

	/** Permet de fermer le champs de proposition IA et de faire réapparaître le bouton */
	private closeProposalField(): void {
		this.observableIsOpen.value = false;
		this.field.parent.className = "dis-flex";
	}

	/** Action lors de l'acceptation de la proposition IA */
	public acceptProposal(): void {
		this.userText = this.moTargetField.value;
		this.moTargetField.setValue(this.generatedTexts[this.generatedTextPosition]);
		this.closeProposalField();
	}

	/** Action lors du refus de la proposition IA */
	public refuseProposal(): void {
		this.closeProposalField();
	}

	/** Génére un nouveau rapport */
	public newProposal(): void {
		this.generateReport();
	}

	/** Passe à la génération de texte précédente si possible */
	public downTextPosition(): void {
		if (this.generatedTextPosition > 0)
			this.generatedTextPosition -= 1;
	}

	/** Passe à la génération de texte suivante si possible */
	public upTextPosition(): void {
		if (this.generatedTextPosition < this.generatedTexts.length - 1)
			this.generatedTextPosition += 1;
	}

	//#endregion
}
