import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { ICurveOption } from '../../model/curve/ICurveOption';
import { ICurveParams } from '../../model/curve/ICurveParams';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';
import { CurveService } from './curve.service';

@Component({
	selector: 'calao-curve',
	templateUrl: 'curve.component.html'
})

export class CurveComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region PROPERTIES

	@Input() public curveData: any;
	@Input() public curveOption: ICurveOption;

	public view: [number, number];
	public results: Array<any>;
	public isFemale = true;
	public displayCurve = false;
	public setcurveDataForWomens;
	public scheme: any;
	public showXAxisLabel: boolean;
	public showYAxisLabel: boolean;
	public yAxisLabel: string;
	public xAxisLabel: string;
	public xAxis: boolean;
	public yAxis: boolean;
	public autoScale: boolean;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des courbes. */
		private isvcCurve: CurveService,
		private ioZone: NgZone,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef) {

		super(poParentPage, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		// On s'abonne aux événements du service pour écouter les ordres donnés par les autres composants.
		this.isvcCurve.getCurveEventObservable()
			.pipe(
				tap((poParams: { action: string, params: { curveOption: ICurveOption, curveData: Array<any> } }) => this.onCurveEvent(poParams)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		//Vérifier si c'est utile ou pas. A tester après migration du projet IMC.
		this.ioZone.onStable
			.pipe(
				take(1),
				tap(() => this.detectChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	protected onLifeCycleEvent(poEvent: IApplicationEvent): void { }

	/** Événement de la courbe qui exécute la méthode associée à l'action passée en paramètre.
	 * @param poEvent objet contenant l'action à réaliser et les paramètres pour la réaliser.
	 */
	public onCurveEvent(poEvent: { action: string, params: ICurveParams }): void {
		switch (poEvent.action) {

			case "refresh":
				this.refresh(poEvent.params);
				break;
		}
	}

	/** Raffraîchissement de la courbe avec de nouvelles valeurs.
	 * @param poOptions Options de la courbe qu'il faut mettre à jour.
	 */
	public refresh(poOptions: ICurveParams): void {
		this.results = poOptions.curveData;

		this.view = poOptions.curveOption.view as [number, number];
		this.autoScale = poOptions.curveOption.autoScale;
		this.scheme = poOptions.curveOption.scheme;
		this.showXAxisLabel = poOptions.curveOption.showXAxisLabel;
		this.showYAxisLabel = poOptions.curveOption.showYAxisLabel;
		this.xAxis = poOptions.curveOption.xAxis;
		this.yAxis = poOptions.curveOption.yAxis;
		this.xAxisLabel = poOptions.curveOption.xAxisLabel;
		this.yAxisLabel = poOptions.curveOption.yAxisLabel;

		this.displayCurve = true;

		this.detectChanges();
	}

	//#endregion
}
