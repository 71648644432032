import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerNamePipe } from './customer-name.pipe';
import { CustomersService } from './services/customers.service';

@NgModule({
	declarations: [CustomerNamePipe],
	exports: [CustomerNamePipe],
	imports: [
		CommonModule
	],
	providers: [CustomersService]
})
export class CustomerModule { }
