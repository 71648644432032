import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserData } from '../../../model/application/UserData';
import { IContact } from '../../../model/contacts/IContact';
import { IConversation } from '../../../model/conversation/IConversation';
import { IOpenConversationOptions } from '../../../model/conversation/IOpenConversationOptions';
import { IEntity } from '../../../model/entities/IEntity';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../services/uiMessage.service';
import { IConversationButtonParams } from '../model/IConversationButtonParams';

@Component({
	selector: "osapp-conversation-button",
	templateUrl: './conversation-button.component.html',
	styleUrls: ['./conversation-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationButtonComponent extends ComponentBase implements OnInit, IConversationButtonParams {

	//#region FIELDS

	private static readonly C_DEFAULT_SHAPE = "round";
	private static readonly C_DEFAULT_FILL = "solid";
	private static readonly C_DEFAULT_ICON = "chatbubbles";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public linkEntityToConversation: boolean;
	/** @implements */
	@Input() public openVisio: boolean;
	/** @implements */
	@Input() public setEntityAsConversationParticipant: boolean;
	/** @implements */
	@Input() public currentEntity: IEntity;
	/** @implements */
	@Input() public galleryAcceptFiles: string;
	/** @implements */
	@Input() public shape: string;
	/** @implements */
	@Input() public fill: string;
	/** @implements */
	@Input() public label: string;
	/** @implements */
	@Input() public icon: string;
	/** @implements */
	@Input() public cssClass: string;

	private mbDisabled = false;
	public get disabled(): boolean { return this.mbDisabled; }
	/** @implements */
	@Input() public set disabled(pbNewValue: boolean) {
		if (typeof pbNewValue === "boolean" && pbNewValue !== this.mbDisabled) {
			this.mbDisabled = pbNewValue;
			this.detectChanges();
		}
	}

	@Output("onConversationOpened") private readonly moConversationOpenedEventEmitter = new EventEmitter<IConversation>();

	//#endregion

	//#region METHODS

	constructor(
		private isvcConversation: ConversationService,
		private isvcEntityLinks: EntityLinkService,
		private isvcUiMessage: UiMessageService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (StringHelper.isBlank(this.icon))
			this.icon = ConversationButtonComponent.C_DEFAULT_ICON;
		if (StringHelper.isBlank(this.fill))
			this.fill = ConversationButtonComponent.C_DEFAULT_FILL;
		if (StringHelper.isBlank(this.shape))
			this.shape = ConversationButtonComponent.C_DEFAULT_SHAPE;
		if (!this.currentEntity)
			this.currentEntity = this.isvcEntityLinks.currentEntity;

		this.setEntityAsConversationParticipant = typeof this.setEntityAsConversationParticipant === "boolean" ?
			this.setEntityAsConversationParticipant : this.setEntityAsConversationParticipant !== undefined;

		this.linkEntityToConversation = typeof this.linkEntityToConversation === "boolean" ?
			this.linkEntityToConversation : this.linkEntityToConversation !== undefined;
	}

	public onClick(): void {
		if (this.disabled)
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Vous devez indiquer l'email de ce contact avant d'ouvrir une conversation avec lui.` }));
		else
			this.createOrOpenConversation();
	}

	private createOrOpenConversation(): void {
		const loCurrentParticipant: IContact | null = (this.setEntityAsConversationParticipant && this.currentEntity) ?
			this.currentEntity.model as IContact : null;

		// On lie la conversation à l'entité active à condition qu'il ne s'agisse pas du contact participant (les participants ne sont pas liés).
		const laEntities: Array<IEntity> =
			this.linkEntityToConversation && this.currentEntity && loCurrentParticipant !== this.currentEntity.model ?
				[this.currentEntity] : [];

		const loOptions: IOpenConversationOptions = {
			participants: loCurrentParticipant ? [loCurrentParticipant] : [],
			linkedEntities: laEntities,
			galleryAcceptFiles: this.galleryAcceptFiles,
			openVisio: this.openVisio
		};

		if (UserData.current) {
			this.isvcConversation.createOrOpenConversation(ContactsService.getContactIdFromUserId(UserData.current.name), loOptions)
				.pipe(
					tap(
						(poConversation?: IConversation) => this.moConversationOpenedEventEmitter.emit(poConversation),
						poError => console.error("CONVBTN.C::", poError)
					),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else
			console.error("CONVBTN.C::Error opening or creating a conversation: no current user.");

	}

	//#endregion

}