import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionButtonFieldBaseComponent } from '@calaosoft/osapp/components/forms/form/customFields/actionButtons/actionButtonFieldBase.component';
import { ERouteUrlPart } from '@calaosoft/osapp/model/route/ERouteUrlPart';
import { FormsService } from '@calaosoft/osapp/services/forms.service';

@Component({
	templateUrl: './actionButtonFieldReport.component.html'
})
export class ActionButtonFieldReportComponent extends ActionButtonFieldBaseComponent<any> {

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Ouvre un composant. */
	public override action(): void {
		this.ioRouter.navigate(["rapports", ERouteUrlPart.new], { state: { parentModel: this.model } });
	}

	//#endregion
}