import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanEditGroupGuard } from '@calaosoft/osapp/components/contacts/can-edit-group.guard';
import { ContactEditPageInfoResolver } from '@calaosoft/osapp/components/contacts/contact-edit-pageInfo.resolver';
import { ContactResolver } from '@calaosoft/osapp/components/contacts/contact.resolver';
import { GroupResolver } from '@calaosoft/osapp/components/contacts/group.resolver';
import { ConversationResolver } from '@calaosoft/osapp/components/conversation/conversation.resolver';
import { CanLeaveConversationEdit } from '@calaosoft/osapp/components/conversation/edit/guards/canLeaveConversationEdit.guard';
import { DynamicPageComponent } from '@calaosoft/osapp/components/dynamicPage/dynamicPage.component';
import { GoogleMapComponent } from '@calaosoft/osapp/components/geolocation/googleMap.component';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { CanDeactivateGuard } from '@calaosoft/osapp/guards/canDeactivate.guard';
import { GuestGuard } from '@calaosoft/osapp/guards/guest.guard';
import { LogOutGuard } from '@calaosoft/osapp/guards/log-out.guard';
import { NotAuthenticatedGuard } from '@calaosoft/osapp/guards/not-authenticated.guard';
import { ESuffix } from '@calaosoft/osapp/model/ESuffix';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { CanActivateGuardsScheduler } from "@calaosoft/osapp/modules/routing/models/can-activate-guards-scheduler";
import { RemoveCredentialsGuard } from '@calaosoft/osapp/modules/security/guards/remove-credentials.guard';
import { FormEntryResolver } from '@calaosoft/osapp/resolvers/formEntry.resolver';
import { TEXPLUSERP_URL } from '../config';

const HOME_PAGE_INFO = new PageInfo({
	templateId: "home",
	componentName: "home",
	title: "Accueil",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		menuKey: "homeMenu"
	}
});

const CUSTOMERS_PAGE_INFO = new PageInfo({
	title: "Clients",
	componentName: "customers",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		idFormDesc: "formDesc_customer",
		idFormList: "allCustomers"
	}
});

const CUSTOMER_PAGE_INFO = new PageInfo({
	title: "Client",
	componentName: "form",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		formDescriptorId: 'formDesc_customer'
	}
});

const EDIT_REPORT_PAGE_INFO = new PageInfo({
	title: "Rapport",
	componentName: "form",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		formDefinitionId: "report_edit",
		formDescriptorId: "formDesc_customer_report"
	}
});

const REPORT_PAGE_INFO = new PageInfo({
	title: "Rapport",
	componentName: "form",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		formDescriptorId: "formDesc_customer_report"
	}
});

const CONTACTS_PAGE_INFO = new PageInfo({
	title: "Contacts",
	componentName: "contacts-book",
	hasMenuButton: true,
	hasSyncButton: true,
});

const CONTACT_VIEW_PAGE_INFO = new PageInfo({
	title: "Contact",
	componentName: "contact-form",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		formDefinitionId: `contact${ESuffix.view}`,
		formDescriptorId: "formDesc_contacts"
	}
});

const CONTACT_EDIT_PAGE_INFO = new PageInfo({
	title: "Contact",
	componentName: "contact-form",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		formDefinitionId: `contact${ESuffix.edit}`,
		formDescriptorId: "formDesc_contacts"
	}
});

const GROUP_EDIT_PAGE_INFO = new PageInfo({
	title: "Détails du groupe",
	componentName: "group-details",
	hasMenuButton: true,
	hasSyncButton: true
});

const CONVERSATIONS_PAGE_INFO = new PageInfo({
	title: "Conversations",
	componentName: "conversationTab",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		params: {
			galleryCameraButtonVisible: false,
			galleryFilePickerVisible: false,
			galleryAcceptFiles: "image"
		}
	}
});

const CONVERSATION_PAGE_INFO = new PageInfo({
	title: "Conversation",
	componentName: "conversation",
	hasMenuButton: true,
	hasSyncButton: true,
	params: {
		galleryCameraButtonVisible: false,
		galleryFilePickerVisible: false,
		galleryAcceptFiles: "image"
	}
});

const CONVERSATION_EDIT_PAGE_INFO = new PageInfo({
	title: "Conversation",
	componentName: "conversationEdit",
	hasMenuButton: true,
	hasSyncButton: true
});

const SUPPORT_PAGE_INFO = new PageInfo({
	title: "Support",
	componentName: "support-page",
	hasMenuButton: true,
	hasSyncButton: true
});

const GOOGLE_MAP_PAGE_INFO = new PageInfo({
	hasMenuButton: true,
	hasHomeButton: true,
	hasBackButton: true,
	hasSyncButton: true
});

const GESTION_FRAIS_PAGE_INFO = new PageInfo({
	title: "Gestion des frais",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: TEXPLUSERP_URL,
		cssClass: "full-size"
	}
});

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'home',
		component: DynamicPageComponent,
		data: {
			pageInfo: HOME_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'authenticator',
		loadChildren: () => import('./authentication/authentication-page.module').then(poModule => poModule.AuthenticationPageModule),
		canActivate: [NotAuthenticatedGuard]
	},
	{
		path: 'clients',
		component: DynamicPageComponent,
		data: {
			pageInfo: CUSTOMERS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'clients/:modelId',
		component: DynamicPageComponent,
		data: {
			pageInfo: CUSTOMER_PAGE_INFO
		},
		resolve: { model: FormEntryResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'rapports/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: {
				...EDIT_REPORT_PAGE_INFO,
				title: "Création rapport"
			}
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'rapports/:modelId',
		component: DynamicPageComponent,
		data: {
			pageInfo: REPORT_PAGE_INFO
		},
		resolve: { model: FormEntryResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'rapports/:modelId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: EDIT_REPORT_PAGE_INFO
		},
		resolve: { model: FormEntryResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACTS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACT_EDIT_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/:contactId',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACT_VIEW_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/:contactId/edit',
		component: DynamicPageComponent,
		resolve: {
			model: ContactResolver,
			pageInfo: ContactEditPageInfoResolver
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/groupes/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/groupes/:groupId',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/groupes/:groupId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard, CanEditGroupGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATIONS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations/:conversationId',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATION_PAGE_INFO
		},
		resolve: { conversation: ConversationResolver },
		canActivate: [GuestGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations/:conversationId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATION_EDIT_PAGE_INFO
		},
		resolve: { conversation: ConversationResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard, CanLeaveConversationEdit]
	},
	{
		path: 'support',
		component: DynamicPageComponent,
		data: {
			pageInfo: SUPPORT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'googleMap',
		component: GoogleMapComponent,
		data: {
			pageInfo: GOOGLE_MAP_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'disconnect',
		loadChildren: () => import('@calaosoft/osapp/modules/authentication/authentication.module').then(poModule => poModule.AuthenticationModule),
		canActivate: [LogOutGuard]
	},
	{
		path: 'invalidate-session',
		loadChildren: () => import('@calaosoft/osapp/modules/authentication/authentication.module').then(poModule => poModule.AuthenticationModule),
		//! On doit séquentialiser et appeler `LogOutGuard` en second car sinon on navigue directement et les autres gardiens ne sont pas activés.
		canActivate: [CanActivateGuardsScheduler.schedule([RemoveCredentialsGuard, LogOutGuard])]
	},
	{
		path: 'frais',
		component: DynamicPageComponent,
		data: {
			pageInfo: GESTION_FRAIS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'diagnostics',
		loadChildren: () => import('./diagnostics/diagnostics.module').then(poModule => poModule.DiagnosticsPageModule)
	},
	{
		path: 'changelogs',
		loadChildren: () => import('@calaosoft/osapp/modules/about/changelogs/changelogs.module').then(m => m.ChangelogsModule),
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }