import { StringHelper } from '../../helpers/stringHelper';
import { EntityBuilder } from '../../services/EntityBuilder';
import { IConversation } from '../conversation/IConversation';
import { EPrefix } from '../EPrefix';
import { EAvatarSize } from '../picture/EAvatarSize';
import { EDatabaseRole } from '../store/EDatabaseRole';
import { DefaultEntityGuard } from './DefaultEntityGuard';
import { Entity } from './Entity';

export class ConversationEntityBuilder extends EntityBuilder<IConversation>{
	constructor() {
		super(
			(psModelId: string) => RegExp(EPrefix.conversation).test(psModelId),
			(poConversation: IConversation) => new Entity(
				poConversation,
				`conversations/${poConversation._id}`,
				() => StringHelper.isBlank(poConversation.title) ? `Nouvelle conversation` : poConversation.title
			),
			(psModelId: string) => "Conversations",
			EDatabaseRole.conversations,
			EPrefix.conversation,
			() => ({
				size: EAvatarSize.medium,
				icon: "chatbubbles"
			}),
			undefined,
			undefined,
			undefined,
			new DefaultEntityGuard("Cette conversation est liée")
		);
	}
}