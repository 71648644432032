import PCancelable from 'p-cancelable';
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Database } from "../../../../model/store/Database";
import { IStoreDocument } from "../../../../model/store/IStoreDocument";
import { IStoreReplicationResponse } from "../../../../model/store/IStoreReplicationResponse";
import { NetworkService } from "../../../../services/network.service";
import { NotImplementedError } from '../../../errors/model/not-implemented-error';
import { NoDatabaseInternetConnectionError } from "../../model/errors/no-database-internet-connection-error";
import { IOnProgressFunction } from "./ion-progress-function";
import { IReplicatorParamsBase } from './ireplicator-params-base';

export abstract class ReplicatorBase {

	//#region METHODS

	constructor(protected readonly isvcNetwork: NetworkService, public readonly name: string) { }

	/** Réplique une base de données.
	 * @param poParams
	 * @param pfOnProgress
	 * @throws `ReplicateError` si une erreur survient lors de la réplication.
	 */
	public abstract replicateAsync(poParams: IReplicatorParamsBase, pfOnProgress?: IOnProgressFunction): PCancelable<IStoreReplicationResponse<IStoreDocument>>;

	/** Réplique en continue une base de données.
	 * @param poParams
	 * @throws `ReplicateError` si une erreur survient lors de la réplication.
	 */
	public replicateLive$(poParams: IReplicatorParamsBase): Observable<IStoreReplicationResponse<IStoreDocument>> {
		throw new NotImplementedError("replicateLive$()");
	}

	protected async handleNetworkAsync(poDatabase: Database): Promise<void> {
		if (!await this.isvcNetwork.asyncIsNetworkReliable().toPromise())
			throw this.createConnectionError(poDatabase);
	}

	private createConnectionError(poDatabase: Database): NoDatabaseInternetConnectionError {
		return new NoDatabaseInternetConnectionError(poDatabase.id, "La connexion réseau est indispensable pour une synchronisation.");
	}

	protected handleNetwork$(poDatabase: Database): Observable<void> {
		return this.isvcNetwork.asyncIsNetworkReliable().pipe(
			mergeMap((pbHasNetwork: boolean) => pbHasNetwork ?
				of(undefined) :
				throwError(this.createConnectionError(poDatabase))
			)
		);
	}

	//#endregion METHODS

}