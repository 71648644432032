import { Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
	selector: '[osappAutoCloseSlideItem]'
})
export class AutoCloseSlideItemDirective implements OnDestroy {

	//#region FIELDS

	private static readonly C_NB_STEPS = 20;
	private static readonly C_THRESHOLDS: number[] = AutoCloseSlideItemDirective.buildThresholds();

	private readonly moMutationObserver = new IntersectionObserver(() => {
		this.ioSlidingItem.nativeElement.close();
	}, { threshold: AutoCloseSlideItemDirective.C_THRESHOLDS });

	//#endregion

	//#region METHODS

	constructor(private readonly ioSlidingItem: ElementRef<HTMLIonItemSlidingElement>) {
		this.moMutationObserver.observe(this.ioSlidingItem.nativeElement);
	}

	public ngOnDestroy(): void {
		this.moMutationObserver.disconnect();
	}

	private static buildThresholds(): number[] {
		const laThresholds: number[] = [];

		for (let lnIndex = 0; lnIndex <= AutoCloseSlideItemDirective.C_NB_STEPS; lnIndex++) {
			laThresholds.push(lnIndex / AutoCloseSlideItemDirective.C_NB_STEPS);
		}

		return laThresholds;
	}

	//#endregion
}
