import { Component, OnInit } from '@angular/core';
import { PatternResolverService } from '../../../../../services/pattern-resolver.service';
import { FormHelper } from '../../../helpers/formHelper';
import { FieldBase } from '../../../models/FieldBase';
import { IOneTimeFilledField } from '../../../models/fieldComponent/ione-time-filled-field';
import { FormsService } from '../../../services/forms.service';

@Component({
	template: ''
})
export class HiddenFieldComponent extends FieldBase<string> implements OnInit, IOneTimeFilledField { //todo : params.

	//#region PROPERTIES

	public wasFilled: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPatternResolver: PatternResolverService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.wasFilled = !!this.fieldValue;

		if (this.to.data.value) {
			if (FormHelper.canBeFilled(this.to.data.oneTimeFilled, this, this.fieldValue)) {
				this.to.data.value = this.replaceDynValues();
				this.fieldValue = this.to.data.value;
			}
		}
	}

	/**Remplace le pattern par une valeur dynamique*/
	private replaceDynValues(): string {
		let lnIndex = 0;

		return (this.to.data.value as string)
			.split((/[{{}}]+/))
			.reduce((psAccumulator: string, psCurrentValue: string) => {
				if (lnIndex % 2 === 0) {
					++lnIndex;
					return psAccumulator + psCurrentValue;
				}
				else {
					++lnIndex;
					return psAccumulator + this.isvcPatternResolver.resolveFormsPattern(psCurrentValue);
				}
			}, "");
	}

	//#endregion
}