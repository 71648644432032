<!-- Mode Edit (!RO) -->
<div *ngIf="!(observableIsReadonly.value$ | async) as isReadonly">
	<ion-range [min]="observableMinValue.value$ | async" [max]="observableMaxValue.value$ | async"
		[step]="observableStep.value$ | async" [pin]="observablePin.value$ | async" [value]="observableValue.value$ | async"
		[ticks]="observableTicks.value$ | async" [snaps]="observableSnaps.value$ | async"
		[style]="observableStyle.value$ | async" (ionChange)="onChanged($event)">
	</ion-range>
	<div class="bottom">
		<div class="label">
			<ion-label *ngIf="(observableShowMinValue.value$ | async) && (observableMinValue.value$ | async) as minValue">
				{{ minValue }}</ion-label>
			<ion-label *ngIf="observableMinLabel.value$ | async as minLabel">{{ minLabel }}</ion-label>
		</div>
		<div class="label">
			<ion-label *ngIf="(observableShowMaxValue.value$ | async) && (observableMaxValue.value$ | async) as maxValue"
				class="align-right">{{ maxValue }}</ion-label>
			<ion-label *ngIf="observableMaxLabel.value$ | async as maxLabel">{{ maxLabel }}</ion-label>
		</div>
	</div>
</div>