import { Injectable } from '@angular/core';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { IdHelper } from '../../helpers/idHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EPrefix } from '../../model/EPrefix';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreDataResponse } from '../../model/store/IStoreDataResponse';
import { SecurityService } from '../../services/security.service';
import { Store } from '../../services/store.service';
import { DestroyableServiceBase } from '../services/models/destroyable-service-base';
import { Queue } from '../utils/queue/decorators/queue.decorator';
import { IPreferences } from './model/IPreferences';
import { IPreferencesConfig } from './model/IPreferencesConfig';

@Injectable()
export abstract class PreferencesServiceBase<T extends IPreferences = IPreferences, U extends IPreferencesConfig = IPreferencesConfig> extends DestroyableServiceBase {

	//#region FIELDS

	protected get databaseId(): string {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext, false));

		if (StringHelper.isBlank(lsDatabaseId))
			console.log(`PREF.S::Aucune base de rôle ${EDatabaseRole.userContext}.`);

		return lsDatabaseId;
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Prefixe du document de préférences. */
		protected mePreferencesPrefix: EPrefix,
		protected isvcStore: Store,
		protected isvcSecurity: SecurityService,
		protected ioPreferencesConfig?: U
	) {
		super();
	}

	/** Récupère les préférences.
	 * @param pePrefix Préfixe du type de donnée dont on veut récupérer les préférences.
	 */
	public get(pePrefix: EPrefix, pbLive?: boolean): Observable<T> {
		const loDataSource: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				key: `${this.mePreferencesPrefix}${this.isvcSecurity.userId}-${pePrefix}`,
				include_docs: true
			},
			live: pbLive
		};

		if (StringHelper.isBlank(loDataSource.databaseId))
			return EMPTY;

		return this.isvcStore.getOne<T>(loDataSource, false);
	}

	/** Ajoute une préférence.
	 * @param psId Identifiant de la donnée à ajouter en préférence.
	 * @param poData Document de préférences à modifier.
	 */
	@Queue<PreferencesServiceBase, Parameters<PreferencesServiceBase["add"]>, ReturnType<PreferencesServiceBase["add"]>>({
		idBuilder: (psId: string) => IdHelper.getPrefixFromId(psId)
	})
	public add(psId: string, poData?: T): Observable<boolean> {
		return this.getOrReturnPreference(psId, poData)
			.pipe(
				mergeMap((poPreferences?: T) => {
					const loNewPreferences: T = this.createOrClonePrefences(psId, poPreferences);

					if (loNewPreferences.ids.includes(psId))
						ArrayHelper.removeElement(loNewPreferences.ids, psId);

					loNewPreferences.ids.push(psId);

					if (loNewPreferences.ids.length > (this.ioPreferencesConfig?.limit ?? loNewPreferences.ids.length))
						loNewPreferences.ids.shift();

					return this.update(poPreferences, loNewPreferences);
				})
			);
	}

	/** Récupère la préférence ou bien la retourne si passée en paramètre.
	 * @param psId Identifiant ou préfixe de la ressource visée.
	 * @param poData Document de préférence à retourner si défini.
	 * @returns
	 */
	protected getOrReturnPreference(psId: string, poData?: T): Observable<T> {
		return defer(() => !poData ? this.get(IdHelper.getPrefixFromId(psId)) : of(poData));
	}

	/** Permet de créer une nouvelle préférence si le document passé en paramètre n'est pas défini.
	 * @param psId Identifiant ou préfixe de la ressource visée.
	 * @param poPreferences
	 * @returns
	 */
	protected createOrClonePrefences(psId: string, poPreferences?: T): T {
		return !poPreferences ? this.createPreferencesDoc(IdHelper.getPrefixFromId(psId)) : this.clonePreferences(poPreferences);
	}

	/** Supprime une préférence.
	 * @param psId Identifiant de la donnée à supprimer en préférence.
	 * @param poData Document de préférences à modifier.
	 */
	@Queue<PreferencesServiceBase, Parameters<PreferencesServiceBase["remove"]>, ReturnType<PreferencesServiceBase["remove"]>>({
		idBuilder: (psId: string) => IdHelper.getPrefixFromId(psId)
	})
	public remove(psId: string, poData?: T): Observable<boolean> {
		return this.getOrReturnPreference(psId, poData)
			.pipe(
				mergeMap((poPreferences?: T) => {
					if (!poPreferences)
						return of(true);

					const loOldPreferences: T = this.clonePreferences(poPreferences);

					ArrayHelper.removeElement(poPreferences.ids, psId);

					return this.update(loOldPreferences, poPreferences);
				})
			);
	}

	/** Change le status d'une préférence.
	 * @param psId Identifiant de la donnée à modifier en préférence.
	 * @param poData Document de préférences à modifier.
	 */
	@Queue<PreferencesServiceBase, Parameters<PreferencesServiceBase["toggle"]>, ReturnType<PreferencesServiceBase["toggle"]>>({
		idBuilder: (psId: string) => IdHelper.getPrefixFromId(psId)
	})
	public toggle(psId: string, poData?: T): Observable<boolean> {
		return this.getOrReturnPreference(psId, poData)
			.pipe(
				mergeMap((poPreferences?: T) => {
					if (!poPreferences || !poPreferences.ids.includes(psId))
						return this.add(psId, poData);

					return this.remove(psId, poData);
				})
			);
	}

	/** Réinitialise les préférences
	 * @param pePrefix Préfixe du type de donnée duquel on veut réinitialiser les préférences.
	 */
	@Queue<PreferencesServiceBase, Parameters<PreferencesServiceBase["reset"]>, ReturnType<PreferencesServiceBase["reset"]>>({
		idBuilder: (pePrefix: EPrefix) => pePrefix
	})
	public reset(pePrefix: EPrefix): Observable<boolean> {
		return this.get(pePrefix)
			.pipe(
				mergeMap((poPreferences?: T) => {
					if (!poPreferences)
						return of(true);

					const loOldPreferences: T = this.clonePreferences(poPreferences);
					poPreferences.ids = [];

					return this.update(loOldPreferences, poPreferences);
				})
			);
	}

	/** Crée un document de préférences.
	 * @param pePrefix Préfixe du type de donnée pour lequel on veut créer un document de préférences.
	 */
	protected createPreferencesDoc(pePrefix: EPrefix): T {
		return {
			_id: IdHelper.buildId(this.mePreferencesPrefix, `${this.isvcSecurity.userId}-${pePrefix}`),
			ids: []
		} as T;
	}

	/** Met à jour les préférences si besoin.
	 * @param poOldPreferences
	 * @param poNewPreferences
	 */
	protected update(poOldPreferences: T, poNewPreferences: T): Observable<boolean> {
		if (StringHelper.isBlank(this.databaseId))
			return EMPTY;
		else if (this.preferenceDirty(poOldPreferences, poNewPreferences))
			return this.isvcStore.put(poNewPreferences, this.databaseId).pipe(map((poResponse: IStoreDataResponse) => poResponse.ok));
		else
			return of(true);
	}

	/** Indique si la préférence a eu un changement.
	 * @param poOldPreferences
	 * @param poNewPreferences
	 * @returns
	 */
	protected preferenceDirty(poOldPreferences: T, poNewPreferences: T): boolean {
		return ((poNewPreferences?.ids.length ?? 0) !== (poOldPreferences?.ids.length ?? 0)) ||
			poNewPreferences?.ids.some((psId: string, pnIndex: number) => psId !== poOldPreferences?.ids[pnIndex]);
	}

	/** Crée un clone des préférences.
	 * @param poPreferences
	 */
	protected clonePreferences(poPreferences: T): T {
		return { ...poPreferences, ids: [...(poPreferences.ids ?? [])] };
	}

	//#endregion

}