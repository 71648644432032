import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, defer, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { FileHelper } from '../../../helpers/fileHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IHttpOptions } from '../../../model/IHttpOptions';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { ConfigData } from '../../../model/config/ConfigData';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ApplicationService } from '../../../services/application.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { LogAction } from '../../logger/decorators/log-action.decorator';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { ILogSource } from '../../logger/models/ILogSource';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';
import { ITransfertParameters } from '../../transfert/models/Itransfert-parameters';
import { TransfertService } from '../../transfert/services/transfert.service';
import { DmsFile } from '../model/DmsFile';
import { IDms } from '../model/IDms';
import { IDmsData } from '../model/IDmsData';
import { IDmsDocumentAttribute } from '../model/IDmsDocumentAttribute';
import { IDmsEvent } from '../model/IDmsEvent';
import { IDmsMeta } from '../model/IDmsMeta';
import { IDmsProgressEvent } from '../model/idms-progress-event';

interface IServerDmsMeta {
	/** Identifiant du fichier. */
	docId: string,
	/** Date de dernier accès au fichier stringifiée. */
	lastAccessDate: string,
	/** Date de création du fichier stringifiée. */
	createDate: string,
	/** Description du fichier, à l'air d'être constamment "Rapport INCONNU INCONNU". */
	description: string,
	/** Date de modification du fichier stringifiée. */
	modifyDate: string,
	/** MimeType. */
	contentType: string,
	/** Taille du fichier. */
	size: number,
	/** Nom du fichier avec extension. */
	name: string,
	/** Extension du fichier. */
	ext: string,
	/** Type de document. */
	type: string,
	/** Type et extension du fichier : "TYP={type},EXT={ext},". */
	attributes: string,
	/** Chemin de classification dans les interfaces de gestion des documents */
	paths: string[],
	/** Id de l'auteur. */
	authorId: string;
	/** Titre du document. */
	title: string
}

/** Service de gestion des fichiers sur le système de fichier en mode remote uniquement. */
@Injectable({ providedIn: "root" })
export class RemoteDmsService implements IDms, ILogSource {

	//#region FIELDS

	/** "/meta" */
	private readonly C_META = "/meta";
	/** Sujet pour l'envoi d'événement. */
	private readonly moDmsEventSubject: Subject<IDmsEvent> = new Subject();

	/** URL qui pointe vers le serveur. */
	private C_SERVER_LINK: string;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "RDMS.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des popups et toasts. */
		protected readonly isvcUiMessage: UiMessageService,
		/** Service de gestion des requêtes http. */
		private readonly ioHttpClient: HttpClient,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcTransfert: TransfertService,
		psvcApplication: ApplicationService
	) {
		this.init(psvcApplication);
	}

	/** @implements */
	public execUploads(paPendings: IStoreDocument[], pbHasToContinueIfFail: boolean): Observable<string[]> {
		return of([]);
	}

	/** @implements */
	public getPendingDocs(pePrefix: EPrefix, pbLive: boolean): Observable<IStoreDocument[]> {
		return of([]);
	}

	/** Initialisation du service qui se sert de données de config.
	 * @param psvcApplication Service de gestion de l'application.
	 */
	private init(psvcApplication: ApplicationService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.C_SERVER_LINK = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_dms_suffix}`))
			.subscribe();
	}

	/** Crée un URL vers le serveur à partir de l'identifiant du fichier à manipuler.
	 * @param psId Identifiant du fichier du DMS avec lequel créer l'URL.
	 */
	private createUrl(psId: string): string {
		return `${this.C_SERVER_LINK}${GuidHelper.extractGuid(psId)}`;
	}

	/** Supprime le fichier sur le serveur ainsi que son méta associé.
	 * @param psId Identifiant du fichier à supprimer.
	 */
	public delete(psId: string): Observable<boolean> {
		const loOptions: IHttpOptions<"arraybuffer"> = this.getHttpBaseOptions("arraybuffer");
		loOptions.params = { DocumentMeta: `{"type":"RPTDOC"}` };

		return this.ioHttpClient.delete(this.createUrl(psId), loOptions) // Suppression fichier ET méta sur le serveur.
			.pipe(
				catchError(poError => { console.error(`RDMS.S:: Erreur de suppression du fichier "${psId}" sur le serveur : `, poError); return throwError(poError); }),
				map(_ => {
					console.log(`RMDS.S:: Suppression du fichier "${psId}" sur le serveur réussi !`);
					this.raiseEvent(psId);
					return true;
				})
			);
	}

	/** Lève un événement pour notifier un changement.
	 * @param psFileId Identifiant du fichier.
	 */
	private raiseEvent(psFileId: string, poDmsFile?: DmsFile, poDmsMeta?: IDmsMeta): void {
		const loEvent: IDmsEvent = {
			type: EApplicationEventType.DmsEvent,
			createDate: new Date(),
			data: {
				fileId: psFileId,
				dmsFile: poDmsFile,
				dmsMeta: poDmsMeta
			}
		};

		this.moDmsEventSubject.next(loEvent);
	}

	/** Récupération d'un fichier depuis le serveur à partir de ses méta.
	 * @param psId Identifiant du fichier à récupérer.
	 * @param pnQuality Pas pris en compte.
	 * @param pfOnProgress Callback appelée lors de l'avancement de téléchargement.
	 */
	public get(psId: string, pnQuality: number, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsData> {
		return this.getMeta(psId)
			.pipe(
				mergeMap((poDmsMetaResult: IDmsMeta) => {

					const loParameters: ITransfertParameters = {
						fileUrl: this.createUrl(psId),
						headers: new HttpHeaders({
							appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
							Token: ConfigData.authentication.token
						}),
						onProgress: pfOnProgress,
						fileSize: poDmsMetaResult.size
					};

					return this.isvcTransfert.downloadFile$(loParameters)
						.pipe(
							tap(
								(poBlob: Blob) => console.debug(`RDMS.S:: Récupération du fichier "${psId}" sur le serveur réussi !`, poBlob),
								poError => console.error(`RDMS.S:: Erreur récupération fichier "${psId}" : `, poError)
							),
							map((poBlobResult: Blob): IDmsData => {
								return {
									file: new DmsFile(this.getBlobWithGoodMimeType(poBlobResult, poDmsMetaResult.name), poDmsMetaResult),
									meta: poDmsMetaResult
								};
							})
						);
				})
			);
	}

	/** Permet de récupérer des options http basiques comprenant les infos utiles pour communiquer avec le serveur.
	 * @param poResponseType Type de la réponse du serveur.
	 */
	private getHttpBaseOptions<T extends "blob" | "arraybuffer" | "json">(poResponseType: T): IHttpOptions<T> {
		const loHttpBaseOptions: IHttpOptions<T> = {
			responseType: poResponseType,
			headers: { appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo) }
		};

		if (ConfigData.authentication.token)
			loHttpBaseOptions.headers["Token"] = ConfigData.authentication.token;

		return loHttpBaseOptions;
	}

	/** Récupération d'un document de méta du DMS sur le serveur à partir de son identifiant.
	 * @param psId Identifiant du DmsMeta à récupérer.
	 */
	public getMeta(psId: string): Observable<IDmsMeta> {
		//!! Peut créer des problèmes, à surveiller.
		psId = IdHelper.extractIdWithoutPrefix(psId, EPrefix.pendingDownload);

		return this.ioHttpClient.get(`${this.createUrl(psId)}${this.C_META}`, this.getHttpBaseOptions("json"))
			.pipe(
				tap(
					_ => { },
					poError => {
						console.error(`RDMS.S:: Erreur récupération meta "${psId}" : `, poError);
						this.raiseEvent(psId);
					}
				),
				map((poResult: IServerDmsMeta): IDmsMeta => {
					return {
						_id: IdHelper.buildId(EPrefix.dms, poResult.docId),
						lastAccess: poResult.lastAccessDate ? new Date(poResult.lastAccessDate) : new Date(), // Utilise la date actuelle si pas de date retournée par l'API
						createDate: poResult.createDate ? new Date(poResult.createDate) : new Date(), // Utilise la date actuelle si pas de date retournée par l'API
						description: poResult.description,
						modifyDate: poResult.modifyDate ? new Date(poResult.modifyDate) : new Date(), // Utilise la date actuelle si pas de date retournée par l'API
						size: poResult.size,
						name: poResult.name,
						originalName: poResult.name,
						documentType: poResult.type,
						criteria: [],
						paths: poResult.paths,
						authorId: poResult.authorId,
						title: poResult.title
					};
				})
			);
	}

	/** Récupère un blob avec le bon type mime (clone celui en paramètre s'il n'a pas un type mime correct).
	 * @param poBlob Blob qu'il faut peut-être cloner pour obtenir un clone avec le bon type mime.
	 * @param psName Nom du fichier que représente le blob (avec extension).
	 */
	private getBlobWithGoodMimeType(poBlob: Blob, psName: string): Blob {
		// Cas `image/*` notamment : sur webapp, si on tente de télécharger des images prises par un mobile on peut ne pas avoir d'extension automatiquement
		// lors de l'enregistrement du fichier sur le pc.

		if (poBlob.type.includes("*")) {
			const lsMimeType: string | undefined = FileHelper.extractMimeTypeFromFileNameWithExtension(psName);
			return lsMimeType ? FileHelper.cloneBlob(poBlob, lsMimeType) : poBlob;
		}
		else
			return poBlob;
	}

	/** Enregistre un fichier du dossier DMS sur le serveur (téléversement).
	 * @param poData Objet contenant le fichier sous forme de Blob ou base64 ainsi que son nom, sa taille, son mimeType.
	 * @param poMeta Méta du document.
	 * @param pfOnProgress Callback appelée lors de l'avancement du téléversement.
	 */
	@LogAction<Parameters<RemoteDmsService["save"]>, ReturnType<RemoteDmsService["save"]>>({
		actionId: ELogActionId.dmsDocUpload,
		successMessage: "Document (DMS) transmitted to the server.",
		errorMessage: "Error during the transmission of the document (DMS) to the server.",
		dataBuilder: (_, poMeta: IDmsMeta, __, poMetaParam?: IDmsMeta) => ({
			guid: IdHelper.extractIdWithoutPrefix(poMeta._id, EPrefix.dmsDoc),
			filename: poMeta.name,
			metaId: poMeta?._id ?? poMetaParam._id
		})
	})
	public save(poData: DmsFile, poMeta: IDmsMeta, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsMeta> {
		if (!poData) {
			return throwError(new HttpErrorResponse({
				error: "Le document à téléverser n'existe pas localement.",
				status: 400
			}));
		}
		else if (StringHelper.isBlank(poData.Path)) { // Cas Blob ou base64 qu'on transforme en blob.
			return this.upload(
				poData.File instanceof Blob ? poData.File : FileHelper.base64toBlob({ base64: poData.File }, poData.MimeType),
				poMeta,
				pfOnProgress
			);
		}
		else // Chemin d'accès.
			return defer(() => this.isvcFilesystem.getFileAsync(poData.Path)).pipe(mergeMap((poBlobResult: Blob) => this.upload(poBlobResult, poMeta, pfOnProgress)));
	}

	/** Permet de s'abonner aux événements du RemoteDmsService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: IDmsEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: any).
	 */
	public subscribe(pfNext: Function, pfError?: Function): void {
		this.moDmsEventSubject.asObservable()
			.subscribe(
				(poResult: IDmsEvent) => pfNext(poResult),
				poError => pfError(poError)
			);
	}

	/** Téléverse le fichier vers le serveur qui créera aussi la méta associée.
	 * @param poFile Fichier qu'il faut téléverser, sous forme de `Blob` ou `File`.
	 * @param poDmsMeta Objet méta associé au fichier qu'on téléverse.
	 * @param pfOnProgress Callback appelée lors de l'avancement du téléversement.
	 */
	private upload(poFile: Blob | File, poDmsMeta: IDmsMeta, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsMeta> {
		const loFormData = new FormData();
		const loOptions: IHttpOptions<"json"> = this.getHttpBaseOptions("json");

		loFormData.append("file", poFile instanceof File ? FileHelper.cloneFile(poFile) : FileHelper.cloneBlob(poFile), poDmsMeta.name);

		loOptions.params = { DocumentMeta: JSON.stringify(this.convertToRemoteDmsMeta(poDmsMeta)) };

		const loParameters = new HttpParams()
			.append("DocumentMeta", JSON.stringify(this.convertToRemoteDmsMeta(poDmsMeta)));

		const loUploadParameters: ITransfertParameters = {
			fileUrl: this.createUrl(poDmsMeta._id),
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				Token: ConfigData.authentication.token
			}),
			onProgress: pfOnProgress,
			body: loFormData,
			parameters: loParameters,
			fileSize: poDmsMeta.size
		};

		return this.isvcTransfert.upload$(loUploadParameters)
			.pipe(
				tap(
					(psGuid: string) => console.log(`RDMS.S:: Upload du fichier "${poDmsMeta.name}" sur le serveur réussi : ${psGuid}`),
					(poError: HttpErrorResponse) => console.error(`RDMS.S:: Erreur post fichier "${poDmsMeta.name}" sur le serveur :`, poError)
				),
				map(_ => {
					poDmsMeta.lastAccess = poDmsMeta.modifyDate = new Date();
					this.raiseEvent(poDmsMeta._id, new DmsFile(poFile, poDmsMeta), poDmsMeta);
					return poDmsMeta;
				})
			);
	}

	private convertToRemoteDmsMeta(poDmsMeta: IDmsMeta): { type: string, attributes: string } {
		return { type: poDmsMeta.documentType, attributes: this.convertAttributesToCsvString(poDmsMeta) };
	}

	/** Transforme le tableau d'attributs des metas en chaîne CSV.
	 * @param poDmsMeta
	 * @returns Peut retourner `undefined` si le tableau n'est pas défini.
	 */
	private convertAttributesToCsvString(poDmsMeta: IDmsMeta): string {
		return poDmsMeta.attributes?.map((poAttribute: IDmsDocumentAttribute) => `${poAttribute.name}=${poAttribute.value},`).join(""); //On force aucun séparateur pour laisser une virgule en fin, comme attendu par le webservice.
	}

	//#endregion

}
