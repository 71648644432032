import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IGalleryCommand } from '../../../../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../../../../model/gallery/IGalleryFile';
import { GalleryFile } from '../../../../../model/gallery/gallery-file';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { FieldBase } from '../../../models/FieldBase';
import { IGalleryFieldParams } from '../../../models/IGalleryFieldParams';
import { FormsService } from '../../../services/forms.service';

@Component({
	templateUrl: './galleryField.component.html',
})
export class GalleryFieldComponent extends FieldBase<IGalleryFile[]> implements OnInit, OnDestroy {

	//#region FIELDS

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();

	//#endregion

	//#region PROPERTIES

	public readonly galleryCommand$: Observable<IGalleryCommand>;

	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IGalleryFieldParams;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetector: ChangeDetectorRef) {
		super(psvcForms, poChangeDetector);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moGalleryCommandSubject.complete();
	}

	public onFilesChanged(paFiles: IGalleryFile[]): void {
		this.fieldValue = paFiles.map((poFile: IGalleryFile) => ModelResolver.toClass(GalleryFile, poFile));
		this.markAsDirty();
	}

	//#endregion
}