import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';

/* Justification de l'implémentation :
Le texte, y compris les balises HTML, est stocké dans le DOM.
Si on utilise les méthodes `onblur` ou `onfocusend`, pour gérer les modifications de la valeur, alors, lors du clic sur le bouton de soumission, celui-ci est ignoré.
Pour contourner ce problème, on stocke dans une variable, le contenu du DOM, à chaque fois qu'une lettre est tapée.
On s'abonne au `EFormEventType.submit`, et on modifie la valeur du modèle quand l'évènement `submit` est émis.
*/
@Component({
	templateUrl: './htmlTextEditorField.component.html',
	styleUrls: ['./htmlTextEditorField.component.scss']
})
export class HTMLTextEditorFieldComponent extends FieldBase<Event> implements OnInit, OnDestroy { //todo : params.

	//#region FIELDS

	/** Valeur au début du formulaire. Utilisée pour mettre l'input en `pristine`. */
	private msInitialValue: string;

	/** Balise HTML contenant la valeur du modèle, la valeur est dans son DOM. */
	@ViewChild("htmlContainer") private htmlContainer: ElementRef;

	//#endregion

	//#region PROPERTIES

	/** Valeur du contenu HTML, avant la dernière saisie. */
	public bufferValue: Event;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		if (!this.fieldValue)
			this.msInitialValue = "";
		else
			this.msInitialValue = this.fieldValue as unknown as string;

		this.bufferValue = this.msInitialValue as unknown as Event;
	}

	public onKeyUp(): void {
		if ((!this.msInitialValue && this.htmlContainer.nativeElement.innerHTML.trim() === "") ||
			this.htmlContainer.nativeElement.innerHTML.trim() === this.msInitialValue.trim()) {

			// Si la valeur actuelle est la même qu'à l'ouverture du formulaire, on considère que le formulaire est propre.
			this.fieldValue = this.htmlContainer.nativeElement.innerHTML;

			if (!this.formControl.pristine)
				this.markAsPristine();
		}
		else if (this.htmlContainer.nativeElement.innerHTML !== this.fieldValue) {
			// Si valeur actuelle différente de précédente, on la sauvegarde et on marque comme sale.
			this.fieldValue = this.htmlContainer.nativeElement.innerHTML;

			if (!this.formControl.dirty)
				this.markAsDirty();
		}

		console.log(this.fieldValue);
	}

	//#endregion

}