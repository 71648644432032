import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalComponentBase } from '@calaosoft/osapp/modules/modal/model/ModalComponentBase';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { ModalController } from '@ionic/angular';
import { IReport } from '../../../model/IReport';
import { IJoinReportSelectorModalParams } from '../../../model/reports/join-report-selector-modal-params';

@Component({
	selector: "com-join-report-selector-modal",
	templateUrl: "./join-report-selector-modal.component.html",
	styleUrls: ["./join-report-selector-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinReportSelectorModalComponent extends ModalComponentBase<string | undefined> implements IJoinReportSelectorModalParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public readonly newReportKey: string;
	/** @implements */
	@Input() public readonly title: string;
	/** @implements */
	@Input() public readonly reports: IReport[];

	/** Valeur du bouton radio sélectionné. */
	public selectedRadioValue: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	//#endregion METHODS

}