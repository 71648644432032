/** Répertorie les différents patterns qu'on peut résoudre. */
export enum EPattern {

	completeName = "user.name",
	contact = "user.contactId",
	date = "date",
	entity = "entity",
	entityGuid = "entity.guid",
	entityId = "entity.id",
	entityModel = "entity.model",
	guid = "guid",
	guidWithHyphens = "guidWithHyphens",
	login = "user.login",
	parentEntityGuid = "parentEntity.guid",
	parentEntityId = "parentEntity.id",
	parentEntityModel = "parentEntity.model",
	externalToken = "externalToken"
}