import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { UserHelper } from '../../../../helpers/user.helper';
import { IUser } from '../../../../model/application/IUser';
import { UserData } from '../../../../model/application/UserData';
import { EContactFlag } from '../../../../model/contacts/EContactFlag';
import { IContact } from '../../../../model/contacts/IContact';
import { EPrefix } from '../../../../model/EPrefix';
import { NoCurrentUserDataError } from '../../../../model/errors/NoCurrentUserDataError';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { ContactsService } from '../../../../services/contacts.service';
import { FlagService } from '../../../../services/flag.service';
import { Store } from '../../../../services/store.service';

@Injectable()
export class UserContactService {

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		private readonly isvcStore: Store,
		private readonly ioRouter: Router,
		private readonly isvcFlag: FlagService
	) { }

	/** Récupère le contact "moi" de l'utilisateur depuis la base de données de l'appStorage, `undefined` si non trouvé.
	 * @param psUserContactId Identifiant du contact de l'utilisateur.
	 * @param pbGetFailIfNoResult Indique si la récupération du document doit générer une erreur s'il n'est pas trouvé, `true` par défaut.
	 */
	private getContactFromAppStorage$(psUserContactId: string, pbGetFailIfNoResult: boolean = true): Observable<IContact | undefined> {
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
			viewParams: {
				include_docs: true,
				key: IdHelper.buildChildId(EPrefix.contact, ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id, UserHelper.getUserGuid(psUserContactId))
			}
		};

		return this.isvcStore.getOne<IContact>(loDataSource, pbGetFailIfNoResult)
			.pipe(
				mergeMap((poContact: IContact) => {
					if (ObjectHelper.isNullOrEmpty(poContact))
						return this.convertOldContactIfNeeded$(psUserContactId, false);
					else
						poContact._id = psUserContactId;
					return of(poContact);
				}),
				catchError((poError: { isEmptyResult: boolean; message: string }) => poError.isEmptyResult ?
					of(undefined) : throwError(poError)
				)
			);
	}

	private convertOldContactIfNeeded$(psUserContactId: string, pbGetFailIfNoResult: boolean = true): Observable<IContact> {
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
			viewParams: {
				include_docs: true,
				key: psUserContactId
			}
		};

		return this.isvcStore.getOne<IContact>(loDataSource, pbGetFailIfNoResult)
			.pipe(
				mergeMap((poOldContact: IContact) => {
					if (!ObjectHelper.isNullOrEmpty(poOldContact)) {
						return this.isvcStore.delete(poOldContact, loDataSource.databaseId)
							.pipe(
								mergeMap(() => {
									StoreHelper.deleteDocumentCacheData(poOldContact);
									return this.saveContact$(poOldContact);
								}),
								mergeMap(() => this.getContactFromAppStorage$(poOldContact._id, false))
							);
					}
					else
						return of(undefined);
				}),
				catchError((poError: { isEmptyResult: boolean; message: string }) => poError.isEmptyResult ?
					of(undefined) : throwError(poError)
				)
			);
	}

	/** Enregistre dans l'appStorage le document contact "moi" de l'utilisateur ainsi que dans les workspaces auxquels il a accès.
	 * @param poContact Modèle du contact "moi" de l'utilisateur.
	 */
	public saveContact$(poContact: IContact): Observable<boolean> {
		const lsAppStorageDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));
		const loCacheContact: IContact = this.isvcContacts.createCacheContactFromContact(poContact);

		// On enregistre le contact utilisateur dans sa base de données de provenance.
		return this.isvcStore.put(loCacheContact, lsAppStorageDatabaseId, true)
			.pipe(
				mergeMap(_ => {
					let loReplicate$: Observable<boolean | never>;

					if (UserData.current) {
						this.initContact(poContact);
						// Tableau des identifiant de toutes les bases de données devant posséder le contact utilisateur.
						const laContactDatabaseIds: Array<string> = this.isvcContacts.getContactsDatabaseIds();
						// On réplique le contact utilisateur enregistré dans toutes les autres bases de données où il doit se trouver.
						loReplicate$ = from(laContactDatabaseIds)
							.pipe(
								mergeMap((psDatabaseId: string) => this.isvcStore.put(poContact, psDatabaseId, true)),
								toArray(),
								map((paResults: IStoreDataResponse[]) => paResults.every((poResult: IStoreDataResponse) => poResult.ok))
							);
					}
					else
						loReplicate$ = throwError(new NoCurrentUserDataError());

					return loReplicate$;
				})
			);
	}

	/** Récupère le contact utilisateur ou en créé un si la contact utilisateur n'est pas dans l'appStorage ou en base de données. */
	public getContact$(): Observable<IContact> {
		const loUser: IUser = UserData.current;
		const lsContactId: string = ContactsService.getContactIdFromUserId(loUser.name);

		return this.getContactFromAppStorage$(lsContactId, false)
			.pipe(
				mergeMap((poContact?: IContact) => {
					if (poContact) {
						this.initContact(poContact);
						return of(poContact);
					}
					else
						return this.isvcContacts.getContact(lsContactId); // Si le contact n'existe pas on le récupère dans la base de workspace.
				}),
				map((poContact?: IContact) => {
					// Si le contact n'existe pas dans le localStorage ou la base de données on le crée.
					return poContact ?? {
						_id: IdHelper.replacePrefixId(loUser.name, EPrefix.contact),
						firstName: loUser.firstName,
						lastName: loUser.lastName,
						phone: loUser.phone,
						email: loUser.email
					};
				})
			);
	}

	/** Initialise le contact utilisateur (affectation nom/prénom au UserData et ajout du champ 'userId')
	 * et lève un drapeau notifiant de la fin d'initialisation du contact utilisateur.
	 * @param poContact Contact utilisateur.
	 */
	private initContact(poContact: IContact): boolean {
		let lbComplete = false;

		if (UserData.current) {
			// On affecte le nom et le prénom de l'utilisateur.
			UserData.current.lastName = poContact.lastName;
			UserData.current.firstName = poContact.firstName;

			if (StringHelper.isBlank(poContact.userId))
				poContact.userId = UserData.current.name;

			lbComplete = true;

			this.raiseContactInitializedFlag();
		}

		return lbComplete;
	}

	private raiseContactInitializedFlag(): void {
		this.isvcFlag.setFlagValue(EContactFlag.userContactInitialized, true);
	}

	/** Redirige vers la fiche "moi" de l'utilisateur. */
	public redirectToContactEditAsync(): Promise<boolean> {
		return this.ioRouter.navigateByUrl(`contacts/${ContactsService.C_CURRENT_USER_ID_FOR_ROUTE}/edit`);
	}

	/** Retourne `true` si le contact utilisateur a été initialisé, sinon `false`.*/
	public isInitialized(): boolean {
		return this.isvcFlag.getFlagValue(EContactFlag.userContactInitialized);
	}

	//#endregion METHODS
}
