import { ObjectHelper } from '../../../helpers/objectHelper';
import { IRange } from '../../utils/models/models/irange';
import { IConstraintLabelParams } from './iconstraint-label-params';
import { IEventOccurrence } from './ievent-occurrence';
import { IEventOccurrenceCriterion } from './ievent-occurrence-criterion';

export abstract class EventOccurrenceCriterion implements IEventOccurrenceCriterion {

	//#region PROPERTIES

	/** @implements */
	public abstract readonly type: string;

	//#endregion

	//#region METHODS

	constructor(poCriterion?: Partial<IEventOccurrenceCriterion>) {
		if (poCriterion)
			ObjectHelper.assign(this, poCriterion);
	}

	public abstract getLabel(poParams: IConstraintLabelParams): string;

	/** Indique si l'occurrence correspond au comparateur.
	 * @param poEventOccurrence
	 */
	public abstract match(poEventOccurrence: IEventOccurrence): boolean;

	public getParticipantsId(): string[] {
		return [];
	}

	public canMatchRange(poRange: IRange<Date>): boolean {
		return true;
	}

	//#endregion

}
