<ng-container calaoHeader [hasBackButton]="false" title="Editor: Fill & Set up your form">
	<div class="container">

		<div class="flex-row">
			<div class="marg-container">

				<general-setting></general-setting>
				<widget-adder></widget-adder>
			</div>
			<div class="marg-container">
				<form-editor></form-editor>
			</div>
			<div class="marg-auto">
				<widget-setting></widget-setting>
			</div>
		</div>
	</div>
</ng-container>