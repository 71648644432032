import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { EPrefix } from '../../../model/EPrefix';
import { ESuffix } from '../../../model/ESuffix';
import { UserData } from '../../../model/application/UserData';
import { ConfigData } from '../../../model/config/ConfigData';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { NoCurrentUserDataError } from '../../../model/errors/NoCurrentUserDataError';
import { IFormDefinition } from '../../../model/forms/IFormDefinition';
import { IFormDescriptor } from '../../../model/forms/IFormDescriptor';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { UserContactService } from '../../../modules/contacts/userContact/services/user-contact.service';
import { PageManagerService } from '../../../modules/routing/services/pageManager.service';
import { ContactsService } from '../../../services/contacts.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FormsService } from '../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../services/uiMessage.service';
import { FormComponent } from '../../forms/form/form.component';

@Component({
	selector: "calao-contact",
	templateUrl: 'contact.component.html',
	styleUrls: ['./contact.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly FORM_DESCRIPTOR_ID = "formDesc_contacts";

	/** Événement levé lors de la soumission du formulaire. */
	@Output("onSubmit") private readonly moOnSubmitEvent = new EventEmitter<IContact>();

	/** Indique si l'utilisateur est sur sa propre fiche contact en première édition. */
	private readonly mbIsMe: boolean;
	/** Indique si l'utilisateur est sur sa propre fiche contact. */
	private readonly mbIsUserContact: boolean;

	//#endregion

	public model: IContact;
	/** Paramètres du formulaire. */
	public formDefinitionId = `contact${ESuffix.edit}`;
	public formDescriptor: IFormDescriptor;
	/** Indique si on doit cacher la toolbar ou non, `false` par défaut. */
	public hideToolbar = false;
	public customSubmit: (poModel: IContact, poForm: FormComponent<IContact>, psTargetDatabase?: string, psActionAfterSave?: string) => Observable<any> =
		() => this.save();

	@Input() public hasToBackAfterSave = true;

	/** `true` si l'utilisateur peut enregistrer des modifications, `false` sinon. */
	private get canSave(): boolean {
		// Cacher la toolbar équivaut à ne pas avoir le droit de modifier un contact, donc si la toolbar est affichée c'est qu'on peut modifier le contact.
		return !this.hideToolbar;
	}

	//#region METHODS

	constructor(
		private isvcContacts: ContactsService,
		private readonly isvcUserContact: UserContactService,
		private isvcUiMessage: UiMessageService,
		private isvcPageManager: PageManagerService,
		private isvcForms: FormsService,
		private isvcEntityLink: EntityLinkService,
		poActivatedRoute: ActivatedRoute,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);

		if (ArrayHelper.hasElements(poActivatedRoute.snapshot.url)) {
			const lsContactId: string | null = poActivatedRoute.snapshot.paramMap.get("contactId");

			this.mbIsMe = lsContactId === ContactsService.C_CURRENT_USER_ID_FOR_ROUTE;
			this.mbIsUserContact = this.mbIsMe || lsContactId === ContactsService.getUserContactId();
		}
	}

	public ngOnInit(): void {
		if (!this.model)
			this.model = {} as IContact;

		this.isvcContacts.checkEditPermissionAsync(this.model)
			.then((pbHasPermission: boolean) => this.updateToolbarVisibility(pbHasPermission));

		// On met à jour l'entité active.
		this.isvcEntityLink.trySetCurrentEntity(this.model)
			.pipe(
				mergeMap(() => this.isvcEntityLink.getLinkedEntities(this.model._id, [EPrefix.group], true)),
				tap((paResults: IGroup[]) => {
					// Sauvegarde en cache les informations de l'adresse pour détecter une modification;
					this.isvcContacts.saveAdressCacheData(this.model);

					if (ArrayHelper.hasElements(paResults))
						this.model.roles = ArrayHelper.unique(ArrayHelper.flat(paResults.map((poGroup: IGroup) => poGroup.roles ?? [])));
				}),
				mergeMap(() => this.init()),
				tap(() => this.detectChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		// On supprime l'entité active.
		this.isvcEntityLink.clearCurrentEntity(this.model._id).subscribe();

		super.ngOnDestroy();
	}

	private init(): Observable<IFormDescriptor<IStoreDocument>> {
		return this.isvcForms.getFormDescriptor(ContactComponent.FORM_DESCRIPTOR_ID)
			.pipe(
				tap((poResult: IFormDescriptor) => {
					if (this.mbIsUserContact)
						delete poResult.permissionScope;

					if (this.mbIsMe || !this.hasToBackAfterSave)
						poResult.formDefinitions.forEach((poFormDefinition: IFormDefinition) => poFormDefinition.onSubmitAction = "none");

					this.formDescriptor = poResult;
				})
			);
	}

	/** Met à jour la visibilité de la toolbar. */
	private updateToolbarVisibility(pbHasEditPermission: boolean): void {
		// Si la permission d'édition est `true` ou si la fiche contact courante est celle de l'utilisateur, on affiche la toolbar.
		this.hideToolbar = !(pbHasEditPermission || this.mbIsUserContact);
	}

	private save(): Observable<boolean> {
		return UserData.current ? this.innerSave() : throwError(new NoCurrentUserDataError());
	}

	private innerSave(): Observable<boolean> {
		const loModel: IContact = ObjectHelper.clone(this.model);
		delete loModel.roles;

		if (this.mbIsUserContact)
			this.model.userId = !UserData.current?.isGuest ? UserData.current?.name : undefined;

		if (!this.canSave) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Ce contact est le seul habilité à modifier ses informations personnelles." }));

			return EMPTY;
		}
		else {
			let loIsSavedContact$: Observable<boolean>;
			if (this.mbIsMe)
				loIsSavedContact$ = this.isvcUserContact.saveContact$(this.model);
			else
				loIsSavedContact$ = this.isvcContacts.saveContact(this.model);

			this.isvcContacts.deleteGPSDataIfNeeded(this.model);
			return loIsSavedContact$
				.pipe(
					tap(_ => {
						this.model._rev = loModel._rev;
						this.isvcContacts.saveAdressCacheData(this.model);
						this.moOnSubmitEvent.emit(this.model);
						if (this.mbIsMe)
							this.isvcPageManager.goHome(ConfigData.contacts?.afterMyContactValidationRoute);
					})
				);
		}
	}

	//#endregion
}