<ng-container *ngIf="observableCurrentPositionLabel.value$ | async as label; else locateButton">
	<ion-button (click)="reset()" shape="round" mode="md" size="small" class="filter-tag-button">
		<ion-icon slot="start" name="locate"></ion-icon>
		{{ label }}
		<ion-icon slot="end" name="close"></ion-icon>
	</ion-button>
</ng-container>

<ng-template #locateButton>
	<ion-button shape="round" mode="md" size="small" (click)="locate()">
		<ion-icon slot="start" name="locate"></ion-icon>
		Me géolocaliser
	</ion-button>
</ng-template>