<ng-container *ngIf="designableDescriptor.selectedField as field">
	<ng-container *ngIf="field.templateOptions as template">
		<form #modification="ngForm">
			<input type="hidden" name="originKey" [ngModel]="field.key">
			<input type="hidden" name="typeOfField" [ngModel]="field.type">
			<input type="hidden" name="fakeValue" [ngModel]="field.fakeValue">

			<ng-container *ngIf="keysOfField.length > 0">
				<ng-container *ngFor="let key of keysOfField; let index = index">
					<label>{{ key }} : </label>
					<ng-container *ngIf="getInputTypeFromKey(field[key]) as typeofKey">
						<input *ngIf="typeofKey !== otherTypeof" [type]="typeofKey" [name]="key" [ngModel]="field[key]"
							[required]="key === 'key'">
						<br *ngIf="typeofKey !== otherTypeof">
					</ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="keysOfTOField.length > 0">
				<ng-container *ngFor="let key of keysOfTOField">
					<label>{{ key }} : </label>
					<ng-container *ngIf="getInputTypeFromKey(template[key]) as typeofTemplate">
						<select *ngIf="key === 'type'" [name]="'to.' + key" [(ngModel)]="template[key]">
							<option *ngFor="let type of inputTypeEnum" [value]="type" [selected]="template[key] === type">{{type}}
							</option>
						</select>
						<input *ngIf="typeofTemplate !== otherTypeof && key !== 'type'" [type]="typeofTemplate" [name]="'to.' + key"
							[ngModel]="template[key]">
						<br *ngIf="typeofTemplate !== otherTypeof">
					</ng-container>
				</ng-container>

				<ng-container *ngIf="template.data as data">
					<ng-container *ngIf="keysOfDataOfTOField.length > 0">
						<ng-container *ngFor="let key of keysOfDataOfTOField">
							<label>{{ key }} : </label>

							<ng-container *ngIf="getInputTypeFromKey(data[key]) as typeofData">

								<ng-container *ngIf="typeofData !== otherTypeof; then input; else default;"></ng-container>
								<ng-template #input>
									<input [type]="typeofData" [name]="'dt.' + key" [ngModel]="data[key]">
								</ng-template>

								<ng-template #default>
									<ng-container [ngSwitch]="key">
										<ng-container *ngSwitchCase="'options'">
											<ng-container *ngFor="let option of data.options; let index = index;">
												<br>
												<label>Option {{ index+1 }} : </label>
												<input type="hidden" [ngModel]="option.id" [name]="'dt.optId' + index" required>
												<button (click)="onModifyOptionsToSelectedField(actionEnum.delete, index)"> - </button>
												<br>
												<label>Label : </label>
												<input type="text" [ngModel]="option.label" [name]="'dt.optLabel' + index" required />
												<br>
												<label>Value : </label>
												<input type="text" [ngModel]="option.value" [name]="'dt.optValue' + index" required />
												<br>
											</ng-container>
											<label>Add option : </label>
											<button (click)="onModifyOptionsToSelectedField(actionEnum.add)">+</button>
										</ng-container>

										<ng-container *ngSwitchCase="'defaultValue'">
											<select [name]="'dt.' + key" [ngModel]="data[key]">
												<option *ngFor="let option of data.options; let index = index" [value]="option.label">
													{{ option.label }}
												</option>
											</select>
										</ng-container>
									</ng-container>
								</ng-template>

							</ng-container>
							<br>
						</ng-container>
					</ng-container>
				</ng-container>

				<div>
					<label>Change position : </label>
					<select name="position" [ngModel]>
						<option *ngFor="let field of designableDefinition.fields; let index = index" [value]="index">{{ index }}
						</option>
					</select>
				</div>

				<div>
					<label>Is displayed in Item List : </label>
					<input type="checkbox" name="isSelectForListDefinition" [ngModel]="field.isSelectForListDefinition">
				</div>
				<div class="marg-zone">
					<button class="btn-delete" (click)="onFinalizeEdition(modification, false)">Cancel</button>
					<button class="btn-success" [disabled]="modification.invalid"
						(click)="onFinalizeEdition(modification, true)">Apply</button>
				</div>
			</ng-container>

		</form>
	</ng-container>
</ng-container>