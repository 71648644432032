import { ObjectHelper } from "../../../helpers/objectHelper";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { EEventParticipationStatus } from "./eevent-participation-status";
import { IEventParticipationOccurrence } from "./ievent-participation-occurrence";

export class EventParticipationOccurrence implements IEventParticipationOccurrence {

	//#region PROPERTIES

	/** @implements */
	public eventRev: string;
	/** @implements */
	@ResolveModel(Date)
	public startDate: Date;
	/** @implements */
	@ResolveModel(Date)
	public endDate: Date;
	/** @implements */
	public place: string;
	/** @implements */
	public participationStatus: EEventParticipationStatus;
	/** @implements */
	@ResolveModel(Date)
	public participationStatusDate: Date;
	/** @implements */
	@ResolveModel(Date)
	public newDateSuggestion?: Date;

	//#endregion

	//#region METHODS

	constructor(poData?: Partial<IEventParticipationOccurrence>) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion

}
