import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { IDesignableDefinition } from '../../../model/IDesignableDefinition';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

/** Permet de voir la définition sélectionnée au format JSON. */
@Component({
	selector: "form-preview-json",
	templateUrl: './form-preview-json.component.html',
	styleUrls: ['./form-preview-json.component.scss']
})
export class FormPreviewJsonComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public show = false;
	public definitions: FormlyFieldConfig[] = [];

	//#endregion

	//#region METHODS

	constructor(private isvcDesign: DesignService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poResult: IDesignableDescriptor) => {
					const lsSelectedDefinitionId: string = poResult.selectedDefinitionId;
					const loDefinition: IDesignableDefinition = poResult.designableDefinitions.find((poDefinition: IDesignableDefinition) =>
						poDefinition.id === lsSelectedDefinitionId
					);
					this.definitions = loDefinition ? this.isvcDesign.convertDesignableDefinitionToFormDefinition(loDefinition).definition : [];
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet d'afficher ou non le contenu du composant. */
	public onShow(): void {
		this.show = !this.show;
	}

	//#endregion

}