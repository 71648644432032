import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LinkedItemsListComponent } from './linkedItemsList.component';

@NgModule({
	imports: [CommonModule, IonicModule],
	exports: [LinkedItemsListComponent],
	declarations: [LinkedItemsListComponent]
})
export class LinkedItemsListModule { }