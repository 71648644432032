<div>
	<label>Form Definition :</label>
	<button (click)="onShow()">?</button>
	<br>
	<ng-container *ngIf="show">
		<div class="widget-area">
			<label>Fields:</label>
			<pre>{{ definitions | json }}</pre>
		</div>
	</ng-container>
</div>