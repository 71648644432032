import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { FavoritesModule } from '../../modules/preferences/favorites/favorites.module';
import { RecentsModule } from '../../modules/preferences/recents/recents.module';
import { SearchComponent } from './search.component';

/** Module de gestion de la recherche. */
@NgModule({
	imports: [IonicModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatIconModule,
		MatInputModule,
		FavoritesModule,
		RecentsModule],
	exports: [SearchComponent],
	declarations: [SearchComponent]
})
export class SearchModule { }