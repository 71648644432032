<div class="dis-flex">
	<div class="title">
		<h5 *ngIf="title">{{title}}</h5>
	</div>

	<!-- ZONE DE RECHERCHE -->
	<div *ngIf="hasSearchbox" class="search-box txt-center form-list width100pc">
		<search class="search" [data]="documents" [options]="searchOptions"
			(filteredEntriesChanged)="onFilteredDocumentsChanged($event)" [autofocus]="autofocusSearchbox"></search>
	</div>

	<!-- Afficher s'il y a un chargement -->
	<div class="full-width">
		<osapp-spinner *ngIf="isLoading" [message]="loadingText">
		</osapp-spinner>
	</div>

	<!-- DISPLAY_TYPE 'TAB' -->
	<ng-container *ngIf="displayType === 'tab' && filteredDocuments.length !== 0">
		<ion-grid class="table table-striped width100pc form-list-tab-tr list header-list" [ngClass]="gridCssClass"
			data-cy="tab">
			<!-- en-tête du tableau -->
			<ion-row class="marg-fav">
				<ion-col [size]="col.size" *ngFor="let col of displayFields" [ngClass]="col.cssClass">
					<a class="ion-text-nowrap grid-header" (click)="orderOrReverse($any(col))">
						{{col.label}}
						<ion-icon [hidden]="sortKey !== (col.searchableKey ? col.searchableKey : col.key)"
							[name]="isReverseSorted ? 'caret-down-outline' : 'caret-up'"></ion-icon>
					</a>
				</ion-col>
			</ion-row>

			<!-- Corps du tableau. -->
			<ion-virtual-scroll class="dis-flex flex-col list" [items]="filteredDocuments" [trackBy]="trackById">
				<ion-item-sliding #itemSliding *virtualItem='let itemFormData; let i = index;'
					(ionDrag)="onDrag($event, itemSliding)">
					<ion-item lines="full" [color]="i%2 === 0 ? 'light' : ''" [ngClass]="rowCssClass ? rowCssClass : ''">
						<ion-row [ngClass]="i%2 === 0 ? 'odd' : 'even'" class="grid-content" data-cy="tab-body">
							<ion-col *ngFor="let col of displayFields" [size]="col.size" [ngClass]="col.cssClass"
								(click)="onItemClicked(itemFormData)" class="colonne">

								<!-- Affichage d'avatar. -->
								<ng-container *ngIf="col.isPicture">
									<avatar *ngIf="!displayFields[0].isArray && itemFormData[displayFields[0].key] && (itemFormData[displayFields[0].key].url ||
									itemFormData[displayFields[0].key].base64 || itemFormData[displayFields[0].key].guid)"
										[src]="getAvatar(itemFormData[displayFields[0].key])" (srcError)="onImgError($event)">
									</avatar>
									<ion-icon *ngIf="!itemFormData[col.key].base64 && !itemFormData[col.key].url" class="img-thumbnail"
										name="person"></ion-icon>
								</ng-container>

								<!-- Affichage d'une icône. -->
								<div *ngIf="col.isIcon" class="grid-icon">
									<ion-icon *ngIf="itemFormData[col.key].icon" [name]="itemFormData[col.key].icon"></ion-icon>
									<ion-icon *ngIf="!itemFormData[col.key].icon && itemFormData[col.key].url"
										[src]="itemFormData[col.key].url"></ion-icon>
								</div>

								<!-- Affichage d'une image. -->
								<div *ngIf="col.isImage" class="margin-auto">
									<ion-avatar
										[ngStyle]="col.size? {'max-height': avatarSize[col.size]+'px', 'max-width': avatarSize[col.size]+'px'} : {}">
										<ion-img #img [src]="itemFormData[col.key].url" [alt]="itemFormData[col.key].alt"
											(ionError)="onImgError(img)"></ion-img>
									</ion-avatar>
								</div>

								<!-- Affichage de texte. -->
								<ng-container *ngIf="!col.isPicture && !col.isIcon && !col.isImage">
									<p>{{ itemFormData[col.key] | pattern: col.key : itemFormData | isDate: 'dd/MM/yyyy HH:mm' }}</p>
								</ng-container>
							</ion-col>

							<!-- Affichage des boutons "supprimer" et "dupliquer". -->
							<ion-col *ngIf="allowDuplicate || allowDelete" size="2" class="buttons">
								<!-- Bouton de duplication. -->
								<ion-button *ngIf="allowDuplicate" (click)="confirmDuplicate(itemFormData)">
									<ion-icon slot="icon-only" class="btn-norm" name="copy"></ion-icon>
								</ion-button>
								<!-- Bouton de suppression. -->
								<ion-button *ngIf="allowDelete" color="danger" (click)="confirmDelete(itemFormData)">
									<ion-icon slot="icon-only" class="btn-norm" name="trash"></ion-icon>
								</ion-button>
							</ion-col>

						</ion-row>
						<div *ngIf="hasFavorites" slot="start" class="start-ico">
							<ng-container>
								<ng-container *ngTemplateOutlet="favButton; context: { itemFormData: itemFormData }">
								</ng-container>
							</ng-container>
						</div>
						<div class="options-area" slot="end">
							<!-- Bouton d'options. -->
							<ion-button class="itemb btn-more" fill="clear"
								*ngIf="itemSlidingEnabled && (!canDisplayOptions || canDisplayOptions(itemFormData))"
								(click)="openOrCloseItemSliding(itemSliding, $event)">
								<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
							</ion-button>
						</div>
					</ion-item>

					<ng-container *ngTemplateOutlet="options; context: { itemFormData: itemFormData, itemSliding: itemSliding }">
					</ng-container>
				</ion-item-sliding>

			</ion-virtual-scroll>

		</ion-grid>
	</ng-container>

	<!-- DISPLAY_TYPE 'LIST' -->
	<div *ngIf="displayType === 'list' && filteredDocuments.length !== 0" class="width100pc">
		<ion-virtual-scroll class="dis-flex flex-col list" [items]="filteredDocuments" [trackBy]="trackById">
			<ion-item-sliding #itemSliding osappAutoCloseSlideItem *virtualItem="let itemFormData"
				[class]='getItemCssClass ? getItemCssClass(itemFormData) : ""' (ionDrag)="onDrag($event, itemSliding)">
				<ion-item #item>
					<div class="click-container" (click)="onItemClicked(itemFormData)">

						<!-- Affichage de l'avatar si on a une image à afficher. -->
						<ng-container *ngIf="displayFields[0] as field">
							<ion-avatar *ngIf="field.isPicture" class="round-avatar align-avatar" slot="start">
								<ng-container *ngIf="itemFormData[field.key] as item; else displayNoPicture">
									<!-- Test pour savoir si le champ d'image possède une image à afficher ou s'il faut afficher celle par défaut.
									Est-ce que l'objet est renseigné et est-ce qu'il contient une base64 ou une url ou un guid valide (l'objet peut contenir un tableau d'images). -->
									<ng-container
										*ngIf="((!field.isArray && (item.url || item.base64 || item.guid)) ||
											(field.isArray && (item[0].url || item[0].base64 || item[0].guid))); then displayPicture; else displayNoPicture">
									</ng-container>
									<!-- Si une image doit être affichée. -->
									<ng-template #displayPicture>
										<!-- Affiche l'image du champ qui contient une base64 ou une URL dans le cas où on a une unique image. -->
										<avatar *ngIf="!field.isArray" [src]="getAvatar(item)" [cssClass]="field.cssClass"
											(srcError)="onImgError($event)"></avatar>
										<!-- Affiche la première image du champ qui contient une base64 ou une URL dans le cas où on a un tableau d'images. -->
										<avatar *ngIf="field.isArray" [src]="getAvatar(item[0])" slot="start" [cssClass]="field.cssClass"
											(srcError)="onImgError($event)">
										</avatar>
									</ng-template>
									<!-- Si aucune image n'est à afficher, on affiche celle par défaut. -->
								</ng-container>
								<ng-template #displayNoPicture>
									<ion-icon class="ico-ava" name="person"></ion-icon>
								</ng-template>
							</ion-avatar>
						</ng-container>

						<!-- Affichage des textes. -->
						<ion-text class="text-container">
							<ion-label [ngClass]="displayFields[1]?.cssClass">
								{{ itemFormData[displayFields[1]?.key] | pattern:
								displayFields[1]?.key : itemFormData | isDate: 'dd MMM yyyy'}}
							</ion-label>
							<ion-label [ngClass]="displayFields[2]?.cssClass">
								{{ itemFormData[displayFields[2]?.key] | pattern:
								displayFields[2]?.key : itemFormData | isDate: 'dd MMM yyyy'}}
							</ion-label>
							<ion-note class="no-margin" [ngClass]="displayFields[3]?.cssClass">
								{{ itemFormData[displayFields[3]?.key] | pattern:
								displayFields[3]?.key : itemFormData | isDate: 'dd MMM yyyy' }}
							</ion-note>

							<p *ngIf="$any(displayFields[4]?.key) == 'likes' ">A été approuvé par {{
								itemFormData[displayFields[4]?.key] }}
								personnes</p>
							<p *ngIf="$any(displayFields[5]?.key) == 'dislikes' ">A été désapprouvé par
								{{ itemFormData[displayFields[5]?.key] }} personnes</p>
						</ion-text>
					</div>

					<div slot="end">
						<ng-container *ngIf="hasFavorites">
							<ng-container *ngTemplateOutlet="favButton; context: { itemFormData: itemFormData }">
							</ng-container>
						</ng-container>
						<!-- Bouton d'options. -->
						<ion-button class="itemb btn-more" fill="clear"
							*ngIf="itemSlidingEnabled && (!canDisplayOptions || canDisplayOptions(itemFormData))"
							(click)="openOrCloseItemSliding(itemSliding, $event)">
							<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
						</ion-button>
					</div>
				</ion-item>

				<ng-container *ngTemplateOutlet="options; context: { itemFormData: itemFormData, itemSliding: itemSliding }">
				</ng-container>
			</ion-item-sliding>
		</ion-virtual-scroll>
	</div>

	<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
	<div *ngIf="!isInitializing() && !isLoading && hasSearchbox && !hasSearchResult" class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon class="no-result-icon" name="information-circle"></ion-icon>
			Aucun résultat.
		</span>
	</div>

	<!-- Si aucun élément, pas de searchbox et un message de liste vide est défini. -->
	<ion-label *ngIf="!isInitializing() && !isLoading && filteredDocuments.length === 0 && !hasSearchbox && emptyMessage"
		class="ion-margin">
		{{emptyMessage}}
	</ion-label>
</div>

<!-- Options des items. -->
<ng-template #options let-itemFormData="itemFormData" let-itemSliding="itemSliding">
	<ion-item-options *ngIf="!canDisplayOptions || canDisplayOptions(itemFormData)" side="end">
		<div *ngFor="let option of itemOptions">
			<ng-container *ngIf="option.action === 'call'; else elseTemplate">
				<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
					[href]="'tel:' + itemFormData[option.fieldName]">
					<ion-icon [name]="option.icon"></ion-icon>
					{{option.label}}
				</ion-button>
			</ng-container>
			<ng-template #elseTemplate>
				<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
					(click)="onItemOptionClicked(itemFormData, option.action, itemSliding, option)">
					<ng-container [ngSwitch]="option.key">
						<ng-container *ngSwitchCase="favoritesEventId">
							<ng-container *ngTemplateOutlet="favIcon; context: { itemFormData: itemFormData, slot: 'start' }">
							</ng-container>
						</ng-container>
						<ion-icon *ngSwitchDefault [name]="option.icon"></ion-icon>
					</ng-container>
					{{option.label}}
				</ion-button>
			</ng-template>
		</div>
	</ion-item-options>
</ng-template>

<ng-template #favIcon let-itemFormData="itemFormData" let-slot="slot">
	<ion-icon [name]="favorites?.ids?.includes(itemFormData._id) ? 'heart' : 'heart-outline'" [slot]="slot"></ion-icon>
</ng-template>

<ng-template #favButton let-itemFormData="itemFormData">
	<ion-button fill="clear" (click)="onFavoriteClicked(itemFormData)">
		<ng-container *ngTemplateOutlet="favIcon; context: { itemFormData: itemFormData, slot: 'icon-only' }">
		</ng-container>
	</ion-button>
</ng-template>