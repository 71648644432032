<!-- Mode Edit (!RO) -->
<osapp-date-time *ngIf="!params.readOnly" [(model)]="fieldValue" [params]="params" (modelChange)="onDateChange()"
	[ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding'">
</osapp-date-time>

<!-- Mode Visu (RO) -->
<ion-item lines="none" *ngIf="params.readOnly" [ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding'">
	<div>
		<ion-label *ngIf="params.label && value">{{params.label}}</ion-label>
		<ion-icon *ngIf="params.icon && fieldValue" [name]="params.icon" class="margelect"></ion-icon>
		<span [class]="params.className">{{value | dateWithLocale : 'fr-FR' :
			params.displayFormat}}</span>
	</div>
</ion-item>