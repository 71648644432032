import { Injectable } from '@angular/core';
import { StringHelper } from '../../../helpers/stringHelper';
import { Version } from '../../../model/application/Version';
import { ConfigData } from '../../../model/config/ConfigData';
import { PlatformService } from '../../../services/platform.service';
import { SecurityService } from '../../../services/security.service';

@Injectable()
export class VersionsService {

	//#region FIELDS

	private static readonly C_CURRENT_VERSION_DOC_ID = "current_version";
	private static readonly C_PREVIOUS_VERSION_DOC_ID = "last_version";
	private static readonly C_LOG_ID = "VERS.S::";

	//#endregion

	//#region PROPERTIES

	private msVersion: string | null;
	public get version(): string | null {
		return this.msVersion;
	}

	private msLastVersion: string | null;
	public get lastVersion(): string | null {
		return this.msLastVersion;
	}

	//#endregion

	//#region METHODS

	constructor(private isvcPlatform: PlatformService, private isvcSecurity: SecurityService) { }

	/** Initialise les numéros de version. */
	public async initVersions(): Promise<void> {
		try {
			// On récupère le document de version local.
			const lsCurrentVersion: string | null = localStorage.getItem(VersionsService.C_CURRENT_VERSION_DOC_ID);

			this.msVersion = await this.getVersion();

			// Si le numéro de version en mémoire est différent du numéro réel, on met à jour.
			if (lsCurrentVersion !== this.msVersion) {
				// Si un numéro de version courant était stocké dans le document locale, on le sauvegarde en tant que version précédente.
				if (!StringHelper.isBlank(lsCurrentVersion))
					this.msLastVersion = lsCurrentVersion;
				// Sinon si on est dans un cas de rétrocompatibilité (l'utilisateur s'est déjà connecté mais n'avait pas de marqueur local de version).
				else if (!StringHelper.isBlank((await this.isvcSecurity.getUserCredentials$().toPromise())._rev))
					this.msLastVersion = Version.C_UNKNOWN_VERSION;

				localStorage.setItem(VersionsService.C_PREVIOUS_VERSION_DOC_ID, this.msLastVersion);
				localStorage.setItem(VersionsService.C_CURRENT_VERSION_DOC_ID, this.msVersion);
			}

			if (StringHelper.isBlank(this.msLastVersion))
				this.msLastVersion = this.getLastVersion();
		}
		catch (poError) {
			console.error(`${VersionsService.C_LOG_ID}Error while initializing versions.`, poError);
			throw poError;
		}
	}

	private getVersion(): string {
		if (!StringHelper.isBlank(this.isvcPlatform.appVersion))
			return this.isvcPlatform.appVersion;
		else {
			const lsVersion: string = ConfigData.environment.version ? ConfigData.environment.version.toString() : Version.maxVersion.toString();
			console.warn(`Failed to get application version. Simulating version ${lsVersion}.`);
			return lsVersion;
		}
	}

	private getLastVersion(): string | null {
		return localStorage.getItem(VersionsService.C_PREVIOUS_VERSION_DOC_ID);
	}

	//#endregion

}
