import { DateHelper } from '../../helpers/dateHelper';
import { ICredentialsDocument } from './ICredentialsDocument';

export class Credentials implements ICredentialsDocument {

	//#region PROPERTIES

	public _id: string;
	public _rev: string;
	public login: string;
	public password?: string;
	public tokenExpirationDate?: string | Date;
	public token?: string;

	//#endregion

	//#region METHODS

	public isValidToken(): boolean {
		return !!this.token && !!this.tokenExpirationDate && DateHelper.compareTwoDates(new Date(this.tokenExpirationDate), new Date()) > 0;
	}

	//#endregion

}