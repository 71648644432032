import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { AlertButton, AlertInput, OverlayEventDetail } from '@ionic/core';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, startWith, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { FileHelper } from '../../../../helpers/fileHelper';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { ESuffix } from '../../../../model/ESuffix';
import { EFormEngine } from '../../../../model/forms/EFormEngine';
import { IFormDefinition } from '../../../../model/forms/IFormDefinition';
import { IFormDescriptor } from '../../../../model/forms/IFormDescriptor';
import { IFormDescriptorDataSource } from '../../../../model/forms/IFormDescriptorDataSource';
import { IListDefinition } from '../../../../model/forms/IListDefinition';
import { IListDefinitionsField } from '../../../../model/forms/IListDefinitionsField';
import { ISelectTag } from '../../../../model/forms/ISelectTag';
import { IFieldComponent } from '../../../../model/forms/fieldComponent/IFieldComponent';
import { IInputField } from '../../../../model/forms/fieldComponent/specifications/IInputField';
import { ILabelField } from '../../../../model/forms/fieldComponent/specifications/ILabelField';
import { ISelectField } from '../../../../model/forms/fieldComponent/specifications/ISelectField';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { EStoreType } from '../../../../model/store/EStoreType';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { FormsService } from '../../../../services/forms.service';
import { Store } from '../../../../services/store.service';
import { AlertMessage } from '../model/AlertMessage';
import { ENameConsole } from '../model/ENameConsole';
import { IDesignableComponent } from '../model/IDesignableComponent';
import { IDesignableDefinition } from '../model/IDesignableDefinition';
import { IDesignableDescriptor } from '../model/IDesignableDescriptor';

type AlertStringCreate = { values: string };
type AlertStringResult = OverlayEventDetail<AlertStringCreate>;
type AlertStringNameCreate = { values: { name: string } };
type AlertStringNameResult = OverlayEventDetail<AlertStringNameCreate>;
type AlertStringBoolCreate = { values: string | boolean };
type AlertStringBoolResult = OverlayEventDetail<AlertStringBoolCreate>;

@Injectable()
export class DesignService {

	//#region FIELDS

	private static readonly C_FORM_DESCRIPTOR_TYPE = "formDescriptor";
	private static readonly C_CONFIRM_BUTTON = "confirm";
	private static readonly C_CANCEL_BUTTON = "cancel";
	private static readonly C_CREATE_BUTTON = "create";
	private static readonly C_UPLOAD_BUTTON = "upload";
	private static readonly C_OPEN_BUTTON = "open";
	private static readonly C_NAME_BUTTON = "name";
	private static readonly C_DOWNLOAD_BUTTON = "download";
	private static readonly C_SAVE_BUTTON = "save";
	private static readonly C_NB_ELEMENTS_LISTDEFINITION = 3;
	private static readonly C_SELECT_TYPE = "select";
	/** Identifiant de la base de données des entrées du designer. */
	private static readonly C_ENTRIES_DATABASE_ID = "designer_core_common_forms_entries";

	public static readonly C_AVAILABLE_FIELD_COMPONENTS: ReadonlyArray<IFieldComponent<any, any>> = [
		DesignService.createInputFieldTemplate(),
		DesignService.createLabelFieldTemplate(),
		DesignService.createSelectFieldTemplate()
	];

	private moDesignableDescriptorSubject = new Subject<IDesignableDescriptor>();
	private moDesignableDescriptor: IDesignableDescriptor = { id: "" };

	private maOptionsOfField: ISelectTag[] = [];
	private msOptionLabel: string;
	private msOptionValue: string;
	private msOptionId: string;

	//#endregion

	//#region METHODS

	constructor(
		private isvcForms: FormsService,
		private isvcStore: Store,
		private ioAlertCtlr: AlertController
	) { }

	private static createInputFieldTemplate(): IInputField {
		return {
			key: "",
			type: "input",
			templateOptions: {
				type: "text",
				required: false,
				placeholder: "",
				uppercase: false,
				max: 100,
				label: ""
			},
			fakeValue: "I'm an input."
		} as any as IInputField;
	}

	private static createLabelFieldTemplate(): ILabelField {
		return {
			key: "",
			type: "label",
			templateOptions: {
				required: false,
				type: "text",
				uppercase: false,
				max: 100,
				label: "",
			},
			fakeValue: "I'm a label."
		} as any as ILabelField;
	}

	private static createSelectFieldTemplate(): ISelectField {
		return {
			key: "",
			type: "select",
			templateOptions: {
				label: "",
				data: {
					multiple: false,
					options: [
						{
							id: "0",
							label: "value1",
							value: "value1"
						}
					],
					labelName: undefined,
					labelFieldName: undefined
				},
				multiple: false,
				required: false
			},
			fakeValue: {
				id: "0",
				label: "0",
				value: "0"
			}
		} as any as ISelectField;
	}

	//#region Alert

	/** Crée une alerte spécifique et renvoie le résultat sélectionné.
	 * @param psHeader En-tête de l'alerte.
	 * @param psMessage Message de l'alerte.
	 * @param paInputs Tableau des inputs de l'alerte.
	 * @param paButtons Tableau des boutons de l'alerte.
	 */
	private createAlert<T>(psHeader: string, psMessage: string, paInputs: AlertInput[], paButtons: AlertButton[]): Observable<OverlayEventDetail<T>> {
		return from(this.ioAlertCtlr.create({
			header: psHeader,
			message: psMessage,
			inputs: paInputs,
			buttons: paButtons
		}))
			.pipe(
				tap((poAlert: HTMLIonAlertElement) => poAlert.present()),
				mergeMap((poAlert: HTMLIonAlertElement) => poAlert.onDidDismiss())
			);
	}

	private chooseDefaultListDefinition(poDesignableDescriptor: IDesignableDescriptor, pbIsModified: boolean): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"Choose ListDefinition",
			"Choose a default listDefinition for the Descriptor.",
			poDesignableDescriptor.listDefinitions.map((poListDef: IListDefinition<any>) => this.createAlertRadioInput(poListDef.id)),
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values)) {
							poDesignableDescriptor.defaultListDefinitionId = poResult.data.values;
							if (pbIsModified)
								return of(AlertMessage.updateListDef);
							else
								return of(AlertMessage.createListDef);
						}
						else
							return of(AlertMessage.unselectListDef);
					}
					else
						return of(true);
				})
			);
	}

	/** Crée un input de type `radio`.
	 * @param psName Nom de l'input radio (label, nom, valeur).
	 * @param pbDisabled L'input doit être désactivé ou non, `false` par défaut.
	 */
	private createAlertRadioInput(psName: string, pbDisabled?: boolean): AlertInput {
		return {
			label: `${psName[0].toUpperCase()}${psName.substring(1)}`,
			name: psName,
			type: "radio",
			value: psName,
			disabled: pbDisabled
		};
	}

	/** Crée un input de type `text` placeholder.
	 * @param psName Nom de l'input placeholder.
	 */
	private createAlertPlaceholderInput(psName: string): AlertInput {
		return {
			name: psName,
			type: "text",
			placeholder: psName
		};
	}

	/** Crée et retourne un tableau de boutons pour l'alert, composé d'un bouton de confirmation et d'un bouton d'annulation. */
	private createDefaultAlertButtons(): AlertButton[] {
		return [
			this.createCancelAlertButton(),
			this.createConfirmAlertButton(DesignService.C_CONFIRM_BUTTON)
		];
	}

	/** Crée et retourne un bouton d'alerte d'annulation. */
	private createCancelAlertButton(): AlertButton {
		return {
			text: DesignService.C_CANCEL_BUTTON,
			role: DesignService.C_CANCEL_BUTTON
		};
	}

	/** Crée et retourne un bouton d'alerte de confirmation.
	 * @param psText Texte du bouton.
	 */
	private createConfirmAlertButton(psText: string): AlertButton {
		return {
			text: `${psText[0].toUpperCase()}${psText.substring(1)}`,
			role: DesignService.C_CONFIRM_BUTTON
		};
	}

	//#endregion

	//#region Transmit to Components

	private raiseDesignableDescriptor(poDesignbaleDescriptor: IDesignableDescriptor): void {
		this.moDesignableDescriptor = poDesignbaleDescriptor;
		this.moDesignableDescriptorSubject.next(this.moDesignableDescriptor);
	}

	public getDesignableDescriptorAsObservable(): Observable<IDesignableDescriptor> {
		return this.moDesignableDescriptorSubject.asObservable().pipe(startWith(this.moDesignableDescriptor));
	}

	//#endregion

	//#region Persistance Methods

	//#region Open Descriptor

	/** Permet de choisir entre l'ouverture ou la création d'un FormDescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @returns Retourne soit true soit un message à afficher.
	 */
	public prepareDescriptor(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"FormDescriptor",
			"What do you want to do ?",
			[
				this.createAlertRadioInput(DesignService.C_CREATE_BUTTON),
				this.createAlertRadioInput(DesignService.C_UPLOAD_BUTTON, true),
				this.createAlertRadioInput(DesignService.C_OPEN_BUTTON)
			],
			this.createDefaultAlertButtons()
		)
			.pipe(mergeMap((poResponse: AlertStringResult) => this.managePrepareDescriptorResponse(poResponse, poDesignableDescriptor)));
	}

	/** Gère la sélection du choix de l'utilisateur.
	 * @param poResponse Résultat renvoyée par l'alerte.
	 * @param poDesignableDescriptor Descripteur.
	 */
	private managePrepareDescriptorResponse(poResponse: AlertStringResult, poDesignableDescriptor: IDesignableDescriptor): Observable<string | boolean> {
		if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
			switch (poResponse.data.values) {
				case DesignService.C_CREATE_BUTTON:
					return this.showCreateDescriptorAlert(poDesignableDescriptor);

				case DesignService.C_OPEN_BUTTON:
					return this.showOpenDatabaseDescriptorAlert(poDesignableDescriptor);

				default:
					return of(AlertMessage.errorOccured);
			}
		}
		else
			return of(undefined);
	}

	/** Permet de remplir le nom du FormDescriptor qui va être créé.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @returns Retourne soit true soit un message à afficher.
	 */
	private showCreateDescriptorAlert(poDesignableDescriptor: IDesignableDescriptor): Observable<string> {
		return this.createAlert<AlertStringNameCreate>(
			"Create FormDescriptor",
			"Choose a name.",
			[this.createAlertPlaceholderInput(DesignService.C_NAME_BUTTON)],
			[this.createCancelAlertButton(), this.createConfirmAlertButton(DesignService.C_CREATE_BUTTON)]
		)
			.pipe(
				mergeMap((poResult: AlertStringNameResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values.name)) {
							this.createDescriptor(poDesignableDescriptor, poResult.data.values.name);
							return of(undefined);
						}
						else
							return of(AlertMessage.emptyName);
					}
					else
						return of(undefined);
				})
			);
	}

	/** Permet de finir la création d'un FormDescriptor avec son ID.
	* @param poDesignableDescriptor IForm d'origine.
	* @param psId Identifiant donné au FormDescriptor.
	*/
	private createDescriptor(poDesignableDescriptor: IDesignableDescriptor, psId: string): void {
		poDesignableDescriptor = this.clearDesignbaleDescriptor(poDesignableDescriptor, true);
		poDesignableDescriptor.id = `${EPrefix.formDesc}${psId}_v1.00.000`;
		poDesignableDescriptor.designableDefinitions = [];

		console.debug(`${ENameConsole.designService} formDescriptor created:`, poDesignableDescriptor, ".");
		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	//  todo: implémenter l'ouverture d'un fichier.
	/*private chooseFileToUpload(poDesignableDescriptor: IDesignableDescriptor): boolean {}*/

	/** Permet de charger un FormDescriptor depuis le PC sous forme de fichier.
	 * Si le fichier correspond à un FormDescriptor alors il est chargé ; sinon il renvoie une erreur.
	 * @param poDesignableDescriptor IForm d'origine.
	 * @param poFile Fichier sélectionné depuis le PC.
	 */
	// todo : corrigé le console.debug affiché avant la fin de la tache et décommenté lorsque la recupération d'un fichier local sera possible
	/*private uploadDescriptor(poDesignableDescriptor: IDesignableDescriptor, poFile: File): boolean {
		console.debug(`${ENameConsole.designService} Uploading formDescriptor :`, poFile);

		poDesignableDescriptor = this.clearDesignbaleDescriptor(poDesignableDescriptor, true);

		const loReader = new FileReader();
		loReader.readAsText(poFile);
		loReader.onloadend = (poEvent: ProgressEvent) => {
			const loFileJson: IFormDescriptor = JSON.parse(loReader.result as string);
			if (loFileJson.type === DesignService.C_FORM_DESCRIPTOR_TYPE) {
				poDesignableDescriptor = this.convertFormDescriptorToDesignableDescriptor(loFileJson);
				console.debug(`${ENameConsole.designService} FormDescriptor uploaded :`, poDesignableDescriptor.descriptor);
				this.raiseDesignableDescriptor(poDesignableDescriptor)
				return true;
			}
			else {
				console.error("File choosen is not a FormDescriptor.");
				return false;
			}
		}
		return false;
	} */

	/** Permet de choisir la base de données où le FormDecriptor se trouve.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @returns Retourne soit true soit un message à afficher.
	 */
	private showOpenDatabaseDescriptorAlert(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"Load FormDescriptor 1/2",
			"Choose a database.",
			this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsDefinitions).map((psDatabaseId: string) => this.createAlertRadioInput(psDatabaseId)),
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResponse: AlertStringResult) => {
					if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResponse.data.values))
							return this.showOpenDescriptorAlert(poDesignableDescriptor, poResponse.data.values);
						else
							return of(AlertMessage.unselectDB);
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de choisir le FormDescriptor à ouvrir.
	 * @param poDesignableDescriptor  DesignableDescriptor d'origine.
	 * @param psId Identifiant de la base de données.
	 */
	private showOpenDescriptorAlert(poDesignableDescriptor: IDesignableDescriptor, psId: string): Observable<boolean | string> {
		return this.isvcForms.getFormDescriptors(psId)
			.pipe(
				mergeMap((paResults: IFormDescriptor[]) => {
					return this.createAlert<AlertStringCreate>(
						"Load FormDescriptor 2/2",
						"Choose a formDescriptor.",
						this.createOpenDescriptorInputs(paResults),
						this.createDefaultAlertButtons()
					)
						.pipe(
							mergeMap((poResponse: AlertStringResult) => {
								if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
									if (!StringHelper.isBlank(poResponse.data.values)) {
										this.openDescriptor(poDesignableDescriptor, paResults, poResponse.data.values);
										return of(true);
									}
									else
										return of(AlertMessage.unselectDesc);
								}
								else
									return of(true);
							})
						);
				})
			);
	}

	/** Crée et retourne un tableau d'input d'alerte de type `radio` pour sélectionner un descripteur de formulaire.
	 * @param paFormDescriptors Tableau des descripteurs de formulaire qu'on peut sélectionner.
	 */
	private createOpenDescriptorInputs(paFormDescriptors: IFormDescriptor[]): AlertInput[] {
		return paFormDescriptors.map((poItem: IFormDescriptor) => {
			return {
				label: poItem.id,
				name: "formDescId",
				type: "radio",
				value: poItem.id
			} as AlertInput;
		});
	}

	/** Ouvre un descripteur.
	 * @param poDesignableDescriptor Descripteur.
	 * @param paResults Tableau des descripteurs de formulaire enregistrés en base de données.
	 * @param psId Identifiant du descripteur à ouvrir.
	 */
	private openDescriptor(poDesignableDescriptor: IDesignableDescriptor, paResults: IFormDescriptor[], psId: string): void {
		poDesignableDescriptor =
			this.convertFormDescriptorToDesignableDescriptor(paResults.find((poFormDescriptor: IFormDescriptor) => poFormDescriptor.id === psId));

		console.debug(`${ENameConsole.designService} FormDescriptor loaded :`, poDesignableDescriptor);
		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	//#endregion

	//#region Save Descriptor

	/** Permet de choisir entre l'enregistrement ou le téléchargement d'un FormDescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	public prepareSaveDescriptor(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"FormDescriptor",
			"What do you want to do ?",
			[this.createAlertRadioInput(DesignService.C_DOWNLOAD_BUTTON), this.createAlertRadioInput(DesignService.C_SAVE_BUTTON)],
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResponse: AlertStringResult) => {
					if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
						if (poResponse.data.values === DesignService.C_SAVE_BUTTON)
							return this.showSaveDescriptorAlert(poDesignableDescriptor);
						else if (poResponse.data.values === DesignService.C_DOWNLOAD_BUTTON)
							return of(this.downloadDescriptor(poDesignableDescriptor));
						else
							return of(AlertMessage.errorOccured);
					}
					else
						return of(true);
				})
			);
	}

	/** Télécharge le descripteur. */
	private downloadDescriptor(poDesignableDescriptor: IDesignableDescriptor): boolean | string {
		if (ArrayHelper.hasElements(poDesignableDescriptor.designableDefinitions)) {
			console.debug(`${ENameConsole.designService} Downloading FormDescriptor.`);

			const lsContent: string = JSON.stringify(this.convertDesignableDescriptorToFormDescriptor(poDesignableDescriptor));
			FileHelper.downloadBlob(new Blob([lsContent]), `${poDesignableDescriptor.id}.json`);

			return true;
		}
		else
			return AlertMessage.emptyDescriptor;
	}

	/** Affiche une alerte permettant de choisir le mode d'enregistrement du descripteur. */
	private showSaveDescriptorAlert(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"Load FormDescriptor 1/2",
			"Choose a database.",
			this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsDefinitions).map((psDatabaseId: string) => this.createAlertRadioInput(psDatabaseId)),
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values))
							return this.saveDescriptor(poDesignableDescriptor, poResult.data.values);
						else
							return of(AlertMessage.unselectDB);
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de sauvegarder un FormDescriptor en fonction de la méthode et de son nom.
	 * @param poDesignableDescriptor IForm d'origine.
	 * @param psNameFile Nom du fichier à enregistrer.
	 */
	private saveDescriptor(poDesignableDescriptor: IDesignableDescriptor, psDatabaseId: string): Observable<boolean | string> {
		if (ArrayHelper.hasElements(poDesignableDescriptor.designableDefinitions)) {
			console.debug(`${ENameConsole.designService} Saving FormDescriptor on database ${psDatabaseId}.`);

			return this.isvcStore.put(this.convertDesignableDescriptorToFormDescriptor(poDesignableDescriptor), psDatabaseId)
				.pipe(
					tap((poResult: IStoreDataResponse) => console.debug(`${ENameConsole.designService} FormDescriptor ${poResult.ok ? "" : "not"} saved.`)),
					map((poResult: IStoreDataResponse) => poResult.ok),
					catchError(poError => {
						console.error(`${ENameConsole.designService}:`, poError);
						return of(false);
					})
				);
		}
		else
			return of(AlertMessage.emptyDescriptor);
	}

	//#endregion

	//#region Open Definition

	/** Permet de choisir entre l'ouverture ou la création d'une définition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	public chooseCreateOrOpenDefinition(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		let lbCantOpen = true;

		if (ArrayHelper.hasElements(poDesignableDescriptor.designableDefinitions))
			lbCantOpen = false;

		return this.createAlert<AlertStringCreate>(
			"FormDefinition",
			"What do you want to do ?",
			[this.createAlertRadioInput(DesignService.C_CREATE_BUTTON), this.createAlertRadioInput(DesignService.C_OPEN_BUTTON, lbCantOpen)],
			this.createDefaultAlertButtons()
		)
			.pipe(mergeMap((poResponse: AlertStringResult) => this.managePrepareDefinitionResponse(poResponse, poDesignableDescriptor)));
	}

	/** Gère la sélection du choix de l'utilisateur.
	 * @param poResponse Résultat renvoyée par l'alerte.
	 * @param poDesignableDescriptor Descripteur.
	 */
	private managePrepareDefinitionResponse(poResponse: AlertStringResult, poDesignableDescriptor: IDesignableDescriptor): Observable<string | boolean> {

		if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
			switch (poResponse.data.values) {
				case DesignService.C_CREATE_BUTTON:
					return this.showCreateDefinitionAlert(poDesignableDescriptor);

				case DesignService.C_OPEN_BUTTON:
					return this.showOpenDefinitionAlert(poDesignableDescriptor);

				default:
					return of(AlertMessage.errorOccured);
			}
		}
		else
			return of(true);
	}

	/** Affiche une alerte permettant de créer une définition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	private showCreateDefinitionAlert(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringNameCreate>(
			"Create FormDefinition",
			"Choose a name.",
			[this.createAlertPlaceholderInput(DesignService.C_NAME_BUTTON)],
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResponse: AlertStringNameResult) => {
					if (poResponse.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResponse.data.values.name)) {
							this.createDefinition(poDesignableDescriptor, poResponse.data.values.name);
							return of(true);
						}
						else
							return of(AlertMessage.emptyName);
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de créer un FormDefinition dans le FormDescriptor avec son ID.
	 * @param poDesignableDescriptor IForm d'origine.
	 * @param psId Identifiant donné au FormDefinition.
	 */
	public createDefinition(poDesignableDescriptor: IDesignableDescriptor, psId: string): void {
		poDesignableDescriptor = this.clearDesignbaleDescriptor(poDesignableDescriptor, false);

		const laFormDefinitions: IDesignableDefinition[] = poDesignableDescriptor.designableDefinitions.filter((poDefinition: IDesignableDefinition) =>
			poDefinition.id === psId || poDefinition.id.includes(`${psId}_v`)
		);

		if (ArrayHelper.hasElements(laFormDefinitions))
			psId += `_v${laFormDefinitions.length}`;

		const loDefinition: IDesignableDefinition = {
			id: psId,
			fields: [],
			readOnly: false
		};
		poDesignableDescriptor.designableDefinitions.push(loDefinition);
		poDesignableDescriptor.selectedDefinitionId = loDefinition.id;

		console.debug(`${ENameConsole.designService} FormDefinition created :`, poDesignableDescriptor.designableDefinitions);
		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Affiche une alerte permettant d'ouvrir une définition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	private showOpenDefinitionAlert(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		return this.createAlert<AlertStringCreate>(
			"Load FormDefinition",
			"Choose a definition.",
			this.createOpenDefinitionInputs(poDesignableDescriptor),
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values)) {
							this.openDefinition(poDesignableDescriptor, poResult.data.values);
							return of(true);
						}
						else
							return of(AlertMessage.unselectDef);
					}
					else
						return of(true);
				})
			);
	}

	/** Crée et retourne un tableau d'input d'alerte de type `radio` pour sélectionner une définition de formulaire.
	 * @param poDesignableDescriptor Objet contenant les définitions qu'on peut sélectionner.
	 */
	private createOpenDefinitionInputs(poDesignableDescriptor: IDesignableDescriptor): AlertInput[] {
		return poDesignableDescriptor.designableDefinitions
			.filter((poDefinition: IDesignableDefinition) => !poDefinition.readOnly)
			.map((poDefinition: IDesignableDefinition) => {
				return {
					label: poDefinition.id,
					name: "fDefId",
					type: "radio",
					value: poDefinition.id
				} as AlertInput;
			});
	}

	/** Permet de charger un FormDefinition sélectionné dans le FormDescriptor.
	 * @param poDesignableDescriptor IForm d'origine.
	 * @param psId Identifiant du FormDefinition sélectionné.
	 */
	public openDefinition(poDesignableDescriptor: IDesignableDescriptor, psId: string): void {
		const loDefinition: IDesignableDefinition =
			poDesignableDescriptor.designableDefinitions.find((poDefinition: IDesignableDefinition) => poDefinition.id === psId);

		if (loDefinition) {
			poDesignableDescriptor = this.clearDesignbaleDescriptor(poDesignableDescriptor, false);
			poDesignableDescriptor.selectedDefinitionId = loDefinition.id;
			console.debug(`${ENameConsole.designService} formDefinition loaded:`, loDefinition.fields);
		}
		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	//#endregion

	//#endregion

	//#region Edit Descriptor

	/** Permet de réinitialiser le IForm, lors d'un changement de descripteurr ou de définition.
	 * @param poDesignableDescriptor
	 * @param pbAll
	 */
	private clearDesignbaleDescriptor(poDesignableDescriptor: IDesignableDescriptor, pbAll: boolean): IDesignableDescriptor {
		delete poDesignableDescriptor.selectedDefinitionId;
		delete poDesignableDescriptor.fakeEntry;

		if (pbAll) {
			poDesignableDescriptor.id = "";
			delete poDesignableDescriptor.dataSources;
			delete poDesignableDescriptor.listDefinitions;
		}

		return poDesignableDescriptor;
	}

	/** Permet de créer ou modifier une listDefinition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	public chooseCreateOrEditListDef(poDesignableDescriptor: IDesignableDescriptor): Observable<boolean | string> {
		let lbIsEditable = true;

		poDesignableDescriptor = this.copySelectedDefinitionToViewFormat(poDesignableDescriptor);

		if (poDesignableDescriptor.listDefinitions && poDesignableDescriptor.listDefinitions.length > 0)
			lbIsEditable = false;

		return this.createAlert<AlertStringBoolCreate>(
			"List Definition",
			"",
			[this.createAlertRadioInput("create"), this.createAlertRadioInput("edit", lbIsEditable)],
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringBoolResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						switch (poResult.data.values) {
							case "create":
								return this.chooseDefinitionForListDefinition(poDesignableDescriptor, false);
							case "edit":
								return this.chooseListDefinitionToEdit(poDesignableDescriptor);
							default:
								return of(AlertMessage.errorOccured);
						}
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de choisir la listDefinition à modifier.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	private chooseListDefinitionToEdit(poDesignableDescriptor: IDesignableDescriptor): Observable<string | boolean> {
		return this.createAlert<AlertStringCreate>(
			"Edit ListDefinition 1/2",
			"Choose a list definition to edit.",
			poDesignableDescriptor.listDefinitions.map((poListDefinition: IListDefinition<any>) => this.createAlertRadioInput(poListDefinition.id)),
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values))
							return this.editListDef(poDesignableDescriptor, poResult.data.values);
						else
							return of(AlertMessage.unselectListDef);
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de choisir entre modifier et supprimer la listDefinition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psId Id de la définition choisit.
	 */
	private editListDef(poDesignableDescriptor: IDesignableDescriptor, psId: string): Observable<boolean | string> {
		return this.createAlert(
			"Edit ListDefinition 2/2",
			"",
			[this.createAlertRadioInput("modify"), this.createAlertRadioInput("delete")],
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: OverlayEventDetail) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						switch (poResult.data.values) {
							case "modify":
								return this.modifyListDefinition(poDesignableDescriptor, psId);
							case "delete":
								return this.deleteListDefinition(poDesignableDescriptor, psId, false) as Observable<boolean | string>;
							default:
								return of(AlertMessage.errorOccured);
						}
					}
					else
						return of(true);
				})
			);
	}

	/** Permet d'appeler les méthodes pour modifier la listDefinition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psId Id de la définition choisit.
	 */
	private modifyListDefinition(poDesignableDescriptor: IDesignableDescriptor, psId: string): Observable<boolean | string> {
		this.deleteListDefinition(poDesignableDescriptor, psId, true)
			.pipe(tap((poResult: IDesignableDescriptor) => poDesignableDescriptor = poResult));

		return this.chooseDefinitionForListDefinition(poDesignableDescriptor, true);
	}

	/**
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psId Id de la définition choisit.
	 * @param pbCalled true si appeler par modifyListDefinition afin de retourner de Descriptor.
	 */
	private deleteListDefinition(poDesignableDescriptor: IDesignableDescriptor, psId: string, pbCalled: boolean)
		: Observable<boolean | string | IDesignableDescriptor> {

		const laIdCuts: string[] = psId.split("ld_");
		const lsId: string = laIdCuts[1];
		const lnIndexLDef: number =
			poDesignableDescriptor.listDefinitions.findIndex((poListDefinition: IListDefinition<any>) => poListDefinition.id.includes(lsId));

		if (lnIndexLDef > -1)
			poDesignableDescriptor.listDefinitions.splice(lnIndexLDef, 1);

		const lnIndexDS: number =
			poDesignableDescriptor.dataSources.findIndex((poDataSource: IFormDescriptorDataSource) => poDataSource.id.includes(lsId));

		if (lnIndexDS > -1)
			poDesignableDescriptor.dataSources.splice(lnIndexDS, 1);

		if (pbCalled)
			return of(poDesignableDescriptor);
		else {
			this.raiseDesignableDescriptor(poDesignableDescriptor);
			return of(AlertMessage.deleteListDef);
		}
	}

	/** Permet de choisir une définition à mettre dans la listDefinition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param pbIsModified true si appler par modifyListDefinition afin de retourner un message différent.
	 */
	private chooseDefinitionForListDefinition(poDesignableDescriptor: IDesignableDescriptor, pbIsModified: boolean): Observable<boolean | string> {
		const laInputs: AlertInput[] = poDesignableDescriptor.designableDefinitions
			.filter((poDefinition: IDesignableDefinition) => !poDefinition.readOnly)
			.map((poDefinition: IDesignableDefinition) => this.createAlertRadioInput(poDefinition.id));

		return this.createAlert<AlertStringCreate>(
			"Create ListDefinition",
			"Choose a definition which permits to create entry.",
			laInputs,
			this.createDefaultAlertButtons()
		)
			.pipe(
				mergeMap((poResult: AlertStringResult) => {
					if (poResult.role === DesignService.C_CONFIRM_BUTTON) {
						if (!StringHelper.isBlank(poResult.data.values))
							return this.getListDefinition(poDesignableDescriptor, poResult.data.values, pbIsModified);
						else
							return of(AlertMessage.unselectDef);
					}
					else
						return of(true);
				})
			);
	}

	/** Permet de créer la DataSource du FormDescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psId Id de la définition choisit.
	 * @param pbIsModified true si appler par modifyListDefinition afin de retourner un message différent.
	 */
	private createDataSource(poDesignableDescriptor: IDesignableDescriptor, psId: string, pbIsModified: boolean): Observable<boolean | string> {
		if (!poDesignableDescriptor.dataSources)
			poDesignableDescriptor.dataSources = [];

		poDesignableDescriptor.dataSources.push({
			id: `ds_${psId}`,
			type: EStoreType.CouchDb,
			db: DesignService.C_ENTRIES_DATABASE_ID,
			view: Store.C_DEFAULT_VIEW_DOCS
		});

		return this.chooseDefaultListDefinition(poDesignableDescriptor, pbIsModified);
	}

	/** Permet de créer la ListDefinition du FormDescriptor.
		* @param poDesignableDescriptor DesignableDescriptor d'origine.
	* @param psId Id de la définition choisit.
	 * @param pbIsModified true si appler par modifyListDefinition afin de retourner un message différent.
	 */
	private getListDefinition(poDesignableDescriptor: IDesignableDescriptor, psId: string, pbIsModified: boolean) {
		if (!poDesignableDescriptor.listDefinitions)
			poDesignableDescriptor.listDefinitions = [];

		const loDefinition: IDesignableDefinition =
			poDesignableDescriptor.designableDefinitions.find((poDefinition: IDesignableDefinition) => poDefinition.id === psId);
		// Prépapre la 'listDefinitionField[]'.
		const laFields: IListDefinitionsField[] = loDefinition.fields.map((poField: IDesignableComponent) => {
			return {
				key: poField.key,
				label: poField.templateOptions.label,
				isPicture: false,
				searchable: true
			} as IListDefinitionsField;
		});
		// Prépare l'ID à utiliser dans la 'listDefinition' et la 'dataSource'.
		let lsId = loDefinition.id.split("_edit")[0];

		if (!ArrayHelper.hasElements(laFields)) {
			if (poDesignableDescriptor.designableDefinitions[0].fields.length >= 3) {
				for (let i = 0; i < 3; i++) {
					laFields.push({
						key: poDesignableDescriptor.designableDefinitions[0].fields[i].key,
						label: poDesignableDescriptor.designableDefinitions[0].fields[i].templateOptions.label
					} as IListDefinitionsField);
				}
			}
			else {
				for (let i = 0; i < poDesignableDescriptor.designableDefinitions[0].fields.length; i++) {
					laFields.push({
						key: poDesignableDescriptor.designableDefinitions[0].fields[i].key,
						label: poDesignableDescriptor.designableDefinitions[0].fields[i].templateOptions.label
					} as IListDefinitionsField);
				}
			}
		}

		// Évite le même nom pour 2 listDefinitions.
		const laListDefinitions = poDesignableDescriptor.listDefinitions.filter((poListDef: IListDefinition<any>) =>
			poListDef.id.includes(lsId));

		if (ArrayHelper.hasElements(laListDefinitions))
			lsId += `_v${laListDefinitions.length}`;

		poDesignableDescriptor.listDefinitions.push(this.createListDefinition(lsId, laFields));

		return this.createDataSource(poDesignableDescriptor, lsId, pbIsModified);
	}

	private createListDefinition(psDefinitionId: string, paFormlyFields: IListDefinitionsField[]): IListDefinition<any> {
		return {
			id: `ld_${psDefinitionId}`,
			autofocusSearchbox: true,
			hasSearchbox: true,
			searchboxPlaceholder: "Rechercher",
			dataSource: `ds_${psDefinitionId}`,
			hasAddButton: true,
			allowDelete: true,
			displayType: "list",
			itemOptionSize: 90,
			itemOptions: [
				{
					action: "sendEvent",
					icon: "trash",
					label: "Delete",
					color: "danger",
					permission: "delete"
				}
			],
			formParams: {
				formDefinitionId: `${psDefinitionId}${ESuffix.view}`
			},
			createFormParams: {
				formDefinitionId: `${psDefinitionId}${ESuffix.edit}`
			},
			fields: paFormlyFields
		} as IListDefinition<any>;
	}

	//#endregion

	//#region Edit Definition

	/** Permet d'ajouter le Fields dans le FormDefinition.
	 * @param poDesignableDescriptor IForm d'origine.
	 */
	// public addSelectedDefinitionToDesignableDescriptor(poDesignableDescriptor: IDesignableDescriptor): void {
	// 	const loSelectedDefinition: IDesignableDefinition = poDesignableDescriptor.selectedDefinition;
	// 	let lbIsSaved: boolean = false;

	// 	if (loSelectedDefinition) {
	// 		const loDefinition: IDesignableDefinition = poDesignableDescriptor.designableDefinitions.find((poFormDefinition: IDesignableDefinition) =>
	// 			poFormDefinition.id === loSelectedDefinition.id
	// 		);

	// 		if (loDefinition) {
	// 			loDefinition.fields = loSelectedDefinition.fields;
	// 			lbIsSaved = true;
	// 		}
	// 		if (!lbIsSaved) {
	// 			const loDefinition: IDesignableDefinition = {
	// 				id: loSelectedDefinition.id,
	// 				fields: loSelectedDefinition.fields,
	// 				readOnly: loSelectedDefinition.readOnly
	// 			}
	// 			poDesignableDescriptor.designableDefinitions.push(loDefinition);
	// 		}
	// 	}

	// 	this.raiseDesignableDescriptor(poDesignableDescriptor);
	// }

	/** Permet de supprimer un DesignableDefinition du DesignableDescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	public deleteDesignableDefinition(poDesignableDescriptor: IDesignableDescriptor): string {
		console.debug(`${ENameConsole.designService}Removing FormDefinition.`);
		const lnIndex: number = poDesignableDescriptor.designableDefinitions.findIndex(
			(poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId
		);

		if (lnIndex > -1) {
			poDesignableDescriptor.designableDefinitions.splice(lnIndex, 1);

			delete poDesignableDescriptor.selectedDefinitionId;
			delete poDesignableDescriptor.selectedField;

			this.raiseDesignableDescriptor(poDesignableDescriptor);

			return AlertMessage.deleteDef;
		}
		else {
			console.error(`${ENameConsole.designService}Can't remove FormDefinition, not found.`);
			return AlertMessage.notFoundDef;
		}
	}

	/** Permet d'ajouter un champ à la definition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psType Type de champ à ajouter.
	 */
	public addFieldToSelectedDefinition(poDesignableDescriptor: IDesignableDescriptor, psType: string): void {
		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions.findIndex(
			(poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);
		const lnRepeat: number = poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields
			.filter((poItem: IDesignableComponent) => poItem.type === psType)
			.length;
		const lnIndex: number = DesignService.C_AVAILABLE_FIELD_COMPONENTS.findIndex((poField: IDesignableComponent) => poField.type === psType);
		const loField: IDesignableComponent = { ...cloneDeep(DesignService.C_AVAILABLE_FIELD_COMPONENTS[lnIndex]), isSelectForListDefinition: false } as any;

		// loField.key = `${psType}_${lnRepeat + 1}`; TODO Maintenance
		poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields.push(loField);

		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Permet de supprimer un champ de la definition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psKey Clé du champ à supprimer.
	 */
	public deleteFieldToSelectedDefinition(poDesignableDescriptor: IDesignableDescriptor, psKey: string): void {
		console.debug(`${ENameConsole.designService}Removing field.`);

		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions
			.findIndex((poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);
		const lnIndex: number = poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields
			.findIndex((poField: IDesignableComponent) => poField.key === psKey);

		if (lnIndex > -1) {
			poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields.splice(lnIndex, 1);
			delete poDesignableDescriptor.selectedField;
		}

		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Permet de restaurer la dernière version du DesignableDefinition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	// todo : à faire avec la version
	public undoModification(poDesignableDescriptor: IDesignableDescriptor): void { }
	public redoModification(poDesignableDescriptor: IDesignableDescriptor): void { }

	/** Permet de supprimer tous les champs de la définition.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	public clearSelectedDefinition(poDesignableDescriptor: IDesignableDescriptor): string {
		poDesignableDescriptor.designableDefinitions
			.find((poDefinition: IDesignableDefinition) => poDefinition.id === poDesignableDescriptor.selectedDefinitionId)
			.fields = [];

		this.raiseDesignableDescriptor(poDesignableDescriptor);

		return AlertMessage.resetDef;
	}

	public transformSelectedDefinition(poDesignableDescriptor: IDesignableDescriptor): Observable<string> {
		console.debug(`${ENameConsole.designService}Transforming Definition.`);

		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions.findIndex((poDesignableDefinition: IDesignableDefinition) =>
			poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId
		);

		poDesignableDescriptor.designableDefinitions[lnIndexDefinition].readOnly = !poDesignableDescriptor.designableDefinitions[lnIndexDefinition].readOnly;

		console.debug(`${ENameConsole.designService}Transformed:`, poDesignableDescriptor.designableDefinitions[lnIndexDefinition].readOnly, ".");

		this.raiseDesignableDescriptor(poDesignableDescriptor);

		return of(`${poDesignableDescriptor.designableDefinitions[lnIndexDefinition].readOnly ? AlertMessage.isReadOnly : AlertMessage.notReadOnly}`);
	}

	//#endregion

	//#region Edit Field

	/** Permet de sélectionner un champ à éditer.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psKey Clé du champ à sélectionner.
	 */
	public selectFieldToEdit(poDesignableDescriptor: IDesignableDescriptor, psKey: string): void {
		console.debug(`${ENameConsole.designService}Selecting field.`);

		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions
			.findIndex((poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);
		const lnIndex: number = poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields
			.findIndex((poField: IDesignableComponent) => poField.key === psKey);

		if (lnIndex > -1) {
			poDesignableDescriptor.selectedField = cloneDeep(poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields[lnIndex]);
			this.raiseDesignableDescriptor(poDesignableDescriptor);
		}
	}

	/** Permet de valider ou non les modifications d'un champ.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param poEntries Entrées du formulaire d'édition.
	 * @param pbValidate Booléen validant ou annulant l'édition.
	 */
	public editSelectedField(poDesignableDescriptor: IDesignableDescriptor, poEntry: NgForm, pbValidate: boolean): void {
		if (pbValidate) {
			const loEntry: { position: number, originKey: string } = poEntry.value;
			const loField: IDesignableComponent = { templateOptions: { data: {} } } as any;
			const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions.findIndex(
				(poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);

			this.setSelectedFieldEntryKeys(loEntry, loField);

			const lnIndex: number = poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields.findIndex((poField: IDesignableComponent) => poField.key === loEntry.originKey);
			poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields[lnIndex] = loField;

			if (NumberHelper.isStringNumber(loEntry.position) && NumberHelper.isValid(+loEntry.position))
				this.moveFieldInSelectedDefinition(poDesignableDescriptor, loField.key as string, +loEntry.position);
		}

		this.maOptionsOfField = [];
		delete poDesignableDescriptor.selectedField;

		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	private setSelectedFieldEntryKeys(poEntry: any, poField: IDesignableComponent) {
		// poField.key = poEntry.key; TODO Maintenance
		poField.type = poEntry.typeOfField;
		poField.isSelectForListDefinition = poEntry.isSelectForListDefinition;

		for (const lsKey in poEntry) {
			if (lsKey === "fakeValue")
				Object.assign(poField.fakeValue = poEntry[lsKey]);

			if (lsKey.includes("to."))
				Object.assign(poField.templateOptions[ArrayHelper.getLastElement(lsKey.split("."))] = poEntry[lsKey]);
			else if (lsKey.includes("dt."))
				this.innerSetSelectedFieldEntryKeys(poEntry, poField, lsKey);
		}
	}

	private innerSetSelectedFieldEntryKeys(poEntry: any, poField: IDesignableComponent, psKey: string): void {
		const lsOptionsKey = "options";
		let lbHasToPushConfirmCreationOfOption = true;

		if (psKey.includes("optLabel"))
			this.msOptionLabel = poEntry[psKey];
		else if (psKey.includes("optValue"))
			this.msOptionValue = poEntry[psKey];
		else if (psKey.includes("optId"))
			this.msOptionId = poEntry[psKey];
		else {
			lbHasToPushConfirmCreationOfOption = false;
			poField.templateOptions.data[ArrayHelper.getLastElement(psKey.split("."))] = poEntry[psKey];
		}

		if (lbHasToPushConfirmCreationOfOption &&
			!StringHelper.isBlank(this.msOptionLabel) &&
			!StringHelper.isBlank(this.msOptionValue) &&
			!StringHelper.isBlank(this.msOptionId)) {

			this.maOptionsOfField.push({
				id: this.msOptionId,
				label: this.msOptionLabel,
				value: this.msOptionValue
			});
			this.msOptionId = this.msOptionLabel = this.msOptionValue = "";
		}

		Object.assign(poField.templateOptions.data[lsOptionsKey] = this.maOptionsOfField);
		Object.assign(poField.templateOptions[lsOptionsKey] = this.maOptionsOfField);
	}

	/** Permet d'ajouter une option à un champ select.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psKey Clé du champ recevant l'option supplémentaire.
	 */
	public addOptionToSelectedField(poDesignableDescriptor: IDesignableDescriptor): void {
		const lsOptionLabelValue = `value${(poDesignableDescriptor.selectedField as ISelectField).templateOptions.data.options.length + 1}`;
		const loOption: ISelectTag = {
			id: GuidHelper.newGuid(),
			value: lsOptionLabelValue,
			label: lsOptionLabelValue
		};

		(poDesignableDescriptor.selectedField as ISelectField).templateOptions.data.options.push(loOption);

		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Permet de supprimer une option à un champ.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 * @param psKey Clé du champ perdant l'option sélectionnée.
	 * @param psIndex Index de l'option dans le champ.
	 */
	public deleteOptionToSelectedField(poDesignableDescriptor: IDesignableDescriptor, psIndex: number): void {
		(poDesignableDescriptor.selectedField as ISelectField).templateOptions.data.options.splice(psIndex, 1);
		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Permet de déplacer un champ du Fields.
	 * @param poDesignableDescriptor IForm d'origine.
	 * @param psKey Clé du champ ciblé.
	 * @param pnTargetIndex Position ciblée du Fields.
	 */
	public moveFieldInSelectedDefinition(poDesignableDescriptor: IDesignableDescriptor, psKey: string, pnTargetIndex: number): boolean {
		console.debug(`${ENameConsole.designService}Moving field.`);

		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions
			.findIndex((poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);
		const lnStartIndex: number = poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields
			.findIndex((field: IDesignableComponent) => field.key === psKey);
		const lbResult: boolean = ArrayHelper.moveElement(poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields, lnStartIndex, pnTargetIndex);

		console.debug(`${ENameConsole.designService}Moved:`, poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields);

		this.raiseDesignableDescriptor(poDesignableDescriptor);

		return lbResult;
	}

	/** Permet de sauvegarder la DesignableDefinition dans le DesignableDsescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor d'origine.
	 */
	private copySelectedDefinitionToViewFormat(poDesignableDescriptor: IDesignableDescriptor): IDesignableDescriptor {
		console.debug(`${ENameConsole.designService}Saving DesignableDefinition_view.`);

		poDesignableDescriptor.designableDefinitions.forEach((poDesignableDefinition: IDesignableDefinition) => {
			if (!poDesignableDefinition.readOnly) {
				const loDefinitionView: IDesignableDefinition = this.createSelectedDefinitionViewVersion(poDesignableDefinition);
				let lbIsSaved = false;
				let loDefinitionV: IDesignableDefinition = poDesignableDescriptor.designableDefinitions
					.find((poFormDefinition: IDesignableDefinition) => poFormDefinition.id === loDefinitionView.id);

				if (loDefinitionV) {
					loDefinitionV = loDefinitionView;
					lbIsSaved = true;
					console.debug(`${ENameConsole.designService}Saved:`, poDesignableDescriptor.designableDefinitions, ".");
				}
				if (!lbIsSaved) {
					poDesignableDescriptor.designableDefinitions.push(loDefinitionView);
					console.debug(`${ENameConsole.designService}Saved:`, poDesignableDescriptor.designableDefinitions, ".");
				}
			}
		});

		return poDesignableDescriptor;
	}

	/** Permet d'enregistrer ou mettre à jour un DesignableDefinition dans son DesignableDescriptor.\
	 * La version Edit puis la version View du DesignbaleDescriptor.
	 * @param poDesignableDefinition Definition selectionnee.
	 */
	private createSelectedDefinitionViewVersion(poDesignableDefinition: IDesignableDefinition): IDesignableDefinition {
		const loDesignableDefinition: IDesignableDefinition = {
			id: `${ArrayHelper.getFirstElement(poDesignableDefinition.id.split(ESuffix.edit))}${ESuffix.view}`,
			fields: [],
			readOnly: true
		};

		poDesignableDefinition.fields.forEach((poField: IDesignableComponent) => {
			if (poField.type !== "label") {
				if (poField.type === "select" && poField.templateOptions.multiple) {
					loDesignableDefinition.fields.push({
						key: `${poField.key}lbl`,
						type: "label",
						isSelectForListDefinition: false,
						templateOptions: {
							required: false,
							label: poField.templateOptions.label
						}
					} as IDesignableComponent);
					poField.templateOptions.readonly = true;
					loDesignableDefinition.fields.push(poField);
				}
				else {
					loDesignableDefinition.fields.push({
						key: poField.key,
						type: "label",
						isSelectForListDefinition: false,
						templateOptions: {
							required: false,
							label: poField.templateOptions.label
						}
					} as IDesignableComponent);
				}
			}
			else
				loDesignableDefinition.fields.push(poField);
		});

		return loDesignableDefinition;
	}

	//#endregion

	//#region Convert Methods

	/** Permet de convertir un DesignableField en FormlyFieldConfig.
	 * @param poDesignableField DesignableField à convertir.
	 */
	public convertDesignableComponentToFormlyFieldConfig(poDesignableField: IDesignableComponent): FormlyFieldConfig {
		const loFormlyField: FormlyFieldConfig = {} as any;
		const loTemplateOptions: FormlyTemplateOptions = {};

		for (const lsKey in poDesignableField) {
			if (lsKey !== "templateOptions" && lsKey !== "fakeValue" && lsKey !== "isSelectForListDefinition")
				loFormlyField[lsKey] = poDesignableField[lsKey];
			else {
				if (lsKey === "templateOptions") {
					for (const lsSubKey in poDesignableField.templateOptions) {
						loTemplateOptions[lsSubKey] = poDesignableField.templateOptions[lsSubKey];
					}
				}
			}
		}

		if (!ObjectHelper.isEmpty(loTemplateOptions))
			loFormlyField.templateOptions = loTemplateOptions;

		return loFormlyField;
	}

	/** Permet de convertir un FormlyFieldConfig en DesignableField.
	 * @param poFormlyField FormlyFieldConfig à convertir.
	 */
	public convertFormlyFieldConfigToDesignableComponent(poFormlyField: FormlyFieldConfig): IDesignableComponent {
		const loDesignableField: IDesignableComponent = { templateOptions: {} } as any;

		for (const lsKey in poFormlyField) {
			if (lsKey !== "templateOptions")
				loDesignableField[lsKey] = poFormlyField[lsKey];
			else {
				for (const lsSubKey in poFormlyField.templateOptions) {
					loDesignableField.templateOptions[lsSubKey] = poFormlyField.templateOptions[lsSubKey];
				}
			}
		}

		if (loDesignableField.type === DesignService.C_SELECT_TYPE)
			loDesignableField.templateOptions.options = loDesignableField.templateOptions.data.options;
		else {
			const loIndex: number = DesignService.C_AVAILABLE_FIELD_COMPONENTS
				.findIndex((poComponent: IDesignableComponent) => loDesignableField.type === poComponent.type);

			loDesignableField.fakeValue = DesignService.C_AVAILABLE_FIELD_COMPONENTS[loIndex].fakeValue;
		}

		return loDesignableField;
	}

	/** Permet de convertir une liste de DesignableComponent en une de FormlyFieldConfig.
	 * @param paDesignbaleComponents Liste devant être convertie.
	 */
	public convertArrayOfDesignableComponentToArrayOfFormlyFieldConfig(paDesignbaleComponents: IDesignableComponent[]): FormlyFieldConfig[] {
		return paDesignbaleComponents.map((poField: IDesignableComponent) => this.convertDesignableComponentToFormlyFieldConfig(poField));
	}

	/** Permet de convertir une liste de FormlyFieldConfig en une de DesignbaleComponent.
	 * @param poDesignableDefinition DesignbaleDefinition ciblée.
	 * @param poFormlyFields Definition à convertir.
	 */
	private convertArrayOfFormlyFieldConfigToArrayOfDesignableComponent(poDesignableDefinition: IDesignableDefinition, poFormlyFields: FormlyFieldConfig[])
		: IDesignableDefinition {

		poFormlyFields.forEach((poFormlyField: FormlyFieldConfig) => {
			if (poFormlyField.fieldGroup)
				poDesignableDefinition = this.convertArrayOfFormlyFieldConfigToArrayOfDesignableComponent(poDesignableDefinition, poFormlyField.fieldGroup);
			else
				poDesignableDefinition.fields.push(this.convertFormlyFieldConfigToDesignableComponent(poFormlyField));
		});

		return poDesignableDefinition;
	}

	/** Permet de convertir une DesignableDefinition en FormDefinition.
	 * @param poDesignableDefinition DesignableDefinition à convertir.
	 */
	public convertDesignableDefinitionToFormDefinition(poDesignableDefinition: IDesignableDefinition, psDataSourceId?: string): IFormDefinition {
		const loFormlyFields: FormlyFieldConfig[] = [];

		poDesignableDefinition.fields.forEach((poDesignableField: IDesignableComponent) => {
			const loFormlyField: FormlyFieldConfig = { templateOptions: {} };

			for (const lsKey in poDesignableField) {
				if (lsKey !== "templateOptions" && lsKey !== "fakeValue" && lsKey !== "isSelectForListDefinition")
					loFormlyField[lsKey] = poDesignableField[lsKey];
				else {
					for (const lsSubKey in poDesignableField.templateOptions) {
						loFormlyField.templateOptions[lsSubKey] = poDesignableField.templateOptions[lsSubKey];
					}
				}
			}

			loFormlyFields.push(loFormlyField);
		});

		return {
			id: poDesignableDefinition.id,
			engine: EFormEngine.formly,
			definition: loFormlyFields,
			dataSource: psDataSourceId,
			submitType: "toolbar",
			readOnly: poDesignableDefinition.readOnly
		} as IFormDefinition;
	}

	/** Permet de convertir une FormDefinition en DesignableDefinition.
	 * @param poFormDefinition FormDefinition à convertir.
	 */
	public convertFormDefinitionToDesignableDefinition(poFormDefinition: IFormDefinition): IDesignableDefinition {
		const loDesignableDefinition: IDesignableDefinition = {
			id: poFormDefinition.id,
			fields: [],
			readOnly: poFormDefinition.readOnly
		};

		return this.convertArrayOfFormlyFieldConfigToArrayOfDesignableComponent(loDesignableDefinition, poFormDefinition.definition);
	}

	/** Permet de convertir un DesignableDescriptor en FormDescriptor.
	 * @param poDesignableDescriptor DesignableDescriptor à convertir.
	 */
	public convertDesignableDescriptorToFormDescriptor(poDesignableDescriptor: IDesignableDescriptor): IFormDescriptor {
		const laFormDefinitions: IFormDefinition[] = [];

		poDesignableDescriptor.designableDefinitions.forEach((poDesignableDefinition: IDesignableDefinition) => {
			const lsProperty = poDesignableDefinition.readOnly ? "_view" : "_edit";
			const lsId: string = ArrayHelper.getFirstElement(poDesignableDefinition.id.split(lsProperty));
			const loDataSource: IFormDescriptorDataSource =
				poDesignableDescriptor.dataSources.find((poDataSource: IFormDescriptorDataSource) => poDataSource.id.includes(lsId));

			laFormDefinitions.push(this.convertDesignableDefinitionToFormDefinition(poDesignableDefinition, loDataSource?.id));
		});

		return {
			id: poDesignableDescriptor.id,
			_id: poDesignableDescriptor.id,
			_rev: poDesignableDescriptor._rev,
			defaultEngine: "formly",
			defaultDefinition: "standard",
			type: "formDescriptor",
			meta: {
				schemaVersion: "1.0.0"
			},
			dataSources: poDesignableDescriptor.dataSources,
			listDefinitions: poDesignableDescriptor.listDefinitions,
			defaultList: poDesignableDescriptor.defaultListDefinitionId,
			formDefinitions: laFormDefinitions
		} as IFormDescriptor;
	}

	/** Permet de convertir un FormDescritor en DesignableDescriptor.
	 * @param poFormDescriptor FormDescriptor à convertir.
	 */
	public convertFormDescriptorToDesignableDescriptor(poFormDescriptor: IFormDescriptor): IDesignableDescriptor {
		this.clearDesignbaleDescriptor(this.moDesignableDescriptor, true);

		poFormDescriptor.formDefinitions.forEach((formDefinition: IFormDefinition) => {
			if (!formDefinition.readOnly) {
				const loDesignableDefinition: IDesignableDefinition = this.convertFormDefinitionToDesignableDefinition(formDefinition);

				poFormDescriptor.listDefinitions.forEach((poListDefinition: IListDefinition<any>) => {
					if (loDesignableDefinition.id === poListDefinition.formParams.formDefinitionId) {
						poFormDescriptor.listDefinitions[0].fields.forEach((poField: IListDefinitionsField) => {
							const lnIndex: number = loDesignableDefinition.fields.findIndex((poComponent: IDesignableComponent) => poComponent.key === poField.key);
							loDesignableDefinition.fields[lnIndex].isSelectForListDefinition = true;
						});
					}
				});
			}
		});

		const laDesignableDefinitions: IDesignableDefinition[] = poFormDescriptor.formDefinitions
			.map((poItem: IFormDefinition) => this.convertFormDefinitionToDesignableDefinition(poItem));

		return {
			id: poFormDescriptor.id,
			_rev: poFormDescriptor._rev,
			defaultListDefinitionId: poFormDescriptor.defaultList,
			dataSources: poFormDescriptor.dataSources,
			listDefinitions: poFormDescriptor.listDefinitions,
			designableDefinitions: laDesignableDefinitions
		} as IDesignableDescriptor;
	}

	/** Permet de créer un modèle d'entrées pour la preview.
	 * @param poDesignableDescriptor IForm d'origine.
	 */
	public prepareEntryToTestDefinition(poDesignableDescriptor: IDesignableDescriptor): void {
		const lnIndexDefinition: number = poDesignableDescriptor.designableDefinitions
			.findIndex((poDesignableDefinition: IDesignableDefinition) => poDesignableDefinition.id === poDesignableDescriptor.selectedDefinitionId);

		poDesignableDescriptor.fakeEntry = { _id: "" };

		poDesignableDescriptor.designableDefinitions[lnIndexDefinition].fields.forEach((poDesignableField: IFieldComponent<any, any>) => {
			if (poDesignableField.type === DesignService.C_SELECT_TYPE) {
				poDesignableField.templateOptions.options = (poDesignableField as ISelectField).templateOptions.data.options;
				poDesignableDescriptor.fakeEntry[poDesignableField.key as string] = (poDesignableField as ISelectField).templateOptions.data.options[0].value;
			}
			else
				poDesignableDescriptor.fakeEntry[poDesignableField.key as string] = poDesignableField.fakeValue;
		});

		this.raiseDesignableDescriptor(poDesignableDescriptor);
	}

	/** Permet d'afficher un message. Retourne toujours false.
	 * @param psMessage Message à afficher dans l'Alert.
	 */
	public showAlertInformation(psMessage: string): Observable<boolean> {
		return this.createAlert(undefined, psMessage, undefined, [{ text: "OK" }]).pipe(mapTo(false));
	}

	//#endregion

	//#endregion
}