import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { secure } from '../../../utils/rxjs/operators/secure';

@Component({
	selector: 'calao-tabs-segment',
	templateUrl: './tabs-segment.component.html',
	styleUrls: ['./tabs-segment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsSegmentComponent extends ComponentBase {

	//#region PROPERTIES

	/** Événement lors du clic sur un segment. */
	@Output("onSegmentClicked") public readonly segmentClickedEvent = new EventEmitter<void>();

	/** Doc propriété. */
	@Input() public index?: number;
	@ObserveProperty<TabsSegmentComponent>({ sourcePropertyKey: "index" })
	public readonly observableIndex = new ObservableProperty<number>(0);

	public readonly value$: Observable<string> = this.observableIndex.value$.pipe(
		map((pnIndex: number) => `${pnIndex}`),
		secure(this)
	);

	//#endregion

	//#region METHODS

	public onClick(): void {
		this.segmentClickedEvent.emit();
	}

	//#endregion

}
