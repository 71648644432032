import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { BadgeModule } from '../badge/badge.module';
import { ConflictsModule } from '../conflicts/conflicts.module';
import { FilterModule } from '../filter/filter.module';
import { GroupsModule } from '../groups/groups.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { ContactSelectorItemComponent } from './components/contact-selector-item/contact-selector-item.component';
import { ContactsFilterBarComponent } from './components/contacts-filter-bar/contacts-filter-bar.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';

const components: Type<any>[] = [
	ContactItemComponent,
	ContactSelectorItemComponent,
	ContactsListComponent,
	ContactsFilterBarComponent
];

@NgModule({
	declarations: components,
	imports: [
		IonicModule,
		CommonModule,
		CalaoAvatarModule,
		ConflictsModule,
		BadgeModule,
		VirtualScrollModule,
		FilterModule,
		GroupsModule
	],
	exports: components
})
export class ContactsModule { }