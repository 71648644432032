import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '../date/date.module';
import { LinkComponent } from './link.component';

/** Module regroupant les outils des links. */
@NgModule({
	imports: [CommonModule, IonicModule, DateModule],
	exports: [LinkComponent],
	declarations: [LinkComponent]
})
export class LinkModule { };