<div class="content-wrapper">
	<div class="content">
		<calao-folder-list [currentPath]="observableCurrentPath.value$ | async"
			[rootPath]="observableRootPath.value$ | async" [displayMode]="observableDisplayMode.value$ | async"
			(onPathChanged)="onPathChanged($event)" (onOptionClicked)="onOptionClickedAsync($event)"
			(onOpenClicked)="openDocumentAsync($event)"></calao-folder-list>
	</div>
</div>

<ion-fab *ngIf="canDisplayFabButton$ | async" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button size="large" color="primary" (click)="onAddDocumentClickedAsync()">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
</ion-fab>