import { Exclude } from "class-transformer";
import { IPicture } from "./IPicture";

export class Picture implements IPicture {
	/** @implements */
	public mimeType: string;
	/** @implements */
	public size: number;
	/** @implements */
	public alt?: string;
	/** @implements */
	public base64?: string;
	/** @implements */
	public url?: string;
	/** @implements */
	public guid?: string;

	@Exclude({ toPlainOnly: true })
	file?: Blob;

	constructor(poPicture: IPicture) {
		this.mimeType = poPicture.mimeType;
		this.size = poPicture.size;
		if (poPicture.alt) this.alt = poPicture.alt;
		if (poPicture.base64) this.base64 = poPicture.base64;
		if (poPicture.url) this.url = poPicture.url;
		if (poPicture.guid) this.guid = poPicture.guid;
		if (poPicture.file) this.file = poPicture.file;
	}
}