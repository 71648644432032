import { ObjectHelper } from '../../../helpers/objectHelper';
import { ConfigData } from '../../config/ConfigData';

export class DeviceNotAuthorizedError extends Error {

	constructor(psErrorMessage: string = `L'appareil n'est pas autorisé. Contactez le support ${ConfigData.appInfo.supportEmail}`) {
		super(psErrorMessage);
		// Nécessaire pour que le 'instanceof DeviceNotAuthorizedError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DeviceNotAuthorizedError);
	}

}