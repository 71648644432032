import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { NumberHelper } from '../../../helpers/numberHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { OsappError } from '../../errors/model/OsappError';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { IDumpProgressEvent } from './IDumpProgressEvent';

export class DumpReader {

	//#region FIELDS

	private static readonly C_DEFAULT_CHUNK_SIZE_O = 524288; // 500ko
	private static readonly C_LOG_ID = "DUMP.O::";

	private readonly moDocumentSubject = new Subject<string[]>();
	private mnStart = 0;
	/** Données incomplètes après lecture (ligne coupée) à reporter sur la lecture suivante. */
	private maFragments: Uint8Array | undefined;
	private moReader: FileReader = new FileReader()["__zone_symbol__originalInstance"];
	private moBlob: Blob;
	private moTextDecoder = new TextDecoder("utf-8");
	private mbClosed: boolean;

	//#endregion

	//#region PROPERTIES

	public get closed(): boolean {
		return this.mbClosed;
	}
	public set closed(pbClosed: boolean) {
		if (pbClosed !== this.mbClosed)
			this.mbClosed = pbClosed;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly msvcFilesystem: FilesystemService,
		private readonly msDumpUrl: string,
		private readonly mnChunkSize: number = DumpReader.C_DEFAULT_CHUNK_SIZE_O
	) {
		if (StringHelper.isBlank(msDumpUrl))
			throw new OsappError("Impossible de lire le dump de la base de données sans le chemin correspondant.");

		if (!NumberHelper.isValidStrictPositive(mnChunkSize))
			throw new OsappError(`${mnChunkSize} n'est pas une valeur valide pour la taille du bloc de lecture.`);

		this.initReader();
	}

	/** Envoie les lectures du dump de base de données par bloc. */
	public onChunkRead(): Observable<IDumpProgressEvent> {
		return this.moDocumentSubject.asObservable().pipe(
			map((paLines: string[]) => {
				return {
					loaded: this.mnStart,
					total: this.moBlob.size,
					data: paLines.map((psLine: string) => {
						try {
							return JSON.parse(psLine.replace(/,$/, "")) as IStoreDocument; // On enlève les virgules en fin de ligne qui séparent les objets.
						}
						catch (poError) {
							console.error(`${DumpReader.C_LOG_ID}Erreur lors de la lecture de la ligne.`, psLine, poError);
							throw poError;
						}
					})
				};
			})
		);
	}

	private initReader(): void {
		// On va lire des lots de 1Mo dans le fichier
		this.moReader.onload = (poEvent: ProgressEvent<FileReader>) => {
			let loResult: Uint8Array;

			// Si fin de fichier
			if ((poEvent.target?.result as ArrayBuffer).byteLength === 0) {
				this.moReader.abort();
				if (this.moReader.onabort)
					this.moReader.onabort({} as unknown as ProgressEvent<FileReader>); // On force pour être sûr de fermer le flux (problème constaté sur TU).
			}
			else {
				if (this.maFragments?.length > 0) { // On fusionne les buffer.
					loResult = new Uint8Array((poEvent.target?.result as ArrayBuffer).byteLength + this.maFragments.byteLength);
					loResult.set(this.maFragments, 0);
					loResult.set(new Uint8Array(poEvent.target?.result as ArrayBuffer), this.maFragments.byteLength);
					this.maFragments = undefined;
				}
				else
					loResult = new Uint8Array(poEvent.target?.result as ArrayBuffer);

				let lsLines: string | undefined;
				for (let lnIndex = loResult.length - 1; lnIndex >= 0; --lnIndex) { // On va découper le buffer pour ne garder que la partie exploitable. Si une ligne est incomplète, on la garde en cache.
					if (loResult[lnIndex] === 10 || lnIndex === 0) { // \n = 10
						this.maFragments = loResult.slice(lnIndex === 0 ? lnIndex : lnIndex + 1);

						if (lnIndex > 0) // Si il y a une partie exploitable on décode.
							lsLines = this.moTextDecoder.decode(loResult.slice(0, lnIndex));

						break;
					}
				}

				const laLines: string[] = lsLines?.split(/\n/g) ?? []; // Alors on split par ligne (1 objet par ligne dans le dump)

				if (ArrayHelper.getFirstElement(laLines)?.startsWith('{"new_edits"')) // On ignore le premier (def des objets)
					laLines.shift();

				if (ArrayHelper.getLastElement(laLines) === "]}") // Et le dernier (fermeture de la def des objets)
					laLines.pop();

				if (ArrayHelper.hasElements(laLines))
					this.moDocumentSubject.next(laLines); // On envoie les lignes
				else
					this.readChunk(); // On force une lecture de ligne pour essayer d'avoir au moins une ligne.
			}
		};

		this.moReader.onabort = () => {
			if (!this.closed) {
				if (this.maFragments?.length > 0)
					console.warn(`${DumpReader.C_LOG_ID}Le fichier ne se termine pas correctement. Longueur non envoyée : ${this.maFragments.byteLength}.`, this.maFragments);

				this.moDocumentSubject.complete();
				this.closed = true;
			}
		};

		this.moReader.onerror = (poEvent: ProgressEvent<FileReader>) => this.moDocumentSubject.error(poEvent.target?.error);
	}

	private getIndexSafe(poBlob: Blob, pnIndex: number): number {
		return pnIndex > poBlob.size ? poBlob.size : pnIndex;
	}

	/** Lis un bloc dans le fichier de dump. Le résultat sera envoyé par le flux du `onLineRead`. */
	public readChunk(): void {
		if (!this.moBlob) {
			this.msvcFilesystem.getFileAsync(this.msDumpUrl)
				.then((poBlob: Blob) => {
					this.moBlob = poBlob;
					this.readChunk();
				})
				.catch((poError: any) => this.moDocumentSubject.error(poError));
		}
		else
			this.moReader.readAsArrayBuffer(this.moBlob.slice(
				this.getIndexSafe(this.moBlob, this.mnStart),
				this.getIndexSafe(this.moBlob, this.mnStart += this.mnChunkSize)
			)); // On utilise un arrayBuffer pour éviter les erreurs de décodage sur IOS.
	}

	//#endregion

}
