<osapp-header *ngIf="!(observableToolbarMode.value$ | async); else toolbarHeader" [title]="title"
	[hasBackButton]="observableHasBackButton.value$ | async" [hasMenuButton]="observableHasMenuButton.value$ | async"
	[hasHomeButton]="observableHasHomeButton.value$ | async" [hasSyncButton]="observableHasSyncButton.value$ | async"
	[hasConversationsButton]="observableHasConversationsButton.value$ | async"
	[hasNotificationsButton]="observableHasNotificationsButton.value$ | async"></osapp-header>

<ng-template #toolbarHeader>
	<calao-sub-header>
		<calao-sub-header-title>{{title}}</calao-sub-header-title>
	</calao-sub-header>
</ng-template>


<div *ngIf="showAddress" lines="none" class="map-header">
	<ion-label>{{ positionLabel.value$ | async }}</ion-label>
</div>

<capacitor-google-map #map></capacitor-google-map>