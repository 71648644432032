<osapp-header-modal title="Type de document"></osapp-header-modal>

<ion-content>
	<div class="selector" *ngIf="observableFoldersOptions.changes$ | async as options">
		<osapp-selector *ngIf="options.length > 0" [options]="options" [displayMode]="selectorDisplayMode.tags"
			[scrollWrapper]="false" (selectionChanged)="onFolderChanged($event)"
			[disabled]="!(observablePath.value$ | async)">
		</osapp-selector>
	</div>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button [disabled]="!(observableSelectedFolder.value$ | async)" size="small" color="primary" class="fab-label"
		(click)="onSubmitAsync()">
		<ion-label>Suivant</ion-label>
	</ion-fab-button>
</ion-fab>