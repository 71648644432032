import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un dashboard. */
@Component({
	templateUrl: './dashboardField.component.html',
	styleUrls: ['./dashboardField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFieldComponent extends FieldBase implements OnInit { //todo : typer + params.

	//#region PROPERTIES

	/** lignes contenant les colonnes de blocs. */
	public content: any;
	/** tableau. */
	public bindingVar: Array<string> = [];

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		const loData: any = this.to.data;

		// Création des variables qui seront bindées avec le template.
		for (const lsKey in loData.key) {
			if (this.model[loData.key[lsKey]] !== undefined && this.model[loData.key[lsKey]] !== null)
				this.bindingVar[lsKey] = this.model[loData.key[lsKey]];
			else
				this.bindingVar[lsKey] = "";

			this.content = loData.content;
		}
	}

	//#endregion

}