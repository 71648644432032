import { ConvertHelper } from "../../../helpers/convertHelper";
import { NumberHelper } from "../../../helpers/numberHelper";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des données de géolocalisation. */
export abstract class GeolocationHelper {

	//#region METHODS

	private constructor() { }

	/** Calcul la distance entre deux coordonnées (vol d'oiseau) en kilmètres et retourne le résultat, `NaN` si un des paramètres n'est pas bon.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	public static calculateDistanceBetweenCoordinatesKm(pnSourceLatitude: number, pnSourceLongitude: number, pnTargetLatitude: number, pnTargetLongitude: number): number {
		if (NumberHelper.areValid([pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude])) {
			return ConvertHelper.angularDistanceRadianToKilometers(
				this.calculateAngularDistanceRadian(pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude)
			);
		}
		else
			return NaN;
	}

	/** Calcule la distance angulaire en radians depuis des coordonnées en degrés.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	private static calculateAngularDistanceRadian(pnSourceLatitude: number, pnSourceLongitude: number, pnTargetLatitude: number, pnTargetLongitude: number): number {
		return Math.acos(
			(this.getSinRadianFromDegree(pnSourceLatitude) * this.getSinRadianFromDegree(pnTargetLatitude)) +
			(this.getCosRadianFromDegree(pnSourceLatitude) * this.getCosRadianFromDegree(pnTargetLatitude) * this.getCosRadianFromDegree(pnTargetLongitude - pnSourceLongitude))
		);
	}

	/** Récupère le sinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getSinRadianFromDegree(pnDegreeValue: number): number {
		return Math.sin(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	/** Récupère le cosinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getCosRadianFromDegree(pnDegreeValue: number): number {
		return Math.cos(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	//#endregion METHODS

}