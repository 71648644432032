import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ISelectorParams } from '../../model/selector/ISelectorParams';
import { ISelectorValue } from '../../model/selector/ISelectorValue';

/** @deprecated */
@Component({
	selector: "osapp-selector",
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Paramètres du composant. */
	@Input() public params: ISelectorParams<any>;
	/** Tableau de la totalité des valeurs disponibles pour le sélecteur. */
	public values: ISelectorValue<any>[];
	/** Valeurs récupérées depuis le composant de recherche. */
	public filteredValues: ISelectorValue<any>[];
	/** Permet de définir le jeu de valeurs utilisées par le composant. */
	@Input("values") public set Values(paValues: ISelectorValue<any>[]) {
		this.filteredValues = paValues ? paValues : [];

		if (!ArrayHelper.hasElements(this.values))
			this.values = this.filteredValues;

		this.selectedValuesChange();
	}
	/** Emet un événement lors de la validation de la sélection. */
	@Output("selectionValidated") public readonly selectionValidatedEventEmitter: EventEmitter<ISelectorValue<any>[]> = new EventEmitter();
	/** Indique si le composant est en mode de sélection unique. */
	public isSingleSelectionMode: boolean;
	/** Indique si l'on peut sélectionner plus de valeurs. */
	public canSelectMore = true;
	/** Indique si l'on peut valider la sélection. */
	public canValidateSelection = false;

	//#endregion

	//#region METHODS

	constructor() { super(); }

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};
		if (this.params.selectionLimit === 1)
			this.isSingleSelectionMode = true;
	}

	/** Récupère les valeurs sélectionnées parmi toutes les valeurs. */
	private getSelectedValues(): ISelectorValue<any>[] {
		return this.filteredValues.filter((poValue: ISelectorValue<any>) => poValue.isSelected);
	}

	/** Fonction appelée lors de la sélection d'une valeur. */
	public onValueSelected(): void {
		this.selectedValuesChange();
	}

	private selectedValuesChange(): void {
		const laSelectedValues: ISelectorValue<any>[] = this.getSelectedValues();
		this.canSelectMore = !this.params.selectionLimit || laSelectedValues.length < this.params.selectionLimit;
		this.canValidateSelection = !this.params.selectionMinimum || laSelectedValues.length > this.params.selectionMinimum;
	}

	/** Valide la sélection des valeurs.
	 * @param poSelectorValue Valeur sélectionnée dans le cas d'une sélection unique.
	 */
	public validateSelection(poSelectorValue?: ISelectorValue<any>): void {
		if (poSelectorValue) {
			poSelectorValue.isSelected = true;
		}
		this.selectionValidatedEventEmitter.emit(poSelectorValue ? [poSelectorValue] : this.getSelectedValues());
	}

	/** Fonction appelée lors de la mise à jours des valeurs à afficher par le composant de recherche.
	 * @param paSelectorValue Liste des nouvelles valeurs à afficher.
	 */
	public onFilteredValuesChanged(paSelectorValue?: ISelectorValue<any>[]): void {
		if (paSelectorValue)
			this.Values = paSelectorValue;
	}

	//#endregion

}