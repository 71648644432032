import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ESuffix } from '../../model/ESuffix';
import { PageInfo } from '../../model/PageInfo';
import { ContactsService } from '../../services/contacts.service';

@Injectable({
	providedIn: 'root'
})
export class ContactEditPageInfoResolver implements Resolve<PageInfo> {

	//#region METHODS

	constructor() { }

	public resolve(poRoute: ActivatedRouteSnapshot): PageInfo {
		const lsContactId: string = poRoute.params.contactId;
		const loEditPageInfo = new PageInfo({
			...poRoute.data.pageInfo,
			title: "Contact",
			componentName: "contact-form",
			params: {
				formDefinitionId: `contact${ESuffix.edit}`,
				formDescriptorId: "formDesc_contacts",
			}
		});

		if (lsContactId === ContactsService.C_CURRENT_USER_ID_FOR_ROUTE) {
			loEditPageInfo.hasHomeButton = false;
			loEditPageInfo.hasBackButton = false;
		}

		return loEditPageInfo;
	}

	//#endregion
}