import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
	selector: 'osapp-field-button',
	template: `
    <div>
      <ion-button [type]="to.type" [ngClass]="'btn btn-' + to.btnType" (click)="onClick($event)">
        {{ to.text }}
      </ion-button>
    </div>
  `,
})
export class FormlyFieldButtonComponent extends FieldType<FormlyFieldConfig> {
	public onClick($event) {
		if (this.to.onClick) {
			this.to.onClick($event);
		}
	}
}