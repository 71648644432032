import { ArrayHelper } from "../../../helpers/arrayHelper";
import { IdHelper } from "../../../helpers/idHelper";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { EPrefix } from "../../../model/EPrefix";
import { StoreDocument } from "../../store/model/store-document";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { EEventOccurrenceDifferentialDataArrayOperation } from "./eevent-occurrence-differential-data-array-operation";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceDifferential } from "./ievent-occurrence-differential";
import { IEventOccurrenceDifferentialData } from "./ievent-occurrence-differential-data";

export class EventOccurrenceDifferential<T extends IEventOccurrence = IEventOccurrence> extends StoreDocument implements IEventOccurrenceDifferential<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "EVTOCCDIFF::";

	//#endregion FIELDS

	//#region PROPERTIES

	@ResolveModel(Date)
	public occurrenceStartDate: Date;
	@ResolveModel(Date)
	public createDate: Date;
	public eventRev: string;
	public previousRev: string;
	public differential: Partial<Record<keyof T, IEventOccurrenceDifferentialData[]>> = {};

	private msEventId?: string;
	public get eventId(): string {
		if (StringHelper.isBlank(this.msEventId))
			this.msEventId = EventOccurrenceDifferential.getEventIdFromId(this._id);

		return this.msEventId;
	}

	private msUserId?: string;
	public get userId(): string {
		if (StringHelper.isBlank(this.msUserId))
			this.msUserId = EventOccurrenceDifferential.getUserIdFromId(this._id);

		return this.msUserId;
	}

	//#endregion

	//#region METHODS

	constructor(poData?: Partial<IEventOccurrenceDifferential<T>>) {
		super();
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	public applyToOccurrence(poOccurrence: IEventOccurrence): void {
		Object.keys(this.differential).forEach((psKey: string) => {
			const laDifferentialData: IEventOccurrenceDifferentialData[] | undefined = this.differential[psKey];

			if (laDifferentialData) {
				let loOccurrenceValue: any = poOccurrence[psKey];

				if (loOccurrenceValue instanceof Array) {
					for (let lnIndex = 0; lnIndex < laDifferentialData.length; ++lnIndex) {
						const loData: IEventOccurrenceDifferentialData = laDifferentialData[lnIndex];

						if (loData.arrayOperation === EEventOccurrenceDifferentialDataArrayOperation.remove)
							ArrayHelper.removeElementByFinder(loOccurrenceValue, (poItem: any) => ObjectHelper.areEqual(poItem, loData.value));
						else if (loData.arrayOperation === EEventOccurrenceDifferentialDataArrayOperation.add)
							loOccurrenceValue.push(loData.value);
					}
				}
				else
					loOccurrenceValue = ArrayHelper.getLastElement(laDifferentialData)?.value;

				try {
					poOccurrence[psKey] = loOccurrenceValue;
				}
				catch (poError: any) {
					console.debug(`${EventOccurrenceDifferential.C_LOG_ID} Le champs ${psKey} n'a pas pu être setté.`, poError);
				}
			}
		});

		poOccurrence.differentialExecuted = true;
	}

	public static getUserIdFromId(psId: string): string {
		return IdHelper.extractIdWithPrefix(psId, EPrefix.user) ?? "";
	}

	public static getEventIdFromId(psId: string): string {
		return IdHelper.extractIdWithPrefix(psId, EPrefix.event) ?? "";

	}

	//#endregion

}
