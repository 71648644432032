import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { IGuidConfig } from './models/iguid-config';
import { LongGuidBuilder } from './models/long-guid-builder';

export const GUID_HELPER_CONFIG = new InjectionToken<IGuidConfig>("DEEPLINK_CONFIG");

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		{ provide: GUID_HELPER_CONFIG, useValue: { builderType: LongGuidBuilder } as IGuidConfig }
	]
})
export class GuidModule {
	public static forRoot(poGuidConfig: IGuidConfig): ModuleWithProviders<GuidModule> {
		return {
			ngModule: GuidModule,
			providers: [
				{ provide: GUID_HELPER_CONFIG, useValue: poGuidConfig }
			]
		};
	}
}