import { Exclude } from "class-transformer";
import { ObjectHelper } from "../../helpers/objectHelper";
import { StoreDocument } from "../../modules/store/model/store-document";
import { IPicture } from "../picture/IPicture";
import { IContact } from "./IContact";
import { IGroup } from "./IGroup";

export class Group extends StoreDocument implements IGroup {

	//#region PROPERTIES

	/** @implements */
	public description?: string;
	/** @implements */
	public name: string;
	/** @implements */
	public isUserGroup?: boolean;
	/** @implements */
	public color?: string;
	/** @implements */
	public picture?: IPicture;
	/** @implements */
	public roles?: string[];

	@Exclude({ toPlainOnly: true })
	public contacts?: IContact[];

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Partial<IGroup>) {
		super();

		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS
}