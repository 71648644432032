import { EApplicationEventType } from "../../../model/application/EApplicationEventType";
import { IApplicationEvent } from "../../../model/application/IApplicationEvent";
import { IDumpApplicationEventData } from "./idump-application-event-data";

export class DumpApplicationEvent implements IApplicationEvent {

	//#region FIELDS

	private readonly mdCreateDate = new Date();

	//#endregion

	//#region PROPERTIES

	public get type(): EApplicationEventType {
		return EApplicationEventType.dumpEvent;
	}

	public get createDate(): Date {
		return this.mdCreateDate;
	}

	public get data(): IDumpApplicationEventData {
		return this.moData;
	}

	//#endregion

	//#region METHODS

	constructor(private readonly moData: IDumpApplicationEventData) {

	}

	//#endregion

}