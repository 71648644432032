import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DateHelper } from '../../../../helpers/dateHelper';
import { SocialNumberHelper } from '../../../../helpers/socialNumberHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ETimetablePattern } from '../../../../model/date/ETimetablePattern';
import { EDisplayControlType } from '../../../../model/forms/EDisplayControlType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ILabelFieldParams } from '../../../../model/forms/ILabelFieldParams';
import { ITemplateOptions } from '../../../../model/forms/fieldComponent/ITemplateOptions';
import { ILabelField } from '../../../../model/forms/fieldComponent/specifications/ILabelField';
import { DateWithLocalePipe } from '../../../../pipes/dateWithLocale.pipe';
import { FormsService } from '../../../../services/forms.service';
import { PatternResolverService } from '../../../../services/pattern-resolver.service';
import { FormComponent } from '../form.component';

/** Champs de formulaire qui affiche un libellé. */
@Component({
	templateUrl: './labelField.component.html',
	styleUrls: ['./labelField.component.scss']
})
//todo : impacter tous les documents couchDb pour utiliser to.data pour les paramètres et compléter/modifier typage ILabelFieldParams.
export class LabelFieldComponent extends FieldBase<string | string[]> implements ILabelField, OnInit {

	//#region PROPERTIES

	/** Classe css à appliquer. */
	public cssClass: string;
	/** Valeur du label à afficher. */
	public displayValue = "";
	/** Indique si le label est un numéro de téléphone ou non. */
	public isPhone = false;
	/** Indique si le label est un courriel ou non. */
	public isMail = false;
	/** Indique si on est dans un mode liste ou non. */
	public isListMode = false;
	/** Indique si le label est une date ou non. */
	public isDate = false;
	/** Indique si le label est une URL */
	public isUrl = false;
	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<never>;
	/** Paramètres du composant. */
	public params: ILabelFieldParams;

	public override get fieldValue(): string | string[] {
		let lsDisplayValue: string;

		if (this.formControl?.value) {
			if (this.formControl.value instanceof Array || (this.to.mode && this.to.mode === "list"))
				this.isListMode = true;
			else
				lsDisplayValue = this.getDisplayValueFromType();
		}

		else {
			if (this.to.type === EDisplayControlType.date && this.to.data.autoGenerate) {
				this.fieldValue = new Date().toJSON();
				this.isDate = true;
				lsDisplayValue = this.ioDatePipe.transform(this.formControl.value, DateHelper.C_LOCAL_FR, ETimetablePattern.EEE_dd_MMMM_yyyy);
			}
			else if (this.to.data?.value)
				lsDisplayValue = this.fieldValue = this.isvcPatternResolver.replaceDynParams(this.to.data.value, this.model, this.ioParentForm.parentEntity);
			else
				lsDisplayValue = "";
		}

		if (!StringHelper.isBlank(this.displayValue))
			lsDisplayValue = this.displayValue.trim();

		return lsDisplayValue;
	}
	public override set fieldValue(poValue: string | string[]) { this.formControl?.patchValue(poValue); }

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioDatePipe: DateWithLocalePipe,
		private readonly ioParentForm: FormComponent,
		private readonly isvcPatternResolver: PatternResolverService,
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;
		this.cssClass = this.to.icon ? "maxioverFlow" : this.to.className ? this.to.className : "";

		this.updateValuesAfterSubmitEvent();
	}

	/** Affecte la valeur à afficher en fonction du type de label. */
	private getDisplayValueFromType(): string {

		switch (this.to.type) {

			case EDisplayControlType.tel:
				this.isPhone = true;
				return (this.formControl.value as string).replace("+33", '0').replace(/([0-9]{2})/g, "$1 ");

			case EDisplayControlType.email:
				this.isMail = true;
				return this.formControl.value as string;

			case EDisplayControlType.date:
				this.isDate = true;
				return this.ioDatePipe.transform(this.formControl.value as string, DateHelper.C_LOCAL_FR, ETimetablePattern.dd_MM_yyyy_slash);

			case EDisplayControlType.birthdate:
				const lnYears: number = DateHelper.diffYear(new Date(), new Date(this.formControl.value as string));
				return `${lnYears} ${lnYears > 1 ? "ans" : "an"}`;

			case EDisplayControlType.socialNumber:
				return SocialNumberHelper.format(this.formControl.value);

			case EDisplayControlType.url:
				this.isUrl = true;
				let lsUrl: string = this.formControl.value;
				// On vérifie si l'URL a déjà été préfixée lors de la saisie du formulaire
				if (!lsUrl.startsWith("https://") && !lsUrl.startsWith("http://")) {
					lsUrl = "http://" + lsUrl;
				}
				return lsUrl;

			default:
				return this.formControl.value as string;
		}
	}

	//#endregion
}