import { ListRange } from "@angular/cdk/collections";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ItemSizeCalculator } from "./item-size-calculator";

type Range = [number, number];

const intersects = (poRangeA: Range, poRangeB: Range): boolean => (
	(poRangeA[0] <= poRangeB[0] && poRangeB[0] <= poRangeA[1]) ||
	(poRangeA[0] <= poRangeB[1] && poRangeB[1] <= poRangeA[1]) ||
	(poRangeB[0] < poRangeA[0] && poRangeA[1] < poRangeB[1])
);
const clamp = (pnMin: number, pnValue: number, pnMax: number): number => Math.min(Math.max(pnMin, pnValue), pnMax);

export class VariableSizeVirtualScrollStrategy {

	//#region FIELDS

	private moViewport?: CdkVirtualScrollViewport;
	private moScrolledIndexChange$ = new Subject<number>();

	//#endregion FIELDS

	//#region PROPERTIES

	public scrolledIndexChange: Observable<number> = this.moScrolledIndexChange$.pipe(distinctUntilChanged());
	public _minBufferPx = 100;
	public _maxBufferPx = 100;

	//#endregion

	//#region METHODS

	constructor(private paItems: any[], private poItemSizeCalculator: ItemSizeCalculator<any>) { }

	public attach(poViewport: CdkVirtualScrollViewport): void {
		this.moViewport = poViewport;
		this.updateTotalContentSize();
		this.updateRenderedRange();
	}

	public detach(): void {
		this.moScrolledIndexChange$.complete();
		delete this.moViewport;
	}

	public updateItemHeights(paItems: any[], poItemSizeCalculator: ItemSizeCalculator<any>): void {
		this.paItems = paItems;
		this.poItemSizeCalculator = poItemSizeCalculator;
		this.updateTotalContentSize();
		this.updateRenderedRange();
	}

	private getItemOffset(pnIndex: number): number {
		return this.paItems.slice(0, pnIndex).reduce((pnOffset: number, poItem: any) => pnOffset + this.poItemSizeCalculator.getItemHeight(poItem), 0);
	}

	private getTotalContentSize(): number {
		return this.paItems.reduce((pnSize: number, poItem: any) => pnSize + this.poItemSizeCalculator.getItemHeight(poItem), 0);
	}

	private getListRangeAt(pnScrollOffset: number, pnViewportSize: number): ListRange {
		const loVisibleOffsetRange: Range = [pnScrollOffset, pnScrollOffset + pnViewportSize];
		const laItemsInRange: number[] = this.paItems.reduce<{ itemIndexesInRange: number[], currentOffset: number }>((poAcc, poItem, pnIndex) => {
			const loItemOffsetRange: Range = [poAcc.currentOffset, poAcc.currentOffset + this.poItemSizeCalculator.getItemHeight(poItem)];

			return {
				currentOffset: poAcc.currentOffset + this.poItemSizeCalculator.getItemHeight(poItem),
				itemIndexesInRange: intersects(loItemOffsetRange, loVisibleOffsetRange) ?
					[...poAcc.itemIndexesInRange, pnIndex] :
					poAcc.itemIndexesInRange
			};
		}, { itemIndexesInRange: [], currentOffset: 0 }).itemIndexesInRange;

		const BUFFER_BEFORE = 5;
		const BUFFER_AFTER = 5;

		return {
			start: clamp(0, (laItemsInRange[0] ?? 0) - BUFFER_BEFORE, this.paItems.length - 1),
			end: clamp(0, (laItemsInRange[laItemsInRange.length - 1] ?? 0) + BUFFER_AFTER, this.paItems.length)
		};
	}

	private updateRenderedRange(): void {
		if (!this.moViewport)
			return;

		const pnViewportSize: number = this.moViewport.getViewportSize();
		const pnScrollOffset: number = this.moViewport.measureScrollOffset();
		const loNewRange: ListRange = this.getListRangeAt(pnScrollOffset, pnViewportSize);
		const loOldRange: ListRange | undefined = this.moViewport?.getRenderedRange();

		if (loNewRange === loOldRange)
			return;

		this.moViewport.setRenderedRange(loNewRange);
		this.moViewport.setRenderedContentOffset(this.getItemOffset(loNewRange.start));
		this.moScrolledIndexChange$.next(loNewRange.start);
	}

	private updateTotalContentSize(): void {
		const lnContentSize: number = this.getTotalContentSize();
		this.moViewport?.setTotalContentSize(lnContentSize);
	}

	public onContentScrolled(): void {
		this.updateRenderedRange();
	}

	public onDataLengthChanged(): void {
		this.updateTotalContentSize();
		this.updateRenderedRange();
	}

	public onContentRendered(): void { }

	public onRenderedOffsetChanged(): void { }

	public scrollToIndex(index: number, behavior: ScrollBehavior): void {
		this.moViewport?.scrollToOffset(this.getItemOffset(index), behavior);
	}

	//#endregion

}
