import { ObjectHelper } from "../../../helpers/objectHelper";
import { StoreDocument } from "../../store/model/store-document";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { IEventState } from "./ievent-state";

export class EventState extends StoreDocument implements IEventState {

	//#region PROPERTIES

	public userId: string;
	public status?: string;

	@ResolveModel(Date)
	public lastUpdate: Date;

	@ResolveModel(Date)
	public lastEndedDate?: Date;

	//#endregion

	//#region METHODS

	constructor(poEventState?: IEventState) {
		super();
		if (poEventState) {
			ObjectHelper.assign(this, poEventState);
		}
	}
}

//#endregion