import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defer, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ESortOrder } from '../../../../model/ESortOrder';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IPicture } from '../../../../model/picture/IPicture';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { IFormDefinition } from '../../../forms/models/IFormDefinition';
import { IFormListParams } from '../../../forms/models/IFormListParams';
import { IListDefinition } from '../../../forms/models/IListDefinition';
import { IListDefinitionsField } from '../../../forms/models/IListDefinitionsField';
import { IFormListDetailEvent } from '../../../forms/models/iform-list-detail-event';
import { FormsService } from '../../../forms/services/forms.service';
import { IColumnListSortEvent } from '../../../lists/models/icolumn-list-sort-event';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { EntitiesService } from '../../services/entities.service';

@Component({
	selector: 'calao-entity-entries-list',
	templateUrl: './entity-entries-list.component.html',
	styleUrls: ['./entity-entries-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListComponent<T extends IStoreDocument> extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors du clic sur le détail d'un item. */
	@Output("onDetailClicked") private readonly moDetailClickedEvent = new EventEmitter<IFormListDetailEvent<T>>();

	/** Événement lors du clic sur un item. */
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres du formList. */
	@Input() public params?: IFormListParams<T>;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IFormListParams<T>>();

	public readonly observableEntries = new ObservableArray<T>([]);

	public readonly observableFormDefinition = new ObservableProperty<IFormDefinition | undefined>();

	public readonly observableDisplayFields = new ObservableArray<IListDefinitionsField<T>>([]);

	/** Clé de tri. */
	@Input() public sortKey?: keyof T;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "sortKey" })
	public readonly observableSortKey = new ObservableProperty<keyof T>();

	/** Indique pour chaque item si on doit afficher le bouton de détail. */
	@Input() public hasDetailById?: Map<string, boolean>;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "hasDetailById" })
	public readonly observableHasDetailById = new ObservableProperty<Map<string, boolean>>();

	/** Indique de quelle manière on veut trier les éléments. */
	protected meSortOrder: ESortOrder;

	/** Indique si le tri est inversé. */
	public isReverseSorted = false;

	/** Représente l'énumération sous forme d'objet. */
	public avatarSize: typeof EAvatarSize = EAvatarSize;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcForms: FormsService<T>,
		private readonly isvcEntities: EntitiesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.observableParams.value$.pipe(
			switchMap((poParams?: IFormListParams<T>) =>
				defer(() =>
					poParams?.entityDescriptor ?
						of(poParams?.entityDescriptor) :
						this.isvcEntities.getDescriptor$(poParams?.entityDescId)
				).pipe(
					tap((poDesc: IEntityDescriptor) => this.initParams(poDesc, poParams)),
					mergeMap((poDesc: IEntityDescriptor) =>
						ArrayHelper.hasElements(this.observableEntries) ?
							of(this.observableEntries) :
							this.isvcForms.getEntries$(poParams.dataSource ?? this.isvcEntities.getDataSource(poDesc, poParams.dataSourceId)).pipe(
								tap((paDocs: T[]) => this.observableEntries.resetArray(paDocs))
							)
					),
					tap(() => this.sortDocuments())
				)
			),
			secure(this)
		).subscribe();
	}

	private initParams(poDesc: IEntityDescriptor, poParams?: IFormListParams<T>): void {
		// Récupération de la définition du formulaire.
		const loFormDefinition: IListDefinition = poParams?.listDefinition ??
			this.isvcEntities.getListDefinition(poDesc, poParams?.listId);
		this.observableFormDefinition.value = loFormDefinition;

		this.isReverseSorted = false;
		this.meSortOrder = loFormDefinition.sortOrder || ESortOrder.ascending;

		const laDisplayFields: IListDefinitionsField<T>[] = [];
		for (let lnIndex = 0; lnIndex < loFormDefinition.fields.length; ++lnIndex) {
			if (!loFormDefinition.fields[lnIndex].hidden)
				laDisplayFields.push(loFormDefinition.fields[lnIndex]);
		}
		this.observableDisplayFields.resetArray(laDisplayFields);

		if (ArrayHelper.hasElements(poParams?.customEntries))
			this.observableEntries.resetArray(poParams.customEntries);

		this.sortKey = (loFormDefinition.sortBy ?
			loFormDefinition.sortBy :
			laDisplayFields.find((poItem: IListDefinitionsField<T>) => poItem && poItem.key !== "picture").key) as keyof T;
	}

	/** Trie le tableau des documents en fonction d'une clé. */
	protected sortDocuments(): void {
		this.observableEntries.resetArray(ArrayHelper.dynamicSort(this.observableEntries, this.observableSortKey.value, this.meSortOrder));
	}

	/** Ordonne les entrées avec la clé en paramètre. Si la clé est déjà la sortKey, inverse la liste.
	 * @param poEvent Clé de tri des données.
	 */
	public orderOrReverse(poEvent: IColumnListSortEvent<T>): void {
		if (poEvent.key) {
			this.sortKey = poEvent.key;
			this.meSortOrder = poEvent.order;
			this.sortDocuments();
		}
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poData Données contenant les informations de l'avatar à récupérer.
	 */
	public getAvatar(poData: IPicture): IAvatar {
		return {
			text: "",
			size: EAvatarSize.big,
			data: StringHelper.isBlank(poData.url) ? poData.base64 : poData.url,
			mimeType: poData.mimeType,
			guid: poData.guid
		};
	}

	public onDetailClicked(poItem: T, poEvent: MouseEvent): void {
		this.moDetailClickedEvent.emit({ event: poEvent, item: poItem });
	}

	public onItemClicked(poItem: T,): void {
		this.moItemClickedEvent.emit(poItem);
	}

	public hasDetail(poItem: T, poHasDetailById?: Map<string, boolean>): boolean {
		return !poHasDetailById || !!poHasDetailById.get(poItem._id);
	}

	//#endregion

}
