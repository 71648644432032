import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateHelper } from '../../../../../helpers/dateHelper';
import { SocialNumberHelper } from '../../../../../helpers/socialNumberHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { ETimetablePattern } from '../../../../../model/date/ETimetablePattern';
import { DateWithLocalePipe } from '../../../../../pipes/dateWithLocale.pipe';
import { PatternResolverService } from '../../../../../services/pattern-resolver.service';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { EDisplayControlType } from '../../../models/EDisplayControlType';
import { FieldBase } from '../../../models/FieldBase';
import { ILabelFieldParams } from '../../../models/ILabelFieldParams';
import { ITemplateOptions } from '../../../models/fieldComponent/ITemplateOptions';
import { ILabelField } from '../../../models/fieldComponent/specifications/ILabelField';
import { FormsService } from '../../../services/forms.service';

/** Champs de formulaire qui affiche un libellé. */
@Component({
	templateUrl: './labelField.component.html',
	styleUrls: ['./labelField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
//todo : impacter tous les documents couchDb pour utiliser to.data pour les paramètres et compléter/modifier typage ILabelFieldParams.
export class LabelFieldComponent extends FieldBase<string | string[]> implements ILabelField, OnInit {

	//#region PROPERTIES

	/** Classe css à appliquer. */
	public cssClass: string;
	/** Valeur du label à afficher. */
	public displayValue = "";
	/** Indique si le label est un numéro de téléphone ou non. */
	public observableIsPhone = new ObservableProperty<boolean>();
	/** Indique si le label est un courriel ou non. */
	public observableIsMail = new ObservableProperty<boolean>();
	/** Indique si on est dans un mode liste ou non. */
	public observableIsListMode = new ObservableProperty<boolean>();
	/** Indique si le label est une date ou non. */
	public observableIsDate = new ObservableProperty<boolean>();
	/** Indique si le label est une URL */
	public observableIsUrl = new ObservableProperty<boolean>();
	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<never>;
	/** Paramètres du composant. */
	public params: ILabelFieldParams;

	public fieldValue$: Observable<string> = this.observableFieldValue.value$.pipe(
		map((poFieldValue?: string | string[]) => {
			let lsDisplayValue: string;

			if (poFieldValue) {
				if (poFieldValue instanceof Array || (this.to.mode && this.to.mode === "list"))
					this.observableIsListMode.value = true;
				else
					lsDisplayValue = this.getDisplayValueFromType(poFieldValue);
			}
			else {
				if (this.observableIsDate.value)
					lsDisplayValue = this.ioDatePipe.transform(new Date().toJSON(), DateHelper.C_LOCAL_FR, ETimetablePattern.EEE_dd_MMMM_yyyy);
				else if (this.to.data?.value)
					lsDisplayValue = this.isvcPatternResolver.replaceDynParams(this.to.data.value, this.model);
				else
					lsDisplayValue = "";
			}

			if (!StringHelper.isBlank(this.displayValue))
				lsDisplayValue = this.displayValue.trim();

			return StringHelper.isBlank(lsDisplayValue) && this.params?.forceDisplaying ? " " : lsDisplayValue;
		}),
		secure(this)
	);

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioDatePipe: DateWithLocalePipe,
		private readonly isvcPatternResolver: PatternResolverService,
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableIsDate.value = this.to.type === EDisplayControlType.date && this.to.data.autoGenerate;
		this.params = this.to.data;

		if (this.params.value)
			this.fieldValue = this.params.value;
		else {
			if (this.params.forceDisplaying)
				this.fieldValue = "";
		}

		this.cssClass = this.to.icon ? "maxioverFlow" : this.to.className ? this.to.className : "";
		this.detectChanges();
	}

	/** Affecte la valeur à afficher en fonction du type de label. */
	private getDisplayValueFromType(psValue: string): string {
		switch (this.to.type) {

			case EDisplayControlType.tel:
				this.observableIsPhone.value = true;
				return psValue.replace("+33", '0').replace(/([0-9]{2})/g, "$1 ");

			case EDisplayControlType.email:
				this.observableIsMail.value = true;
				return psValue;

			case EDisplayControlType.date:
				this.observableIsDate.value = true;
				return this.ioDatePipe.transform(psValue, DateHelper.C_LOCAL_FR, ETimetablePattern.dd_MM_yyyy_slash);

			case EDisplayControlType.birthdate:
				const lnYears: number = DateHelper.diffYear(new Date(), new Date(psValue));
				return `${lnYears} ${lnYears > 1 ? "ans" : "an"}`;

			case EDisplayControlType.socialNumber:
				return SocialNumberHelper.format(this.formControl.value);

			case EDisplayControlType.url:
				this.observableIsUrl.value = true;
				let lsUrl: string = this.formControl.value;
				// On vérifie si l'URL a déjà été préfixée lors de la saisie du formulaire
				if (!lsUrl.startsWith("https://") && !lsUrl.startsWith("http://"))
					lsUrl = `http://${lsUrl}`;
				return lsUrl;

			default:
				return `${this.formControl.value}`;
		}
	}

	public isBlank(psValue: string): boolean {
		return StringHelper.isBlank(psValue);
	}

	//#endregion
}