import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { EnumHelper } from '../../../../../../helpers/enumHelper';
import { EInput } from '../../../../../../model/uiMessage/EInput';
import { EAction } from '../../../model/EAction';
import { ENameConsole } from '../../../model/ENameConsole';
import { IDesignableComponent } from '../../../model/IDesignableComponent';
import { IDesignableDefinition } from '../../../model/IDesignableDefinition';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

/** Permet de modifier les paramètres d'un champ sélectionné.
 * La visualisation des changements n'est accessible que dans le mode `Preview`, à l'exeption du `key` qui sera affiché dans le `FormEditor`.
 */
@Component({
	selector: "widget-setting",
	templateUrl: './widget-setting.component.html',
	styleUrls: ['./widget-setting.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSettingComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public readonly otherTypeof = "other";

	public designableDescriptor: IDesignableDescriptor;
	public designableDefinition: IDesignableDefinition;
	public keysOfField: string[];
	public keysOfTOField: string[];
	public keysOfDataOfTOField: string[];
	public actionEnum = EAction;
	public inputTypeEnum: string[] = EnumHelper.getValues(EInput);

	//#endregion

	//#region METHODS

	constructor(private isvcDesign: DesignService, poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poDescriptor: IDesignableDescriptor) => {
					this.designableDescriptor = poDescriptor;

					if (this.designableDescriptor.designableDefinitions)
						this.designableDefinition = this.designableDescriptor.designableDefinitions.find(
							(poDefinition: IDesignableDefinition) => poDefinition.id === this.designableDescriptor.selectedDefinitionId);

					if (this.designableDescriptor.selectedField)
						this.prepareAllKeysForEdition();

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet de valider ou non les modifications d'un champ par rapport au designingField.
	 * @param poEntries Entrées du formulaire d'édition du designingField.
	 * @param pbValidate Booléen validant ou annulant l'édition.
	 */
	public onFinalizeEdition(poEntries: NgForm, pbValidate: boolean): void {
		this.isvcDesign.editSelectedField(this.designableDescriptor, poEntries, pbValidate);
	}

	/** Retourne le type d'input à partir du type d'une clé.
	 * @param poKey Clé du champ à tester.
	 */
	public getInputTypeFromKey(poKey: any): string {
		switch (typeof poKey) {
			case "number":
				return "number";
			case "boolean":
				return "checkbox";
			case "string":
				return "text";
			default:
				return this.otherTypeof;
		}
	}

	/** Permet de modifier les options d'un champ.
	 * @param peAction Type d'action à effectuer.
	 * @param pnIndex Index de l'option sélectionnée.
	 */
	public onModifyOptionsToSelectedField(peAction: EAction, pnIndex?: number): void {
		switch (peAction) {
			case EAction.add:
				this.isvcDesign.addOptionToSelectedField(this.designableDescriptor);
				break;

			case EAction.delete:
				this.isvcDesign.deleteOptionToSelectedField(this.designableDescriptor, pnIndex);
				break;

			default:
				console.debug(`${ENameConsole.widgetSetting}Unknown action.`);
				break;
		}
	}

	/** Permet de lister tous les paramètres du champ sélectionné afin de les afficher. */
	private prepareAllKeysForEdition(): void {
		const loSelectedField: IDesignableComponent = this.designableDescriptor.selectedField;
		const laFilterParams: string[] = ["options", "data", "multiple"];

		// Récupération des clés du champ qui ne sont pas `templateOptions`, `type` et `fakeValue`.
		this.keysOfField = Object.keys(loSelectedField)
			.filter((psKey: string) => psKey !== "templateOptions" && psKey !== "type" && psKey !== "fakeValue" && psKey !== "isSelectForListDefinition");

		// Récupération des clés de `field.templateOptions`.
		if (loSelectedField.templateOptions.type && loSelectedField.templateOptions.type !== EInput.number)
			laFilterParams.push("max");

		this.keysOfTOField = Object.keys(loSelectedField.templateOptions)
			.filter((psKey: string) => laFilterParams.every((psParam: string) => psKey !== psParam));

		// Récupération des clés de `field.templateOptions.data`.
		if (loSelectedField.templateOptions.data)
			this.keysOfDataOfTOField = Object.keys(loSelectedField.templateOptions.data)
				.filter((psKey: string) => psKey !== "labelFieldName" && psKey !== "labelName");
	}

	//#endregion

}