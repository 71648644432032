<ion-range [debounce]="C_DEBOUNCE_TIME_MS" [min]="sliderData.min ? sliderData.min : 0"
	[max]="sliderData.max ? sliderData.max : 50" [step]="sliderData.step" [pin]="sliderData.pin ? sliderData.pin : true"
	(ionChange)="onSlideChanged($event)" [(ngModel)]="model">
	<ion-label slot="start">{{sliderData.minValue ? sliderData.minValue : null}}</ion-label>
	<ion-label slot="end">{{sliderData.maxValue ? sliderData.maxValue : null}}</ion-label>
</ion-range>

<div class="center">
	<ion-button (click)="decrement()">
		-
	</ion-button>

	<ion-item>
		<ion-select [ngModel]="model" (ionChange)="onSelectChanged($event)" name="number">
			<ion-select-option *ngFor="let value of selectModelValues" [value]="value">{{value}}</ion-select-option>
		</ion-select>
	</ion-item>

	<ion-button (click)="increment()">
		+
	</ion-button>
</div>