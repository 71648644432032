<ng-container *ngIf="observableReadonly.value$ | async; else notReadonly">
	<div *ngIf="observableEveryNLabel.value$ | async as everyNLabel">{{everyNLabel}}</div>
	<div *ngIf="observableWeekDaysLabel.value$ | async as weekDaysLabel">{{weekDaysLabel}}</div>
	<div *ngIf="observableDatesLabel.value$ | async as datesLabel">{{datesLabel}}</div>
</ng-container>

<ng-template #notReadonly>
	<div class="title-section">
		<ion-label>Récurrence</ion-label>
		<div class="activation-wrapper">
			<ion-note>Activée</ion-note>
			<ion-toggle [checked]="observableActivation.value$ | async" (ionChange)="onActivationChanged($event)" mode="ios">
			</ion-toggle>
		</div>
	</div>

	<ng-container *ngIf="observableActivation.value$ | async">
		<div class="repeat bloc-area">
			<div class="every-n-wrapper params-line">
				<ion-note>Toutes/Tous les</ion-note>
				<div class="params-repeat">
					<ion-input #everyNInput [value]="observableEveryN.value$ | async" [min]="1" type="number"
						(ionChange)="onEveryChanged($event, everyNInput)"></ion-input>
					<osapp-selector [preselectedValues]="observableRecurrenceType.value$ | async"
						[options]="recurrenceTypeSelectOptions" [displayMode]="selectorDisplayMode.popover" [min]="1"
						(selectionChanged)="onRecurrenceTypeChanged($event)"></osapp-selector>
				</div>
			</div>

			<div class="week-days-wrapper" *ngIf="displayWeekDaySelector$ | async">
				<ion-note>Chaque</ion-note>
				<osapp-selector [preselectedValues]="observableWeekDays.value$ | async" [options]="weekDaysSelectOptions"
					[displayMode]="selectorDisplayMode.tags" multiple (selectionChanged)="onWeekDaysChanged($event)"
					[min]="1"></osapp-selector>
			</div>
		</div>

		<div class="periodicity">
			<div class="title-section">
				<ion-label class="title-label">Plage de périodicité</ion-label>
			</div>
			<div class="date-field start-date ">
				<ion-note>Date de début</ion-note>
				<osapp-date-time [params]="datePickerParams" [model]="observableStartDate.value$ | async" disabled>
				</osapp-date-time>
			</div>
			<ion-radio-group #group [value]="observableEndDateMode.value$ | async" (ionChange)="onEndModeChanged($event)">
				<div class="date-field">
					<ion-note>Date de fin</ion-note>
					<ion-item lines="none">
						<ion-radio [value]="endDateModes.end" slot="start"></ion-radio>
						<osapp-date-time [params]="observableEndTimePickerParams.value$ | async"
							[disabled]="group.value !== endDateModes.end" [model]="observableEndDate.value$ | async"
							(modelChange)="onEndDateChanged($event)"></osapp-date-time>
					</ion-item>
				</div>
				<div>
					<ion-item class="no-date" lines="none">
						<ion-radio [value]="endDateModes.noEnd" slot="start"></ion-radio>
						<ion-label>Pas de date de fin</ion-label>
					</ion-item>
				</div>
			</ion-radio-group>
		</div>
	</ng-container>
</ng-template>