import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, ImageOptions, Photo } from '@capacitor/camera';
import { Observable, defer, from, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { EXTENSIONS_AND_MIME_TYPES, FileHelper } from '../../../helpers/fileHelper';
import { FilesystemService } from '../../filesystem/services/filesystem.service';

@Injectable()
export class CameraService {

	//#region FIELDS
	/** Chaîne de caratères "User cancelled photos app" présente lorsqu'on annule la sélection/prise d'une photo du plugin camera. */
	private static readonly C_CANCEL_ERROR: string = "User cancelled photos app";

	//#endregion

	//#region PROPERTIES

	/** Taille maximale d'une image en Kb par défaut : 7168 (7Mb). */
	public static readonly C_MAX_PICTURE_SIZE_KB: number = 7168;
	/** Résolution d'une image en pixel par défaut : 1000. */
	public static readonly C_DEFAULT_RESOLUTION_PX: number = 1000;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcFilesystem: FilesystemService) { }

	/** Modifie les valeurs des options du plugin camera avec celles passées en paramètres et/ou celles par défaut et renvoie les nouvelles options.
	 * @param poOptions Options pour le plugin camera, optionnel.
	 * @param psSourceType Nombre indiquant le type de source à utiliser par le plugin camera (prendre ou récupérer une photo), optionnel ('CAMERA' par défaut).
	 */
	public getAndSetCameraOptions(poOptions: ImageOptions, psSourceType: CameraSource = CameraSource.Camera): ImageOptions {
		if (psSourceType !== CameraSource.Photos && psSourceType !== CameraSource.Prompt)
			psSourceType = CameraSource.Camera;

		return poOptions = {
			quality: poOptions?.quality ? poOptions.quality : 100,
			resultType: CameraResultType.Uri,
			height: poOptions?.height ? poOptions.height : CameraService.C_DEFAULT_RESOLUTION_PX,
			width: poOptions?.width ? poOptions.width : CameraService.C_DEFAULT_RESOLUTION_PX,
			correctOrientation: true,
			saveToGallery: false,
			source: psSourceType
		};
	}

	/** Sélectionne une photo à l'aide du plugin camera et renvoie le fichier en prenant en compte la taille maximale demandée,
	 * renvoie `undefined` si annulation de la sélection.
	 * @param poOptions Options pour le plugin camera lors de la récupération d'une image.
	 * @param pnMaxSizeKb Taille maximale de la photo en Kb, optionnelle.
	 */
	public getPicture(poOptions: ImageOptions, pnMaxSizeKb: number = CameraService.C_MAX_PICTURE_SIZE_KB): Observable<File> {
		if (poOptions.source !== CameraSource.Camera && poOptions.source !== CameraSource.Prompt)
			poOptions.source = CameraSource.Photos;

		return this.processPicture(poOptions, pnMaxSizeKb);
	}

	/** Prend une photo à l'aide du plugin camera et renvoie le fichier en prenant en compte la taille maximale demandée,
	 * renvoie `undefined` si annulation de la prise.
	 * @param poParams Objet de paramètres pour le plugin camera (options).
	 * @param pnMaxSizeKb Taille maximale de la photo en Kb.
	 */
	public takePicture(poParams: ImageOptions, pnMaxSizeKb: number = CameraService.C_MAX_PICTURE_SIZE_KB): Observable<File> {
		return this.processPicture(poParams, pnMaxSizeKb);
	}

	/** Traite la récupération et la réduction de taille si nécessaire de l'image et la retourne sous forme de fichier.
	 * @param psUrl Url vers l'image prise/sélectionnée.
	 * @param pnMaxSizeKb Taille maximale de l'image.
	 */
	private processPicture(poParams: ImageOptions, pnMaxSizeKb: number): Observable<File> {
		return from(Camera.getPhoto(poParams))
			.pipe(
				// "No Image Selected" ou "has no access to assets" est renseigné dans l'erreur quand aucune image n'a été sélectionnée (annulation),
				// donc ce n'est pas une erreur.
				catchError(poError => (poError instanceof Error && poError.message?.startsWith(CameraService.C_CANCEL_ERROR)) ? of(undefined) : throwError(poError)),
				mergeMap((poPhoto: Photo) => {
					if (!poPhoto)
						return of(undefined);

					let loGetBlob$: Observable<Blob>;
					if (poPhoto.base64String) // Cas d'annulation.
						loGetBlob$ = of(FileHelper.base64toBlob({ base64: poPhoto.base64String }, EXTENSIONS_AND_MIME_TYPES[poPhoto.format]?.mimeType ?? EXTENSIONS_AND_MIME_TYPES.jpg.mimeType));
					else if (poPhoto.path)
						loGetBlob$ = defer(() => this.isvcFilesystem.getFileAsync(poPhoto.path));
					else if (poPhoto.webPath)
						loGetBlob$ = defer(() => this.isvcFilesystem.getFileFromUriAsync(poPhoto.webPath));
					else
						loGetBlob$ = throwError("Base64, path or webPath are unavailable");


					return loGetBlob$
						.pipe(
							mergeMap((poPictureBlob: Blob) => FileHelper.reduceImage(poPictureBlob, pnMaxSizeKb, poPictureBlob.type)),
							catchError((poError: any) => { console.error("CAMERA.S:: Error while processing picture.", poPhoto, poError); return throwError(poError); })
						);
				})
			);
	}

	//#endregion

}