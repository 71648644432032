import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { IDestroyable } from '../../modules/utils/lifecycle/models/IDestroyable';
import { FormsService } from '../../services/forms.service';
import { IStoreDocument } from '../store/IStoreDocument';
import { IFormEvent } from './IFormEvent';

type KeyType = string | number;

@Component({ template: "" })
export abstract class FieldBase<T = any, M extends IStoreDocument = IStoreDocument> extends FieldType<FormlyFieldConfig> implements OnInit, IDestroyable, OnDestroy {

	//#region FIELDS

	private readonly moDestroySubject = new ReplaySubject<void>(1);
	/** Indique si le composant possède une instance pour rafraîchir la vue ou non. */
	private mbHasChangeDetectorRef: boolean;

	//#endregion

	//#region PROPERTIES

	/** Permet de s'abonner à l'event disant que le composant est détruit */
	public get fieldDestroyed$(): Observable<void> {
		return this.destroyed$;
	}

	public get fieldValue(): T | undefined {
		return this.formControl.value;
	}
	public set fieldValue(poValue: T | undefined) {
		this.formControl.patchValue(poValue);
		this.detectChanges();
	}

	/** @implements */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** @implements */
	public get destroyed(): boolean { return this.mbDestroyed; }

	public override get model(): M {
		return super.model;
	}

	public get fieldKey(): KeyType {
		return this.key instanceof Array ? this.key.join(".") : this.key;
	}

	public override get form(): FormGroup {
		return super.form as FormGroup;
	}

	//#endregion

	//#region METHODS

	constructor(protected readonly isvcForms: FormsService, private readonly ioChangeDetectorRef?: ChangeDetectorRef) {
		super();

		this.mbHasChangeDetectorRef = !!this.ioChangeDetectorRef;
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		this.mbDestroyed = true;
		this.moDestroySubject.next();
		this.moDestroySubject.complete();

		if (this.ioChangeDetectorRef)
			this.ioChangeDetectorRef.detach();
	}

	public ngOnInit(): void {
		this.formControl?.valueChanges
			.pipe(
				tap(_ => this.detectChanges()),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();

		if (!this.to.data)
			this.to.data = {};
	}

	/** Rafraîchie la vue pour la mettre à jour. */
	protected detectChanges(): void {
		if (this.mbHasChangeDetectorRef && !(this.ioChangeDetectorRef as ViewRef).destroyed) // Si la vue n'est pas détruite, on peut la mettre à jour.
			this.ioChangeDetectorRef.detectChanges();
	}

	/** Marque le composant de formulaire comment édité, des changements ont eu lieu. */
	protected markAsDirty(): void {
		this.formControl.markAsTouched();
		this.formControl.markAsDirty();
	}

	/** Marque le champ de formulaire comme non édité, aucun changement n'a eu lieu. */
	protected markAsPristine(): void {
		this.formControl.markAsUntouched();
		this.formControl.markAsPristine();
	}

	/** Met à jour les valeurs après que le formulaire ait été enregistré.
	 * @param paKeys Tableau des clés dont il faut mettre à jour les valeurs, `[this.key]` par défaut (clé du champ de formulaire).
	 */
	protected updateValuesAfterSubmitEvent(paKeys: KeyType[] = [this.fieldKey]): void {
		this.isvcForms.waitAfterSubmitEvent(this.model._id)
			.pipe(
				tap((poEvent: IFormEvent) => this.updateValues(poEvent.data.model, paKeys)),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Met à jour les valeurs du modèle.
	 * @param paKeys Tableau des clés dont il faut mettre à jour les valeurs, `[this.key]` par défaut (clé du champ de formulaire).
	 * @param poUpdatedModel Modèle qui possède les valeurs à mettre à jour.
	 */
	protected updateValues(poUpdatedModel: IStoreDocument, paKeys: KeyType[] = [this.fieldKey]): void {
		// Si on a un tableau qui ne contient que la clé du champ de formulaire courant, on ne modifie que son contrôle et pas tout le formulaire.
		if (paKeys.length === 1 && ArrayHelper.getFirstElement(paKeys) === this.key)
			this.fieldValue = poUpdatedModel[this.key];
		else {
			paKeys.forEach((psKey: string) => this.model[psKey] = poUpdatedModel[psKey]);
			this.form.patchValue(this.model);
			this.detectChanges();
		}
	}

	//#endregion

}