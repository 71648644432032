<div [ngStyle]="divStyle$ | async" class="virtual-scroll-div"
	[ngClass]="(displayedItems?.length$ | async) > 0 && !transparent ? 'calao-virtual-scroll-wrapper': ''">
	<ng-container *ngIf="itemSizeCalculator; then variableSizeItems; else fixedSizeItems;"></ng-container>
</div>

<ng-template #fixedSizeItems>
	<cdk-virtual-scroll-viewport #viewportId [itemSize]="itemSize" [style.height]="autosize ? undefined : maxHeight"
		[orientation]="orientation" [minBufferPx]="minBufferPx" [maxBufferPx]="maxBufferPx" [ngClass]="orientation">
		<ion-list [class]="orientation === 'horizontal' ? 'horizontal-list virtual-scroll-list' : 'virtual-scroll-list'">
			<div
				*cdkVirtualFor="let item of displayedItems?.changes$; let last = last; let first = first; let odd = odd; let even = even; let index = index; let count = count; templateCacheSize: observableTemplateCacheSize.value$ | async"
				[style.margin-bottom]="hasToMargeLastItem(last) ? marginBottom  : ''" class="item-content">
				<ng-container
					*ngTemplateOutlet="(observableTemplate.value$ | async) ?? templateRef; context: {$implicit: item, last: last, first: first, odd: odd, even: even, count: count, index: index}">
				</ng-container>
				<ng-container *ngIf="last; then loadMoreButton"></ng-container>
			</div>
			<ng-container *ngIf="(displayedItems?.length$ | async) === 0; then loadMoreButton"></ng-container>
		</ion-list>
	</cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #variableSizeItems>
	<cdk-virtual-scroll-viewport #viewportId calaoVariableSize [items]="displayedItems?.changes$ | async"
		[itemSizeCalculator]="itemSizeCalculator" [style.height]="autosize ? undefined : maxHeight"
		[orientation]="orientation" [ngClass]="orientation">
		<ion-list [class]="orientation === 'horizontal' ? 'horizontal-list virtual-scroll-list' : 'virtual-scroll-list'">
			<div
				*cdkVirtualFor="let item of displayedItems?.changes$; let last = last; let first = first; let odd = odd; let even = even; let index = index; let count = count; templateCacheSize: observableTemplateCacheSize.value$ | async"
				[style.margin-bottom]="hasToMargeLastItem(last) ? marginBottom  : ''" class="item-content">
				<ng-container
					*ngTemplateOutlet="templateRef; context: {$implicit: item, last: last, first: first, odd: odd, even: even, count: count, index: index}">
				</ng-container>
				<ng-container *ngIf="last; then loadMoreButton"></ng-container>
			</div>
			<ng-container *ngIf="(displayedItems?.length$ | async) === 0; then loadMoreButton"></ng-container>
		</ion-list>
	</cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #loadMoreButton>
	<div *ngIf="observableHasLoadMoreButton.value$ | async" class="end-btn">
		<ion-button (click)="onLoadMoreClicked()">{{observableLoadMoreButtonText.value$ | async}}</ion-button>
	</div>
</ng-template>