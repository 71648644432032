import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../../modules/avatar/calao-avatar.module';
import { LinkModule } from '../link/link.module';
import { LinkPopoverComponent } from './linkPopover.component';
import { PopoverComponent } from './popover.component';

/** Module de gestion des popovers de la lib. */
@NgModule({
	imports: [CommonModule, IonicModule, LinkModule, CalaoAvatarModule],
	exports: [PopoverComponent, LinkPopoverComponent],
	declarations: [PopoverComponent, LinkPopoverComponent]
})
export class PopoverModule { }