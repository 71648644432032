import { Observable, of } from 'rxjs';
import { IGroup } from '../contacts/IGroup';
import { IEntityGuard, IGuardResult } from "./IEntityGuard";
import { IEntityLink } from "./IEntityLink";

export class GroupEntityGuard implements IEntityGuard {

	//#region METHODS

	constructor() { }

	/** Un groupe peut être supprimé s'il a été créé par un utilisateur (ce n'est pas un groupe de sécurité ni un groupe built-in). */
	public isDeletable(poGroup: IGroup, paLinks: IEntityLink[]): Observable<IGuardResult> {
		if (poGroup.isUserGroup)
			return of({ result: true, message: undefined });
		else
			return of({ result: false, message: "Seuls les groupes créés par un utilisateur peuvent être supprimés." });
	}

	//#endregion

}