export class ItemSizeCalculator<T> {

	constructor(pfGetItemHeight?: (poItem: T) => number) {
		if (pfGetItemHeight)
			this.getItemHeight = pfGetItemHeight;
	}

	/** Récupère la hauteur de l'item */
	public getItemHeight(poItem: T): number {
		return 0;
	}

}
