<!-- DISPLAY_TYPE 'TAB' -->
<ng-container *ngIf="observableEntries.changes$ | async as documents">
	<calao-column-list *ngIf="observableDisplayFields.changes$| async as displayFields" [items]="documents"
		[sortKey]="observableSortKey.value$ | async" detail>
		<ng-template let-itemFormData="$implicit" let-index="index">
			<calao-column-list-row [index]="index" (click)="onItemClicked(itemFormData)"
				(onDetailClicked)="onDetailClicked(itemFormData, $event)"
				[detail]="hasDetail(itemFormData, observableHasDetailById.value$ | async)">
				<calao-column-list-row-column *ngFor="let col of displayFields" [ngClass]="col.cssClass" [size]="col.size"
					class="colonne">

					<!-- Affichage d'avatar. -->
					<ng-container *ngIf="col.isPicture">
						<avatar *ngIf="!displayFields[0].isArray && itemFormData[displayFields[0].key] && (itemFormData[displayFields[0].key].url ||
										itemFormData[displayFields[0].key].base64 || itemFormData[displayFields[0].key].guid)"
							[src]="getAvatar(itemFormData[displayFields[0].key])">
						</avatar>
						<ion-icon *ngIf="!itemFormData[col.key].base64 && !itemFormData[col.key].url" class="img-thumbnail"
							name="person"></ion-icon>
					</ng-container>

					<!-- Affichage d'une icône. -->
					<div *ngIf="col.isIcon" class="grid-icon">
						<ion-icon *ngIf="itemFormData[col.key] && itemFormData[col.key].icon"
							[name]="itemFormData[col.key].icon"></ion-icon>
						<ion-icon *ngIf="itemFormData[col.key] && !itemFormData[col.key].icon && itemFormData[col.key].url"
							[src]="itemFormData[col.key].url"></ion-icon>
						<ion-icon *ngIf="itemFormData[col.key] && col.iconName" [name]="col.iconName"></ion-icon>
					</div>

					<!-- Affichage d'une image. -->
					<div *ngIf="col.isImage" class="margin-auto">
						<ion-avatar
							[ngStyle]="col.size? {'max-height': avatarSize[col.size]+'px', 'max-width': avatarSize[col.size]+'px'} : {}">
							<ion-img #img [src]="itemFormData[col.key].url" [alt]="itemFormData[col.key].alt"></ion-img>
						</ion-avatar>
					</div>

					<!-- Affichage de texte. -->
					<ng-container *ngIf="!col.isPicture && !col.isIcon && !col.isImage">
						<p>{{ itemFormData[col.key] | pattern: $any(col.key) : itemFormData | isDate: 'dd/MM/yyyy HH:mm' }}
						</p>
					</ng-container>
				</calao-column-list-row-column>
			</calao-column-list-row>
		</ng-template>
		<calao-column-list-header>
			<calao-column-list-header-column *ngFor="let col of displayFields" [key]="col.key" [ngClass]="col.cssClass"
				[size]="col.size" [label]="col.label"
				(onSortClicked)="orderOrReverse($any($event))"></calao-column-list-header-column>
		</calao-column-list-header>

	</calao-column-list>
</ng-container>