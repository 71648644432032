export enum EAction {
	add = "add",
	delete = "delete",
	edit = "edit",
	move = "move",
	undo = "undo",
	redo = "redo",
	clear = "clear",
	test = "test",
	transform = "transform"
}