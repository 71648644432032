import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { defer, from } from 'rxjs';
import { map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { DateHelper } from '../../../../helpers/dateHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { ItemSizeCalculator } from '../../../virtual-scroll/models/item-size-calculator';
import { Document } from '../../models/document';
import { EDocListItemType } from '../../models/edoc-list-item-type';
import { IDocListItem } from '../../models/idoc-list-item';
import { IListItemOption } from '../../models/ilist-item-option';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';

@Component({
	selector: 'calao-document-list',
	templateUrl: './document-list.component.html',
	styleUrls: ['./document-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent extends DestroyableComponentBase {

	//#region FIELDS

	@Output("onOptionClicked") private readonly moOptionClickedEvent = new EventEmitter<IListItemOptionClickEvent>();
	@Output("onOpenClicked") private readonly moOpenClickedEvent = new EventEmitter<Document>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Flux de la liste des items de la liste à afficher. */
	public readonly observableListItems = new ObservableArray<IDocListItem>([]);

	/** Liste des documents à afficher. */
	@Input() public documents: Document[];
	@ObserveArray<DocumentListComponent>("documents")
	public readonly observableDocuments = new ObservableArray<Document>();

	/** Doc propriété. */
	@Input() public isReadById?: Map<string, boolean>;
	@ObserveProperty<DocumentListComponent>({ sourcePropertyKey: "isReadById" })
	public readonly observableIsReadById = new ObservableProperty<Map<string, boolean>>();

	/** Doc propriété. */
	@Input() public itemOptionsById?: Map<string, IListItemOption[]> | null;
	@ObserveProperty<DocumentListComponent>({ sourcePropertyKey: "itemOptionsById" })
	public readonly observableItemOptionsById = new ObservableProperty<Map<string, IListItemOption[]>>();

	/** Indique s'il faut afficher un message s'il n'y a pas de documents. */
	@Input() public displayEmptyText: boolean;
	@ObserveProperty<DocumentListComponent>({ sourcePropertyKey: "displayEmptyText" })
	public readonly observableDisplayEmptyText = new ObservableProperty<boolean>(false);

	/** Chemin de la liste. */
	@Input() public path?: string;
	/** Indique s'il faut afficher l'arbre de navigation. */
	@Input() public displayNavigationTree: boolean;
	@ObserveProperty<DocumentListComponent>({ sourcePropertyKey: "displayNavigationTree" })
	public readonly observableDisplayNavigationTree = new ObservableProperty<boolean>(false);

	/** Indique si un chargement est en cours. */
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);

	/** Classe permettant de calculer la taille d'un item dynamiquement. */
	public readonly itemSizeCalculator = new ItemSizeCalculator<IDocListItem>((poItem: IDocListItem) => {
		switch (poItem.type) {
			case EDocListItemType.month:
				return 27;

			case EDocListItemType.day:
				return 24;

			case EDocListItemType.doc:
				return 100;
		}
	});

	public readonly docListItemType = EDocListItemType;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcUiMessage: UiMessageService
	) {
		super();

		this.observableDocuments.changes$.pipe(
			tap(() => this.observableIsLoading.value = true),
			mergeMap((paDocuments: Document[]) => defer(() => from(paDocuments).pipe(
				toArray(),
				mapTo(paDocuments)
			))),
			map((paDocuments: Document[]) => {
				const loOrganizedDocumentsByDate: Map<string, Map<string, Document[]>[]> = this.isvcDocExplorerDocuments.getOrganizedDocumentsByDate(paDocuments);
				this.observableListItems.resetArray(this.getListItems(loOrganizedDocumentsByDate));
				return paDocuments;
			}),
			tap(() => this.observableIsLoading.value = false, () => {
				this.observableIsLoading.value = false;
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Une erreur est survenue lors de la récupération des documents." }));
			}),
			secure(this)
		).subscribe();
	}

	private getListItems(poOrganizedMap: Map<string, Map<string, Document[]>[]>): IDocListItem[] {
		const laItems: IDocListItem[] = [];

		poOrganizedMap.forEach((paYearMonthMaps: Map<string, Document[]>[], psYearMonthKey: string) => {
			const laYearMonthKeyParts: string[] = psYearMonthKey.split("-");
			const lsYear: string = laYearMonthKeyParts[0];
			const lnMonthIndex: number = laYearMonthKeyParts[1] && NumberHelper.isValid(+laYearMonthKeyParts[1]) ? +laYearMonthKeyParts[1] - 1 : -1;
			const lsMonth: string = NumberHelper.isValid(lnMonthIndex) ? DateHelper.C_MONTH_NAMES[lnMonthIndex] : "";

			laItems.push({ type: EDocListItemType.month, value: `${lsMonth} ${lsYear}` });
			paYearMonthMaps.forEach((poDayMap: Map<string, Document[]>) => {
				poDayMap.forEach((paDocs: Document[], psDayKey: string) => {
					laItems.push({ type: EDocListItemType.day, value: `${psDayKey} ${lsMonth} ${lsYear}` });

					paDocs.forEach((poDoc: Document) => laItems.push({ type: EDocListItemType.doc, value: poDoc }));
				});
			});
		});

		return laItems;
	}

	public onOptionClicked(poEvent: IListItemOptionClickEvent): void {
		this.moOptionClickedEvent.emit(poEvent);
	}

	public onOpenClicked(poDocument: Document): void {
		this.moOpenClickedEvent.emit(poDocument);
	}

	//#endregion

}
