import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { PathHelper } from '../../../../helpers/path-helper';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DocExplorerConfig } from '../../models/doc-explorer-config';
import { Folder } from '../../models/folder';
import { FolderConfig } from '../../models/folder-config';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';

@Component({
	selector: 'calao-select-sub-folder-modal',
	templateUrl: './select-sub-folder-modal.component.html',
	styleUrls: ['./select-sub-folder-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSubFolderModalComponent extends ModalComponentBase<Folder> implements OnInit {

	//#region PROPERTIES

	@Input() public path?: string;
	@ObserveProperty<SelectSubFolderModalComponent>({ sourcePropertyKey: "path" })
	public readonly observablePath = new ObservableProperty<string | undefined>();

	/** Liste des options. */
	public readonly observableFoldersOptions = new ObservableArray<ISelectOption<Folder>>([]);

	/** Dossier sélectionné. */
	public readonly observableSelectedFolder = new ObservableProperty<Folder | undefined>();

	/** Mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observablePath.value$.pipe(
			filter((psPath?: string) => !!psPath),
			mergeMap((psPath: string) => this.isvcDocExplorerDocuments.getConfig$().pipe(
				tap((poConfig: DocExplorerConfig) => {
					const lsPath: string = PathHelper.parsePath(psPath);
					poConfig.getChildStaticFoldersConfig(lsPath).forEach((poFolderConfig: FolderConfig) => {
						this.observableFoldersOptions.push({ label: poFolderConfig.name, value: new Folder({ ...poFolderConfig, path: `${lsPath}/${poFolderConfig.lastPathPart}`, configPath: poFolderConfig.path }) });
					});
				})
			)),
			secure(this)
		).subscribe();
	}

	public onFolderChanged(paSelectedFolders: Folder[]): void {
		this.observableSelectedFolder.value = ArrayHelper.getFirstElement(paSelectedFolders);
	}

	@Queue<SelectSubFolderModalComponent, Parameters<SelectSubFolderModalComponent["submit$"]>, ReturnType<SelectSubFolderModalComponent["submit$"]>>({
		excludePendings: true
	})
	private submit$(): Observable<Folder> {
		return this.observableSelectedFolder.value$.pipe(
			tap((poFolder: Folder) => this.close(poFolder)),
			secure(this)
		);
	}

	public onSubmitAsync(): Promise<Folder> {
		return this.submit$().toPromise();
	}

	//#endregion

}
