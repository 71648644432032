import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LinkModule } from '../link/link.module';
import { SliderComponent } from './slider.component';

@NgModule({
	imports: [CommonModule, IonicModule, LinkModule, FormsModule],
	exports: [SliderComponent],
	declarations: [SliderComponent]
})
export class SliderModule {
	constructor() { }
}