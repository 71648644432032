import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PopoverService } from '../../../../services/popover.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { IFormListParams } from '../../../forms/models/IFormListParams';
import { IFormListDetailEvent } from '../../../forms/models/iform-list-detail-event';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { TCRUDPermissions } from '../../../permissions/models/tcrud-permissions';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Document } from '../../models/document';
import { EExplorerDisplayMode } from '../../models/eexplorer-display-mode';
import { EListItemOption } from '../../models/elist-item-option';
import { Folder } from '../../models/folder';
import { FolderContent } from '../../models/folder-content';
import { FormDocument } from '../../models/form-document';
import { IListItemOption } from '../../models/ilist-item-option';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';
import { IUserStatus } from '../../models/iuser-status';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';
import { DocExplorerService } from '../../services/doc-explorer.service';
import { DocumentStatusService } from '../../services/document-status.service';

@Component({
	selector: 'calao-folder-list',
	templateUrl: './folder-list.component.html',
	styleUrls: ['./folder-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderListComponent extends DestroyableComponentBase {

	//#region FIELDS

	/** Sujet permettant de raffaichir la liste des documents. */
	private readonly moRefreshSource = new ReplaySubject();

	@Output("onOptionClicked") private readonly moOptionClickedEvent = new EventEmitter<IListItemOptionClickEvent>();
	@Output("onOpenClicked") private readonly moOpenClickedEvent = new EventEmitter<Document>();
	@Output("onPathChanged") private readonly moPathChangedEvent = new EventEmitter<string>();
	@Output("onDisplayModeChanged") private readonly moDisplayModeChangedEvent = new EventEmitter<EExplorerDisplayMode>();

	/** Liste des statuts par id de document. */
	private readonly moObservableStatusById = new ObservableProperty<Map<string, IUserStatus>>(new Map());

	//#endregion FIELDS

	//#region PROPERTIES

	/** Route initiale. */
	@Input() public rootPath: string;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "rootPath" })
	public readonly observableRootPath = new ObservableProperty<string>(undefined);

	/** Route courante. */
	@Input() public currentPath?: string;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "currentPath" })
	public readonly observableCurrentPath = new ObservableProperty<string>();

	/** Mode d'affichage. */
	@Input() public displayMode: EExplorerDisplayMode;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<EExplorerDisplayMode>(EExplorerDisplayMode.folders);

	public readonly displayMode$: Observable<EExplorerDisplayMode> = this.observableDisplayMode.value$.pipe(
		map((peDisplayMode?: EExplorerDisplayMode) => peDisplayMode ?? EExplorerDisplayMode.folders),
		secure(this)
	)

	/** Arbre de navigation. */
	public readonly observableNavigationTree = new ObservableArray<Folder>();

	/** Liste des dossiers à afficher. */
	public readonly observableFolders = new ObservableArray<FolderContent>();

	/** Liste des documents à afficher. */
	public readonly observableDocuments = new ObservableArray<Document>();

	/** Indique si un chargement est en cours. */
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);

	public readonly observableListItemOptionsById = new ObservableProperty<Map<string, IListItemOption[]>>(new Map());


	/** Flux des options d'affichage. */
	public readonly observableDisplayModeOptions = new ObservableArray<ISelectOption<EExplorerDisplayMode>>([
		this.getFoldersSelectOption(),
		this.getDateSelectionOption(),
		this.getTabSelectOption()
	]);

	/** Flux des options de la vue formList. */
	public readonly formListParams$: Observable<IFormListParams<any> | undefined>;
	/** Enum mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;
	/** Enum mode d'affichage. */
	public readonly explorerDisplayMode = EExplorerDisplayMode;

	public readonly observableIsReadById = new ObservableProperty<Map<string, boolean>>(new Map());

	public readonly displayFolders$: Observable<boolean> = this.displayMode$.pipe(
		map((peDisplayMode: EExplorerDisplayMode) => peDisplayMode === EExplorerDisplayMode.folders),
		secure(this)
	);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorer: DocExplorerService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPopover: PopoverService,
		protected readonly isvcDocumentStatus: DocumentStatusService

	) {
		super();

		this.initPath();
		this.initFolderContent();
		this.initRefresh();
		this.initDisplayMode();
		this.initReadStatuses();
		this.formListParams$ = this.getFormListParams$().pipe(secure(this));
	}

	private initFolderContent(): void {
		this.observableCurrentPath.value$.pipe(
			tap(() => this.observableIsLoading.value = true),
			switchMap((psPath: string) => this.isvcDocExplorerDocuments.getFolderContent$(psPath)),
			switchMap((poFolderContent: FolderContent) => {
				if (!ArrayHelper.hasElements(poFolderContent.folders))
					this.replaceOption(this.getDateSelectionOption(true));
				else
					this.replaceOption(this.getDateSelectionOption());

				return this.observableDisplayMode.value$.pipe(
					switchMap((peDisplayMode: EExplorerDisplayMode) =>
						this.getDocumentsStatuses$(poFolderContent.documents).pipe(
							tap(() => {
								this.observableNavigationTree.resetSubscription(
									this.isvcDocExplorer.getNavigationTree$(poFolderContent.current.path).pipe(secure(this))
								);

								this.observableFolders.resetArray(poFolderContent.folders);
								this.setDocuments(peDisplayMode, poFolderContent);
								this.observableIsLoading.value = false;
								this.moPathChangedEvent.next(poFolderContent.current.path);
							}, () => {
								this.observableIsLoading.value = false;
								this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Une erreur est survenue lors de la récupération des documents." }));
							}),
						)
					)
				);
			}),
			secure(this)
		).subscribe();
	}

	private getFormListParams$(): Observable<IFormListParams<any> | undefined> {
		return combineLatest([this.observableNavigationTree.changes$, this.observableDocuments.changes$]).pipe(
			map(([paFolders, paDocs]: [Folder[], Document[]]) => {
				const loCurrentFolder: Folder | undefined = ArrayHelper.getLastElement(paFolders);
				const laFormDocuments: IStoreDocument[] = [];
				paDocs.forEach((poDocument: Document) => {
					if (poDocument instanceof FormDocument)
						laFormDocuments.push(poDocument);
				});

				if (ArrayHelper.hasElements(loCurrentFolder?.forms)) {
					return {
						entityDescId: loCurrentFolder.forms[0].descriptor,
						listId: loCurrentFolder.forms[0].list,
						customEntries: laFormDocuments
					} as IFormListParams<IStoreDocument>;
				}

				return undefined;
			})
		);
	}

	private initReadStatuses(): void {
		combineLatest([this.observableDocuments.changes$, this.moObservableStatusById.value$]).pipe(
			tap(([paDocuments, poStatusById]: [Document[], Map<string, IUserStatus>]) => {
				const loIsReadById = new Map<string, boolean>();

				paDocuments.forEach((poDocument: Document) => {
					const loStatus: IUserStatus | undefined = poStatusById.get(poDocument._id);

					loIsReadById.set(
						poDocument._id,
						!!loStatus && loStatus.docRev === poDocument?._rev && loStatus.status === "read"
					);
				});

				this.observableIsReadById.value = loIsReadById;

				this.observableListItemOptionsById.value = this.getItemsOptionsById(
					paDocuments,
					poStatusById,
					this.getFilePermissionsById(paDocuments)
				);
			}),
			secure(this)
		).subscribe();
	}

	private initDisplayMode(): void {
		this.observableNavigationTree.changes$.pipe(
			tap((paFolder: Folder[]) => {
				const loCurrentFolder: Folder | undefined = ArrayHelper.getLastElement(paFolder);
				if (!ArrayHelper.hasElements(loCurrentFolder?.forms))
					this.replaceOption(this.getTabSelectOption(true));
				else
					this.replaceOption(this.getTabSelectOption());
			}),
			secure(this)
		).subscribe();
	}

	private initRefresh(): void {
		this.moRefreshSource.asObservable().pipe(
			switchMap(() => this.observableCurrentPath.value$),
			tap(() => this.observableIsLoading.value = true),
			switchMap((psPath: string) => this.isvcDocExplorerDocuments.getFolderContent$(psPath)),
			tap((poFolderContent: FolderContent) => {
				this.observableDocuments.resetArray(poFolderContent.documents);
				this.observableFolders.resetArray(poFolderContent.folders);
				this.observableIsLoading.value = false;
			}, () => {
				this.observableIsLoading.value = false;
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Une erreur est survenue lors de la récupération des documents." }));
			}),
			secure(this)
		).subscribe();
	}

	private initPath(): void {
		this.observableRootPath.value$.pipe(
			tap((psPath: string) => {
				const lsPath: string = psPath === null ? "" : psPath;
				this.observableCurrentPath.value = lsPath;
			}),
			secure(this)
		).subscribe();
	}

	private setDocuments(peDisplayMode: EExplorerDisplayMode, poFolderContent: FolderContent): void {
		if (peDisplayMode === EExplorerDisplayMode.date)
			this.observableDocuments.resetArray(poFolderContent.getDocumentsRecursively());
		else
			this.observableDocuments.resetArray(poFolderContent.documents);
	}

	private getTabSelectOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "list", value: EExplorerDisplayMode.tab, hidden: pbHidden };
	}

	private getDateSelectionOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "time", value: EExplorerDisplayMode.date, hidden: pbHidden };
	}

	private getFoldersSelectOption(pbHidden?: boolean): ISelectOption<EExplorerDisplayMode> {
		return { icon: "menu", value: EExplorerDisplayMode.folders, hidden: pbHidden };
	}

	private replaceOption(poOption: ISelectOption<EExplorerDisplayMode>): void {
		const loOption: ISelectOption<EExplorerDisplayMode> | undefined = this.observableDisplayModeOptions.find(
			(poDisplayModeOption: ISelectOption<EExplorerDisplayMode>) => poDisplayModeOption.value === poOption.value
		);

		if (loOption?.hidden !== poOption.hidden)
			ArrayHelper.replaceElement(this.observableDisplayModeOptions, loOption, poOption);
	}

	private getFilePermissionsById(paDocuments: Document[]): Map<string, Map<TCRUDPermissions, boolean>> {
		const loFilePermissionsById = new Map<string, Map<TCRUDPermissions, boolean>>();

		paDocuments.forEach((poDocument: Document) => loFilePermissionsById.set(
			poDocument._id,
			this.getFilePermissions(poDocument)
		));

		return loFilePermissionsById;
	}

	private getFilePermissions(poDocument: Document): Map<TCRUDPermissions, boolean> {
		const loFilePermissions = new Map<TCRUDPermissions, boolean>();
		loFilePermissions.set("delete", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "delete", false));
		loFilePermissions.set("trash", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", false));
		loFilePermissions.set("edit", this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "edit", false));
		return loFilePermissions;
	}

	private getItemsOptionsById(
		paDocuments: Document[],
		poStatusById: Map<string, IUserStatus>,
		poPermissionsById: Map<string, Map<TCRUDPermissions, boolean>>
	): Map<string, IListItemOption[]> {
		const loItemsOptionsById = new Map<string, IListItemOption[]>();

		paDocuments.forEach((poDocument: Document) => loItemsOptionsById.set(
			poDocument._id,
			this.getItemOptions(
				poDocument,
				poStatusById.get(poDocument._id),
				poPermissionsById.get(poDocument._id)
			)
		));

		return loItemsOptionsById;
	}

	private getItemOptions(
		poDocument?: Document,
		poStatus?: IUserStatus,
		poPermission?: Map<TCRUDPermissions, boolean>
	): IListItemOption[] {
		const laOptions: IListItemOption[] = [];
		if (poStatus && poStatus.docRev === poDocument?._rev && poStatus.status === "read")
			laOptions.push({ label: "Non lu", icon: "mail", color: "primary", key: EListItemOption.unread });
		else
			laOptions.push({ label: "Lu", icon: "mail-open", color: "primary", key: EListItemOption.read });

		if (poPermission) {
			if (poDocument instanceof FormDocument && poPermission.get("edit"))
				laOptions.push({ label: "Éditer", icon: "create", color: "primary", key: EListItemOption.edit });
			if (poDocument?.canArchive && poPermission.get("trash"))
				laOptions.push({ label: "Supprimer", icon: "trash", color: "primary", key: EListItemOption.trash });
			if (poPermission.get("delete"))
				laOptions.push({ label: "Détruire", icon: "trash-bin", color: "danger", key: EListItemOption.delete });
			if (poDocument?.canRestore && poPermission.get("trash"))
				laOptions.push({ label: "Restaurer", icon: "refresh", color: "primary", key: EListItemOption.restore });
		}

		return laOptions;
	}

	private getDocumentsStatuses$(paDocuments: Document[]): Observable<Map<string, IUserStatus>> {
		return this.isvcDocumentStatus.getDocumentsUserStatusesById$(paDocuments, true).pipe(
			tap((poDocumentsStatuses: Map<string, IUserStatus>) => this.moObservableStatusById.value = poDocumentsStatuses)
		);
	}

	public goToFolder(poFolder: Folder): void {
		this.observableCurrentPath.value = poFolder.path;
	}

	public reload(): void {
		this.moRefreshSource.next();
	}

	public onDisplayModeChanged(paSelectedDisplayModes: EExplorerDisplayMode[]): void {
		this.moDisplayModeChangedEvent.emit(
			this.observableDisplayMode.value = ArrayHelper.getFirstElement(paSelectedDisplayModes)
		);
	}

	public onOptionClicked(poEvent: IListItemOptionClickEvent): void {
		this.moOptionClickedEvent.emit(poEvent);
	}

	public onOpenClicked(poDocument: Document): void {
		this.moOpenClickedEvent.emit(poDocument);
	}

	public async onFormListDetailClickedAsync(poEvent: IFormListDetailEvent<Document>): Promise<void> {
		const laItemOptions: IListItemOption[] | undefined = this.observableListItemOptionsById.value?.get(poEvent.item._id);

		if (laItemOptions)
			await this.openPopover$(poEvent, laItemOptions).toPromise();

	}

	@Queue<FolderListComponent, Parameters<FolderListComponent["openPopover$"]>, ReturnType<FolderListComponent["openPopover$"]>>({
		excludePendings: true
	})
	private openPopover$(poEvent: IFormListDetailEvent<Document>, paItemOptions: IListItemOption[]): Observable<boolean> {
		return this.isvcPopover.showPopover(
			paItemOptions.map(
				(poListItemOption: IListItemOption) => ({
					title: poListItemOption.label,
					icon: poListItemOption.icon,
					color: poListItemOption.color,
					action: () => of(this.onOptionClicked({ document: poEvent.item, key: poListItemOption.key }))
				})
			),
			poEvent.event
		).pipe(mapTo(true));
	}

	//#endregion

}
