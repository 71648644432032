import { inject, Type } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { defer, Observable, of } from "rxjs";
import { concatMap, last, takeWhile } from "rxjs/operators";
import { TCanActivateGuardsSchedulerReturnType } from "./tcan-activate-guards-scheduler-return-type";

type TCanActivateGuardReturnType = boolean | UrlTree;

export abstract class CanActivateGuardsScheduler {

	//#region METHODS

	/** Permet d'exécuter plusieurs gardiens les uns après les autres
	 * @param paGuards Tableau des gardiens à exécuter les uns après les autres.
	 * @returns `true` si tous les gardiens retournent `true`,
	 * `false` si au moins un gardien retourne `false`,
	 * `urlTree` pour changer de route.
	 */
	public static schedule(paGuards: Type<CanActivate>[]): TCanActivateGuardsSchedulerReturnType {
		return (poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot) => {
			// Instanciation de tous les gardiens.
			const laGuardInstances: CanActivate[] = paGuards.map((poItem: Type<CanActivate>) => inject(poItem));
			// Convertis un tableau en un observable.
			return defer(() => laGuardInstances).pipe(
				// Pour chaque gardien, on lance canActivate et on attend que l'opération soit terminée.
				concatMap((poGuard: CanActivate) => {
					const loResult: TCanActivateGuardReturnType | Observable<TCanActivateGuardReturnType> | Promise<TCanActivateGuardReturnType> = poGuard.canActivate(poRoute, poState);
					return (loResult instanceof Observable || loResult instanceof Promise) ? loResult : of(loResult);
				}),
				// On n'exécute pas le gardien suivant si le résultat du gardien actuel n'est pas `true`.
				takeWhile((poValue: TCanActivateGuardReturnType) => !!poValue, true),
				// On retourne le résultat du dernier gardien.
				last()
			);
		};
	}

	//#endregion METHODS

}