import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BlankInformationComponent } from './blank-information/blank-information.component';

@NgModule({
	declarations: [BlankInformationComponent],
	imports: [
		CommonModule,
		IonicModule
	],
	exports: [
		BlankInformationComponent
	],
})
export class BlankInformationModule { }