import { CommonModule } from '@angular/common';
import { NgModule, Provider, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DynHostModule } from '../dynHost/dynHost.module';
import { ToolbarComponent } from './toolbar.component';
import { FabButtonComponent } from './toolbarDynComponents/fabButton.component';

const modules: Array<Type<any>> = [
	IonicModule,
	CommonModule,
	DynHostModule
];
const components: Array<Type<any>> = [
	ToolbarComponent,
	FabButtonComponent
];
const providers: Array<Provider> = [];

@NgModule({
	imports: [...modules],
	exports: [...components],
	declarations: [...components],
	providers: [...providers]
})
export class ToolbarModule {
	constructor() { }
}