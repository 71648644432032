import { ArrayHelper } from '../../../helpers/arrayHelper';
import { Document } from '../models/document';

export class DocumentsCounters {

	//#region PROPERTIES

	/** Nombre total des documents. */
	public total = 0;
	/** Nombre de documents non lus. */
	public notRead = 0;

	public get notReadLabel() { return this.notRead > 1 ? "non lus" : "non lu"; };

	//#endregion PROPERTIES

	//#region METHODS

	constructor(paDocuments?: Document[]) {
		if (ArrayHelper.hasElements(paDocuments)) {
			this.total = paDocuments.length;
			this.notRead = paDocuments.filter((poDocument: Document) => !poDocument.userStatus || poDocument.userStatus.status === "notRead").length;
		}
	}

	//#endregion METHODS

}