import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DmsService } from '../../dms/services/dms.service';
import { LogAction } from '../../logger/decorators/log-action.decorator';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { ILogActionHandler } from '../../logger/models/ILogActionHandler';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { DmsDocument } from '../models/dms-document';
import { DocExplorerConfig } from '../models/doc-explorer-config';
import { Document } from '../models/document';
import { Folder } from '../models/folder';
import { DocExplorerDocumentsService } from './doc-explorer-documents.service';

@Injectable({
	providedIn: 'root'
})
export class DocExplorerService extends DestroyableServiceBase implements ILogActionHandler {

	//#region FIELDS

	private static readonly C_LOG_ID = "DOC.EXPLR.S::";

	private static readonly C_DEFAULT_ROOT_PATH = "";

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId: string = DocExplorerService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioRouter: Router,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService
	) {
		super();
	}

	//#region EXPLORER

	public getNavigationTree$(psPath?: string): Observable<Folder[]> {
		return this.isvcDocExplorerDocuments.getConfig$().pipe(
			map((poConfig?: DocExplorerConfig) => {
				const laFolders: Folder[] = [];

				if (psPath !== DocExplorerService.C_DEFAULT_ROOT_PATH) { // Si on n'est pas sur le chemin par défaut on construit les sous-dossiers.
					const laPathParts: string[] = psPath?.split(DmsService.C_PATH_SEPARATOR) ?? [];

					for (let lnIndex = 0; lnIndex < laPathParts.length; ++lnIndex) {
						const lsPath: string = laPathParts.slice(0, lnIndex + 1).join(DmsService.C_PATH_SEPARATOR);

						laFolders.push(
							this.isvcDocExplorerDocuments.resolveFolderPropertiesPattern(
								this.isvcDocExplorerDocuments.createFolder(poConfig?.getFolderConfig(lsPath), lsPath)
							)
						);
					}
				}

				laFolders.unshift( // On ajoute le dossier par défaut
					this.isvcDocExplorerDocuments.createFolder(
						poConfig?.getFolderConfig(DocExplorerService.C_DEFAULT_ROOT_PATH),
						DocExplorerService.C_DEFAULT_ROOT_PATH
					)
				);

				return laFolders;
			})
		);
	}

	//#endregion EXPLORER

	//#region DOCUMENTS

	/** Place un fichier à la corbeille.
	 * @param psGuid Guid du fichier à supprimer.
	 */
	public moveToTrash$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", true);

		return this.innerMoveToTrash$(poDocument, psPath).pipe(
			tap((poDeletedDocument?: Document) => this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				duration: 4000,
				message: poDeletedDocument ? `${poDeletedDocument.name} supprimé.` : "Erreur lors de la suppression du document.",
				buttons: poDeletedDocument ? [{
					text: "Voir la corbeille",
					handler: () => this.ioRouter.navigate(["documents"], { queryParams: { path: ArrayHelper.getFirstElement(poDeletedDocument.paths) } })
				}] : undefined
			}))),
			map((poDeletedDocument?: Document) => !!poDeletedDocument)
		);
	}

	@LogAction<Parameters<DocExplorerService["innerMoveToTrash$"]>, ReturnType<DocExplorerService["innerMoveToTrash$"]>>({
		actionId: ELogActionId.explorerDocMoveToTrash,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' placé dans la corbeille.`,
		errorMessage: (_, poDocument: Document) => `Le placement du document '${poDocument._id}' dans la corbeille a échoué.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerMoveToTrash$(poDocument: Document, psPath: string): Observable<Document | undefined> {
		const pbAlreadyDeleted: boolean = poDocument.paths.every((psDocumentPath: string, pnIndex: number) => {
			if (psDocumentPath.startsWith("trash"))
				return true;
			else {
				poDocument.paths[pnIndex] = `trash${DmsService.C_PATH_SEPARATOR}${psDocumentPath}`;
				poDocument.archiveDate = new Date();
				poDocument.restoreDate = undefined;
				return false;
			}
		});
		if (pbAlreadyDeleted)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already in trash.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Suppression",
						message: `Êtes-vous sûr de vouloir placer ${poDocument.name} dans la corbeille ?`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Supprimer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.moveToTrashDmsDocument$(poDocument);
						return EMPTY;
					})
				);
			}
			return EMPTY;
		}
		else {
			return this.isvcDocExplorerDocuments.moveToTrashFormDocument$(psPath, poDocument);
		}
	}

	/** Restaure un fichier archivé.
	 * @param psGuid Guid du fichier à restaurer.
	 */
	public restore$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "trash", true);
		return this.innerRestore$(poDocument, psPath).pipe(
			tap((poRestoredDocument?: Document) => this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				duration: 4000,
				message: poRestoredDocument ? `${poRestoredDocument.name} restauré.` : "Erreur lors de la restauration du document.",
				buttons: poRestoredDocument ? [{
					text: "Voir le dossier",
					handler: () => this.ioRouter.navigate(["documents"], { queryParams: { path: ArrayHelper.getFirstElement(poRestoredDocument.paths) } })
				}] : undefined
			}))),
			map((poRestoredDocument?: Document) => !!poRestoredDocument)
		);
	}

	@LogAction<Parameters<DocExplorerService["innerRestore$"]>, ReturnType<DocExplorerService["innerRestore$"]>>({
		actionId: ELogActionId.explorerDocRestore,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' restored.`,
		errorMessage: (_, poDocument: Document) => `Restoration of document '${poDocument._id}' failed.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerRestore$(poDocument: Document, psPath: string): Observable<Document | undefined> {
		const pbAlreadyRestored: boolean = poDocument.paths.every((psDocumentPath: string, pnIndex: number) => {
			if (!psDocumentPath.startsWith("trash") && !psDocumentPath.startsWith("archives"))
				return true;
			else {
				const laPathParts: string[] = psDocumentPath.split(DmsService.C_PATH_SEPARATOR);
				laPathParts.shift(); // Supprime la partie 'trash'
				poDocument.paths[pnIndex] = laPathParts.join(DmsService.C_PATH_SEPARATOR);
				poDocument.archiveDate = undefined;
				poDocument.restoreDate = new Date();
				return false;
			}
		});

		if (pbAlreadyRestored)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already restored.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Restauration",
						message: `Êtes-vous sûr de vouloir restaurer ${poDocument.name} ?`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Restaurer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.restoreDmsDocument$(poDocument);

						return EMPTY;
					})
				);
			}
			return EMPTY;
		}
		else {
			return this.isvcDocExplorerDocuments.restoreFormDocument$(psPath, poDocument);
		}
	}

	/** Restaure un fichier archivé.
	 * @param psGuid Guid du fichier à restaurer.
	 */
	public delete$(poDocument: Document, psPath: string): Observable<boolean> {
		this.isvcDocExplorerDocuments.checkDocumentPermissions(poDocument, "delete", true);
		return this.innerDelete$(poDocument, psPath);
	}

	@LogAction<Parameters<DocExplorerService["innerDelete$"]>, ReturnType<DocExplorerService["innerDelete$"]>>({
		actionId: ELogActionId.explorerDocDestroy,
		successMessage: (_, poDocument: Document) => `Document '${poDocument._id}' destroyed.`,
		errorMessage: (_, poDocument: Document) => `Destruction of document '${poDocument._id}' failed.`,
		dataBuilder: (_, __, poDocument: Document) => { return { id: poDocument._id }; }
	})
	private innerDelete$(poDocument: Document, psPath: string): Observable<boolean> {
		if (poDocument._deleted)
			console.error(`${DocExplorerService.C_LOG_ID}Document '${poDocument._id}' already deleted.`);

		if (poDocument instanceof DmsDocument) {
			if (!!ConfigData.environment.dms.shareDocumentMeta) {
				return this.isvcUiMessage.showAsyncMessage(
					new ShowMessageParamsPopup({
						header: "Suppression",
						message: `Êtes-vous sûr de vouloir supprimer ${poDocument.name} ?<br/>Attention : le document sera définitivement supprimé !`,
						buttons: [
							{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
							{ text: "Supprimer", handler: UiMessageService.getTruthyResponse }
						]
					})
				).pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						if (poResponse.response)
							return this.isvcDocExplorerDocuments.deleteDmsDocument$(poDocument);
						return of(false);
					})
				);
			}
			else
				return of(true);
		}
		else
			return this.isvcDocExplorerDocuments.deleteFormDocument$(psPath, poDocument);
	}

	//#endregion DOCUMENTS

	//#endregion METHODS

}
