import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IGroup } from '../../model/contacts/IGroup';
import { HasPermissions } from '../../modules/permissions/decorators/has-permissions.decorator';
import { EPermission } from '../../modules/permissions/models/EPermission';
import { IHasPermission, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { DestroyableServiceBase } from '../../modules/services/models/destroyable-service-base';
import { GroupsService } from '../../services/groups.service';
import { ShowMessageParamsToast } from '../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../services/uiMessage.service';

@Injectable({ providedIn: "root" })
export class CanEditGroupGuard extends DestroyableServiceBase implements CanActivate, IHasPermission {

	//#region PROPERTIES

	@HasPermissions({ permission: "edit" })
	public get canEdit(): boolean { return true; }

	public readonly permissionScope: EPermission[] = [EPermission.contacts, EPermission.groups];

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private isvcGroups: GroupsService,
		private isvcUiMessage: UiMessageService
	) {
		super();
	}

	public canActivate(poNext: ActivatedRouteSnapshot): Observable<boolean> {
		return this.isvcGroups.getGroup(poNext.params.groupId)
			.pipe(
				map((poGroup: IGroup) => this.canEdit && poGroup && this.isvcPermissions.canApplyRoles(...(poGroup.roles ?? []))),
				tap((pbCanEdit: boolean) => {
					if (!pbCanEdit)
						this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Vous n'avez pas les permissions nécessaires pour modifier ce groupe." }));
				})
			);
	}

	//#endregion

}