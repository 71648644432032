<ng-container *ngIf="observableNavigationTree.changes$ | async as tree">
	<div *ngIf="tree.length > 1" class="breadcrumbs">
		<ng-container *ngFor="let folder of tree; let i = index">
			<div class="folder-name" (click)="goToFolder(folder)">
				<ion-icon *ngIf="folder.icon" [name]="folder.icon"></ion-icon>
				<ion-label *ngIf="folder.name">{{ folder.name }}</ion-label>
			</div>
			<ion-icon *ngIf="tree.length !== i + 1" name="chevron-forward"></ion-icon>
		</ng-container>
	</div>
</ng-container>

<ng-container *ngIf="observableIsLoading.value$ | async; else content">
	<osapp-spinner></osapp-spinner>
</ng-container>

<ng-template #content>
	<div class="selector">
		<osapp-selector [min]="1" [preselectedValues]="displayMode$ | async"
			[options]="observableDisplayModeOptions.changes$ | async" [displayMode]="selectorDisplayMode.tags"
			[scrollWrapper]="false" (selectionChanged)="onDisplayModeChanged($event)">
		</osapp-selector>
	</div>

	<div class="folder-list" *ngIf="displayFolders$ | async">
		<ng-container *ngFor="let folder of observableFolders.changes$ | async">
			<calao-folder-item [folder]="folder.current" (onOpenFolderClicked)="goToFolder($event)"></calao-folder-item>
		</ng-container>
	</div>

	<ng-container [ngSwitch]="displayMode$ | async">
		<ng-container *ngSwitchCase="explorerDisplayMode.tab">
			<calao-entity-entries-list *ngIf="formListParams$ | async as params" [params]="params"
				(onItemClicked)="onOpenClicked($event)"
				(onDetailClicked)="onFormListDetailClickedAsync($event)"></calao-entity-entries-list>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<calao-document-list [documents]="observableDocuments.changes$ | async"
				[displayEmptyText]="(observableFolders.length$ | async) === 0 && (observableDocuments.length$ | async) === 0"
				[path]="observableCurrentPath.value$ | async" [itemOptionsById]="observableListItemOptionsById.value$ | async"
				[isReadById]="observableIsReadById.value$ | async" (onOpenClicked)="onOpenClicked($event)"
				(onOptionClicked)="onOptionClicked($event)">
			</calao-document-list>
		</ng-container>
	</ng-container>
</ng-template>