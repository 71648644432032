<div>
	<label>Form Entries :</label>
	<button (click)="onShow()"> ? </button>
	<br>
	<ng-container *ngIf="show">
		<div class="widget-area">
			<label>Entry :</label>
			<pre>
{{ designableDescriptor.fakeEntry | json }}
			</pre>
		</div>
	</ng-container>
</div>