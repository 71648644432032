import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CalledComponent } from './called/called.component';
import { CallerComponent } from './caller/caller.component';
import { CanRecordAudioGuard } from './can-record-audio.guard';
import { JitsiModalComponent } from './jitsi/components/modal/jitsi-modal.component';
import { JitsiService } from './jitsi/services/jitsi.service';
import { LiveService } from './live.service';

@NgModule({
	declarations: [
		CalledComponent,
		CallerComponent,
		JitsiModalComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		RouterModule,
	],
	exports: [
		CalledComponent,
		CallerComponent
	],
	providers: [
		CanRecordAudioGuard,
		LiveService,
		JitsiService
	]
})
export class LiveModule { }
