import { EApplicationEventType } from "../../../model/application/EApplicationEventType";
import { EventParticipantEventBase } from "./event-participant-event-base";
import { IEventParticipantEventData } from "./ievent-participant-event-data";

export class EventParticipantAddEvent extends EventParticipantEventBase<IEventParticipantEventData> {

	//#region PROPERTIES

	public readonly type = EApplicationEventType.eventParticipantAddEndEvent;

	//#endregion

}
