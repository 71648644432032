import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { Database } from "../../../../model/store/Database";
import { EDatabaseSyncStatus } from "../../model/EDatabaseSyncStatus";
import { ETrackingStatus } from "./etracking-status.enum";

export class ChangeTrackedDatabase extends Database {

	//#region PROPERTIES

	private readonly moTrackingStatusSubject = new ReplaySubject<ETrackingStatus>(1);
	private meTrackingStatus: ETrackingStatus;
	/** Numéro de séquence de la base locale. */
	public get trackingStatus(): ETrackingStatus {
		return this.meTrackingStatus;
	}
	public set trackingStatus(peTrackingStatus: ETrackingStatus) {
		this.moTrackingStatusSubject.next(this.meTrackingStatus = peTrackingStatus);
	}
	/** Observe le numéro de séquence de la base locale. */
	public get trackingStatus$(): Observable<ETrackingStatus> {
		return this.moTrackingStatusSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	protected override getSyncStatus$(): Observable<EDatabaseSyncStatus> {
		return this.trackingStatus$.pipe(
			map((peTrackingStatus: ETrackingStatus) => {
				switch (peTrackingStatus) {
					case ETrackingStatus.tracked: // Si des documents sont tracés alors il faut répliquer.
						return EDatabaseSyncStatus.obsolete;
					case ETrackingStatus.none: // Si aucun document on est à jour.
						return EDatabaseSyncStatus.upToDate;
					case ETrackingStatus.error:
						return EDatabaseSyncStatus.error;
					default:
						return EDatabaseSyncStatus.error;
				}
			}));
	}

	//#endregion

}
