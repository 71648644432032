import { Injectable } from "@angular/core";
import { Observable, Subject, of, throwError } from "rxjs";
import { catchError, filter, mergeMap, take } from "rxjs/operators";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { EPrefix } from "../../../model/EPrefix";
import { EApplicationEventType } from "../../../model/application/EApplicationEventType";
import { IApplicationEvent } from "../../../model/application/IApplicationEvent";
import { ESecurityFlag } from "../../../model/security/ESecurityFlag";
import { Database } from "../../../model/store/Database";
import { EDatabaseRole } from "../../../model/store/EDatabaseRole";
import { EStoreEventStatus } from "../../../model/store/EStoreEventStatus";
import { EStoreEventType } from "../../../model/store/EStoreEventType";
import { IDataSource } from "../../../model/store/IDataSource";
import { IStoreDataResponse } from "../../../model/store/IStoreDataResponse";
import { IStoreDocument } from "../../../model/store/IStoreDocument";
import { IStoreEvent } from "../../../model/store/IStoreEvent";
import { EntityLinkService } from "../../../services/entityLink.service";
import { FlagService } from "../../../services/flag.service";
import { GalleryService } from "../../../services/gallery.service";
import { PatternResolverService } from "../../../services/pattern-resolver.service";
import { Store } from "../../../services/store.service";
import { EventsService } from "../../events/events.service";
import { IFormDescriptor } from "../models/IFormDescriptor";
import { IFormDescriptorDataSource } from "../models/IFormDescriptorDataSource";
import { IFormListEvent } from "../models/IFormListEvent";

@Injectable()
export class FormsService<T extends IStoreDocument = IStoreDocument> {

	//#region FIELDS

	/** Sujet du service des FormList permettant de partager/envoyer/recevoir des données via un système d'abonnement. */
	private moFormListSubject: Subject<IFormListEvent<T>>;

	//#endregion

	//#region PROPERTIES

	/** Identifiant de l'action "back" lors d'un enregistrement de formulaire. */
	public static readonly C_BACK_ACTION_ID: string = "back";
	/** Nombre maximum d'entrées dans la liste de formulaire à afficher, 50 par défaut. */
	public static readonly C_MAX_DISPLAY_ENTRIES: number = 50;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcEvents: EventsService,
		private readonly isvcFlags: FlagService
	) {

		this.moFormListSubject = new Subject();
	}

	/** Récupération de l'observable écoutant les événement des liste de formulaires. */
	public onFormListEvent(): Observable<IFormListEvent<T>> {
		return this.moFormListSubject.asObservable();
	}

	/** Lève un événement pour une liste de formulaires.
	 * @param poEvent Événement à lever.
	 */
	public raiseFormListEvent(poEvent: IFormListEvent<T>): void {
		this.moFormListSubject.next(poEvent);
	}

	/** Enregistre dans la base de données le document.
	 * @param poDocument Document qu'il faut enregistrer sur la base de données.
	 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
	 */
	public postFormEntry(poDocument: IStoreDocument, psDatabaseId: string): Observable<IStoreDataResponse> {
		return this.isvcStore.put(poDocument, psDatabaseId)
			.pipe(catchError(poError => { console.error(`FORM.S:: Error put entry : `, poError); return throwError(poError); }));
	}

	/** Transforme les paramètres de la source de données en données tenant compte de l'environnement de l'application.
	 * @param poDataSource Source de données à transformer.
	 * ### Example
	 *
	 * ```javascript
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key={{app.profession}}"
	 * }
	 * //DEVIENT
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key=42"
	 * }
	 * ```
	 */
	private resolveDynDataSourceParams(poDataSource: IDataSource): IDataSource {
		const loResolvedDataSource: IDataSource = poDataSource;

		if (poDataSource && poDataSource.viewParams) {
			if (poDataSource.viewParams.key)
				poDataSource.viewParams.key = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.key as string);

			if (typeof poDataSource.viewParams.startkey === "string")
				poDataSource.viewParams.startkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.startkey);

			if (typeof poDataSource.viewParams.endkey === "string")
				poDataSource.viewParams.endkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.endkey);
		}

		return loResolvedDataSource;
	}

	public getModel$<U extends IStoreDocument>(psModelId?: string): Observable<U | undefined> {
		if (StringHelper.isBlank(psModelId))
			return of(undefined);

		return this.isvcStore.getOne<U>(
			{
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					key: psModelId,
					include_docs: true
				}
			} as IDataSource<U>,
			false
		);
	}

	/** Configure une source de données conformément aux exigences du descripteur en appliquant notamment les valeurs dynamiques.
	 * @param poDescriptorDataSource DataSource telle qu'elle est décrite dans le descripteur.
	 * @returns Une DataSource dans laquelle les templates sont remplacés par les valeurs évaluées dynamiquement.
	 */
	public prepareFormDataSource(poDescriptorDataSource: IFormDescriptorDataSource): IDataSource {
		let loPreparedDataSource: IDataSource;

		if (poDescriptorDataSource) {
			loPreparedDataSource = {
				id: poDescriptorDataSource.id,
				type: poDescriptorDataSource.type,
				databaseId: poDescriptorDataSource.db, // Écart de nommage NoSQL/TypeScript.
				databasesIds: [],
				viewName: poDescriptorDataSource.view, // Écart de nommage NoSQL/TypeScript.
				viewParams: poDescriptorDataSource.viewParams,
				live: poDescriptorDataSource.live
			};

			if (poDescriptorDataSource.databases) // Écart de nommage NoSQL/TypeScript.
				loPreparedDataSource.databasesIds!.push(...poDescriptorDataSource.databases);

			if (poDescriptorDataSource.role)
				loPreparedDataSource.databasesIds!.push(...this.isvcStore.getDatabasesIdsByRole(poDescriptorDataSource.role));
		}
		else {
			loPreparedDataSource = {
				id: undefined,
				type: undefined,
				databaseId: undefined,
				databasesIds: [],
				viewName: undefined,
				viewParams: undefined
			};
		}

		return this.resolveDynDataSourceParams(loPreparedDataSource);
	}

	public getFormDescriptors(psDatabaseId: string): Observable<IFormDescriptor[]> {
		return this.isvcStore.get({
			databaseId: psDatabaseId,
			viewParams: {
				startkey: EPrefix.formDesc,
				endkey: EPrefix.formDesc + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		} as IDataSource<IFormDescriptor>);
	}

	/** Récupère toutes les entrées possèdant le `FormDescId` en paramètre et par la vue de la datasource, dans la base donnée par la datasource.
 * @param poDataSource Donne la base et la vue à utiliser pour trouver les entrées, mode 'live' par défaut.
 */
	public getEntries$(poDataSource?: IDataSource<T>): Observable<Array<T>> {
		if (!poDataSource)
			return of([]);

		const laDatabases: Database[] = [];
		poDataSource.live = poDataSource.live !== false; // Si on n'a pas mis explicitement un `live === false`, on le met.

		// Si l'utilisateur n'est pas authentifié on ne doit pas faire de preparation des workspaces.
		if (this.isvcFlags.getFlagValue(ESecurityFlag.authenticated)) {
			const laDatabasesIds: string[] | undefined = poDataSource.databaseId ? [poDataSource.databaseId] : poDataSource.databasesIds;
			if (ArrayHelper.hasElements(laDatabasesIds))
				poDataSource.databasesIds = this.isvcStore.prepareWorkspaceFiltersDatabases(laDatabasesIds);
		}
		else
			poDataSource.databasesIds = poDataSource.databaseId ? [poDataSource.databaseId] : poDataSource.databasesIds; // Pour retro compat

		if (poDataSource.databasesIds)
			poDataSource.databasesIds.forEach((psDatabaseId: string) => laDatabases.push(this.isvcStore.getDatabaseById(psDatabaseId)));

		// On est dans le cas où la base de données est initialisée.
		if (laDatabases.every((loDatabase: Database) => loDatabase && !StringHelper.isBlank(loDatabase.id) && loDatabase.isReady))
			return this.execGetAllEntries$(poDataSource);

		else if (ArrayHelper.hasElements(laDatabases)) {
			// On est dans le cas où la base de données n'est pas encore initialisée, 'loDatabase' ne doit pas être undefined sinon cela veut dire
			// que la base de données n'a pas été mise dans le fichier de config de l'application --> elle ne sera donc jamais initialisée.
			return this.isvcEvents.events$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterGetEntriesFromApplicationEvent(poEvent, laDatabases, poDataSource)),
					take(1),
					mergeMap(() => this.execGetAllEntries$(poDataSource))
				);
		}
		else {
			const lsMessage = "La base de données demandée pour récupérer les entries n'a pas été trouvé dans la config dynamique de l'application";
			console.error(`FORM.S:: ${lsMessage}`);
			return throwError(lsMessage);
		}
	}

	/** Exécute la requête qui récupère des données qui pouvant être : une entry (en fonctin de son id), plusieurs entry, ou un formDescriptor.
* @param poDataSource paramètres du menu que l'on veut initialiser.
* @param psDatabaseId id de la base de données à récupérer.
*/
	private execGetAllEntries$(poDataSource: IDataSource<T>): Observable<T[]> {
		return this.isvcStore.get<T>(poDataSource)
			.pipe(
				catchError(poError => {
					const lsStringifiedDatabases: string = poDataSource.databaseId ? poDataSource.databaseId : JSON.stringify(poDataSource.databasesIds);
					console.error(`FORM.S:: Erreur récupération base de données ${lsStringifiedDatabases} : `, poError);
					return throwError(poError);
				})
			);
	}

	/** Filtre les événements d'application pour ne garder que celui qui nous intéresse.
	 * @param poEvent Événement d'application reçu.
	 * @param paWantedDatabases Tableau des bases de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 * @param poDataSource Datasource qui doit contenir l'identifiant de la base de données cible.
	 */
	private filterGetEntriesFromApplicationEvent(
		poEvent: IApplicationEvent,
		paWantedDatabases: Array<Database>,
		poDataSource: IDataSource<T>
	): boolean {

		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent) {

			if (poDataSource.databaseId)
				paWantedDatabases.push(this.isvcStore.getDatabaseById(poDataSource.databaseId));
			else if (poDataSource.databasesIds)
				poDataSource.databasesIds.forEach((psDatabaseId: string) => paWantedDatabases.push(this.isvcStore.getDatabaseById(psDatabaseId)));

			// On attend que le statut de l'initialisation de la base de données des entries soit terminée.
			lbIsFilterOkay = paWantedDatabases.some((poDatabase: Database) =>
				poDatabase && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
				(poEvent as IStoreEvent).data.databaseId === poDatabase.id && (poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init
			);
		}

		return lbIsFilterOkay;
	}

	//#endregion
}