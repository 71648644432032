<ng-container *ngIf="observableRole.value$ | async as role">
	<div [ngClass]="displayHeaderLabels ? 'header' : ''" class="container">
		<div class="column role-name">
			<ion-label *ngIf="displayHeaderLabels">Rôles</ion-label>
			{{ role.label }}
		</div>
		<ng-container *ngFor="let permission of role.permissions">
			<div class="column">
				<ion-label *ngIf="displayHeaderLabels">{{ permission.label }}</ion-label>
				<ion-checkbox [checked]="permission.isChecked" [disabled]="permission.isLocked"
					(ionChange)="raisePermissionChanged(role.label, permission.label, $event)"></ion-checkbox>
			</div>
		</ng-container>
	</div>
</ng-container>