import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ICallContact } from '../../..//model/booking/ICallContact';
import { IStream } from '../../..//model/booking/IStream';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { StringHelper } from '../../../helpers/stringHelper';
import { IAppointment } from '../../../model/booking/IAppointment';
import { IBookingSession } from '../../../model/booking/IBookingSession';
import { ICall } from '../../../model/booking/ICall';
import { IContact } from '../../../model/contacts/IContact';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../services/uiMessage.service';
import { BookingService } from '../../booking/booking.service';
import { PageManagerService } from '../../routing/services/pageManager.service';
import { LiveService } from '../live.service';

declare const apiRTC; // Indispensable pour utiliser l'apiRTC importé via script js.

interface Counter {
	min: number;
	sec: number;
}

@Component({
	selector: "calao-caller",
	templateUrl: '../call/call.component.html',
	styleUrls: ['../call/call.component.scss'],
})
export class CallerComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private moConnectedSession: IBookingSession;
	private moCall: ICall;
	private moLocalStream: IStream;
	private moDefaultCounter: Counter;
	private mnCallDuration: number;

	//#endregion

	//#region PROPERTIES

	public appointmentId: string;
	public counter: Counter;
	public isCustomerView: boolean;
	public isCallStarted: boolean;

	private moCalledContact: IContact;
	public get calledContact(): IContact { return this.moCalledContact; }
	@Input() public set calledContact(poCalledContact: IContact) {
		if (poCalledContact) {
			this.moCalledContact = poCalledContact;
			this.call(true);
		}
	}
	@Input() public isVideoMuted = false;
	@Input() public isAudioMuted = false;
	@Input() public defaultRemoteImageSrc: string;
	@Input() public waitingMessage: string;

	@Output("callStarted") private readonly callStatedEventEmitter = new EventEmitter<string>();
	@Output("callFinished") private readonly callFinishedEventEmitter = new EventEmitter<string>();
	@Output("calledLeft") private readonly calledLeftEventEmitter = new EventEmitter<void>();

	//#endregion

	//#region METHODS

	constructor(
		private isvcLive: LiveService,
		private ioActivatedRoute: ActivatedRoute,
		private isvcBooking: BookingService,
		private isvcPageManager: PageManagerService,
		private isvcUiMessage: UiMessageService
	) {
		super();
	}

	public ngOnInit(): void {
		this.moConnectedSession = this.isvcLive.getSession();
		this.appointmentId = this.ioActivatedRoute.snapshot.params.appointmentId;
	}

	public override ngOnDestroy(): void {
		this.isvcLive.hangupCall(this.moCall);
	}

	/** Lance l'appel du client.
	 * @param pbAudioOnly Lance l'appel en audio uniquement si true.
	 */
	public call(pbAudioOnly: boolean): void {
		this.isvcBooking.getAppointment(this.appointmentId)
			.pipe(
				tap((poAppointment: IAppointment) => {
					const loContact: ICallContact = this.moConnectedSession.getOrCreateContact(`${this.calledContact._id}|${this.appointmentId}`);
					const loCall: ICall = loContact.call(null, { audioOnly: pbAudioOnly }); // null, { record: true } enregistre l'appel

					this.mnCallDuration = poAppointment.durationInMinutes;

					if (!StringHelper.isBlank(poAppointment.callStartDate))
						this.moDefaultCounter = this.isvcLive.generateCounter(this.mnCallDuration, new Date(poAppointment.callStartDate));

					if (loCall) {
						this.moCall = loCall;
						this.setCallListeners(loCall);
					}
					else
						console.warn("CALL.C:: Cannot establist call.");
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Initialise les événements liés à l'appel en cours
	 * @param poCall l'appel en cours.
	 */
	private setCallListeners(poCall: ICall): void {
		poCall
			.on("localStreamAvailable", (poStream: IStream) => this.onLocalStreamAvailable(poStream))
			.on("streamAdded", (poStream: IStream) => this.onStreamAdded(poStream))
			.on("streamRemoved", (poStream: IStream) => this.onStreamRemoved(poStream))
			.on("userMediaError", (poError: any) => this.onUserMediaError(poError))
			.on("hangup", () => this.onHangup());
	}

	private onLocalStreamAvailable(poStream: IStream): void {
		console.log("localStreamAvailable");

		this.moLocalStream = poStream;
		this.callStatedEventEmitter.emit(this.appointmentId);

		this.isvcLive.startTimer(this.moDefaultCounter, this.mnCallDuration)
			.pipe(
				tap((poCounter: Counter) => {
					this.counter = poCounter;

					if (poCounter.min === 0 && poCounter.sec === 0)
						this.hangupCall();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		if (this.isAudioMuted)
			this.moLocalStream.muteAudio();
		if (this.isVideoMuted)
			this.moLocalStream.muteVideo();

		this.isvcLive.addStreamInDiv(this.moLocalStream, "localStream", `local-media-${this.moLocalStream.getId()}`, { width: "100%" }, true);
		this.moLocalStream.on("stopped", () => this.onStopped());
	}

	private onStopped(): void {
		console.warn("Stream stopped");
		document.getElementById(`local-media-${this.moLocalStream.getId()}`).remove();
	}

	private onStreamAdded(poStream: IStream): void {
		this.isvcLive.addStreamInDiv(poStream, "remoteStream", `remote-media-${poStream.getId()}`, { width: "100%" }, false);
	}

	private onStreamRemoved(poStream: IStream): void {
		document.getElementById(`remote-media-${poStream.getId()}`).remove();
		this.calledLeftEventEmitter.emit();
	}

	private onUserMediaError(poError: any): void {
		console.log("CALLER.C:: userMediaError detected : ", poError);
		console.log("CALLER.C:: userMediaError detected with error : ", poError.error);
	}

	private onHangup(): void {
		this.call = null;
	}

	/** Raccrocher un appel. */
	public hangupCall(pbIsUserAction?: boolean): void {
		let loValidate$: Observable<boolean>;

		if (pbIsUserAction) {
			loValidate$ = this.isvcUiMessage.showAsyncMessage<boolean>(
				new ShowMessageParamsPopup({
					buttons: [
						{ text: "Non" },
						{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
					],
					message: "Vous allez quitter définitivement l'appel. Voulez-vous continuer?", header: "Quitter"
				})
			)
				.pipe(map((poResponse: IUiResponse<boolean>) => !!poResponse.response));
		}
		else
			loValidate$ = of(true);

		loValidate$.pipe(
			filter((pbHangup: boolean) => pbHangup),
			tap(_ => {
				this.calledLeftEventEmitter.complete();
				this.isvcLive.hangupCall(this.moCall);
				this.callFinishedEventEmitter.emit(this.appointmentId);
				this.isvcPageManager.goBack();
			}),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	/** Active ou coupe le micro. */
	public toggleMuteAudio(): void {
		if (this.isAudioMuted) {
			this.isAudioMuted = false;

			if (this.call)
				this.moLocalStream.unmuteAudio();
		}
		else {
			this.isAudioMuted = true;

			if (this.call)
				this.moLocalStream.muteAudio();
		}
	}

	/** Active ou coupe la vidéo. */
	public toggleMuteVideo(): void {
		if (this.isVideoMuted) {
			this.isVideoMuted = false;

			if (this.call)
				this.moLocalStream.unmuteVideo();
		}
		else {
			this.isVideoMuted = true;

			if (this.call)
				this.moLocalStream.muteVideo();
		}
	}

	//#endregion

}