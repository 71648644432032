import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { afterSubscribe } from '../../utils/rxjs/operators/after-subscribe';
import { ICanWaitToRoute } from '../models/ican-wait-to-route';

@Injectable()
export class WaitToNavigateGuard implements CanDeactivate<ICanWaitToRoute> {

	public canDeactivate(poComponent: ICanWaitToRoute): Observable<boolean> {
		return poComponent.routingAwaiter.ready$.pipe(afterSubscribe(() => poComponent.routingAwaiter.sendRoutingStartEvent()), take(1));
	}

}