import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DynamicPageComponent } from "@calaosoft/osapp/components/dynamicPage/dynamicPage.component";
import { FormListComponent } from "@calaosoft/osapp/components/forms/formList/formList.component";
import { ComponentBase } from "@calaosoft/osapp/helpers/ComponentBase";
import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp/model/EPrefix";
import { UserData } from "@calaosoft/osapp/model/application/UserData";
import { CustomersService } from "@calaosoft/osapp/modules/customer/services/customers.service";
import { FavoritesService } from "@calaosoft/osapp/modules/preferences/favorites/services/favorites.service";
import { PageManagerService } from "@calaosoft/osapp/modules/routing/services/pageManager.service";
import { PatternPipe } from "@calaosoft/osapp/pipes/pattern.pipe";
import { EntityLinkService } from "@calaosoft/osapp/services/entityLink.service";
import { FormsService } from "@calaosoft/osapp/services/forms.service";
import { PatternResolverService } from "@calaosoft/osapp/services/pattern-resolver.service";
import { Store } from "@calaosoft/osapp/services/store.service";
import { UiMessageService } from "@calaosoft/osapp/services/uiMessage.service";
import { Observable, of } from "rxjs";
import { CONVOYEUR_ROLE_ID, MERCHANDISEUR_ROLE_ID } from '../../../app/app.constants';
import { IMerchUserId } from '../../../model/IMerchUserId';
import { ICustomer } from '../../../model/icustomer';
@Component({
	templateUrl: 'customers.component.html',
	styleUrls: ['./customers.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersComponent extends FormListComponent<ICustomer> implements OnInit {

	//#region METHODS

	constructor(
		private readonly isvcCustomers: CustomersService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPageManager: PageManagerService,
		psvcUiMessage: UiMessageService,
		psvcForms: FormsService<ICustomer>,
		psvcEntity: EntityLinkService,
		poPatternPipe: PatternPipe,
		poRenderer: Renderer2,
		psvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRoute: ActivatedRoute,
		poRouter: Router,
		psvcStore: Store,
		psvcFavorites: FavoritesService
	) {
		super(poParentPage, psvcPageManager, psvcUiMessage, psvcForms, psvcEntity, poPatternPipe,
			poRenderer, psvcPatternResolver, poChangeDetectorRef,
			poRoute, poRouter, psvcStore, psvcFavorites);
	}

	public override ngOnInit(): void {
		// Les convoyeurs n'ont pas accès aux clients.
		if (this.isvcPermissions.hasRole(CONVOYEUR_ROLE_ID))
			this.customGetEntries = () => of([]);

		// Les merchandiseurs ont accès uniquement aux clients de leurs tournées.
		if (this.isvcPermissions.hasRole(MERCHANDISEUR_ROLE_ID))
			this.customGetEntries = () => this.getMerchCustomer();

		super.ngOnInit();
	}

	/** Filtre les clients affichés en fonction de l'identifiant du merchandiseur. */
	private getMerchCustomer(): Observable<ICustomer[]> {
		const lsUserId: string = IdHelper.buildId(EPrefix.user, UserData.current?.name);

		this.moDataSource.filter = (poCustomer: ICustomer) => {
			return ArrayHelper.hasElements(poCustomer.merchUserIds) &&
				poCustomer.merchUserIds.some((poItem: IMerchUserId) => IdHelper.buildId(EPrefix.user, poItem.id) === lsUserId);
		};

		return this.isvcCustomers.getCustomers(this.moDataSource);
	}

	//#endregion

}
