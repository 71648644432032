import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalNotificationDescriptor, LocalNotifications } from '@capacitor/local-notifications';
import { Observable, Subscription, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IApplicationEvent } from '../../../model/application/IApplicationEvent';
import { ConfigData } from '../../../model/config/ConfigData';
import { EConfigFlag } from '../../../model/config/EConfigFlag';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { ILocalNotificationExtras } from '../../../model/notification/ilocal-notification-extras';
import { EChangeType } from '../../../model/store/EChangeType';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IChangeEvent } from '../../../model/store/IChangeEvent';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { FlagService } from '../../../services/flag.service';
import { NotificationService } from '../../../services/notification.service';
import { PlatformService } from '../../../services/platform.service';
import { Store } from '../../../services/store.service';
import { BaseEvent } from '../../calendar-events/models/base-event';
import { EventsService } from '../../events/events.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { DumpApplicationEvent } from '../../store/model/dump-application-event';
import { EDumpStatus } from '../../store/model/edump-status';
import { IDumpApplicationEventData } from '../../store/model/idump-application-event-data';
import { IAlarm } from '../models/IAlarm';
import { IRemindable } from '../models/IRemindable';
import { IRemindableResolutionContext } from '../models/IRemindableResolutionContext';
import { Reminder } from '../models/Reminder';

/** Service de gestion des rappels. Doit être initialisé le plus tôt possible.
 * Ecoute en continu les changements (locaux ET distants) de certaines entités sur la base de workspace,
 *  afin de créer/détruire les notifications locales de rappel.
 * Détecte automatiquement l'application d'un dump et force la mise à jour des notifications locales dans ce cas.
 */
@Injectable({
	// On ne veut qu'une seule instance de ce service pour toute l'app
	providedIn: 'root'
})
export class RemindersService extends DestroyableServiceBase {

	//#region FIELDS

	/** Identifiant de log */
	private static readonly C_LOG_ID = "RMD.S::";
	/** Types de documents en base porteurs d'entités "Remindables".
	 * Le service va écouter les changements sur ces données pour construire les rappels au fil de l'eau */
	private static readonly C_REMINDABLES: IRemindableResolutionContext<IStoreDocument>[] = [{ prefix: EPrefix.event, baseClass: BaseEvent }];
	private static readonly C_STORAGE_LAST_REMINDER_SYSTEM_ID = "reminders.lastSystemId";
	private static readonly C_STORAGE_LAST_FULL_UPDATE = "reminders.lastFullUpdate";
	/** Version de la base IndexedDB, à incrémenter en cas de màj du schéma */
	private static readonly C_DB_VERSION = 1;
	private static readonly C_DB_NAME = "reminders";
	private static readonly C_ALARMS_STORE_NAME = "alarms";
	private static readonly C_ALARMS_INDEX_BY_ENTITY = "byEntityId";
	/** Nombre de jours sur lesquels seront générés les rappels (par défaut on génère pour 1 mois) */
	private static readonly C_MAX_GENERATION_RANGE_DAYS = 30;
	/** Fréquence de recréation des rappels (par défaut recréés tous les jours) */
	private static readonly C_MAX_REFRESH_INTERVAL_DAYS = 1;

	private remindablesChangesSubscription: Subscription | undefined;
	private moDB: IDBDatabase;
	private mbResetInProgress = false;
	private mbInitialized = false;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcFlag: FlagService,
		private readonly isvcStore: Store,
		private readonly ioRouter: Router,
		private readonly isvcEvents: EventsService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcNotifications: NotificationService
	) {
		super();
	}

	//#region INITIALISATION

	/** Initialise les rappels sur les entités supportées, pour toute l'app sur toute sa durée de vie en mémoire.
	 * DOIT ETRE APPELEE AVANT L'INITIALISATION DES BASES
	 */
	public async initAsync(): Promise<void> {
		console.debug(`${RemindersService.C_LOG_ID}Initialisation...`);

		// On attend que la config d'appli soit montée en mémoire pour choisir de démarrer ou non le service
		await this.isvcFlag.waitForFlagAsync(EConfigFlag.StaticConfigReady, true);

		// Le service ne sera démarré que sur mobile, uniquement pour les apps qui indiquent explicitement l'utiliser
		if (this.isvcPlatform.isMobileApp && ConfigData.appInfo.useReminders && !this.mbInitialized) {
			// On empêche les mutliples initialisations
			this.mbInitialized = true;

			// On initialise les notfications locales, pour bénéficier du routage à l'ouverture des notifs
			this.isvcNotifications.initLocalNotifications();

			// Détection de l'application d'un dump (lorsqu'un dump est appliqué, tous les rappels sont reconstruits d'un seul coup)
			this.isvcEvents.events$.subscribe((poApplicationEvent: IApplicationEvent) => {
				if (poApplicationEvent instanceof DumpApplicationEvent) {
					const loEventData: IDumpApplicationEventData = (poApplicationEvent as DumpApplicationEvent).data;
					if (loEventData.database.hasRole(EDatabaseRole.workspace) && loEventData.status === EDumpStatus.finished) {
						console.debug(`${RemindersService.C_LOG_ID} Dump workspace terminé`);
						// Génération initiale de tous les rappels suite à l'application d'un dump sur le workspace
						this.resetAllAlarmsAsync();
					}
				}
			});

			// On attend que la base de WS soit initialisée après login pour écouter les changements. On reste abonné "à vie" pour gérer les déconnexions/reconnexions
			this.isvcFlag.waitForFlag(EStoreFlag.DBInitialized, true).pipe(
				tap(async _ => {
					// Si la dernière génération complète des rappels est trop ancienne, on remet tout à jour
					if (this.needFullUpdate()) {
						console.debug(`${RemindersService.C_LOG_ID}Grand reset nécessaire`);
						await this.resetAllAlarmsAsync();
					}
					this.listenForRemindablesChanges();
				})
			).subscribe();

			try {
				this.moDB = await this.initIndexedDBAsync();
				console.debug(`${RemindersService.C_LOG_ID}Initialisé !`);
			}
			catch (poError) {
				console.error(`${RemindersService.C_LOG_ID}Impossible d'initialiser IndexedDB`);
			}
		}
		else {
			console.debug(`${RemindersService.C_LOG_ID}Initialisation avortée. (webapp ou application n'utilisant pas les rappels)`);
		}
	}

	private listenForRemindablesChanges(): void {
		console.debug(`${RemindersService.C_LOG_ID}Ecoute des changements sur le workspace`);

		// Cette méthode est bindée sur un évènement qu'on ne maitrise pas, si jamais elle a déjà été appelée ou libère le flux précédent pour éviter les fuites mémoire
		if (this.remindablesChangesSubscription) this.remindablesChangesSubscription.unsubscribe();

		// On crée un datasource par type de donnée "Remindable"
		let remindableChanges$: Observable<IChangeEvent<IStoreDocument>> | undefined;
		RemindersService.C_REMINDABLES.forEach(poRemindable => {
			const loRemindableDataSource: IDataSourceRemoteChanges = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				remoteChanges: true,
				live: true,
				activePageManager: new ActivePageManager(this, this.ioRouter, () => true),
				viewParams: {
					include_docs: true,
					startkey: poRemindable.prefix,
					endkey: `${poRemindable.prefix}${Store.C_ANYTHING_CODE_ASCII}`
				},
				baseClass: poRemindable.baseClass
			};

			const loChanges$ = merge(this.isvcStore.localChanges(loRemindableDataSource), this.isvcStore.changes(loRemindableDataSource));

			// On merge toutes les remontées de données dans un même flux
			if (!remindableChanges$)
				remindableChanges$ = loChanges$;
			else
				remindableChanges$ = merge(remindableChanges$, loChanges$);

		});

		this.remindablesChangesSubscription = remindableChanges$?.subscribe(this.onRemindableChange.bind(this));
	}

	//#endregion

	//#region CALLBACKS

	private onRemindableChange(poChangeEvent: IChangeEvent<IStoreDocument>): void {
		if (!this.mbResetInProgress) {
			console.debug(`${RemindersService.C_LOG_ID} Changement détecté pour ${poChangeEvent.document._id}`);

			switch (poChangeEvent.changeType) {
				case EChangeType.create:
				case EChangeType.update:
					const loRemindable: IRemindable = poChangeEvent.document as unknown as IRemindable;
					this.setAlarmsAsync(loRemindable);
					break;
				case EChangeType.delete:
					this.cancelAlarmsAsync(poChangeEvent.document._id);
					// Dans ce cas, supprimer tous les reminders liés à la donnée
					break;
				default:
					console.debug(`${RemindersService.C_LOG_ID}Changement non géré`);
			}
		}
	}

	//#endregion

	//#region INDEXEDDB

	private initIndexedDBAsync(): Promise<IDBDatabase> {
		// IndexedDB propose une API à base de callbacks, on "promessifie" pour homogénéiser le code
		let lfResolve, lfReject;
		const loReturnPromise = new Promise<IDBDatabase>((pfResolve, pfReject) => {
			lfResolve = pfResolve;
			lfReject = pfReject;
		});

		const loOpenRequest = indexedDB.open(RemindersService.C_DB_NAME, RemindersService.C_DB_VERSION);

		loOpenRequest.onupgradeneeded = () => {
			const loDb: IDBDatabase = loOpenRequest.result;

			const loChangesObjectStore: IDBObjectStore = loDb.createObjectStore(
				RemindersService.C_ALARMS_STORE_NAME,
				{ keyPath: ["entityId", "targetDate"] }
			);
			loChangesObjectStore.createIndex(RemindersService.C_ALARMS_INDEX_BY_ENTITY, "entityId");
		};

		loOpenRequest.onerror = () => lfReject(loOpenRequest.error);

		loOpenRequest.onsuccess = () => lfResolve(loOpenRequest.result);

		return loReturnPromise;
	}

	private getStoreFromDB(psStore: string): IDBObjectStore {
		// Ouverture d'une transaction vers le store des Alarmes dans IndexedDB
		const loTransaction = this.moDB.transaction(psStore, "readwrite");

		// Accès au store des Alarmes
		return loTransaction.objectStore(psStore);
	}

	private deleteAlarmsFromDBAsync(psEntityId: string): Promise<void> {
		// IndexedDB propose une API à base de callbacks, on "promessifie" pour homogénéiser le code
		let lfResolve, lfReject;
		const loReturnPromise = new Promise<void>((pfResolve, pfReject) => {
			lfResolve = pfResolve;
			lfReject = pfReject;
		});

		const loAlarmsStore = this.getStoreFromDB(RemindersService.C_ALARMS_STORE_NAME);
		// On utilise un curseur pour parcourir l'index des entités
		const loCursorRequest = loAlarmsStore.index(RemindersService.C_ALARMS_INDEX_BY_ENTITY).openKeyCursor(psEntityId);

		loCursorRequest.onsuccess = () => {
			const loCursor = loCursorRequest.result;
			if (loCursor) {
				// On supprime chaque entrée correspondant à l'id d'entité
				loAlarmsStore.delete(loCursor.primaryKey);
				loCursor.continue();
			}
			else lfResolve();
		};

		loCursorRequest.onerror = () => lfReject(loCursorRequest.error);

		return loReturnPromise;
	}

	/** Utilitaire pour rendre plus lisibles les requêtes à IndexedDB. Permet d'utiliser des promesses au lieu des callbacks. */
	private IDBRequestToPromise<T = any>(poRequest: IDBRequest<T>): Promise<T> {
		// IndexedDB propose une API à base de callbacks, on "promessifie" pour homogénéiser le code
		let lfResolve, lfReject;
		const loReturnPromise = new Promise<T>((pfResolve, pfReject) => {
			lfResolve = pfResolve;
			lfReject = pfReject;
		});

		poRequest.onsuccess = () => lfResolve(poRequest.result);
		poRequest.onerror = () => lfReject(poRequest.error);

		return loReturnPromise;
	}

	private async clearStoreInDBAsync(psStore: string) {
		await this.IDBRequestToPromise(this.getStoreFromDB(psStore).clear());
	}

	private async putAlarmInDBAsync(poAlarm: IAlarm): Promise<void> {
		// Requête d'ajout de l'Alarme en base
		await this.IDBRequestToPromise(this.getStoreFromDB(RemindersService.C_ALARMS_STORE_NAME).add(poAlarm));
	}

	private async getAlarmsFromDBAsync(psEventId: string): Promise<IAlarm[]> {
		return await this.IDBRequestToPromise(this.getStoreFromDB(RemindersService.C_ALARMS_STORE_NAME).index(RemindersService.C_ALARMS_INDEX_BY_ENTITY).getAll(psEventId));
	}

	private async getAllAlarmsFromDBAsync(): Promise<IAlarm[]> {
		return await this.IDBRequestToPromise(this.getStoreFromDB(RemindersService.C_ALARMS_STORE_NAME).getAll());
	}

	//#endregion

	//#region ALARMS

	/** Supprime toutes les notifications planifiées (s'il y en a) et crée toutes les notifications à partir des données du workspace
	 */
	private async resetAllAlarmsAsync(): Promise<void> {
		console.debug(`${RemindersService.C_LOG_ID} (Re)setting all alarms...`);
		if (!this.mbResetInProgress) {
			this.mbResetInProgress = true;
			const laSystemIdsToDelete: LocalNotificationDescriptor[] = (await this.getAllAlarmsFromDBAsync()).map(poAlarm => {
				return { id: poAlarm.systemId };
			});
			// S'il y a effectivement des notifications à déprogrammer
			if (ArrayHelper.hasElements(laSystemIdsToDelete)) {
				console.debug(`${RemindersService.C_LOG_ID} Unplanning all Alarms`);

				// Les annuler dans l'os
				LocalNotifications.cancel({ notifications: laSystemIdsToDelete });

				// Tout supprimer d'indexedDb pour cet id
				await this.clearStoreInDBAsync(RemindersService.C_ALARMS_STORE_NAME);
			}

			// Requêtage de tous les Remindables présents en base
			let remindableGet$: Observable<IRemindable[]> | undefined;
			// On crée un datasource par type de donnée "Remindable"
			RemindersService.C_REMINDABLES.forEach(poRemindable => {
				const loRemindableDataSource: IDataSource = {
					databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
					live: false,
					viewParams: {
						include_docs: true,
						startkey: poRemindable.prefix,
						endkey: `${poRemindable.prefix}${Store.C_ANYTHING_CODE_ASCII}`
					},
					baseClass: poRemindable.baseClass
				};

				const loGet$: Observable<IRemindable[]> = this.isvcStore.get(loRemindableDataSource) as unknown as Observable<IRemindable[]>;

				// On merge toutes les remontées de données dans un même flux
				if (!remindableGet$)
					remindableGet$ = loGet$;
				else
					remindableGet$ = merge(remindableGet$, loGet$);
			});

			const laRemindables: IRemindable[] | undefined = await remindableGet$?.toPromise();
			console.debug(`${RemindersService.C_LOG_ID} Tous les Remindables de la base ont été collectés`);
			laRemindables?.forEach(async (poRemindable) => { await this.setAlarmsAsync(poRemindable); });
			// Mise à jour de la date de dernière update complète
			this.setLastUpdate();
			console.debug(`${RemindersService.C_LOG_ID} All alarms setted from workspace`);
			this.mbResetInProgress = false;
		}
		else {
			console.debug(`${RemindersService.C_LOG_ID} Reset canceled`);
		}
	}

	/** (Re)crée toutes les notifications (Alarmes) pour une entité "Remindable" donnée */
	private async setAlarmsAsync(poRemindable: IRemindable): Promise<void> {
		console.debug(`${RemindersService.C_LOG_ID} Planning all reminders for : ${poRemindable.id}`);
		const laReminders: Reminder[] = poRemindable.getReminders(DateHelper.addDays(new Date(), RemindersService.C_MAX_GENERATION_RANGE_DAYS));

		// Suppression (ne fait rien s'il n'y a rien à supprimer)
		await this.cancelAlarmsAsync(poRemindable.id);

		// Création de toutes les notifications dont la date n'est pas déjà passée
		const ldNow = new Date();
		laReminders
			.filter(poReminder => poReminder.date >= ldNow)
			.forEach(async poReminder => {
				await this.scheduleAlarmAsync(poReminder);
			});
	}

	/** Permet de planifier une notification (OS) à partir d'un Reminder */
	private async scheduleAlarmAsync(poReminder: Reminder): Promise<void> {
		const lnSystemId: number = this.buildNextAlarmSystemId();
		const loExtras: ILocalNotificationExtras = {
			route: poReminder.route
		}
		LocalNotifications.schedule({
			notifications: [{
				title: poReminder.title,
				body: poReminder.description ?? "",
				id: lnSystemId,
				schedule: {
					at: poReminder.date
				},
				extra: loExtras
			}]
		});
		await this.putAlarmInDBAsync({
			entityId: poReminder.entityId,
			targetDate: poReminder.date,
			systemId: lnSystemId,
			update: new Date()
		});
	}

	private async cancelAlarmsAsync(psRemindableEntityId: string): Promise<void> {
		// Requêter les alarmes qui ont cet id d'entité en elles
		const ldNow = new Date();
		const laSystemIdsToDelete: LocalNotificationDescriptor[] = (await this.getAlarmsFromDBAsync(psRemindableEntityId))
			.filter(poAlarm => poAlarm.targetDate > ldNow) // On ne cancel dans l'os que les alarmes qui ne sont pas encore passées, pour éviter les disparitions intempestives de notifications
			.map(poAlarm => {
				return { id: poAlarm.systemId };
			});

		// S'il y a effectivement des notifications à déprogrammer
		if (ArrayHelper.hasElements(laSystemIdsToDelete)) {
			console.debug(`${RemindersService.C_LOG_ID}Reminders to unplan : ${psRemindableEntityId}`);

			// Annulation dans l'os
			await LocalNotifications.cancel({ notifications: laSystemIdsToDelete });
		}

		// Tout supprimer d'indexedDb pour cet id
		await this.deleteAlarmsFromDBAsync(psRemindableEntityId);
	}

	//#endregion

	//#region LOCALSTORAGE

	/** Unique générateur d'identifiant de notification locale. A utiliser pour chaque création de notification. */
	private buildNextAlarmSystemId(): number {
		// Obtention de l'id courant (0 par défaut s'il n'existe pas) et incrément de 1
		const lnId = (+(localStorage.getItem(RemindersService.C_STORAGE_LAST_REMINDER_SYSTEM_ID) ?? "0")) + 1;
		localStorage.setItem(RemindersService.C_STORAGE_LAST_REMINDER_SYSTEM_ID, lnId.toString());
		return lnId;
	}

	/** Permet de savoir si on a dépassé l'âge maximum admissible des rappels déjà créés */
	private needFullUpdate(): boolean {
		const lastUpdateString = localStorage.getItem(RemindersService.C_STORAGE_LAST_FULL_UPDATE);
		if (lastUpdateString)
			return new Date(lastUpdateString) < DateHelper.addDays(new Date(), (-1) * RemindersService.C_MAX_REFRESH_INTERVAL_DAYS);
		else
			return true;
	}

	/** Met à jour la date de dernière mise à jour de la totalité des rappels */
	private setLastUpdate(): void {
		localStorage.setItem(RemindersService.C_STORAGE_LAST_FULL_UPDATE, (new Date()).toISOString());
	}

	//#endregion

	//#endregion

}
