import { ObjectHelper } from "../../../../helpers/objectHelper";

export class FilesystemRemoveError extends Error {

	constructor(psFileRemoveFailed: string) {
		super(`Une erreur est survenue lors de la suppression du fichier '${psFileRemoveFailed}'`);
		// Nécessaire pour que le 'instanceof FilesystemRemoveError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemRemoveError);
	}

}