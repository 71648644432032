import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { Observable, defer, of } from 'rxjs';
import { ISearchOptions } from '../../../../model/search/ISearchOptions';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { ISelectorParams } from '../../../selector/selector/ISelectorParams';
import { FilterBarModalContentComponent } from '../filter-bar-modal-content/filter-bar-modal-content.component';

@Component({
	selector: 'calao-filter-bar-item-selector-field',
	templateUrl: './filter-bar-item-selector-field.component.html',
	styleUrls: ['./filter-bar-item-selector-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarItemSelectorFieldComponent<T = any> implements ISelectorParams<T>{

	//#region FIELDS

	/** Événement lors du changement de sélection. */
	@Output("onSelectionChanged") private readonly moSelectionChangedEvent = new EventEmitter<T[]>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public preselectedValues?: T[] | null;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "preselectedValues" })
	public readonly observablePreselectedValues = new ObservableProperty<T[]>();

	/** @implements */
	@Input() public options?: ISelectOption[] | null;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "options" })
	public readonly observableOptions = new ObservableProperty<ISelectOption[]>();

	/** @implements */
	@Input() public displayMode?: ESelectorDisplayMode;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<ESelectorDisplayMode>();

	/** @implements */
	@Input() public multiple?: boolean | string | null;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "multiple", transformer: coerceBooleanProperty })
	public readonly observableMultiple = new ObservableProperty<boolean>();

	/** @implements */
	@Input() public selectCss?: string;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "selectCss" })
	public readonly observableSelectCss = new ObservableProperty<string>();

	/** @implements */
	@Input() public searchOptions?: ISearchOptions<T>;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "searchOptions" })
	public readonly observableSearchOptions = new ObservableProperty<ISearchOptions<T>>();

	/** @implements */
	@Input() public limit?: number;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "limit" })
	public readonly observableLimit = new ObservableProperty<number>(Number.MAX_VALUE);

	/** @implements */
	@Input() public min?: number;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "min" })
	public readonly observableMin = new ObservableProperty<number>();

	/** @implements */
	@Input() public label?: string;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	/** @implements */
	@Input() public color?: string;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "color" })
	public readonly observableColor = new ObservableProperty<string>();

	/** @implements */
	@Input() public disabled?: boolean;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "disabled", transformer: coerceBooleanProperty })
	public readonly observableDisabled = new ObservableProperty<boolean>(false);

	/** @implements */
	@Input() public disabledWarningMessage?: string;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "disabledWarningMessage" })
	public readonly observableDisabledWarningMessage = new ObservableProperty<string>();

	/** @implements */
	@Input() public display?: boolean;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "display", transformer: coerceBooleanProperty })
	public readonly observableDisplay = new ObservableProperty<boolean>(true);

	/** @implements */
	@Input() public placeholder?: string;
	@ObserveProperty<FilterBarItemSelectorFieldComponent>({ sourcePropertyKey: "placeholder" })
	public readonly observablePlaceholder = new ObservableProperty<string>();

	public isVisible$: Observable<boolean> = defer(() => this.ioModal ? this.ioModal.observableIsModalOpen.value$ : of(true));

	//#endregion

	//#region METHODS

	constructor(@Optional() private readonly ioModal: FilterBarModalContentComponent) { }

	public onSelectionChanged(paValues: T[]): void {
		this.moSelectionChangedEvent.emit(paValues);
	}

	//#endregion

}
