import { Type } from "@angular/core";
import { EventParticipantAddEndEvent } from "../models/event-participant-add-end-event";
import { EventParticipantAddEvent } from "../models/event-participant-add-event";
import { EventParticipantEventBase } from "../models/event-participant-event-base";
import { IEventParticipantEventData } from "../models/ievent-participant-event-data";
import { IEventParticipantEventEndedData } from "../models/ievent-participant-event-ended-data";
import { EventParticipantTaskBase } from "./event-participant-task-base";

export class EventParticipantAddTask extends EventParticipantTaskBase {

	//#region FIELDS

	protected override startEventType: Type<EventParticipantEventBase<IEventParticipantEventData>> = EventParticipantAddEvent;
	protected override endEventType: Type<EventParticipantEventBase<IEventParticipantEventEndedData>> = EventParticipantAddEndEvent;

	//#endregion

}
