import { Exclude, Expose } from "class-transformer";
import { ObservableArray } from "../models/observable-array";

const C_LOG_ID = "OBSARRAY.DECORATOR::";

/** Observe une propriété afin de réagir à ses changements.
 * @param psSourceArrayKey Nom du tableau à observer.
 */
export function ObserveArray<T>(psSourceArrayKey: keyof T): PropertyDecorator {
	return function (poTarget: T, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<ObservableArray<any>>) {
		Exclude()(poTarget, psPropertyKey); // On exclue la propriété observable.

		const loSourceDescriptor = Object.getOwnPropertyDescriptor(poTarget, psSourceArrayKey);
		const lfGet = function () {
			return this[psPropertyKey];
		};
		const lfSet = function (poNewVal: any[]) {
			loSourceDescriptor?.set?.apply(this, [poNewVal]);

			const loObservableArray: ObservableArray<any> = this[psPropertyKey];
			if (!(loObservableArray instanceof ObservableArray))
				console.error(`${C_LOG_ID}The property '${psPropertyKey}' from '${poTarget.constructor.name}' should be an instance of ObservableArray`, loObservableArray);
			else
				loObservableArray.resetArray(poNewVal);
		};

		// On surcharge la propriété source avec les méthodes `get` et `set` personnalisées.
		Object.defineProperty(poTarget, psSourceArrayKey, { get: lfGet, set: lfSet });
		Expose()(poTarget, psSourceArrayKey as string); // On expose la propriété de base.

		return poDescriptor;
	};
}