import { Exclude } from "class-transformer";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { DocumentFields } from "./document-fields";
import { Folder } from "./folder";
import { IDocumentFields } from "./idocument-fields";
import { IFormDocument } from "./iform-document";
import { IUserStatus } from "./iuser-status";

export class FormDocument implements IFormDocument, IDocumentFields {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string | undefined;
	/** @implements */
	public _deleted?: boolean | undefined;
	/** @implements */
	public deleted?: boolean | undefined;
	/** @implements */
	public _conflicts?: string[] | undefined;
	/** @implements */
	@ResolveModel(DocumentFields)
	public $document: DocumentFields;
	/** @implements */
	@ResolveModel(Date)
	public archiveDate?: Date;
	/** @implements */
	@ResolveModel(Date)
	public restoreDate?: Date;

	/** @implements */
	public get name(): string { return this.$document.name; };
	/** @implements */
	public get authorId(): string { return this.$document.authorId; };
	/** @implements */
	public get createDate(): string { return this.$document.createDate; };
	/** @implements */
	public get paths(): string[] { return this.$document.paths; };

	@Exclude()
	/** Icône du type de fichier. */
	@Exclude()
	public icon?: string;
	@Exclude()
	/** Nom de l'auteur. */
	@Exclude()
	public authorName?: string;
	@Exclude()
	/** Type de document. */
	@Exclude()
	public type?: string;
	@Exclude()
	/** Arbre de navigation pour atteindre le document avec son 1er chemin. */
	@Exclude()
	public navigationTree: Folder[] = [];
	@Exclude()
	/** Statut de l'utilisateur par rapport au document. */
	public userStatus?: IUserStatus;

	//#endregion PROPERTIES

	//#region METHODS

	public get canArchive(): boolean {
		return this.paths.some((psPath: string) => !psPath.startsWith("trash") && !psPath.startsWith("archives"));
	}

	public get canRestore(): boolean {
		return this.paths.some((psPath: string) => psPath.startsWith("trash") || psPath.startsWith("archives"));
	}

	//#endregion METHODS

}
