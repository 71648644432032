import { ArrayHelper } from '../../../helpers/arrayHelper';
import { EPattern } from '../../../model/EPattern';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des patterns. */
export abstract class PatternsHelper {

	//#region FIELDS

	/** Caractères qui débutent un pattern. */
	public static readonly C_START_PATTERN = "{{";
	/** Caractères qui terminent un pattern. */
	public static readonly C_END_PATTERN = "}}";
	/** Regex pour tester si un pattern est présent. */
	private static readonly C_PATTERN_REGEX = new RegExp(`${PatternsHelper.C_START_PATTERN}([^{}]+)${PatternsHelper.C_END_PATTERN}`);
	private static readonly C_PATTERNS_REGEX = new RegExp(this.C_PATTERN_REGEX, "g");

	//#endregion

	//#region METHODS

	/** Crée et retourne un pattern utilisable par le résolveur de pattern à partir d'une valeur.
	 * @param pePattern Valeur du pattern qu'il faut créer.
	 */
	public static createPattern(pePattern: EPattern): string {
		return `${this.C_START_PATTERN}${pePattern}${this.C_END_PATTERN}`;
	}

	/** Vérifie que le paramètre est un string et qu'il possède un pattern ou non.
	 * @param poData Données de n'importe quel type dont il faut vérifier s'il possède un pattern (et est donc un string) ou non.
	 */
	public static hasPattern(poData: any): boolean {
		return typeof poData === "string" ? this.C_PATTERN_REGEX.test(poData) : false;
	}

	/**
	 * @param psValue ex: "cont_{{model._id}}_{{guid}}"
	 * @returns ex: ["{{model._id}}", "{{guid}}"]
	 */
	public static extractPatterns(psValue: string): string[] {
		return psValue.match(this.C_PATTERNS_REGEX) ?? [];
	}

	/**
	 * @param psPattern ex: "{{model._id}}"
	 * @returns ex: "model._id"
	 */
	public static extractPattern(psPattern: string): string | undefined {
		return ArrayHelper.getLastElement(this.C_PATTERN_REGEX.exec(psPattern));
	}

	//#endregion

}