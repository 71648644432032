import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../helpers/ComponentBase';
import { IFormDescriptor } from '../../../../../model/forms/IFormDescriptor';
import { EPersistance } from '../../model/EPersistance';
import { IDesignableDescriptor } from '../../model/IDesignableDescriptor';
import { DesignService } from '../../services/design.service';

/** Permet de charger/créer et télécharger/sauvegarder un descipteur de formulaire.
 * Seulement certains de ses paramètres seront accessibles ainsi que la définition sélectionnée qui sera éditable.
 */
@Component({
	selector: "general-setting",
	templateUrl: './general-setting.component.html',
	styleUrls: ['./general-setting.component.scss']
})
export class GeneralSettingComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public formDescriptors: IFormDescriptor[];
	public designableDescriptor: IDesignableDescriptor;
	public databaseIds: string[] = [];
	public persistanceEnum = EPersistance;

	//#endregion

	//#region METHODS

	constructor(private isvcDesign: DesignService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poDesignableDescriptor: IDesignableDescriptor) => this.designableDescriptor = poDesignableDescriptor),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet d'ouvrir un DesignableDescriptor. */
	public onPrepareDescriptor(): void {
		this.isvcDesign.prepareDescriptor(this.designableDescriptor)
			.pipe(mergeMap((poResult: boolean | string) => this.prepareResult(poResult)))
			.subscribe();
	}

	/** Permet de sauvegarder le DesignableDescriptor. */
	public onPrepareSaveDescriptor(): void {
		this.isvcDesign.prepareSaveDescriptor(this.designableDescriptor)
			.pipe(mergeMap((poResult: boolean | string) => this.prepareResult(poResult)))
			.subscribe();
	}

	/** Permet d'ouvrir une DesignableDefinition. */
	public onPrepareDefinition(): void {
		this.isvcDesign.chooseCreateOrOpenDefinition(this.designableDescriptor)
			.pipe(mergeMap((poResult: boolean | string) => this.prepareResult(poResult)))
			.subscribe();
	}

	private prepareResult(poResult: boolean | string): Observable<any> {
		const loResults$: Observable<boolean | never> = typeof poResult === "string" ? this.isvcDesign.showAlertInformation(poResult) : EMPTY;
		return loResults$.pipe(takeUntil(this.destroyed$));
	}

	/** Permet de créer ou d'éditer une listDefinition et un dataSource.
	 */
	public onPrepareListDef(): void {
		this.isvcDesign.chooseCreateOrEditListDef(this.designableDescriptor)
			.pipe(mergeMap((poResult: boolean | string) => this.prepareResult(poResult)))
			.subscribe();
	}

	//#endregion

}