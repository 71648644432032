<div *ngIf="!(observableIsOpen.value$ | async)" class="centera">
	<ion-button class="ion-no-padding btn-ctct" (click)="openProposalField()" [ngClass]="params.cssClass"
		color="action-button" slot="icon-only" [fill]="params.fill ? params.fill : 'solid'" data-cy="action-button"
		[shape]="params.shape" [disabled]="(observableIsLoading.value$ | async)">
		<ion-icon *ngIf="!(observableIsLoading.value$ | async)" class="txt24px" name="bulb-outline"></ion-icon>
		<ion-spinner *ngIf="(observableIsLoading.value$ | async)"></ion-spinner>
	</ion-button>
</div>

<input type="hidden" [ngModel]="userText" [formControl]="$any(formControl)" />

<ion-item *ngIf="(observableIsOpen.value$ | async)" fill="outline" lines="none">
	<ion-text class="ion-text-wrap">{{generatedTexts[generatedTextPosition]}}</ion-text>
	<ion-list slot="end" lines="none" text-align="center">
		<ion-item class="centera">
			<ion-button (click)="acceptProposal()">
				<ion-icon slot="icon-only" name="checkmark-outline"></ion-icon>
			</ion-button>
		</ion-item>
		<ion-item class="centera">
			<ion-button (click)="refuseProposal()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button>
		</ion-item>
		<ion-item class="centera">
			<ion-button (click)="newProposal()" [disabled]="(observableIsLoading.value$ | async)">
				<ion-icon slot="icon-only" name="refresh-outline" *ngIf="!(observableIsLoading.value$ | async)"></ion-icon>
				<ion-spinner slot="icon-only" *ngIf="(observableIsLoading.value$ | async)"></ion-spinner>
			</ion-button>
		</ion-item>
		<span *ngIf="generatedTexts.length > 1">
			<ion-item class="centera" [style.visibility]="generatedTextPosition > 0 ? 'visible' : 'hidden'"
				(click)="downTextPosition()">
				<ion-button>
					<ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
				</ion-button>
			</ion-item>
			<ion-item class="centera">
				<ion-label>{{ generatedTextPosition + 1 }}/{{ generatedTexts.length }}</ion-label>
			</ion-item>
			<ion-item class="centera"
				[style.visibility]="generatedTextPosition < generatedTexts.length - 1 ? 'visible' : 'hidden'">
				<ion-button (click)="upTextPosition()">
					<ion-icon slot="icon-only" name="arrow-forward-outline"></ion-icon>
				</ion-button>
			</ion-item>
		</span>
	</ion-list>
</ion-item>