import { RouteComponent } from '@calaosoft/osapp/model/RouteComponent';
import { ConfigOption } from '@ngx-formly/core';
import { CustomersComponent } from '../app/customers/components/customers.component';
import { ActionButtonFieldReportComponent } from '../modules/report/actionButton/actionButtonFieldReport.component';
import { SupportComponent } from '../modules/support/support.component';

export class ComComponentRegister {

	/** Liste de tous les composants de Texcom. */
	private static maComponents: RouteComponent[] = [
		{ id: 'support-page', componentType: SupportComponent },
		{ id: "customers", componentType: CustomersComponent }
	];

	/** Retourne tous les composants spécifiques de Texcom, avec leurs id. */
	public static getRouteComponents(): RouteComponent[] {
		return ComComponentRegister.maComponents;
	}
}

/** Tous les composants formly spécifiques de Texcom. */
export const FORMLY_COMPONENT_REGISTRY: ConfigOption = {
	types: [
		{ name: "actionButtonReport", component: ActionButtonFieldReportComponent }
	]
};