<div class="user-selector" (click)="openContactSelector()">
	<ng-container *ngIf="observableContactName.value$ | async as contactName; else placeholder">
		<ion-label class="contact-name">{{ contactName }}</ion-label>
	</ng-container>
	<ion-icon name="caret-down"></ion-icon>
</div>

<ng-template #placeholder>
	<ng-container *ngIf="observableParams.value$ | async as params">
		<ion-label class="placeholder">{{ params.placeholder }}</ion-label>
	</ng-container>
</ng-template>