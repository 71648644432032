import { Pipe, PipeTransform } from '@angular/core';
import { ContactHelper } from '../helpers/contactHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { IContact } from '../model/contacts/IContact';

@Pipe({ name: "contactName" })
export class ContactNamePipe implements PipeTransform {

	//#region FIELDS

	protected static readonly C_UNKNOWN_CONTACT = "Contact inconnu";

	//#endregion

	//#region METHODS

	constructor() { }

	/** Renvoie le nom complet du contact `NOM Prénom` s'il est renseigné, `Contact inconnu` sinon.
	 * @param poValue Contact dont il faut récupérer le nom complet.
	 */
	public transform(poValue?: IContact, psDefault: string = ContactNamePipe.C_UNKNOWN_CONTACT): string {
		return ObjectHelper.isNullOrEmpty(poValue) ? psDefault : ContactHelper.getCompleteFormattedName(poValue);
	}

	//#endregion

}