import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { DynHostItem } from '../../../../model/DynHostItem';
import { IIndexedArray } from '../../../../model/IIndexedArray';
import { RouteComponent } from '../../../../model/RouteComponent';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ISlideboxFieldParams } from '../../../../model/forms/ISlideboxFieldParams';
import { ISlidebox } from '../../../../model/slidebox/ISlidebox';
import { ISlideboxData } from '../../../../model/slidebox/ISlideboxData';
import { PageManagerService } from '../../../../modules/routing/services/pageManager.service';
import { FormsService } from '../../../../services/forms.service';
import { SlideboxService } from '../../../../services/slidebox.service';
import { DynHostWrapperComponent } from './dynHostWrapper.component';
import { FormListDynHostWrapperComponent } from './formListDynHostWrapper.component';

/** Champs contenant une slidebox. */
@Component({
	templateUrl: './slideboxField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideboxFieldComponent extends FieldBase<string> implements OnInit {

	//#region FIELDS

	// Dans le composant slidebox pour les formulaires on ne peux pas avoir de composant custom on doit avoir un composant de type formulaire uniquement.
	private mapStringToDynHostItem: IIndexedArray<DynHostItem> = {};

	//#endregion

	//#region PROPERTIES

	public params: ISlideboxFieldParams;
	public slideboxData: ISlideboxData;
	public componentId: string = GuidHelper.newGuid();

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des slidebox. */
		private readonly isvcSlidebox: SlideboxService,
		psvcPageManager: PageManagerService,
		/** Service de gestion du rafraîchissement de la vue. */
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);

		psvcPageManager.getRouteComponents().forEach(
			(poRouteComponent: RouteComponent) => this.mapStringToDynHostItem[poRouteComponent.id] = new DynHostItem(poRouteComponent.componentType)
		);

		this.mapStringToDynHostItem["form"] = new DynHostItem(DynHostWrapperComponent);
		this.mapStringToDynHostItem["formList"] = new DynHostItem(FormListDynHostWrapperComponent);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;

		if (StringHelper.isBlank(this.fieldValue) && !StringHelper.isBlank(this.params.defaultValue))
			this.fieldValue = this.params.defaultValue;

		this.init().subscribe();
	}

	private init(): Observable<boolean> {
		return this.isvcSlidebox.initSlideboxData(this.fieldValue, this.mapStringToDynHostItem, this.componentId)
			.pipe(
				map((poSlideboxDataResult: ISlideboxData) => {
					for (let lnIndex = 0; lnIndex < poSlideboxDataResult.slideBoxes.length; ++lnIndex) {
						const loSlidebox: ISlidebox = poSlideboxDataResult.slideBoxes[lnIndex];

						if (StringHelper.isBlank(loSlidebox.params.modelId))
							loSlidebox.params.model = this.model;
					}

					this.slideboxData = poSlideboxDataResult;
					this.detectChanges();

					return true;
				}),
				tap(
					_ => { },
					poError => console.error(`SBF.C:: Slidebox initialization failed:`, poError)
				),
				takeUntil(this.fieldDestroyed$)
			);
	}

	//#endregion
}