import { Entity } from '@calaosoft/osapp/model/entities/Entity';
import { EAvatarSize } from '@calaosoft/osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IDataSource } from '@calaosoft/osapp/model/store/IDataSource';
import { EntityBuilder } from '@calaosoft/osapp/services/EntityBuilder';
import { C_PREFIX_CUSTOMER } from '../app/app.constants';
import { ICustomer } from './icustomer';

export class CustomerEntityBuilder<T extends ICustomer> extends EntityBuilder<T>{

	//#region FIELDS

	public static readonly C_CUSTOMER_CATEGORY = "Clients";

	//#endregion FIELDS

	//#region METHODS

	constructor() {
		super(
			(psModelId: string) => new RegExp("^[0-9]{6}$").test(psModelId), // L'id d'un client est composé uniquement de 6 chiffres.
			(poClient: T) => new Entity(
				poClient,
				`clients/${poClient._id}`,
				() => `${poClient._id} ${poClient.tradingName} ${poClient.city}`
			),
			() => CustomerEntityBuilder.C_CUSTOMER_CATEGORY,
			EDatabaseRole.formsEntries,
			C_PREFIX_CUSTOMER,
			() => ({
				size: EAvatarSize.medium,
				icon: "people"
			}),
			undefined,
			{
				fields: ["tradingName", "city", "id", "tradingLogo"],
				viewParams: {
					batch_size: 200,
					startkey: "0",
					endkey: "9"
				}
			} as IDataSource<T>
		);
	}

	//#endregion METHODS
}
