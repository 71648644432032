<calao-filter-bar #filterBar [filterValues]="observableFilterValues.value$ | async"
	(onFilterValuesChange)="onFilterValuesChange($any($event))">
	<calao-filter-bar-main-content>
		<calao-filter-bar-item key="name">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un groupe" [value]="observableValue.value$ | async"
					(onValueChange)="observableValue.value = $event">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-main-content>
</calao-filter-bar>