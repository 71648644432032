import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, defer, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../helpers/ComponentBase';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { ObjectHelper } from '../../../../../helpers/objectHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { IFormDefinition } from '../../../models/IFormDefinition';
import { FormsService } from '../../../services/forms.service';

/** Crée et affiche un formulaire à partir des définitions et entrées données en paramètre. */
@Component({
	selector: "calao-formly-wrapper",
	templateUrl: 'formly-wrapper.component.html',
	styleUrls: ['./formly-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.overflow-y]": "'auto'",
		"[style.margin-bottom]": "'auto'"
	}
})
export class FormlyWrapperComponent<T = any> extends ComponentBase
	implements OnInit, OnDestroy {

	//#region PROPERTIES

	private moModel: T;
	public get model(): T { return this.moModel; }
	/** @implements */
	@Input() public set model(poValue: T) {
		this.moModel = poValue;
		// On encapsule le `detectChanges()` dans un observable afin d'éviter d'exécuter cette méthode si la vue a été détruite, sinon erreur 'viewDestroyedError'.
		defer(() => of(this.detectChanges())).pipe(takeUntil(this.destroyed$)).subscribe();
	}

	/** Définition du formulaire. */
	@Input() public formDefinition?: IFormDefinition;
	@ObserveProperty<FormlyWrapperComponent>({ sourcePropertyKey: "formDefinition" })
	public readonly observableFormDefinition = new ObservableProperty<IFormDefinition>();

	private mbReadOnly?: boolean;
	public get readOnly(): boolean { return this.mbReadOnly; }
	@Input() public set readOnly(pbValue: boolean | string) {
		this.mbReadOnly = coerceBooleanProperty(pbValue);
	};

	@Output("onModelChanged") private readonly moModelChangedEvent = new EventEmitter<T>();

	@Output("onValidityChanged") private readonly moValidityChangedEvent = new EventEmitter<boolean>();

	/* référence vers le formulaire. */
	public form: UntypedFormGroup;

	public formReady?: boolean;
	@ObserveProperty<FormlyWrapperComponent>({ sourcePropertyKey: "formReady" })
	public readonly observableFormReady = new ObservableProperty<boolean>(false);

	/** Emet un booléean lorsque le formulaire change d'état (valide/invalide) */
	public readonly formValid$: Observable<boolean> = this.observableFormReady.value$.pipe(
		switchMap(pbReady => {
			if (pbReady) {
				return this.form.statusChanges.pipe(
					startWith(this.form.valid),
					map(_ => this.form.valid),
					distinctUntilChanged()
				);
			}
			else
				return of(false);
		}),
		secure(this)
	);

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des formulaires. */
		protected readonly isvcForms: FormsService,
		/** Service de gestion des pages. */
		/** Service de gestion des popups et toasts. */
		protected readonly isvcUiMessage: UiMessageService,
		poFormBuilder: UntypedFormBuilder,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);

		this.form = poFormBuilder.group({});
	}

	/** Endroit où initialiser le composant après sa création. Initialisation du contenu du formulaire après avoir récupéré les attributs. */
	public ngOnInit(): void {
		if (!this.model)
			this.model = {} as any;

		this.observableFormDefinition.value$
			.pipe(
				filter((poFormDefinition?: IFormDefinition) => !!poFormDefinition),
				tap((poFormDefinition: IFormDefinition) => this.addReadModeOnFields(poFormDefinition.definition))
			).subscribe(() => {
				this.formReady = true;
				this.form.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
					this.clearValues(this.moModel);
					this.moModelChangedEvent.emit(this.moModel);
				});
				this.formValid$.pipe(takeUntil(this.destroyed$)).subscribe((pbValid: boolean) => {
					this.moValidityChangedEvent.emit(pbValid);
				});
			});
	}

	/** Ajout des attributs de lecture sur les options de chaque champs de façon récursive dans le cas où des groupe de champs sont présents.
	 * @param paFields Tableau de champs dans lesqeuls ajouter la valeur du mode readOnly.
	 */
	private addReadModeOnFields(paFields: Array<FormlyFieldConfig>): void {
		for (let lnIndex = paFields.length - 1; lnIndex >= 0; --lnIndex) {
			const loItem: FormlyFieldConfig = paFields[lnIndex];

			if (loItem.fieldGroup)
				this.addReadModeOnFields(loItem.fieldGroup);

			else {
				if (loItem.templateOptions) {
					if (loItem.templateOptions.data)
						loItem.templateOptions.data.readOnly = this.readOnly;
					else
						loItem.templateOptions.data = { readOnly: this.readOnly };
				}
				else
					loItem.templateOptions = { data: { readOnly: this.readOnly } };
			}
		}
	}

	/** Nettoie les chaînes de caractères et nombres non valides en les affectant à `undefined` pour ne pas sérialiser les valeurs en base de données.
 * @param poModel
 */
	private clearValues(poModel: T): void {
		Object.keys(poModel).forEach((psKey: string) => {
			if (!this.isValidValue(poModel[psKey]))
				poModel[psKey] = undefined;
		});
	}

	/** Retourne `true` si la valeur est considérée comme valide.
	 * @param poValue Valeur à vérifier si elle est valide ou non.
	 */
	private isValidValue(poValue: any): boolean {
		if (typeof poValue === "string")
			return !StringHelper.isBlank(poValue);
		else if (typeof poValue === "number")
			return NumberHelper.isValid(poValue);
		else
			return ObjectHelper.isDefined(poValue);
	}

	//#endregion

}
