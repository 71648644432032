import { Component } from '@angular/core';

@Component({
	templateUrl: "./support.component.html",
	styleUrls: ["./support.component.scss"]
})
export class SupportComponent {

	//#region METHODS

	constructor() {

	}

	//#endregion
}