import { Injectable } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { ESortOrder } from '@calaosoft/osapp/model/ESortOrder';
import { IConversation } from '@calaosoft/osapp/model/conversation/IConversation';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IDataSource } from '@calaosoft/osapp/model/store/IDataSource';
import { ModalService } from '@calaosoft/osapp/modules/modal/services/modal.service';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { ModalOptions } from '@ionic/core';
import { C_PREFIX_REPORT } from '../../../../app/app.constants';
import { IReport } from '../../../../model/IReport';
import { EReportSelection } from '../../../../model/reports/ereport-selection';
import { IJoinReportSelectorModalParams } from '../../../../model/reports/join-report-selector-modal-params';
import { IJoinReportSelectorResult } from '../../../../model/reports/join-report-selector-result';
import { JoinReportSelectorModalComponent } from '../join-report-selector-modal.component';

@Injectable()
export class JoinReportSelectorModalService {

	//#region FIELDS

	private static readonly C_LOG_ID = "JN.RPT.SLCTR.MDL.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		private readonly isvcStore: Store,
		private readonly isvcEntityLink: EntityLinkService
	) { }

	/** Affiche le sélecteur de rapport et retourne le résultat de la sélection.
	 * @param poConversation Conversation à laquelle lier le rapport.
	 * @param psCustomerId Identifiant du client pour filtrer les rapports à présenter et sélectionner.
	 */
	public async getReportSelectionResultAsync(poConversation: IConversation, psCustomerId: string): Promise<IJoinReportSelectorResult> {
		const lsNewReportKey = "newReport";
		const laNotLinkedReports: IReport[] = await this.getNotLinkedReportsAsync(psCustomerId, await this.getLinkedReportsAsync(poConversation));
		const lsSelectedReportId: string | undefined = await this.openReportSelectorModalAsync(laNotLinkedReports, lsNewReportKey);

		return this.getSelectionResult(lsNewReportKey, laNotLinkedReports, lsSelectedReportId);
	}

	private getLinkedReportsAsync(poConversation: IConversation): Promise<IReport[]> {
		return this.isvcEntityLink.getLinkedEntities<IReport>(poConversation, C_PREFIX_REPORT)
			.toPromise();
	}

	private getNotLinkedReportsAsync(psCustomerId: string, paLinkedReports: IReport[]): Promise<IReport[]> {
		const loDataSource: IDataSource<IReport> = {
			role: EDatabaseRole.formsEntries,
			viewParams: {
				include_docs: true,
				startkey: `${C_PREFIX_REPORT}${psCustomerId}`,
				endkey: `${C_PREFIX_REPORT}${psCustomerId}${Store.C_ANYTHING_CODE_ASCII}`,
			}
		};

		return this.isvcStore.get(loDataSource)
			.toPromise()
			.then((paReports: IReport[]) => // On ne conserve que les rapports qui ne sont pas liés à la conversation.
				paReports.filter((poReport: IReport) => paLinkedReports.every((poLinkedReport: IReport) => poLinkedReport._id !== poReport._id))
			);
	}

	private openReportSelectorModalAsync(paReports: IReport[], psNewReportKey: string): Promise<string | undefined> {
		const loComponentParams: IJoinReportSelectorModalParams = {
			newReportKey: psNewReportKey,
			title: "Lier un rapport à la conversation",
			reports: ArrayHelper.dynamicSort(paReports, "createDate", ESortOrder.descending) // Tri par date de création décroissant.
		};
		const loOptions: ModalOptions = {
			component: JoinReportSelectorModalComponent,
			componentProps: loComponentParams
		};

		return this.isvcModal.open<string>(loOptions).toPromise();
	}

	private getSelectionResult(psNewReportKey: string, paReports: IReport[], psReportId: string | undefined): IJoinReportSelectorResult {
		if (StringHelper.isBlank(psReportId)) // Annulation de liaison.
			return { state: EReportSelection.cancelation };

		else if (psReportId === psNewReportKey) // Création d'un rapport.
			return { state: EReportSelection.creation };

		else if (psReportId.startsWith(C_PREFIX_REPORT)) // Ouverture d'un rapport.
			return { state: EReportSelection.selection, report: paReports.find((poReport: IReport) => poReport._id === psReportId) };

		else { // Cas non gérés.
			console.error(`${JoinReportSelectorModalService.C_LOG_ID}Response from report selection '${psReportId}' is not recognized. Cancelling action.`);
			return { state: EReportSelection.cancelation };
		}
	}

	//#endregion METHODS

}