import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ConfigData } from "../../../../../model/config/ConfigData";
import { ShowMessageParamsPopup } from "../../../../../services/interfaces/ShowMessageParamsPopup";
import { PlatformService } from "../../../../../services/platform.service";
import { UiMessageService } from "../../../../../services/uiMessage.service";
import { ModalComponentBase } from "../../../../modal";
import { EJitsiButton } from "../../models/Ejitsi-button";
import { JitsiOptions } from "../../models/IJitsi-options";

declare const JitsiMeetExternalAPI: any;

@Component({
	selector: "jitsi",
	templateUrl: 'jitsi-modal.component.html',
	styleUrls: ["jitsi-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitsiModalComponent extends ModalComponentBase<void> implements OnInit, OnDestroy {

	private static readonly C_LOG_ID = "JM.C::";

	@Input() public name: string;
	@Input() public room: string;

	private moOptions: JitsiOptions;

	private readonly msDomain = ConfigData.conversation?.visioApiUrl;

	constructor(
		private readonly moElRef: ElementRef,
		ioModalCtrl: ModalController,
		private readonly isvcUiMessage: UiMessageService,
		psvcPlatform: PlatformService) {
		super(ioModalCtrl, psvcPlatform);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		// Permissions sur téléphone
		this.getCameraAndMicAccessAsync().then(_ => {
			// La réference html en params
			const loElement: Element | null = document.querySelector('#meeting');
			if (loElement) {
				this.setOptions(loElement);
			};

			// Crée l'iframe
			new JitsiMeetExternalAPI(this.msDomain, this.moOptions);
		}).catch(poError => {
			console.error(`${JitsiModalComponent.C_LOG_ID}Permissisons mic et vid denied.`, poError);
			this.isvcUiMessage.showPopupMessageAsync(new ShowMessageParamsPopup({
				header: "Erreur", message: "Impossible de passer un appel si vous refusez les permissions.", buttons: [
					{ text: "Fermer", handler: () => this.close() }
				]
			}));
		});
	}

	private getCameraAndMicAccessAsync(): Promise<MediaStream> {
		return navigator.mediaDevices.getUserMedia({ video: true, audio: true });
	}

	public setOptions(poElement: Element): void {
		const loWindownView: any = this.moElRef.nativeElement.ownerDocument.defaultView;

		this.moOptions = {
			parentNode: poElement,
			roomName: this.room,
			width: loWindownView.innerWidth,
			// *0.94 pour laisser la place au boutton "quitter la visio"
			height: loWindownView.innerHeight * 0.94,
			configOverwrite: {
				prejoinPageEnabled: false,
				prejoinConfig: {
					enabled: false,
				},

				// Specify the settings for video quality optimizations on the client.
				videoQuality: {
					minHeightForQualityLvl: {
						150: 'standard',
						300: 'high',
					},
				},
				maxFullResolutionParticipants: 3,
				enableLobbyChat: false,
				LANG_DETECTION: false,
				defaultLanguage: 'fr',
				hideAddRoomButton: true,
				enableWelcomePage: false,
				disableShortcuts: true,
				enableClosePage: false,
				defaultLocalDisplayName: 'Moi',
				toolbarButtons: [
					EJitsiButton.camera,
					EJitsiButton.microphone,
					EJitsiButton.togglecamera,
				],
				rtcstatsEnabled: true,
				rtcstatsStoreLogs: true,
				hideLogo: true,
				// Sets the conference subject
				subject: 'CalaoLive',

				// Sets the conference local subject
				// localSubject: 'Conference Local Subject',
				disableDeepLinking: true,
				SHOW_JITSI_WATERMARK: false,
			},
			interfaceConfigOverwrite: {
				DEFAULT_BACKGROUND: '#141414',
				DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
				DISABLE_FOCUS_INDICATOR: true,
				defaultLanguage: 'fr',
				LANG_DETECTION: false,
				prejoinPageEnabled: false,
				ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 1,
				disableDeepLinking: true,
				MOBILE_APP_PROMO: false,
				SHOW_JITSI_WATERMARK: false,
				HIDE_DEEP_LINKING_LOGO: true,
				SHOW_BRAND_WATERMARK: false,
				SHOW_WATERMARK_FOR_GUESTS: false
			},
			disabledSounds: [],
			userInfo: {
				displayName: this.name,
			},
		};
	}

}
