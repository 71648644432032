import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-list-item-end',
	templateUrl: './list-item-end.component.html',
	styleUrls: ['./list-item-end.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.align-items]": "'center'"
	}
})
export class ListItemEndComponent { }
