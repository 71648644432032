import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { BlankInformationModule } from '../blank-information/blank-information.module';
import { FormModule } from '../forms/form/form.module';
import { ListsModule } from '../lists/lists.module';
import { SpinnerModule } from '../spinner';
import { TabsModule } from '../tabs/tabs.module';
import { EntityEntriesListComponent } from './components/entity-entries-list/entity-entries-list.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { LayoutComponent } from './components/layout/layout.component';
import { StackLayoutComponent } from './components/stack-layout/stack-layout.component';
import { TabLayoutComponent } from './components/tab-layout/tab-layout.component';

const components: Array<Type<any> | any[]> = [
	TabLayoutComponent,
	LayoutComponent,
	StackLayoutComponent,
	EntityEntriesListComponent,
	EntityFormComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TabsModule,
		IonicModule,
		ListsModule,
		CalaoAvatarModule,
		PipeModule,
		FormModule,
		SpinnerModule,
		BlankInformationModule
	]
})
export class EntitiesModule { }
