import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IConversation } from '../../../../model/conversation/IConversation';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { ICreateConversationSelectorModalComponentParams } from './models/icreate-conversation-selector-modal-component-params';

@Component({
	selector: 'calao-create-conversation-selector-modal',
	templateUrl: './create-conversation-selector-modal.component.html',
	styleUrls: ['./create-conversation-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateConversationSelectorModalComponent extends ModalComponentBase<string> implements ICreateConversationSelectorModalComponentParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public newConversationKey: string;
	/** @implements */
	@Input() public title: string;
	/** @implements */
	@Input() public conversations: IConversation[];

	/** Valeur du bouton radio sélectionné. */
	public selectedRadioValue: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	//#endregion METHODS
}
