import { DateHelper } from "../../../helpers/dateHelper";
import { NumberHelper } from "../../../helpers/numberHelper";
import { DefaultValue } from "../../utils/models/decorators/default-value.decorator";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { IRange } from "../../utils/models/models/irange";
import { EEventOccurrenceActionType } from "./eevent-occurrence-action-type";
import { EventDuration } from "./event-duration";
import { EventOccurrenceAction } from "./event-occurrence-action";
import { IEventOccurrence } from "./ievent-occurrence";
import { IEventOccurrenceAction } from "./ievent-occurrence-action";
import { IEventOccurrenceDelayAction } from "./ievent-occurrence-delay-action";

const C_TYPE = EEventOccurrenceActionType.delay;

@ModelMatch((poData: IEventOccurrenceAction) => poData?.type === C_TYPE, EventOccurrenceAction)
export class EventOccurrenceDelayAction extends EventOccurrenceAction implements IEventOccurrenceDelayAction {

	//#region PROPERTIES

	public override readonly type: EEventOccurrenceActionType = C_TYPE;

	@ResolveModel(EventDuration)
	@DefaultValue(() => new EventDuration)
	public delay: EventDuration;

	public delayInPast?: boolean;

	//#endregion

	//#region METHODS

	constructor(poAction?: Partial<IEventOccurrenceDelayAction>) {
		super(poAction);
	}

	public override action(poEventOccurrence: IEventOccurrence): void {
		if (poEventOccurrence.startDate) {
			if (this.delayInPast)
				poEventOccurrence.startDate = this.delay.removeDurationToDate(poEventOccurrence.startDate);
			else
				poEventOccurrence.startDate = this.delay.addDurationToDate(poEventOccurrence.startDate);
		}
	}

	public setDelay(pdFrom: Date, pdTo: Date): void {
		this.delay.minutes = DateHelper.diffMinutes(pdTo, pdFrom);
	}

	public override buildlabel(): string {
		const lbIsPositive: boolean = NumberHelper.isValidStrictPositive(this.delay.days) ??
			NumberHelper.isValidStrictPositive(this.delay.hours) ??
			NumberHelper.isValidStrictPositive(this.delay.minutes);
		const lsMoveLabel: string = lbIsPositive ? "avancée(s) de " : "reculée(s) de ";

		const laDelaysLabel: string[] = [];
		const pnDelayDays: number = Math.abs(this.delay.days);
		if (pnDelayDays > 0)
			laDelaysLabel.push(`${pnDelayDays} jour${pnDelayDays > 1 ? "s" : ""}`);

		const pnDelayHours: number = Math.abs(this.delay.hours);
		if (pnDelayHours > 0)
			laDelaysLabel.push(`${pnDelayHours} heure${pnDelayHours > 1 ? "s)" : ""}`);

		const pnDelayMinutes: number = Math.abs(this.delay.minutes);
		if (pnDelayMinutes > 0)
			laDelaysLabel.push(`${pnDelayMinutes} minute${pnDelayMinutes > 1 ? "s" : ""}`);

		const lsDelayLabel: string = laDelaysLabel.map((psDelayLabel: string, pnIndex: number) => {
			let lsSeparator: string;

			if (pnIndex + 1 === laDelaysLabel.length)
				lsSeparator = ".";
			else if (pnIndex + 2 === laDelaysLabel.length)
				lsSeparator = " et ";
			else
				lsSeparator = ", ";

			return psDelayLabel + lsSeparator;
		}).join("");

		return lsMoveLabel + lsDelayLabel;
	}

	public override actionOnRange(poRange: IRange<Date>): IRange<Date> {
		if (poRange.from)
			poRange.from = this.delay.removeDurationToDate(poRange.from);
		if (poRange.to)
			poRange.to = this.delay.addDurationToDate(poRange.to);

		return super.actionOnRange(poRange);
	}

	//#endregion
}
