import { UserData } from '../model/application/UserData';
import { IContact } from '../model/contacts/IContact';
import { EPrefix } from '../model/EPrefix';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { ArrayHelper } from './arrayHelper';
import { IdHelper } from './idHelper';
import { StringHelper } from './stringHelper';

export abstract class UserHelper {

	//#region METHODS

	/** Retourne l'id de l'utilisateur. */
	public static getUserId(): string {
		if (UserData.current)
			return UserData.current._id;
		else
			throw new NoCurrentUserDataError();
	}

	/** Retourne le guid de l'identifiant de l'utilisateur.
	 * @param psUserId Identifiant de l'utilisateur.
	 */
	public static getUserGuid(psUserId: string): string {
		return ArrayHelper.getLastElement(psUserId?.split("_"));
	}

	/** Retourne l'identifiant du contact de l'utilisateur courant. */
	public static getUserContactId(psUserId?: string): string {
		if (!StringHelper.isBlank(psUserId) || UserData.current)
			return IdHelper.buildId(EPrefix.contact, UserHelper.getUserGuid(StringHelper.isBlank(psUserId) ? UserData.current.name : psUserId));
		else
			throw new NoCurrentUserDataError();
	}

	/** Retourne `true` si le contact est un utilisateur, `false` sinon.
	 * @param poContact Contact dont il faut vérifier s'il est ou non un utilisateur.
	 */
	public static isUser<T extends IContact>(poContact?: T): boolean {
		return !StringHelper.isBlank(poContact?.userId);
	}

	/** Retourne `true` si le contact est le contact de l'utilisateur courant, `false` sinon.
	 * @param poContact Contact dont il faut vérifier s'il est l'utilisateur courant.
	 */
	public static isCurrentUserContact<T extends IContact>(poContact?: T): boolean {
		return poContact?.userId === UserData.current?.name;
	}

	/** Retourne l'id du site courant. */
	public static getCurrentSiteId(): string {
		if (UserData.currentSite)
			return UserData.currentSite._id;
		else
			throw new NoCurrentUserDataError();
	}

	//#endregion
}