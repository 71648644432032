import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { UserHelper } from '../../../../helpers/user.helper';
import { IContact } from '../../../../model/contacts/IContact';
import { IGroup } from '../../../../model/contacts/IGroup';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { ContactsService } from '../../../../services/contacts.service';
import { GroupsService } from '../../../../services/groups.service';
import { IApplicationRole } from '../../../../services/security/IApplicationRole';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EPermission } from '../../../permissions/models/EPermission';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';

@Component({
	selector: 'calao-contacts-list',
	templateUrl: './contacts-list.component.html',
	styleUrls: ['./contacts-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors d'un clic sur un item d'un contact. */
	@Output("onContactClicked") private readonly moOnContactClicked = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton supprimer d'un item d'un contact. */
	@Output("onDeleteContactClicked") private readonly moOnDeleteContactClickedEmitter = new EventEmitter<IContact>();
	/** Événement lors d'un clic sur le bouton editer d'un item d'un contact. */
	@Output("onEditContactClicked") private readonly moOnEditContactClickedEmitter = new EventEmitter<IContact>();

	private readonly maRoles: IApplicationRole[];

	private readonly moObservableGroupsByContactId = new ObservableProperty<Map<string, IGroup[]>>(new Map());


	//#endregion FIELDS

	//#region PROPERTIES

	/** Contacts à afficher. */
	@Input() public contacts?: IContact[];
	@ObserveArray<ContactsListComponent>("contacts")
	public readonly observableContacts = new ObservableArray<IContact>();

	public get canEditContact(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermission.contacts, "edit");
	}

	public get canDeleteContact(): boolean {
		return this.isvcPermissions.evaluatePermission(EPermission.contacts, "delete");
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly isvcPermissions: PermissionsService
	) {
		super();

		this.maRoles = isvcPermissions.getPermissionRoles();
	}

	public ngOnInit(): void {
		this.observableContacts.changes$.pipe(
			map((paContacts: IContact[]) => ArrayHelper.hasElements(paContacts) ? this.moveUserContactToFirst(paContacts) : paContacts),
			switchMap((paContacts: IContact[]) => {
				if (ArrayHelper.hasElements(paContacts)) {
					return this.isvcGroups.getContactsGroups(paContacts, true).pipe(
						tap((poGroupsByContactId: Map<string, IGroup[]>) => {
							this.moObservableGroupsByContactId.value = poGroupsByContactId;
						}),
					);
				}
				else
					return of(undefined);
			}),
			secure(this)
		).subscribe();
	}

	private moveUserContactToFirst(paContacts: IContact[]): IContact[] {
		const lnUserContactIndex: number = paContacts.findIndex((poContact: IContact) => this.isUserContact(poContact));
		if (lnUserContactIndex) {
			ArrayHelper.moveElement(paContacts, lnUserContactIndex, 0);
		}
		return paContacts;
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poContact
	 */
	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big, true);
	}

	private getRolesName(paGroups?: IGroup[]): string {
		const laRoleLabels: string[] = [];
		paGroups?.forEach((poGroup: IGroup) => {
			poGroup.roles?.forEach((psRoleId: string) => {
				const loRole: IApplicationRole | undefined = this.maRoles.find((poRole: IApplicationRole) => poRole.id === psRoleId);
				if (loRole && !laRoleLabels.includes(loRole.label)) {
					laRoleLabels.push(loRole.label);
				}
			});
		});
		return laRoleLabels.join(', ');
	}

	/** Récupère la concaténation des noms des groupes en paramètre, chaîne vide s'il n'y en a pas.
	 * @param paGroups Tableau des groupes à concaténer.
	 */
	private getGroupsName(paGroups?: IGroup[]): string {
		return paGroups ?
			paGroups.filter((poGroup: IGroup) => !ArrayHelper.hasElements(poGroup.roles))
				.map((poGroup: IGroup) => poGroup.name).join(", ") :
			"";
	}

	public isUserContact(poContact: IContact): Boolean {
		return UserHelper.getUserContactId() === poContact._id;
	}

	/** Retourne la concaténation des noms des groupes du contact passé en paramètre.
	 * @param poContact Le contact associé aux groupes.
	 */
	public getGroupslabel$(poContact: IContact): Observable<string> {
		return this.moObservableGroupsByContactId.value$.pipe(
			map((poGroupsByContactId: Map<string, IGroup[]>) => {
				if (poGroupsByContactId.has(poContact._id)) {
					const laGroups: IGroup[] = this.moObservableGroupsByContactId.value.get(poContact._id ?? "");
					const lsRolesName: string = this.getRolesName(laGroups);
					const lsGroupsName: string = this.getGroupsName(laGroups);
					return `${lsRolesName}${!StringHelper.isBlank(lsRolesName) && !StringHelper.isBlank(lsGroupsName) ? ", " : ""}${lsGroupsName}`;
				}
				else
					return "";
			})
		);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	/** Emet l'évènement au clic sur l'item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onContactClicked(poContact: IContact): void {
		this.moOnContactClicked.emit(poContact);
	}

	/** Emet l'évènement au clic sur le bouton supprimer d'un item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onDeleteContactClicked(poContact: IContact): void {
		this.moOnDeleteContactClickedEmitter.emit(poContact);
	}

	/** Emet l'évènement au clic sur le bouton éditer d'un item d'un contact.
	 * @param poContactLe Le contact cliqué.
	 */
	public onEditContactClicked(poContact: IContact): void {
		this.moOnEditContactClickedEmitter.emit(poContact);
	}

	//#endregion METHODS

}
