import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { ETaskPrefix } from '../../../../model/backgroundTask/ETaskPrefix';
import { ISaveLinksTaskParams } from '../../../../model/backgroundTask/taskParams/ISaveLinksTaskParams';
import { IContact } from '../../../../model/contacts/IContact';
import { IContactCacheData } from '../../../../model/contacts/IContactCacheData';
import { IGroup } from '../../../../model/contacts/IGroup';
import { IGroupSaveLinksItem } from '../../../../model/contacts/IGroupSaveLinksItem';
import { IGroupSelection } from '../../../../model/contacts/IGroupSelection';
import { IGroupsChecklistParams } from '../../../../model/contacts/IGroupsChecklistParams';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ERouteUrlPart } from '../../../../model/route/ERouteUrlPart';
import { EPermission } from '../../../../modules/permissions/models/EPermission';
import { TCRUDPermissions } from '../../../../modules/permissions/models/tcrud-permissions';
import { PermissionsService } from '../../../../modules/permissions/services/permissions.service';
import { BackgroundTaskService } from '../../../../services/backgroundTask.service';
import { TaskDescriptor } from '../../../../services/backgroundTask/TaskDescriptor';
import { FormsService } from '../../../../services/forms.service';
import { GroupsService } from '../../../../services/groups.service';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../../services/uiMessage.service';

@Component({
	templateUrl: './groups-checklist-field.component.html',
	styleUrls: ['./groups-checklist-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsChecklistFieldComponent extends FieldBase<IGroup> implements OnInit {

	//#region FIELDS

	/** Tableau des groupes sélectionnés. */
	private maGroupSelections: IGroupSelection<IGroup>[] = [];
	/** Url de la page courante. */
	private msCurrentUrl: string;
	/** Indique si les groupes présélectionnés sont initialisés. */
	private mbPreselectedGroupsInit: boolean;

	//#endregion

	//#region PROPERTIES

	/** Paramètres possibles pour le composant. */
	public params: IGroupsChecklistParams<IGroup>;
	/** Indique si l'utilisateur a les droits de création/édition avec les groupes ou non. */
	public hasPermission: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly isvcUiMessageService: UiMessageService,
		private readonly isvcBackgroundTask: BackgroundTaskService,
		private readonly isvcPermissions: PermissionsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRouter: Router
	) {
		super(psvcForms, poChangeDetectorRef);

		this.msCurrentUrl = poRouter.url;
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		if (!this.params.model)
			this.params.model = this.model as IContact;

		this.initHasPermission().pipe(
			tap((pbHasPermissions: boolean) => {
				this.hasPermission = pbHasPermissions;
				this.detectChanges();
			})
		)
			.subscribe();

		this.initData().pipe(
			tap((paResults: IGroup[]) => {
				this.params.data = paResults;
				this.detectChanges();
			})
		)
			.subscribe();

		this.saveChanges().subscribe();
	}

	/** Initialise le jeu de permissions de l'utilisateur et le retourne, retourne un observable vide si la page courante n'est pas une création ou édition. */
	private initHasPermission(): Observable<boolean | never> {
		let loInitPermission$: Observable<boolean | never>;

		if (this.msCurrentUrl.includes(ERouteUrlPart.new) || this.msCurrentUrl.includes(ERouteUrlPart.edit)) {
			const lsPermissionType: TCRUDPermissions = this.msCurrentUrl.includes(ERouteUrlPart.new) ? "create" : "edit";
			loInitPermission$ = of(this.isvcPermissions.evaluatePermission(EPermission.contacts, lsPermissionType));
		}
		else
			loInitPermission$ = EMPTY;

		return loInitPermission$.pipe(takeUntil(this.fieldDestroyed$));
	}

	/** Initialise les groupes et les retourne, retourne un observable vide s'il n'y a pas de groupe. */
	private initData(): Observable<IGroup[] | never> {
		let loInitData$: Observable<IGroup[] | never>;

		if (!ArrayHelper.hasElements(this.params.groupIds)) {
			if (this.params.readOnly) // Si mode visu et pas d'identifiants de groupes précisés, on récupère ceux en lien avec le modèle courant.
				loInitData$ = this.isvcGroups.getContactGroups(this.model as IContact, true);

			else if (ArrayHelper.hasElements(this.params.roles)) // Si mode edit et rôles précisés, on récuère par rôles.
				loInitData$ = this.isvcGroups.getGroupsByRoles(this.params.roles, true);

			else // Si mode edit et pas d'identifiants de groupes précisés, on les récupère tous.
				loInitData$ = this.isvcGroups.getGroups(true);
		}
		else
			loInitData$ = EMPTY;

		return loInitData$.pipe(takeUntil(this.fieldDestroyed$));
	}

	/** Enregistre tous les changements en base de données. */
	private saveChanges(): Observable<void | never> {
		let loSaveChanges$: Observable<void | never>;

		if (!this.params.readOnly && !this.params.model?._id.startsWith(EPrefix.site))
			loSaveChanges$ = this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit).pipe(map(_ => this.addGroupLinksTask()));
		else
			loSaveChanges$ = EMPTY;

		return loSaveChanges$.pipe(takeUntil(this.fieldDestroyed$));
	}

	public onSelectionChanged(paSelections: IGroupSelection<IGroup>[]): void {
		if (this.required) // Ne pas oublier de supprimer le champ du modèle lors de la sauvegarde si on utilise des liens.
			this.fieldValue = paSelections.find((poSelection: IGroupSelection<IGroup>) => poSelection.selected)?.group;
		this.maGroupSelections = paSelections.filter((poSelection: IGroupSelection<IGroup>) => !poSelection.disabled);
		StoreHelper.updateDocumentCacheData(this.model, { groupsIds: this.maGroupSelections.filter((poSelection: IGroupSelection<IGroup>) => poSelection.selected).map((poSelection: IGroupSelection<IGroup>) => poSelection.group._id) } as IContactCacheData);
		if (this.mbPreselectedGroupsInit)
			this.markAsDirty();
		this.mbPreselectedGroupsInit = true;
	}

	public onClick(): void {
		if (!this.params.readOnly && !this.hasPermission) // Si l'utilisateur n'a pas la permission adéquate, on affiche un toast le signalant.
			this.isvcUiMessageService.showMessage(new ShowMessageParamsToast({ message: "Vous n'avez pas les droits nécessaires pour modifier cet élément." }));
	}

	/** Crée une tâche de fond pour mettre à jour les liens entre le modèle et les groupes sélectionnés. */
	private addGroupLinksTask(): void {
		this.isvcBackgroundTask.addTask(
			new TaskDescriptor<ISaveLinksTaskParams<IGroupSaveLinksItem>>(
				{
					id: IdHelper.buildId(ETaskPrefix.saveLinks, this.model._id),
					name: "Enregistrement des entités liées au modèle",
					taskType: "GroupSaveLinksTask",
					params: {
						items: this.maGroupSelections.map((poSelection: IGroupSelection<IGroup>) => this.createGroupSaveLinksItem(poSelection))
					} as ISaveLinksTaskParams<IGroupSaveLinksItem>,
					enableTaskPersistance: true
				})
		);
	}

	/** Crée et retourne un élément de paramètres pour créer une tâche de mise à jour des liens entre le modèle et les groupes sélectionnés. */
	private createGroupSaveLinksItem(poSelection: IGroupSelection<IGroup>): IGroupSaveLinksItem {
		const laOldContactIds: string[] = poSelection.contacts.map((poContact: IContact) => poContact._id);
		const laNewContactIds: string[] = Array.from(laOldContactIds);

		if (poSelection.selected)
			laNewContactIds.push(this.model._id);
		else
			ArrayHelper.removeElement(laNewContactIds, this.model._id);

		return {
			groupId: poSelection.group._id,
			oldMemberIds: laOldContactIds,
			newMemberIds: laNewContactIds
		} as IGroupSaveLinksItem;
	}

	//#endregion
}