import { Exclude, Expose } from "class-transformer";
import { IdHelper } from "../../../helpers/idHelper";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { EPrefix } from "../../../model/EPrefix";
import { StoreDocument } from "../../store/model/store-document";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { EEventParticipationStatus } from "./eevent-participation-status";
import { EventParticipationOccurrence } from "./event-participation-occurrence";
import { IEventParticipation } from "./ievent-participation";

export class EventParticipation extends StoreDocument implements IEventParticipation {

	//#region PROPERTIES

	/** @implements */
	public previousRev?: string;
	@Exclude()
	private msContactId: string;
	/** @implements */
	@Expose({ toPlainOnly: true })
	public get contactId(): string {
		if (StringHelper.isBlank(this.msContactId))
			this.msContactId = EventParticipation.extractContactId(this._id);

		return this.msContactId;
	}
	@Exclude()
	private msEventId: string;
	/** @implements */
	@Expose({ toPlainOnly: true })
	public get eventId(): string {
		if (StringHelper.isBlank(this.msEventId))
			this.msEventId = EventParticipation.extractEventId(this._id);

		return this.msEventId;
	}
	/** @implements */
	@ResolveModel(Date)
	public startDate: Date;
	/** @implements */
	@ResolveModel(Date)
	public endDate: Date;
	/** @implements */
	public place: string;
	/** @implements */
	public participationStatus: EEventParticipationStatus;
	/** @implements */
	@ResolveModel(Date)
	public participationStatusDate: Date;
	/** @implements */
	@ResolveModel(EventParticipationOccurrence)
	public occurrences: EventParticipationOccurrence[] = [];

	//#endregion

	//#region METHODS

	constructor(poData?: Partial<IEventParticipation>) {
		super();
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	public static extractEventId(psId: string): string {
		return IdHelper.extractIdWithPrefix(psId, EPrefix.event);
	}

	public static extractContactId(psId: string): string {
		return IdHelper.extractIdWithPrefix(psId, EPrefix.contact);
	}

	public static buildId(psEventId: string, psContactId: string): string {
		return IdHelper.buildChildId(
			EPrefix.eventParticipation,
			IdHelper.buildVirtualNode([psEventId, psContactId]),
			""
		);
	}

	//#endregion

}
