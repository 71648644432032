import { ArrayHelper } from '../../helpers/arrayHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { StringHelper } from '../../helpers/stringHelper';

/** Classe permettant de gérer les numéros de versions et les transformer du format 'X.Y.Z', au format attendu sur CouchDB 'X.YY.ZZZ'. */
export class Version {

	//#region FIELDS

	public static readonly C_UNKNOWN_VERSION = "unknown";

	/** Retourne la version 0 de l'application : 0.00.000. */
	public static readonly zeroVersion: Version = new Version(0, 0, 0);

	/** Retourne la version max de l'application : 999.999.999. */
	public static readonly maxVersion: Version = new Version(999, 999, 999);

	//#endregion

	//#region PROPERTIES

	/** Première partie de l'identifiant de version. */
	private mnMajor?: number;
	/** Deuxième partie de l'identifiant de version. */
	private mnMinor?: number;
	/** Dernière partie de l'identifiant de version. */
	private mnPatch?: number;
	private mbUnknown?: boolean;

	//#endregion

	//#region METHODS

	public constructor();
	public constructor(pnMajor: number, pnMinor: number, pnPatch: number);
	public constructor(pnMajor?: number, pnMinor?: number, pnPatch?: number) {
		if (NumberHelper.isValid(pnMajor) && NumberHelper.isValid(pnMinor) && NumberHelper.isValid(pnPatch)) {
			this.mnMajor = pnMajor;
			this.mnMinor = pnMinor;
			this.mnPatch = pnPatch;
		}
		else
			this.mbUnknown = true;
	}

	/** Transforme la version de l'app du format 'X.Y.Z' vers le format 'X.YY.ZZZ' correspondant au formattage sur les bases de données.
	 * @param psVersion chaîne de caractères correspondant à la version de l'app au format 'X.Y.Z', optionnel après une première utilisation.
	 */
	public static fromString(psVersion?: string): Version {
		if (StringHelper.isBlank(psVersion))
			return new Version;

		if (psVersion === Version.C_UNKNOWN_VERSION)
			return new Version;

		const laVersionFragments: string[] = psVersion.split('.');

		return new Version(+laVersionFragments[0], +laVersionFragments[1], +laVersionFragments[2]);
	}

	/** Extrait la version d'un id si elle est présente et la retourne, retourne undefined si l'id ne contient pas de version.
	 * @param psId id dont on veut extraire le numéro de version, s'il est présent.
	 */
	public static fromDescriptorId(psDescriptorId: string): Version {
		return /_v[0-9]+\.[0-9]+\.[0-9]+/.test(psDescriptorId) ? Version.fromString(ArrayHelper.getLastElement(psDescriptorId.split("_v"))) : undefined;
	}

	/** Détermine le 1er et le dernier (startkey/endkey) ID de descripteur de composant à recherche en fonction de la version d'application active.
	 * @param psDescriptorId ID de descripteur de composant incluant ou non un numéro de version.
	 * @param psAppVersion Version active de l'application.
	 * @returns Une paire de valeur min/max où min=max si un ID incluant un n° version est indiqué,
	 * sinon min=ID du component en version minimale (0.0.0), max=ID de composant en version maximale admissible (version d'application).
	*/
	public static getDescriptorVersionRange(psDescriptorId: string, psAppVersion: string): IDescriptorVersionRange {
		const loDescriptorVersion: Version = Version.fromDescriptorId(psDescriptorId);
		const lsExtractedVersion: string = loDescriptorVersion ? loDescriptorVersion.toFormattedString() : null;

		if (!lsExtractedVersion) // Pas de version dans le formDescId => résolution
			return {
				minVersion: `${psDescriptorId}_v${Version.zeroVersion.toFormattedString()}`,
				maxVersion: `${psDescriptorId}_v${Version.fromString(psAppVersion).toFormattedString()}`
			};
		else {
			return { minVersion: psDescriptorId, maxVersion: psDescriptorId };
		}
	}

	/** Retourne la version majeure. Correspond au premier chiffre de la version. */
	public getMajor(): number {
		return this.mnMajor;
	}

	/** Retourne la version mineure. Correspond au deuxième chiffre de la version. */
	public getMinor(): number {
		return this.mnMinor;
	}

	/** Retourne la version du patch. Correspond au dernier chiffre de la version. */
	public getPatch(): number {
		return this.mnPatch;
	}

	/** Retourne la version majeure. Correspond au 'X' du 'X.YY.ZZZ'. */
	private getFormattedMajor(): string {
		return this.mnMajor.toString();
	}

	/** Retourne la version mineure. Correspond au 'YY' du 'X.YY.ZZZ'. */
	private getFormattedMinor(): string {
		return this.mnMinor.toString().padStart(2, '0');
	}

	/** Retourne la version du patch. Correspond au 'ZZZ' du 'X.YY.ZZZ'. */
	private getFormattedPatch(): string {
		return this.mnPatch.toString().padStart(3, '0');
	}

	/** Retourne 1 si la version courante est supérieure à la version passée en paramètre. -1 si elle est inférieure, 0 si elles sont égales.
	 * @param poOther Version à comparer.
	 */
	public compareTo(poOther: Version): number {
		// Comparaison de la version majeure.
		if (this.getMajor() > poOther.getMajor())
			return 1;

		if (this.getMajor() < poOther.getMajor())
			return -1;

		// Comparaison de la version mineure.
		if (this.getMinor() > poOther.getMinor())
			return 1;

		if (this.getMinor() < poOther.getMinor())
			return -1;

		// Comparaison de la version du patch.
		if (this.getPatch() > poOther.getPatch())
			return 1;

		if (this.getPatch() < poOther.getPatch())
			return -1;

		// Même version.
		return 0;
	}

	/**
	 * Retourne un string au format 'X.YY.ZZZ'.
	 */
	public toFormattedString(): string {
		if (this.mbUnknown)
			return Version.C_UNKNOWN_VERSION;

		return `${this.getFormattedMajor()}.${this.getFormattedMinor()}.${this.getFormattedPatch()}`;
	}

	/**
	 * Retourne un string au format 'X.Y.Z'.
	 */
	public toString = (): string => this.mbUnknown ? Version.C_UNKNOWN_VERSION : `${this.mnMajor}.${this.mnMinor}.${this.mnPatch}`

	//#endregion

}

export interface IDescriptorVersionRange {
	minVersion: string
	maxVersion: string
}