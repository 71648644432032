import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, forwardRef } from '@angular/core';
import { ItemSizeCalculator } from '../models/item-size-calculator';
import { VariableSizeVirtualScrollStrategy } from '../models/variable-size-virtual-scroll-strategy';

function factory(dir: VariableSizeDirective) {
	return dir.scrollStrategy;
}

@Directive({
	selector: 'cdk-virtual-scroll-viewport[calaoVariableSize]',
	providers: [{
		provide: VIRTUAL_SCROLL_STRATEGY,
		useFactory: factory,
		deps: [forwardRef(() => VariableSizeDirective)]
	}],
})
export class VariableSizeDirective implements OnChanges {

	//#region PROPERTIES

	public readonly scrollStrategy: VariableSizeVirtualScrollStrategy;

	@Input() public items: any[] = [];
	@Input() public itemSizeCalculator = new ItemSizeCalculator<any>();

	//#endregion

	constructor(
		private poElementRef: ElementRef,
		private poChangeDetectorRef: ChangeDetectorRef
	) {
		this.scrollStrategy = new VariableSizeVirtualScrollStrategy(this.items, this.itemSizeCalculator);
	}

	public ngOnChanges() {
		this.scrollStrategy.updateItemHeights(this.items, this.itemSizeCalculator);
		this.poChangeDetectorRef.detectChanges();
	}

}
