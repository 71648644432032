
import { ContactHelper } from '../../helpers/contactHelper';
import { DataSourceHelper } from '../../helpers/dataSourceHelper';
import { EntityBuilder } from '../../services/EntityBuilder';
import { IContact } from '../contacts/IContact';
import { EPrefix } from '../EPrefix';
import { EAvatarSize } from '../picture/EAvatarSize';
import { EDatabaseRole } from '../store/EDatabaseRole';
import { ContactEntityGuard } from './ContactEntityGuard';
import { Entity } from './Entity';

export class ContactEntityBuilder extends EntityBuilder<IContact>{
	constructor() {
		super(
			(psModelId: string) => RegExp(EPrefix.contact).test(psModelId),
			(poContact: IContact) => {
				return new Entity(
					poContact,
					`contacts/${poContact._id}`,
					() => ContactHelper.getCompleteFormattedName(poContact.lastName, poContact.firstName),
					DataSourceHelper.getDatabaseId(this.dataSource, poContact)
				);
			},
			(psModelId: string) => "Contacts",
			EDatabaseRole.contacts,
			EPrefix.contact,
			() => ({
				size: EAvatarSize.medium,
				icon: "person"
			}),
			(poContact: IContact) => ({
				size: EAvatarSize.medium,
				guid: poContact.picture && poContact.picture.guid ? poContact.picture.guid : null,
				data: poContact.picture && poContact.picture.base64 ? poContact.picture.base64 : null,
				icon: "person"
			}),
			undefined,
			undefined,
			new ContactEntityGuard()
		);
	}
}
