import { DateHelper } from "../../../helpers/dateHelper";

export class Reminder {

	/** Date à laquelle le rappel devra être présenté à l'utilisateur */
	get date() {
		return DateHelper.addMinutes(this.referenceDate, (-1) * this.minutes);
	}

	constructor(
		/** Entité sur laquelle porte le rappel */
		public entityId: string,
		public referenceDate: Date,
		public minutes: number,
		public title: string,
		public description?: string | undefined,
		/** Route de vers l'entité sur laquelle porte le rappel */
		public route?: string | undefined) { }
}