import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VersionsService } from './services/versions.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [VersionsService]
})
export class VersionsModule { }