import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { Observable, merge } from 'rxjs';
import { map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { TabsSegmentComponent } from '../tabs-segment/tabs-segment.component';

@Component({
	selector: 'calao-tabs-segments',
	templateUrl: './tabs-segments.component.html',
	styleUrls: ['./tabs-segments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsSegmentsComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChildren(TabsSegmentComponent) private moSegments: QueryList<TabsSegmentComponent>;

	//#endregion

	//#region PROPERTIES

	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);
	public readonly activeTabValue$: Observable<string> = this.observableActiveTabIndex.value$.pipe(
		map((pnIndex: number) => `${pnIndex}`),
		secure(this)
	);

	//#endregion

	//#region METHODS

	public ngAfterContentInit(): void {
		this.moSegments.changes.pipe(
			startWith({}),
			switchMap(() => merge(...this.moSegments.map(
				(poItem: TabsSegmentComponent, pnIndex: number) => {
					poItem.index = pnIndex;
					return poItem.segmentClickedEvent.pipe(mapTo(pnIndex));
				}
			))),
			tap((pnIndex: number) => this.observableActiveTabIndex.value = pnIndex),
			secure(this)
		).subscribe();

		this.observableActiveTabIndex.value$.pipe(
			tap(() => {
				this.moSegments.forEach((poItem: TabsSegmentComponent) => {
					poItem.detectChanges();
				})
			}),
			secure(this)
		).subscribe();
	}

	//#endregion

}
