import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchModule } from '@calaosoft/osapp/components/search/search.module';
import { CalaoAvatarModule } from '@calaosoft/osapp/modules/avatar/calao-avatar.module';
import { CustomerModule } from '@calaosoft/osapp/modules/customer/customer.module';
import { CustomersService } from '@calaosoft/osapp/modules/customer/services/customers.service';
import { SpinnerModule } from '@calaosoft/osapp/modules/spinner/spinner.module';
import { PipeModule } from '@calaosoft/osapp/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { CustomersComponent } from './components/customers.component';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		SearchModule,
		SpinnerModule,
		CalaoAvatarModule,
		PipeModule,
		CustomerModule
	],
	exports: [],
	declarations: [CustomersComponent],
	providers: [CustomersService]
})
export class CustomersModule { }