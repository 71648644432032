<div class="content-wrapper">
	<div class="content">
		<calao-folder-list [currentPath]="observableCurrentPath.value$ | async"
			[rootPath]="observableRootPath.value$ | async" [displayMode]="observableDisplayMode.value$ | async"
			(onPathChanged)="onPathChanged($event)" (onOptionClicked)="onOptionClickedAsync($event)"
			(onOpenClicked)="openDocumentAsync($event)"
			(onDisplayModeChanged)="onDisplayModeChanged($event)"></calao-folder-list>

		<ion-fab *ngIf="observableCanCreate.value$ | async" vertical="bottom" horizontal="end" slot="fixed"
			class="fab-margin fab-list-text">
			<ion-fab-button class="fab-shadow" (click)="onAddDocumentClickedAsync()">
				<ion-icon name="add"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	</div>
</div>