import { ModuleWithProviders, NgModule } from '@angular/core';
import { WaitToNavigateGuard } from './guards/wait-to-navigate.guard';
import { IPageManagerConfig } from './models/ipage-manager-config';
import { PAGE_MANAGER_CONFIG, PageManagerService } from './services/pageManager.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		WaitToNavigateGuard,
		PageManagerService,

	]
})
export class RoutingModule {

	public static forRoot(poPageManagerConfig: IPageManagerConfig): ModuleWithProviders<RoutingModule> {
		return {
			ngModule: RoutingModule,
			providers: [
				{ provide: PAGE_MANAGER_CONFIG, useValue: poPageManagerConfig }
			]
		};
	}

}