import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BlobUrlManager } from '../../../components/image/model/BlobUrlManager';

@Pipe({
	name: 'avatarSanitizer'
})
export class AvatarSanitizerPipe implements PipeTransform, OnDestroy {

	//#region FIELDS

	private moBlobUrlManager = new BlobUrlManager;

	//#endregion

	//#region METHODS

	constructor(private ioSanitizer: DomSanitizer) { }

	/** Transforme la valeur reçue pour l'afficher.
	 * @param poValue Valeur à transformer/vérifier.
	 * @param psMimeType Type mime de l'image
	 */
	public transform(poValue: Blob | string, psMimeType: string): SafeUrl | undefined {
		if (!poValue || (!(poValue instanceof Blob) && typeof poValue !== "string"))
			return undefined;

		const lsValueToSanitize: string = poValue instanceof Blob ?
			this.moBlobUrlManager.createUrl(poValue) : (poValue.indexOf('.') > 0 || poValue.indexOf("data:") !== -1) ?
				poValue : `data:${psMimeType};base64,${poValue}`;

		return this.ioSanitizer.bypassSecurityTrustUrl(lsValueToSanitize);
	}

	public ngOnDestroy(): void {
		this.moBlobUrlManager.releaseUrls();
	}

	//#endregion

}
