import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { EAction } from '../../../model/EAction';
import { ENameConsole } from '../../../model/ENameConsole';
import { IDesignableDefinition } from '../../../model/IDesignableDefinition';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

/** Permet d'éditer la définition sélectionnée et d'accéder aux paramètres d'un champ. Ils seront visibles dans le WidgetSetting. */
@Component({
	selector: "form-editor",
	templateUrl: './form-editor.component.html',
	styleUrls: ['./form-editor.component.scss']
})
export class FormEditorComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public designableDescriptor: IDesignableDescriptor;
	public designableDefinition: IDesignableDefinition;
	public actionEnum = EAction;

	//#endregion

	//#region METHODS

	constructor(
		private isvcDesign: DesignService,
		private ioRouter: Router,
		private ioRoute: ActivatedRoute,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poDescriptor: IDesignableDescriptor) => {
					this.designableDescriptor = poDescriptor;

					if (this.designableDescriptor.designableDefinitions)
						this.designableDefinition = this.designableDescriptor.designableDefinitions.find(
							(poDefinition: IDesignableDefinition) => poDefinition.id === this.designableDescriptor.selectedDefinitionId);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet d'agir sur un champ choisi de la définition.
	 * @param peAction Action choisie.
	 * @param psKey Clé du champ sélectionné.
	 * @param pnIndex Index du champ dans la définition.
	 * @param pbUp Booléen indiquant la direction du mouvement.
	 */
	public onSelectedFieldTo(peAction: EAction, psKey: string, pnIndex?: number, pbUp?: boolean): void {
		switch (peAction) {
			case EAction.edit:
				if (this.designableDescriptor.selectedField && this.designableDescriptor.selectedField.key === psKey)
					this.isvcDesign.editSelectedField(this.designableDescriptor, null, false);
				else
					this.isvcDesign.selectFieldToEdit(this.designableDescriptor, psKey);
				break;

			case EAction.delete:
				this.isvcDesign.deleteFieldToSelectedDefinition(this.designableDescriptor, psKey);
				break;

			case EAction.move:
				const lnNewIndex: number = pbUp ? pnIndex - 1 : pnIndex + 1;
				this.isvcDesign.editSelectedField(this.designableDescriptor, null, false);
				this.isvcDesign.moveFieldInSelectedDefinition(this.designableDescriptor, psKey, lnNewIndex);
				break;

			default:
				console.warn(`${ENameConsole.formEditor}Unknown action.`);
				break;
		}
	}

	/** Permet d'agir sur la défintion.
	 * @param peAction
	 */
	public onSelectedDefintionTo(peAction: EAction): void {
		let loAction$: Observable<boolean>;

		switch (peAction) {

			case EAction.clear:
				loAction$ = this.selectedDefinitionToClear();
				break;

			case EAction.delete:
				loAction$ = this.selectedDefinitionToDelete();
				break;

			case EAction.test:
				if (!StringHelper.isBlank(this.designableDescriptor.selectedDefinitionId)) {
					this.isvcDesign.prepareEntryToTestDefinition(this.designableDescriptor);
					this.ioRouter.navigate(["preview"], { relativeTo: this.ioRoute });
				}
				break;

			default:
				console.warn(`${ENameConsole.formEditor}Unknown action.`);
				break;
		}

		if (loAction$)
			loAction$.pipe(takeUntil(this.destroyed$)).subscribe();
	}

	private selectedDefinitionToClear(): Observable<boolean> {
		if (!StringHelper.isBlank(this.designableDescriptor.selectedDefinitionId))
			return this.isvcDesign.showAlertInformation(this.isvcDesign.clearSelectedDefinition(this.designableDescriptor));
		else
			return EMPTY;
	}

	private selectedDefinitionToDelete(): Observable<boolean> {
		if (!StringHelper.isBlank(this.designableDescriptor.selectedDefinitionId))
			return this.isvcDesign.showAlertInformation(this.isvcDesign.deleteDesignableDefinition(this.designableDescriptor));
		else
			return EMPTY;
	}

	//#endregion

}