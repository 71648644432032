export enum ETaskPrefix {

	dbSync = "sync_db_",
	dbInit = "init_db_",
	dmsSync = "sync_dms_",
	heartbeat = "heartbeat_change_",
	checkHashExpiration = "check_hash_expiration_",
	flushLog = "log_flush_",
	routePage = "route_page_",
	savePurchase = "save_purchase_",
	saveLinks = "save_lnk_",
	eventParticipantInvitation = "evtPartAdd_",
	eventParticipantRemove = "evtPartRem_",
	eventParticipantUpdate = "evtPartUp_"
}