import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ConfigData } from '../../../model/config/ConfigData';
import { IConversationFormConfig } from '../../../model/config/IConversationFormConfig';
import { EGalleryCommand } from '../../../model/gallery/EGalleryCommand';
import { IGalleryCommand } from '../../../model/gallery/IGalleryCommand';

@Component({
	selector: "attachments-popover",
	templateUrl: './attachmentsPopover.component.html',
	styleUrls: ['./attachmentsPopover.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsPopoverComponent extends ComponentBase {

	//#region PROPERTIES

	@Input() public galleryCommandSubject: Subject<IGalleryCommand>;

	public readonly formConfigs: ReadonlyArray<IConversationFormConfig> = ConfigData.conversation?.forms ?? [];

	private mbAreFormsVisible = false;
	public get areFormsVisible(): boolean { return this.mbAreFormsVisible; }
	public set areFormsVisible(pbValue: boolean) {
		if (pbValue !== this.mbAreFormsVisible) {
			this.mbAreFormsVisible = pbValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(private readonly ioPopoverCtrl: PopoverController, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public takePicture(): void {
		this.galleryCommandSubject.next({ type: EGalleryCommand.takePicture });
		this.ioPopoverCtrl.dismiss();
	}

	public pickFile(): void {
		this.galleryCommandSubject.next({ type: EGalleryCommand.pickFile });
		this.ioPopoverCtrl.dismiss();
	}

	public pickImage(): void {
		this.galleryCommandSubject.next({ type: EGalleryCommand.pickImage });
		this.ioPopoverCtrl.dismiss();
	}

	public showForms(): void {
		this.areFormsVisible = !this.areFormsVisible;
	}

	public openForm(poFormConfig: IConversationFormConfig): void {
		this.ioPopoverCtrl.dismiss(poFormConfig);
	}

	//#endregion

}