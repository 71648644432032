<ion-list>
	<ion-list-header>
		<ion-label class="support-title">Calaosoft</ion-label>
	</ion-list-header>
	<ion-item>
		<ion-label>ZAC Les Ancises</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>03300 CREUZIER-LE-NEUF</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>Tél: +334 70 59 59 73</ion-label>
	</ion-item>
	<br />
	<ion-button expand="full" href="tel:+33470595973">Appelez-nous</ion-button>
</ion-list>