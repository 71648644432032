export abstract class PathHelper {

	//#region FIELDS

	private static readonly C_PARSE_REGEX = new RegExp(`\\\\`, "g");
	private static readonly C_PREPARE_REGEX = new RegExp(`/`, "g");

	//#endregion FIELDS

	//#region METHODS

	/** Traduit un chemin sauvegardé pour pouvoir le manipuler plus facilement.
	 * @param psPath
	 */
	public static parsePath(psPath: string): string {
		return psPath.replace(PathHelper.C_PARSE_REGEX, "/");
	}

	/** Met en forme un chemin avant sauvegarde.
	 * @param psPath
	 */
	public static preparePath(psPath: string): string {
		return psPath.replace(PathHelper.C_PREPARE_REGEX, "\\");
	}

	//#endregion

}
