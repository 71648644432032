import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { OsappError } from '../../../errors/model/OsappError';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EOrientation } from '../../models/eorientation.enum';
import { ItemSizeCalculator } from '../../models/item-size-calculator';


@Component({
	selector: 'calao-virtual-scroll',
	templateUrl: './virtual-scroll.component.html',
	styleUrls: ['./virtual-scroll.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-grow]": "'1'"
	}
})
export class VirtualScrollComponent<T> extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {

	//#region FIELDS

	private static readonly C_DEFAULT_MAX_HEIGHT = "60vh";
	private static readonly C_DEFAULT_MARGIN_PX = 80;

	private readonly moHasHeightSubject = new BehaviorSubject<boolean>(false);
	private readonly moDivStyleSubject = new BehaviorSubject<CSSStyleDeclaration | undefined>(undefined);
	private readonly moSlidingItemsSubject = new ReplaySubject<QueryList<IonItemSliding>>(1);

	/** Événement lors clic sur le bouton 'charger plus'. */
	@Output("onLoadMoreClicked") private readonly moLoadMoreClickedEvent = new EventEmitter<void>();

	private moMutationObserver: MutationObserver;
	private moIntersectionObserver: IntersectionObserver;
	private moVirtualScrollScrolledIndexChangedSubscription: Subscription;

	//#endregion

	//#region PROPERTIES

	@ContentChild(TemplateRef) public readonly templateRef: TemplateRef<any>;

	private moItems: ObservableArray<T>;
	public get displayedItems(): ObservableArray<T> { return this.moItems; }
	@Input() public set items(paNewItems: T[]) {
		if (!this.moItems) {
			if (paNewItems) {
				this.moItems = paNewItems instanceof ObservableArray ? paNewItems : new ObservableArray(paNewItems);
				this.initDivStyleObservable();
				this.detectChanges();
			}
		}
		else
			this.moItems.resetArray(paNewItems);
	}

	private moItemSizeCalculator: ItemSizeCalculator<any> | undefined;
	public get itemSizeCalculator(): ItemSizeCalculator<any> | undefined { return this.moItemSizeCalculator; }
	@Input() public set itemSizeCalculator(poItemSizeCalculator: ItemSizeCalculator<any> | undefined) {
		if (poItemSizeCalculator && poItemSizeCalculator !== this.moItemSizeCalculator) {
			this.moItemSizeCalculator = poItemSizeCalculator;
			this.detectChanges();
		}
	}

	private mnItemSize: number;
	public get itemSize(): number { return this.mnItemSize; }
	@Input() public set itemSize(pnItemSize: number) {
		const lnItemSize: number = coerceNumberProperty(pnItemSize);
		if (NumberHelper.isValid(lnItemSize) && lnItemSize !== this.mnItemSize) {
			this.mnItemSize = lnItemSize;
			this.updateStyle();
			this.detectChanges();
		}
	}

	private msMaxHeight: string;
	public get maxHeight(): string { return this.msMaxHeight ?? VirtualScrollComponent.C_DEFAULT_MAX_HEIGHT; }
	@Input() public set maxHeight(poMaxHeight: string) {
		if (poMaxHeight !== this.msMaxHeight) {
			this.msMaxHeight = poMaxHeight;
			this.detectChanges();
		}
	}

	private meOrientation: EOrientation;
	public get orientation(): EOrientation { return this.meOrientation ?? EOrientation.vertical; }
	@Input() public set orientation(peOrientation: EOrientation) {
		if (peOrientation !== this.meOrientation) {
			this.meOrientation = peOrientation;
			this.updateStyle();
			this.detectChanges();
		}
	}

	private mnMaxBufferPx: number;
	public get maxBufferPx(): number { return this.mnMaxBufferPx; }
	@Input() public set maxBufferPx(pnMaxBufferPx: number) {
		if (pnMaxBufferPx !== this.mnMaxBufferPx) {
			this.mnMaxBufferPx = pnMaxBufferPx;
			this.detectChanges();
		}
	}

	private mnMinBufferPx: number;
	public get minBufferPx(): number { return this.mnMinBufferPx; }
	@Input() public set minBufferPx(pnMinBufferPx: number) {
		if (pnMinBufferPx !== this.mnMinBufferPx) {
			this.mnMinBufferPx = pnMinBufferPx;
			this.detectChanges();
		}
	}

	private mbTransparent: boolean;
	public get transparent(): boolean { return this.mbTransparent; }
	@Input() public set transparent(pbTransparent: boolean | string) {
		const lbTransparent: boolean = coerceBooleanProperty(pbTransparent);
		if (lbTransparent !== this.mbTransparent) {
			this.mbTransparent = lbTransparent;
			this.detectChanges();
		}
	}

	private mbAutosize: boolean;
	public get autosize(): boolean { return this.mbAutosize; }
	@Input() public set autosize(pbAutosize: boolean | string) {
		const lbAutosize: boolean = coerceBooleanProperty(pbAutosize);
		if (lbAutosize !== this.mbAutosize) {
			this.mbAutosize = lbAutosize;
			this.initMaxHeightFromComponentRef();
		}
	}

	private mbMargeLastItem: boolean;
	public get margeLastItem(): boolean { return this.mbMargeLastItem; }
	@Input() public set margeLastItem(pbMargeLastItem: boolean | string) {
		const lbMargeLastItem: boolean = coerceBooleanProperty(pbMargeLastItem);
		if (lbMargeLastItem !== this.mbMargeLastItem) {
			this.mbMargeLastItem = lbMargeLastItem;
			this.detectChanges();
		}
	}

	/** Taille du cache utilisé pour stocker les composants. */
	@Input() public templateCacheSize: number;
	@ObserveProperty<VirtualScrollComponent<T>>({ sourcePropertyKey: "templateCacheSize", transformer: (pnTemplateCacheSize: number) => coerceNumberProperty(pnTemplateCacheSize, 20) })
	public readonly observableTemplateCacheSize = new ObservableProperty<number>(20);

	/** Doc propriété. */
	@Input() public hasLoadMoreButton?: boolean | string;
	@ObserveProperty<VirtualScrollComponent<T>>({ sourcePropertyKey: "hasLoadMoreButton", transformer: (poNewValue: any) => coerceBooleanProperty(poNewValue) })
	public readonly observableHasLoadMoreButton = new ObservableProperty<boolean>();

	/** Texte du bouton chager plus. */
	@Input() public loadMoreButtonText?: string;
	@ObserveProperty<VirtualScrollComponent<T>>({ sourcePropertyKey: "loadMoreButtonText" })
	public readonly observableLoadMoreButtonText = new ObservableProperty<string>("Afficher plus de résultats");

	/** Doc propriété. */
	@Input() public template?: TemplateRef<any>;
	@ObserveProperty<VirtualScrollComponent<T>>({ sourcePropertyKey: "template" })
	public readonly observableTemplate = new ObservableProperty<TemplateRef<any>>();

	private moViewport: CdkVirtualScrollViewport;
	public get viewport(): CdkVirtualScrollViewport { return this.moViewport; }
	@ViewChild("viewportId", { read: CdkVirtualScrollViewport }) public set viewport(poViewport: CdkVirtualScrollViewport) {
		if (poViewport !== this.moViewport) {
			this.moViewport = poViewport;

			this.initCloseAllSlidingItems();

			this.moHasHeightSubject.next(this.viewportHasHeight());
			this.moMutationObserver = new MutationObserver(() => this.moHasHeightSubject.next(this.viewportHasHeight()));
			this.moMutationObserver.observe(this.viewport.elementRef.nativeElement, { attributeFilter: ["opacity", "display", "visibility"] });
			this.moIntersectionObserver = new IntersectionObserver(() => this.moHasHeightSubject.next(this.viewportHasHeight()), { threshold: [0, 0.25, 0.5, 0.75, 1] });
			this.moIntersectionObserver.observe(this.viewport.elementRef.nativeElement);

			this.detectChanges();
		}
	}

	//! C'est angular qui vient set directement cette valeur, à ne pas supprimer.
	@ContentChildren(IonItemSliding, { read: IonItemSliding, descendants: true }) private set slidingItems(poSlidingItems: QueryList<IonItemSliding>) {
		this.moSlidingItemsSubject.next(poSlidingItems);
	}

	public readonly divStyle$: Observable<CSSStyleDeclaration | undefined> = this.moDivStyleSubject.asObservable();

	/** Taille calculée à partir du nombre d'item et de la taille d'un item. */
	public get dynamicHeight(): number { return this.moItems?.length * this.itemSize; }

	public get viewportHeight(): string | undefined {
		if (!this.viewport)
			return undefined;

		return `${this.viewport?.elementRef.nativeElement.clientHeight}px`;
	}

	/** Taille du composant. */
	public get elementHeight(): number { return this.ioElementRef.nativeElement?.offsetHeight; }

	public get marginBottom(): string { return `${VirtualScrollComponent.C_DEFAULT_MARGIN_PX}px`; }

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef, private readonly ioElementRef: ElementRef) {
		super(poChangeDetector);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moIntersectionObserver?.disconnect();
		this.moMutationObserver?.disconnect();
		this.moHasHeightSubject.complete();
		this.moDivStyleSubject.complete();
		this.moSlidingItemsSubject.complete();
		this.moVirtualScrollScrolledIndexChangedSubscription?.unsubscribe();
	}

	public ngOnInit(): void {
		if (!NumberHelper.isValid(this.itemSize) && !this.itemSizeCalculator)
			throw new OsappError("Le paramètre 'itemSize' doit être renseigné.");
	}

	public override ngAfterViewInit(): void {
		this.initMaxHeightFromComponentRef();
	}


	@HostListener("window:resize")
	private initMaxHeightFromComponentRef(): void {
		if (this.autosize) {
			this.updateStyle();
			this.detectChanges();
			this.viewport?.checkViewportSize();
		}
	}

	private viewportHasHeight(): boolean {
		return this.viewport?.elementRef.nativeElement.clientHeight > 0;
	}

	private initDivStyleObservable(): void {
		let laPreviousValues: [number, boolean] = [0, false];

		combineLatest([
			this.moItems.length$.pipe(distinctUntilChanged()),
			this.moHasHeightSubject.asObservable()])
			.pipe(
				filter((paValues: [number, boolean]) => {
					if (paValues[1] && (laPreviousValues[0] !== paValues[0] || laPreviousValues[1] !== paValues[1])) {
						this.updateStyle();
						setTimeout(() => this.viewport.checkViewportSize()); // On utilise le setTimeout pour permettre à l'UI de recevoir son nouveau style avant le refresh.
					}

					laPreviousValues = paValues;

					return paValues[1];
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private updateStyle(): void {
		if (this.orientation === EOrientation.vertical) {
			const lnDynamicHeight: number = this.dynamicHeight;

			if (!this.autosize)
				this.moDivStyleSubject.next({ height: `${lnDynamicHeight}px`, maxHeight: this.viewportHeight } as CSSStyleDeclaration);
		}
	}

	public scrollToIndex(pnIndex: number, psBehavior?: ScrollBehavior): void {
		return this.moViewport.scrollToIndex(pnIndex, psBehavior);
	}

	private initCloseAllSlidingItems(): void {
		if (this.moVirtualScrollScrolledIndexChangedSubscription)
			this.moVirtualScrollScrolledIndexChangedSubscription.unsubscribe();

		this.moVirtualScrollScrolledIndexChangedSubscription = this.moViewport.scrolledIndexChange.pipe(
			takeUntil(this.destroyed$),
			switchMap(() => this.moSlidingItemsSubject.asObservable()),
			tap((poSlidingItems: QueryList<IonItemSliding>) => poSlidingItems.forEach((poItem: IonItemSliding) => poItem.close()))
		).subscribe();
	}

	/** Indique si on doit appliquer la marge de fin de liste.
	 * @param pbLast
	 */
	public hasToMargeLastItem(pbLast: boolean): boolean {
		return this.autosize && pbLast && this.margeLastItem && (this.elementHeight - VirtualScrollComponent.C_DEFAULT_MARGIN_PX) < this.dynamicHeight;
	}

	public onLoadMoreClicked(): void {
		this.moLoadMoreClickedEvent.emit();
	}

	//#endregion

}