/** Pour les identifiants de logs spécifiques à une lib ou une app, mettre le préfixe adéquat à la place de `calao`. */
export enum ELogActionId {
	/** Identifiant de l'action pour le démarrage de l'application. */
	appStart = "calao-app-start",
	/** Identifiant de l'action pour la détection d'une nouvelle mise à jour. */
	appUpdateAvailable = "calao-app-update-available",
	/** Identifiant de l'action pour l'installation d'une nouvelle mise à jour. */
	appUpdateStart = "calao-app-update-start",
	/** Identifiant de l'action quand la nouvelle mise à jour a été instalée. */
	appUpdateComplete = "calao-app-update-complete",
	/** Identifiant de l'action qui indique que le contenu de l'application est disponible. */
	appAvailable = "calao-app-available",
	/** Identifiant de la lib calao pour la connexion. */
	login = "calao-auth-login",
	/** Identifiant de la lib calao pour la déconnexion. */
	logout = "calao-auth-logout",
	/** Identifiant de la lib calao pour un échec d'authentification. */
	authenticationFailed = "calao-authentication-failed",
	/** Identifiant de l'action de sauvegarde d'un document sur le serveur. */
	dmsDocUpload = "calao-dms-doc-upload",
	/** Identifiant de la sauvegarde d'un document (serveur ou local). */
	dmsDocSave = "calao-dms-doc-save",
	/** L'envoi d'un document a été annulé. */
	dmsDocUploadCancelled = "dms-doc-upload-cancelled",
	/** Identifiant de l'action de déplacement vers la corbeille d'un document. */
	explorerDocMoveToTrash = "explorer-doc-move-to-trash",
	/** Identifiant de l'action de restauration d'un document. */
	explorerDocRestore = "explorer-doc-restore",
	/** Identifiant de l'action de suppression d'un document. */
	explorerDocDestroy = "explorer-doc-destroy",
	/** Identifiant de la sauvegarde d'un document du store. */
	storeDocSave = "calao-store-doc-save",
	/** Identifiant de la suppression d'un document du store. */
	storeDocDelete = "calao-store-doc-delete",
	/** Identifiant de la sauvegarde de plusieurs documents du store. */
	storeDocsBulk = "calao-store-doc-bulk",
	/** Identifiant de la réplication. */
	storeReplicate = "calao-store-replicate",
	/** Identifiant de l'initialisation d'une base de données. */
	storeInitDb = "calao-store-init-database",
	/** Identifiant de la réinitialisation d'une base de données. */
	resetDatabase = "calao-reset-database",
	/** Identifiant d'une entrée de log qui a échoué. */
	loggerAddEntryFailed = "logger-add-entry-failed",
	/** Identifiant de batterie faible de l'appareil. */
	lowDeviceBattery = "calao-device-battery-low",
	/** Identifiant de l'action de mise en arrière plan de l'application. */
	appSuspended = "calao-app-suspended",
	/** Identifiant de reprise d'activité de l'application. */
	appResumed = "calao-app-resumed",

	//#region Mise à jour

	/** Identifiant indiquant qu'une mise à jour de l'application est disponible. */
	updateAvailable = "calao-update-available",
	/** Identifiant de mise à jour refusée par l'utilisateur. */
	updateCanceled = "calao-update-canceled",
	/** Indentifiant de mise à jour installée par l'utilisateur. */
	updateSuccess = "calao-update-success",
	/** Identifiant d'échec lors du téléchargement ou de l'installation de la mise à jour. */
	updateFailed = "calao-update-failed",

	//#endregion

	//#region OneSignal

	/** Identifiant indiquant l'initialisation du marqueur de oneSignal. */
	notifMarkerInit = "calao-notif-marker-init",
	/** Identifiant indiquant l'initialisation de oneSignal. */
	notifInit = "calao-notif-init",
	/** Identifiant indiquant la mise à jour de oneSignal. */
	notifUpdated = "calao-notif-updated",

	//#endregion

	//#region Pin

	/** Identifiant pour une tentative de déblocage par pin échouée. */
	unlockPinAttemptFailed = "calao-unlock-pin-attempt-failed",

	//#endregion

	//#region ConversationParticipant

	updateConversationParticipants = "calao-conversation-updated-participants",

	//#endregion ConversationParticipant

	//#region BDD sqlite

	/** Identifiant qui compare la version actuelle de la base de données sqlite avec celle disponible sur le serveur. */
	sqliteCompareAvailableVersion = "calao-sqlite-compare-available-version",
	/** Identifiant de commencement de téléchargement d'une base de données sqlite. */
	sqliteDownloadStart = "calao-sqlite-download-start",
	/** Identifiant de complétion de fourniture d'une base de données sqlite. */
	sqliteProvidingComplete = "calao-sqlite-providing-complete",
	/** Identifiant d'échec de fourniture d'une base de données sqlite. */
	sqliteProvidingFailed = "calao-sqlite-providing-failed",
	/** Identifiant de réussite de la vérification d'intégrité d'une base de données sqlite. */
	sqliteIntegritySuccess = "calao-sqlite-integrity-success",
	/** Identifiant d'échec de la vérification d'intégrité d'une base de données sqlite. */
	sqliteIntegrityFailed = "calao-sqlite-integrity-failed",

	//#endregion BDD sqlite

	//#region Catalogue

	/** Identifiant de réussite de suppresion d'un catalogue. */
	catalogRemoveSuccess = "calao-catalog-remove-success",

	//#endregion Catalogue

	//#region Events

	eventDelete = "calao-evt-delete",
	eventStateDelete = "calao-evt-state-delete",
	eventOccDiffDelete = "calao-evt-occ-diff-delete"

	//#endregion Events

}