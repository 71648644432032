<osapp-header-modal [title]="title"></osapp-header-modal>

<mat-list>
	<mat-radio-group [(ngModel)]="selectedRadioValue" class="liste-wrapper">
		<mat-list-item>
			<mat-radio-button [value]="newReportKey">
				<div>Créer un nouveau rapport</div>
				<mat-divider></mat-divider>
			</mat-radio-button>
		</mat-list-item>
		<calao-virtual-scroll [templateCacheSize]="0" [itemSize]="50" [items]="reports" class="reports" autosize>
			<ng-template let-report="$implicit">
				<mat-list-item>
					<mat-radio-button [value]="report._id" class="mat-radio-button">
						<div>{{ report.title }}</div>
						<div class="txt10px date-label">
							{{ report.createDate | dateWithLocale : 'fr-FR' : 'EEE dd MMMM yyyy à HH:mm' }}
						</div>
					</mat-radio-button>
				</mat-list-item>
				<mat-divider></mat-divider>
			</ng-template>
		</calao-virtual-scroll>
	</mat-radio-group>
</mat-list>

<ion-toolbar>
	<ion-buttons slot="end">
		<ion-button (click)="close()">Annuler</ion-button>
		<ion-button [disabled]="!selectedRadioValue" (click)="close(selectedRadioValue)" color="primary">Ok</ion-button>
	</ion-buttons>
</ion-toolbar>