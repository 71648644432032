<ion-header mode="md">
	<ion-toolbar color="toolbar" mode="md" class="navbar">
		<!-- Titre. -->
		<ng-content select="calao-sub-header-title"></ng-content>

		<ng-content></ng-content>

		<ion-buttons class="sub-header-buttons" slot="end">
			<ng-content select="calao-sub-header-end-buttons"></ng-content>
		</ion-buttons>

	</ion-toolbar>
</ion-header>