<div class="content">
	<div class="title-icon">
		<!-- Le ngSwitch corrige un bug de duplication. -->
		<ng-container [ngSwitch]="!!iconProp">
			<div *ngSwitchCase="true" class="icon-content">
				<ion-icon [name]="iconProp.value$ | async"></ion-icon>
			</div>
		</ng-container>
		<span *ngIf="!!titleProp" class="title">{{ titleProp.value$ | async }}</span>
	</div>
	<span *ngIf="!!subtitleProp" class="subtitle">{{ subtitleProp.value$ | async }}</span>
</div>