export enum EApplicationEventType {
	LifeCycleEvent = 0,
	BackgroundTaskEvent,
	FlagEvent,
	StoreEvent,
	ConfigEvent,
	DmsEvent,
	SecurityEvent,
	CurrentEntityChangedEvent,
	ConversationEvent,
	NetworkEvent,
	databaseDocumentEvent,
	formEvent,
	loggerEvent,
	notificationEvent,
	permissionsEvent,
	contactsEvent,
	purchaseEvent,
	dumpEvent,
	eventParticipantAddEvent,
	eventParticipantAddEndEvent,
	eventParticipantRemoveEvent,
	eventParticipantRemoveEndEvent,
	eventParticipantUpdateEvent,
	eventParticipantUpdateEndEvent
}