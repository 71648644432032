<!-- Mode Edit (!RO) -->
<div *ngIf="params && !params.readOnly">
	<ion-range [min]="params.minValue" [max]="params.maxValue" [step]="params.step" [pin]="params.pin"
		[value]="fieldValue" (ionChange)="onModelChanged($event)" [ticks]="params.ticks" [snaps]="params.snaps">
		<ion-label slot="start">{{params.minValue}}</ion-label>
		<ion-label slot="end">{{params.maxValue}}</ion-label>
	</ion-range>
</div>

<!-- Mode Visu (RO) -->
<div *ngIf="params && params.readOnly && fieldValue" class="progress-bar-area">
	<ion-label [class]="'label-group txt14px margin-b'">
		{{params.label}}
	</ion-label>
	<ion-progress-bar [value]="fieldValue/(params.maxValue-params.minValue)">
	</ion-progress-bar>
</div>