import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'calao-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'100%'",
		"[style.background-color]": "'white'",
		"[style.display]": "'flex'",
		"[style.overflow]": "'hidden'"
	}
})
export class ListItemComponent {

	//#region PROPERTIES

	/** Événement lors du clic sur l'item. */
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<MouseEvent>();

	//#endregion

	//#region METHODS

	public onItemClicked(poEvent: MouseEvent): void {
		this.moItemClickedEvent.emit(poEvent);
	}

	//#endregion

}
