import { Exclude } from "class-transformer";
import { Observable, defer, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { ObservableProperty } from "../../observable/models/observable-property";
import { IPermissionContext } from "../models/ipermission-context";
import { IHasPermission } from "../services/permissions.service";
import { IHasPermissionsParams, IHasStringPermissionsParams } from "./models/ihas-permissions-params";

const C_LOG_ID = "HAS.PERM.D::";

function evaluatePermission<T extends IHasPermission>(
	poTarget: T,
	poParams: IHasPermissionsParams<T> | IHasStringPermissionsParams<T>,
	poContext?: IPermissionContext
): boolean {
	return poTarget.isvcPermissions.evaluatePermission(
		poParams.permissionScopes ?? poTarget.permissionScope,
		poParams.permission,
		poContext
	);
}

//#region Version synchrone

/** Ajoute la vérification d'une permission à un accesseur booleen.
 * @param psPermission
 */
export function HasPermissions<T extends IHasPermission>(poParams: IHasPermissionsParams<T>): PropertyDecorator;
/** Ajoute la vérification d'une permission à un accesseur booleen.
 * @param psPermission
 */
export function HasPermissions<T extends IHasPermission>(poParams: IHasStringPermissionsParams<T>): PropertyDecorator;
export function HasPermissions<T extends IHasPermission>(poParams: IHasPermissionsParams<T> | IHasStringPermissionsParams<T>): PropertyDecorator {

	return function (
		poTarget: T,
		psPropertyKey: string,
		poDescriptor?: TypedPropertyDescriptor<boolean>
	) {
		const lfOriginalGet: (() => boolean) | undefined = poDescriptor?.get; // On sauvegarde l'ancienne implémentation du getter.
		const lfGet = function (): boolean {
			const loTarget: T = this; // Représente la classe qui appelle le décorateur.

			const lbHasPermissions: boolean = (lfOriginalGet ? lfOriginalGet.apply(loTarget, arguments) : true) && // On appelle l'ancien getter en le mettant dans le contexte de l'appelant.
				evaluatePermission(loTarget, poParams, poParams.context ? poParams.context(loTarget) : undefined);

			if (!lbHasPermissions)
				poParams.showHasNotPermissionsPopup?.(loTarget);

			return lbHasPermissions;
		};

		if (!lfOriginalGet)
			Object.defineProperty(poTarget, psPropertyKey, { get: lfGet, configurable: true });
		else if (poDescriptor)
			poDescriptor.get = lfGet;
		else
			console.error(`${C_LOG_ID}No original getter and no descriptor for target '${poTarget.constructor.name}' with property key '${psPropertyKey}' (sync version) !`);

		return poDescriptor;
	};
};

//#endregion Version synchrone

//#region Version Observable/flux

const C_OBSERVABLEPROP_PREFIX = "#obsHasPerm_";

/** Observe une permission afin de réagir à ses changements en fonction de son contexte.
 * @param poParams
 */
export function HasPermissions$<T extends IHasPermission>(poParams: IHasPermissionsParams<T>): PropertyDecorator;
/** Observe une permission afin de réagir à ses changements en fonction de son contexte.
 * @param poParams
 */
export function HasPermissions$<T extends IHasPermission>(poParams: IHasStringPermissionsParams<T>): PropertyDecorator;
export function HasPermissions$<T extends IHasPermission>(poParams: IHasPermissionsParams<T> | IHasStringPermissionsParams<T>): PropertyDecorator {

	return function (
		poTarget: T,
		psPropertyKey: string,
		poDescriptor?: TypedPropertyDescriptor<Observable<boolean>>
	) {
		Exclude()(poTarget, psPropertyKey); // On exclut l'Observable.
		const lsObservablePropertyKey = `${C_OBSERVABLEPROP_PREFIX}${psPropertyKey}`;
		const lfOriginalGet: (() => Observable<boolean>) | undefined = poDescriptor?.get;
		const lfGet = function () {
			const loTarget: T = this; // Représente la classe qui appelle le décorateur.
			let loObservableProperty: ObservableProperty<any> | undefined = loTarget[lsObservablePropertyKey];
			const loOriginalGetResult$: Observable<boolean> | undefined = lfOriginalGet?.apply(loTarget);

			if (!loObservableProperty) {
				loObservableProperty = loTarget[lsObservablePropertyKey] = new ObservableProperty(
					evaluatePermission(loTarget, poParams, poParams.context ? poParams.context(loTarget) : undefined)
				);

				bindFromObservable(loObservableProperty, loTarget);
				bindFromPromise(loObservableProperty, loTarget);
			}

			// On vérifie qu'on a la permission, si c'est le cas on exécute le getter original (si pas de getter original, c'est ok) sinon on retourne `false`.
			return loObservableProperty.value$.pipe(
				mergeMap((pbResult: boolean) => pbResult ? (loOriginalGetResult$ ?? of(true)) : of(false)),
				tap((pbHasPermissions: boolean) => {
					if (!pbHasPermissions)
						poParams.showHasNotPermissionsPopup?.(loTarget);
				})
			);
		};

		if (!lfOriginalGet)
			Object.defineProperty(poTarget, psPropertyKey, { get: lfGet, configurable: true });
		else if (poDescriptor)
			poDescriptor.get = lfGet;
		else
			console.error(`${C_LOG_ID}No original getter and no descriptor for target '${poTarget.constructor.name}' with property key '${psPropertyKey}' (observable version) !`);

		return poDescriptor;
	};

	function bindFromObservable(poObservableProperty: ObservableProperty<any>, poTarget: T): void {
		if (poParams.context$) {
			poObservableProperty.bind(
				poParams.context$(poTarget).pipe(
					map((poContext: IPermissionContext) => evaluatePermission(poTarget, poParams, poContext))
				),
				poTarget
			);
		}
	}

	function bindFromPromise(poObservableProperty: ObservableProperty<any>, poTarget: T): void {
		if (poParams.contextAsync) {
			poObservableProperty.bind(
				defer(() => poParams.contextAsync!(poTarget)).pipe(
					map((poContext: IPermissionContext) => evaluatePermission(poTarget, poParams, poContext))
				),
				poTarget
			);
		}
	}
}

//#endregion Version Observable/flux