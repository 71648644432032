import { AnimationBuilder, Color, IonicSafeString, Mode, ToastButton, ToastOptions } from '@ionic/core';
import { NumberHelper } from '../../helpers/numberHelper';
import { ObjectHelper } from '../../helpers/objectHelper';
import { StringHelper } from '../../helpers/stringHelper';

export class ShowMessageParamsToast implements ToastOptions {

	//#region FIELDS

	private static readonly C_DEFAULT_DURATION_MS = 2500;
	private static readonly C_DEFAULT_POSITION_MS = "bottom";

	//#endregion

	//#region PROPERTIES

	public header: string;
	public message: string | IonicSafeString;
	public cssClass: string | string[];
	public duration;
	public buttons: Array<ToastButton | string> = [];
	public showCloseButton = false;
	public closeButtonText = "OK";
	public position: "top" | "bottom" | "middle" = "bottom";
	public translucent?: boolean;
	public animated?: boolean;
	public color?: Color;
	public mode?: Mode;
	public keyboardClose?: boolean;
	public id?: string;
	public enterAnimation?: AnimationBuilder;
	public leaveAnimation?: AnimationBuilder;

	//#endregion

	//#region METHODS

	constructor(poOptions: ToastOptions & { showCloseButton?: boolean, closeButtonText?: string } = {}) {
		ObjectHelper.initInstanceOf(this, ShowMessageParamsToast);

		// Propriétés définis par défaut si non renseignées.
		this.header = StringHelper.isBlank(poOptions.header) ? "" : poOptions.header;
		this.duration = NumberHelper.isValid(poOptions.duration) ? poOptions.duration : ShowMessageParamsToast.C_DEFAULT_DURATION_MS;
		this.buttons = poOptions.buttons || [];
		this.showCloseButton = !!poOptions.showCloseButton;
		this.closeButtonText = StringHelper.isBlank(poOptions.closeButtonText) ? "" : poOptions.closeButtonText;
		this.position = StringHelper.isBlank(poOptions.position) ? ShowMessageParamsToast.C_DEFAULT_POSITION_MS : poOptions.position;

		if (typeof poOptions.message === "string")
			this.message = poOptions.message;
		else
			this.message = poOptions.message || "";

		if (typeof poOptions.cssClass === "string")
			this.cssClass = poOptions.cssClass;
		else
			this.cssClass = poOptions.cssClass || "";

		// Propriétés optionnelles.
		this.translucent = poOptions.translucent;
		this.animated = poOptions.animated;
		this.color = poOptions.color;
		this.mode = poOptions.mode;
		this.keyboardClose = poOptions.keyboardClose;
		this.id = poOptions.id;
		this.enterAnimation = poOptions.enterAnimation;
		this.leaveAnimation = poOptions.leaveAnimation;
	}

	//#endregion

}
