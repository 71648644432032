import { Exclude, Transform } from "class-transformer";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { PathHelper } from "../../../helpers/path-helper";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { Folder } from "./folder";
import { IDmsDocument } from "./idms-document";
import { IDocumentFields } from "./idocument-fields";
import { IUserStatus } from "./iuser-status";

export class DmsDocument implements IDmsDocument, IDocumentFields {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string | undefined;
	/** @implements */
	public _deleted?: boolean | undefined;
	/** @implements */
	public deleted?: boolean | undefined;
	/** @implements */
	public _conflicts?: string[] | undefined;
	/** @implements */
	public guid: string;
	/** @implements */
	@ResolveModel(Date)
	public createDate: Date;
	/** @implements */
	@ResolveModel(Date)
	public lastAccess: Date;
	/** @implements */
	@ResolveModel(Date)
	public modifyDate: Date;
	/** @implements */
	public size: number;
	/** @implements */
	public name: string;
	/** @implements */
	public type: string;
	/** @implements */
	@Transform(({ value }: { value: string[] }): string[] => value.map((poValue: string) => PathHelper.parsePath(poValue)) ?? [], { toClassOnly: true })
	@Transform(({ value }: { value: string[] }): string[] => value.map((poValue: string) => PathHelper.preparePath(poValue)) ?? [], { toPlainOnly: true })
	public paths: string[];
	/** @implements */
	public authorId: string;
	/** @implements */
	public description?: string | undefined;
	/** @implements */
	@ResolveModel(Date)
	public archiveDate?: Date;
	/** @implements */
	@ResolveModel(Date)
	public restoreDate?: Date;

	@Exclude()
	/** Icône du type de fichier. */
	public icon?: string;
	@Exclude()
	/** Nom de l'auteur. */
	public authorName?: string;
	@Exclude()
	/** Arbre de navigation pour atteindre le document avec son 1er chemin. */
	public navigationTree: Folder[] = [];
	@Exclude()
	/** Statut de l'utilisateur par rapport au document. */
	public userStatus?: IUserStatus;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Partial<DmsDocument>) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	public get canArchive(): boolean {
		return this.paths.some((psPath: string) => !psPath.startsWith("trash") && !psPath.startsWith("archives"));
	}

	public get canRestore(): boolean {
		return this.paths.some((psPath: string) => psPath.startsWith("trash") || psPath.startsWith("archives"));
	}

	//#endregion METHODS

}
