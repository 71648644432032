import { Injectable } from '@angular/core';
import PCancelable from 'p-cancelable';
import { BehaviorSubject, defer, Observable, ReplaySubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { PerformanceManager } from '../../../performance/PerformanceManager';
import { ETrackingStatus } from '../models/etracking-status.enum';
import { IChangeTrackerItem } from '../models/ichange-tracker-item';
import { ILot } from '../models/ilot';

@Injectable()
export class ChangeTrackingService {

	//#region FIELDS

	private static readonly C_TRACKER_DB_NAME = "change_tracker_db";
	private static readonly C_CHANGES_STORE_NAME = "changes";
	private static readonly C_LOTS_STORE_NAME = "lots";
	private static readonly C_CHANGES_ID_INDEX_NAME = "id";
	private static readonly C_LOG_ID = "CHANGETRACK.S::";
	private static readonly C_START_LOT_ID = 0;

	private readonly moIDBTrackerDatabaseSubjectsByDatabaseId = new Map<string, ReplaySubject<IDBDatabase>>();

	private readonly moTrackingStatusSubjectsByDatabaseId = new Map<string, BehaviorSubject<ETrackingStatus>>();

	//#endregion

	//#region METHODS

	/** Permet de traquer un document.
	 * @param psDatabaseId
	 * @param poChangeTrackedItem
	 */
	public track(psDatabaseId: string, poChangeTrackedItem: IChangeTrackerItem): Promise<void> {
		return this.trackMultiple(psDatabaseId, [poChangeTrackedItem]);
	}

	/** Permet de traquer plusieurs documents en une seule transaction.
	 * @param psDatabaseId
	 * @param paChangeTrackerItems
	 */
	public trackMultiple(psDatabaseId: string, paChangeTrackerItems: IChangeTrackerItem[]): Promise<void> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (pfResolve, pfReject) => {
			// On exclut les _local pour ne pas les tracer pour éviter de les répliquer par la suite.
			const laChangeTrackerItems: IChangeTrackerItem[] = paChangeTrackerItems.filter((poItem: IChangeTrackerItem) => !poItem.id.startsWith(EPrefix.local));

			if (ArrayHelper.hasElements(laChangeTrackerItems)) {
				console.debug(`${ChangeTrackingService.C_LOG_ID}Tracking documents.`, laChangeTrackerItems);
				try {
					const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
					const loTransaction: IDBTransaction = loDb.transaction(
						[
							ChangeTrackingService.C_CHANGES_STORE_NAME,
							ChangeTrackingService.C_LOTS_STORE_NAME
						],
						"readwrite"
					);

					loTransaction.oncomplete = async () => {
						pfResolve();
						await this.sendTrackingStatus(psDatabaseId, ETrackingStatus.tracked);
					};
					loTransaction.onerror = async (poEvent: Event) => {
						pfReject((poEvent.target as IDBTransaction).error);
						await this.sendTrackingStatus(psDatabaseId, ETrackingStatus.error);
					};

					const loLastLot: ILot | undefined = await this.getLastLot(loTransaction);

					await this.putNext(
						paChangeTrackerItems,
						0,
						loTransaction.objectStore(ChangeTrackingService.C_CHANGES_STORE_NAME),
						loLastLot?.id ?? ChangeTrackingService.C_START_LOT_ID
					);
				}
				catch (poError) {
					pfReject(poError);
				}
			}
			else
				pfResolve();
		});
	}

	/** Permet de récupérer tous les marqueurs jusqu'à un lot (inclus).
	 * @param psDatabaseId
	 * @param poToLot
	 */
	public getTracked(psDatabaseId: string, poToLot?: ILot): PCancelable<IChangeTrackerItem[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new PCancelable<IChangeTrackerItem[]>(async (pfResolve, pfReject, pfOnCancel) => {
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([ChangeTrackingService.C_CHANGES_STORE_NAME], "readonly");

			pfOnCancel(() => loTransaction.abort());

			loTransaction.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);

			loTransaction.objectStore(ChangeTrackingService.C_CHANGES_STORE_NAME)
				.getAll(IDBKeyRange.upperBound([(poToLot?.id ?? ChangeTrackingService.C_START_LOT_ID) + 1], true)) // On récupère les marqueurs du lot.
				.onsuccess = (poEvent: Event) => {
					console.debug(`${ChangeTrackingService.C_LOG_ID}Get tracked time : ${loPerformanceManager.markEnd().measure()}ms.`);
					pfResolve((poEvent.target as IDBRequest).result);
				};
		});
	}

	/** Supprime les marqueurs pour un lot et une liste de documents. Supprimera le lot lors de la synchronisation d'un lot supérieur.
	 * @param psDatabaseId
	 * @param pnLotId
	 * @param paDocIds
	 */
	public dropTracked(psDatabaseId: string, pnLotId: number, paDocIds: string[]): PCancelable<void> {
		const loPromise = new PCancelable<void>(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			if (ArrayHelper.hasElements(paDocIds)) {
				const loTransaction: IDBTransaction = loDb.transaction(
					[
						ChangeTrackingService.C_CHANGES_STORE_NAME,
						ChangeTrackingService.C_LOTS_STORE_NAME
					],
					"readwrite"
				);

				pfOnCancel(() => loTransaction.abort());

				loTransaction.oncomplete = async () => {
					console.debug(`${ChangeTrackingService.C_LOG_ID}Drop tracked time : ${loPerformanceManager.markEnd().measure()}ms.`);
					pfResolve();
					await this.sendTrackingStatus(psDatabaseId, await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none);
				};
				loTransaction.onerror = async (poEvent: Event) => {
					pfReject((poEvent.target as IDBTransaction).error);
					await this.sendTrackingStatus(psDatabaseId, ETrackingStatus.error);
				};

				paDocIds.sort(); // On trie pour avoir l'ordre de l'index dans la base.
				loTransaction.objectStore(ChangeTrackingService.C_CHANGES_STORE_NAME)
					// On ouvre un curseur pour parcourir la base jusqu'au dernier doc supprimé.
					.openCursor(IDBKeyRange.upperBound([pnLotId, ArrayHelper.getLastElement(paDocIds)]))
					.onsuccess = (poEvent: Event) => this.processDropTrackedCursorEvent(poEvent, paDocIds, pnLotId, loTransaction);
			}
			else
				pfResolve();
		});

		return loPromise;
	}

	private processDropTrackedCursorEvent(
		poEvent: Event,
		paDocIds: string[],
		pnLot: number,
		poTransaction: IDBTransaction
	): void {
		const loCursor: IDBCursorWithValue = (poEvent.target as IDBRequest).result;
		if (loCursor) {
			if (ArrayHelper.binarySearch(paDocIds, ArrayHelper.getLastElement(loCursor.key as string[]))) // On récupère le dernier élément de la clé qui est l'id du document (ex: cont_...).
				loCursor.delete();

			loCursor.continue();
		}
		else if (pnLot > 1) { // Le lot 0 n'existe pas
			poTransaction.objectStore(ChangeTrackingService.C_LOTS_STORE_NAME)
				// On supprime les anciens lots, car on n'a potentiellement pas terminé le lot en cours
				.openCursor(IDBKeyRange.upperBound(pnLot - 1))
				.onsuccess = (poLotsEvent: Event) => {
					const loLotsCursor: IDBCursorWithValue = (poLotsEvent.target as IDBRequest).result;
					if (loLotsCursor) {
						loLotsCursor.delete();
						loLotsCursor.continue();
					}
				};
		}
	}

	/** Permet de récupérer la liste des lots à envoyer puis de créer un nouveau lot, sans l'ajouter au tableau retourné.
	 * @param psDatabaseId
	 * @param pnSince
	 * @returns Retourne les lots avant mise à jour.
	 */
	public getAndUpdateLastLot(psDatabaseId: string, pnSince: number): PCancelable<ILot[]> {
		// eslint-disable-next-line no-async-promise-executor
		return new PCancelable(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([ChangeTrackingService.C_LOTS_STORE_NAME], "readwrite");

			pfOnCancel(() => loTransaction.abort());

			const loLotsObjectStore: IDBObjectStore = loTransaction.objectStore(ChangeTrackingService.C_LOTS_STORE_NAME);
			const laLots: ILot[] = await this.getLots(loLotsObjectStore, (poLotsObjectStore: IDBObjectStore, paLots: ILot[]) => {
				const loAddPerformanceManager = new PerformanceManager().markStart();
				const loRequest: IDBRequest = poLotsObjectStore.add({
					id: (ArrayHelper.getLastElement(paLots)?.id ?? ChangeTrackingService.C_START_LOT_ID) + 1,
					since: pnSince
				} as ILot);
				loRequest.onsuccess = () =>
					console.debug(`${ChangeTrackingService.C_LOG_ID}Update last lot time : ${loAddPerformanceManager.markEnd().measure()}ms.`);
			});

			loTransaction.oncomplete = () => {
				console.debug(`${ChangeTrackingService.C_LOG_ID}Get and update last lot time : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(laLots);
			};
			loTransaction.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	/** Récupère tous les lots.
	 * @param poObjectStore
	 * @param pfCallback Utile pour exécuter un traitement en conservant la transaction.
	 */
	private getLots(
		poObjectStore: IDBObjectStore,
		pfCallback: (poObjectStore: IDBObjectStore, paLots: ILot[]) => Promise<void> | void
	): Promise<ILot[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new Promise((pfResolve, pfReject) => {
			const loRequest: IDBRequest = poObjectStore.getAll();

			loRequest.onsuccess = async (poEvent: Event) => {
				console.debug(`${ChangeTrackingService.C_LOG_ID}Get lots time : ${loPerformanceManager.markEnd().measure()}ms.`);
				const laLots: ILot[] = (poEvent.target as IDBRequest).result;
				await pfCallback(poObjectStore, laLots);
				pfResolve(laLots);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	/** Récupère le dernier lot.
	 * @param poTransaction
	 */
	private getLastLot(poTransaction: IDBTransaction): Promise<ILot | undefined> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new Promise((pfResolve, pfReject) => {
			const loRequest: IDBRequest<IDBCursorWithValue | null> = poTransaction.objectStore(ChangeTrackingService.C_LOTS_STORE_NAME)
				.openCursor(undefined, "prev");

			loRequest.onsuccess = (poEvent: Event) => {
				const loLotsCursor: IDBCursorWithValue = (poEvent.target as IDBRequest).result;
				if (loLotsCursor) {
					console.debug(`${ChangeTrackingService.C_LOG_ID}Get last lot time : ${loPerformanceManager.markEnd().measure()}ms.`);
					pfResolve(loLotsCursor.value);
				}
				else {
					pfResolve(undefined);
				}
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	/** Permet de sauvegarder les marqueurs un par un mais en restant dans la même transaction (impossible de faire des bulk put sur indexeddb). Les transactions sont coûteuses.
	 * @param paChangeTrackerItems
	 * @param pnIndex
	 * @param poObjectStore
	 * @param pnLotId
	 */
	private putNext(
		paChangeTrackerItems: IChangeTrackerItem[],
		pnIndex: number,
		poObjectStore: IDBObjectStore,
		pnLotId: number
	): Promise<void> {
		return new Promise((pfResolve, pfReject) => {
			if (pnIndex < paChangeTrackerItems.length) {
				const loItem: IChangeTrackerItem = paChangeTrackerItems[pnIndex];
				const loRequest: IDBRequest = poObjectStore.add({
					id: loItem.id,
					rev: loItem.rev,
					lotId: pnLotId
				} as IChangeTrackerItem);

				++pnIndex;
				loRequest.onsuccess = () => this.putNext(paChangeTrackerItems, pnIndex, poObjectStore, pnLotId).then(pfResolve).catch(pfReject);
				loRequest.onerror = (poEvent: Event) => {
					const loError: DOMException | null = (poEvent.target as IDBTransaction).error;
					if (loError?.code === 0) { // Permet de gérer le cas d'une valeur déjà présente
						poEvent.preventDefault();
						poEvent.stopPropagation();
						this.putNext(paChangeTrackerItems, pnIndex, poObjectStore, pnLotId).then(pfResolve).catch(pfReject);
					}
					else
						pfReject(poEvent);
				};
			}
			else
				pfResolve();
		});
	}

	private getDbAsync(psDatabaseId: string): Promise<IDBDatabase> {
		let loSubject: ReplaySubject<IDBDatabase> | undefined = this.moIDBTrackerDatabaseSubjectsByDatabaseId.get(psDatabaseId);

		if (!loSubject) {
			loSubject = this.openDb(psDatabaseId);
			this.moIDBTrackerDatabaseSubjectsByDatabaseId.set(psDatabaseId, loSubject);
		}

		// Je suis obligé de passer par une création de promesse manuelle à cause d'un bug du toPromise: https://stackblitz.com/edit/rxjs-75thii?file=index.ts
		return new Promise((pfResolve, pfReject) => loSubject?.asObservable().pipe(take(1)).subscribe(pfResolve, pfReject));
	}

	private openDb(psDatabaseId: string): ReplaySubject<IDBDatabase> {
		const loSubject = new ReplaySubject<IDBDatabase>(1);
		const loOpenDbRequest: IDBOpenDBRequest = indexedDB.open(`${ChangeTrackingService.C_TRACKER_DB_NAME}_${psDatabaseId}`);

		loOpenDbRequest.onerror = (poEvent: Event) =>
			console.error(`${ChangeTrackingService.C_LOG_ID}Error while creating change tracking database for ${psDatabaseId}`, (poEvent.target as IDBOpenDBRequest).error);
		loOpenDbRequest.onsuccess = (poEvent: Event) => loSubject.next((poEvent.target as IDBOpenDBRequest).result);

		loOpenDbRequest.onupgradeneeded = (poEvent: Event) => { // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#creating_or_updating_the_version_of_the_database
			const loDb: IDBDatabase = (poEvent.target as IDBOpenDBRequest).result;

			const loChangesObjectStore: IDBObjectStore = loDb.createObjectStore(
				ChangeTrackingService.C_CHANGES_STORE_NAME,
				{ keyPath: ["lotId", "id"] }
			);
			loChangesObjectStore.createIndex(ChangeTrackingService.C_CHANGES_ID_INDEX_NAME, "id");
			loDb.createObjectStore(ChangeTrackingService.C_LOTS_STORE_NAME, { keyPath: "id" });
		};

		return loSubject;
	}

	private async getTrackedSubjectAsync(psDatabaseId: string): Promise<BehaviorSubject<ETrackingStatus>> {
		let loSubject: BehaviorSubject<ETrackingStatus> | undefined = this.moTrackingStatusSubjectsByDatabaseId.get(psDatabaseId);

		if (!loSubject) {
			this.moTrackingStatusSubjectsByDatabaseId.set(psDatabaseId, loSubject = new BehaviorSubject<ETrackingStatus>(
				await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none
			));
		}

		return loSubject;
	}

	private async sendTrackingStatus(psDatabaseId: string, peTrackingStatus: ETrackingStatus): Promise<void> {
		(await this.getTrackedSubjectAsync(psDatabaseId)).next(peTrackingStatus);
	}

	public trackingStatus$(psDatabaseId: string): Observable<ETrackingStatus> {
		return defer(() => this.getTrackedSubjectAsync(psDatabaseId)).pipe(
			mergeMap((poSubject: BehaviorSubject<ETrackingStatus>) => poSubject.asObservable())
		);
	}

	private hasTrackedAsync(psDatabaseId: string): PCancelable<boolean> {
		return new PCancelable<boolean>(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([ChangeTrackingService.C_CHANGES_STORE_NAME], "readonly");

			pfOnCancel(() => loTransaction.abort());

			const loLotsObjectStore: IDBObjectStore = loTransaction.objectStore(ChangeTrackingService.C_CHANGES_STORE_NAME);

			const loRequest: IDBRequest<number> = loLotsObjectStore.count();

			loRequest.onsuccess = async (poEvent: Event) => {
				console.debug(`${ChangeTrackingService.C_LOG_ID}Check if has documents tracked : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve((poEvent.target as IDBRequest).result > 0);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	//#endregion

}
