<calao-virtual-scroll [items]="observableContacts.changes$ | async" [itemSize]="70" autosize margeLastItem transparent>
	<ng-template let-contact="$implicit">
		<div class="content">
			<ng-container
				*ngTemplateOutlet="isUserContact(contact) ? itemNoSliding : itemSliding; context: { contact: contact }">
			</ng-container>
		</div>
	</ng-template>
</calao-virtual-scroll>

<ng-template #itemNoSliding let-contact="contact">
	<ion-item lines="none" class="first-contact" (click)="onContactClicked(contact)">
		<ng-container *ngTemplateOutlet="itemContent; context : { contact : contact }"></ng-container>
	</ion-item>
</ng-template>

<ng-template #itemSliding let-contact="contact">
	<ion-item-sliding #slidingItem osappAutoCloseSlideItem>
		<ion-item lines="none" (click)="onContactClicked(contact)">
			<ng-container *ngTemplateOutlet=" itemContent; context : { contact : contact }"></ng-container>
			<div slot="end" class="no-margin-start">
				<ion-button class="itemb btn-more" fill="clear" (click)="openOrCloseItemSliding(slidingItem, $event)">
					<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
				</ion-button>
			</div>
		</ion-item>
		<ion-item-options side="end">
			<ion-item-option *ngIf="canEditContact && !contact.userId" color="primary"
				(click)="onEditContactClicked(contact)">
				<ion-icon name="create"></ion-icon>
				<ion-label>Éditer</ion-label>
			</ion-item-option>
			<ion-item-option *ngIf="canDeleteContact && !contact.userId" color="danger"
				(click)="onDeleteContactClicked(contact)">
				<ion-icon name="trash"></ion-icon>
				<ion-label>Supprimer</ion-label>
			</ion-item-option>
		</ion-item-options>
	</ion-item-sliding>
</ng-template>

<ng-template #itemContent let-contact="contact">
	<calao-conflict-list-marker *ngIf="contact._conflicts"></calao-conflict-list-marker>
	<!-- Avatar -->
	<div class="start">
		<ion-avatar class="avatar" calaoBadgeIcon="trade" calaoBadgeIconPosition="below after"
			[calaoBadgeIconHidden]="!contact.userId">
			<avatar [src]="getContactAvatar(contact)" cssClass="icon-area opacity60">
			</avatar>
		</ion-avatar>
	</div>
	<!-- Affichage des textes. -->
	<ion-text class="text-container">
		<ng-container
			*ngTemplateOutlet="isUserContact(contact) ? userContactLabel: contactLabel; context : { contact : contact }">
		</ng-container>
		<ion-note class="no-margin contact-groups">{{ getGroupslabel$(contact) | async }}</ion-note>
	</ion-text>
</ng-template>

<ng-template #userContactLabel>
	<ion-label ngClass="first-name">Moi</ion-label>
</ng-template>

<ng-template #contactLabel let-contact="contact">
	<div class="contactlabel">
		<ion-label class="uppercase last-name">{{ contact.lastName }}</ion-label>
		<ion-label class="capitalize">{{ contact.firstName }}</ion-label>
	</div>
</ng-template>