/// <reference path="./index.d.ts"/>

const pouch = require('pouchdb');
const PouchDB = pouch.default ? pouch.default : pouch;

const PouchdbAdapterCordovaSqlite = require('pouchdb-adapter-cordova-sqlite');

const debug = require('pouchdb-debug');
const DebugPouch = debug.default ? debug.default : debug;
const PouchDBUtils = require('pouchdb-utils');

export { PouchDB, PouchdbAdapterCordovaSqlite, DebugPouch, PouchDBUtils };
