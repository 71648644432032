import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ToggleChangeEventDetail } from '@ionic/core';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { EDateTimePickerMode } from '../../../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../../../model/date/IDateTimePickerParams';
import { EventDuration } from '../../../../../calendar-events/models/event-duration';
import { CustomIonCheckboxEvent } from '../../../../../ionic/models/icustom-ion-input-event';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { ModelResolver } from '../../../../../utils/models/model-resolver';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';

interface IEventDurationFieldComponentParams {
	startDateLabel?: string;
	endDateLabel?: string;
	startDateKey?: string;
	readOnly?: boolean;
	fullDayKey?: string;
}

@Component({
	selector: 'calao-event-duration-field',
	templateUrl: './event-duration-field.component.html',
	styleUrls: ['./event-duration-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDurationFieldComponent extends FieldBase<EventDuration> implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "EVTDURATION.C::";
	private moStartDateControl: AbstractControl<Date>;
	private moFullDayControl: AbstractControl<boolean>;

	//#endregion

	//#region PROPERTIES

	public observableStartDateTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public observableStartTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	public observableEndDateTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public observableEndTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	public readonly observableStartDate = new ObservableProperty<Date | undefined>();
	public readonly observableEndDate = new ObservableProperty<Date | undefined>();

	public readonly observableDurationReadonlyLabel = new ObservableProperty<string>();

	public readonly observableReadonly = new ObservableProperty<boolean>;

	public params: IEventDurationFieldComponentParams;

	/** `true` si la tâche est programmée (timée) sinon `false`. */
	public readonly observableActivation = new ObservableProperty<boolean>().bind(
		this.observableStartDate.value$.pipe(map((pdDate: Date) => !!pdDate)),
		this
	);

	/** `true` si l'évènement est sur la journée complète. */
	public fullDay: boolean;
	@ObserveProperty<EventDurationFieldComponent>({ sourcePropertyKey: "fullDay" })
	public readonly observableFullDay = new ObservableProperty<boolean>();

	//#endregion

	//#region METHODS

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data ?? {};
		this.observableReadonly.value = !!this.params.readOnly;
		this.moStartDateControl = this.form.controls[this.params.startDateKey];
		this.moFullDayControl = this.form.controls[this.params.fullDayKey];

		if (this.moStartDateControl)
			this.initStartDateChanges$().pipe(secure(this)).subscribe();
		else
			console.error(`${EventDurationFieldComponent.C_LOG_ID}The field ${this.params.startDateKey} is not found.`);

		if (this.moFullDayControl)
			this.initFullDayChanges$().pipe(secure(this)).subscribe();
		else
			console.error(`${EventDurationFieldComponent.C_LOG_ID}The field ${this.params.fullDayKey} is not found.`);

		if (this.params.readOnly)
			this.observableDurationReadonlyLabel.bind(this.getReadonlyLabel$(), this);

		this.observableStartDateTimePickerParams.value.label = this.params.startDateLabel;
		this.observableEndDateTimePickerParams.value.label = this.params.endDateLabel;
	}

	private getReadonlyLabel$(): Observable<string> {
		return combineLatest([this.observableStartDate.value$, this.observableFullDay.value$, this.observableEndDate.value$]).pipe(
			map(([pdStartDate, pbFullDay, pdEndDate]: [Date | undefined, boolean, Date | undefined]) => {
				const leStartPattern: ETimetablePattern = pbFullDay ? ETimetablePattern.EEE_dd_MMMM_yyyy : ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm;
				if (!pdStartDate && !pdEndDate)
					return "";
				else {
					if (DateHelper.areDayEqual(pdStartDate, pdEndDate)) {
						if (pbFullDay)
							return `${DateHelper.transform(pdStartDate, leStartPattern)}`;
						return `${DateHelper.transform(pdStartDate, leStartPattern)} à ${DateHelper.transform(pdEndDate, ETimetablePattern.HH_mm)}`;
					}
					else
						return `${DateHelper.transform(pdStartDate, leStartPattern)} au ${DateHelper.transform(pdEndDate, leStartPattern)}`;
				}
			}));
	}

	private initFullDayChanges$(): Observable<boolean> {
		return this.moFullDayControl.valueChanges.pipe(
			startWith(this.moFullDayControl.value),
			tap((pbFullDay?: boolean) => this.fullDay = !!pbFullDay)
		);
	}

	private initStartDateChanges$(): Observable<Date> {
		return this.moStartDateControl.valueChanges.pipe(
			startWith(this.moStartDateControl.value),
			map((pdValue?: Date) => pdValue ? new Date(pdValue) : undefined),
			tap((pdStartDate?: Date) => {
				if (this.required && !pdStartDate)
					this.moStartDateControl.setValue(pdStartDate = new Date());
				if (!DateHelper.isDate(pdStartDate))
					this.fieldValue = undefined;
				else if (!this.fieldValue)
					this.fieldValue = this.getDefaultDuration();

				this.observableStartDate.value = pdStartDate;
				this.observableEndDate.value = pdStartDate ? this.fieldValue.addDurationToDate(new Date(pdStartDate)) : undefined;

				if (pdStartDate) {
					this.observableEndDateTimePickerParams.value = {
						...this.observableEndDateTimePickerParams.value,
						min: pdStartDate.toISOString()
					};
				}
			})
		);
	}

	private getDefaultDuration(): EventDuration {
		return ModelResolver.toClass(EventDuration, this.fieldValue ?? { hours: 1 });
	}

	public onEndDateChanged(pdEndDate: Date): void {
		this.fieldValue = new EventDuration({
			minutes: DateHelper.diffMinutes(pdEndDate, this.observableStartDate.value)
		});
		this.observableEndDate.value = pdEndDate;
	}

	public onStartDateChanged(pdStartDate: Date): void {
		this.moStartDateControl.setValue(pdStartDate);
		this.fieldValue = new EventDuration({
			minutes: DateHelper.diffMinutes(this.observableEndDate.value, pdStartDate)
		});
	}

	public onActivationChanged(poEvent: Event) {
		const loEvent: ToggleChangeEventDetail = (poEvent as CustomEvent).detail;
		if (!loEvent.checked) {
			if (this.moStartDateControl)
				this.moStartDateControl.setValue(null);
		}
		else
			this.moStartDateControl.setValue(new Date());
	}

	public onFullDayChanged(poEvent: Event): void {
		const lbFullDay: boolean = (poEvent as CustomIonCheckboxEvent).detail.checked;
		this.fullDay = lbFullDay;
		this.moFullDayControl.patchValue(lbFullDay);
	}

	//#endregion

}
