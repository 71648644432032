import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleMap } from '@capacitor/google-maps';
import { LatLng, Marker } from '@capacitor/google-maps/dist/typings/definitions';
import { Adress, NativeGeocoder } from '@capgo/nativegeocoder';
import { ViewDidEnter, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { BooleanHelper } from '../../helpers/boolean.helper';
import { NumberHelper } from '../../helpers/numberHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { ConfigData } from '../../model/config/ConfigData';
import { IGoogleMapParams } from '../../model/googleMap/IGoogleMapParams';
import { IAddress } from '../../model/navigation/IAddress';
import { IGeolocData } from '../../model/navigation/IGeolocData';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { ContactAddressPipe } from '../../pipes/contactAddress.pipe';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { NavigationService } from '../../services/navigation.service';
import { PlatformService } from '../../services/platform.service';
import { UiMessageService } from '../../services/uiMessage.service';
import { IGoogleMapHeaderConfig } from './models/igoogle-map-component-header-config';

@Component({
	selector: "calao-googleMap",
	templateUrl: 'googleMap.component.html',
	styleUrls: ['./googleMap.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent extends ComponentBase implements IGoogleMapParams, ViewWillEnter, ViewDidEnter, ViewWillLeave {

	//#region FIELDS

	/** Valeur de zoom par défaut : 18. */
	private static readonly C_DEFAULT_ZOOM = 18;
	/** Valeur de l'angle de vue par défaut : 30. */
	private static readonly C_DEFAULT_VIEW_ANGLE = 30;
	/** Valeur de la taille de l'icône par défaut : 32x32. */
	private static readonly C_DEFAULT_ICON_SIZE = 32;
	/** Texte informant l'utilisateur que la position est approximative (pas de coordonnées GPS). */
	private static readonly C_APPOXIMATIVE_POSITION_TEXT = "(aucune coordonnée GPS enregistrée, position approximative)";
	/** Texte informant l'utilisateur que la position est basée sur les coordonnées GPS. */
	private static readonly C_GPS_POSITION_TEXT = "(position GPS relevée)";

	private moMap: GoogleMap;

	@ViewChild("map")
	private moMapRef: ElementRef<HTMLElement>;
	private moPosition: LatLng;

	//#endregion

	//#region PROPERTIES

	public readonly mapCanvasId = "mapCanvas";
	public readonly title = "Localisation";

	/** @implements */
	public latitude?: number;
	/** @implements */
	public longitude?: number;
	/** @implements */
	public markerTitle: string;
	/** @implements */
	public address?: string;
	/** @implements */
	public showAddress?: boolean;

	/** Label informant la position de l'utilisateur est son niveau de fiabilité (coordonnées = ok, adresse = approximatif). */
	public readonly positionLabel = new ObservableProperty("");

	/** `true`si l'icone de retour doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasBackButton = new ObservableProperty<boolean>(false);
	/** `true`si le bouton du menu doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasMenuButton = new ObservableProperty<boolean>(true);
	/** `true`si le bouton de retour à l'accueil doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasHomeButton = new ObservableProperty<boolean>(false);
	/** `true`si le bouton de synchronisation doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasSyncButton = new ObservableProperty<boolean>(false);
	/** `true`si le bouton des conversations doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasConversationsButton = new ObservableProperty<boolean>(false);
	/** `true`si le bouton des notifications doit être affiché, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasNotificationsButton = new ObservableProperty<boolean>(false);

	public readonly observableToolbarMode = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		private isvcUIMessage: UiMessageService,
		private ioRoute: ActivatedRoute,
		private ioContactAddressPipe: ContactAddressPipe<IAddress>,
		private isvcNavigation: NavigationService,
		private readonly isvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
		const loHeaderConfiguration: IGoogleMapHeaderConfig = this.ioRoute.snapshot.data.pageInfo as IGoogleMapHeaderConfig;
		this.observableHasBackButton.value = loHeaderConfiguration.hasBackButton;
		this.observableHasMenuButton.value = loHeaderConfiguration.hasMenuButton;
		this.observableHasHomeButton.value = loHeaderConfiguration.hasHomeButton;
		this.observableHasSyncButton.value = loHeaderConfiguration.hasSyncButton;
		this.observableToolbarMode.value = loHeaderConfiguration.tabBarStyle;
		this.observableHasConversationsButton.value = loHeaderConfiguration.hasConversationsButton;
		this.observableHasNotificationsButton.value = loHeaderConfiguration.hasNotificationsButton;
	}

	public ionViewWillEnter(): void {
		const loQueryParams: IGoogleMapParams = this.ioRoute.snapshot.queryParams as IGoogleMapParams;

		this.markerTitle = loQueryParams.markerTitle;

		this.address = loQueryParams.address;
		this.showAddress = typeof loQueryParams.showAddress !== "undefined" ? BooleanHelper.convertToBoolean(loQueryParams.showAddress) : false;

		this.latitude = (NumberHelper.isValid(loQueryParams.latitude) || NumberHelper.isStringNumber(loQueryParams.latitude)) ?
			+loQueryParams.latitude : undefined;

		this.longitude = (NumberHelper.isValid(loQueryParams.longitude) || NumberHelper.isStringNumber(loQueryParams.longitude)) ?
			+loQueryParams.longitude : undefined;

		console.debug(`GM.C:: Positions latitude=${this.latitude} ; longitude=${this.longitude} ; adresse=${this.address}`);
	}

	public ionViewDidEnter(): void {
		if (this.isvcPlatform.isMobileApp && this.isvcPlatform.isIOS)
			setTimeout(() => this.loadMapAsync(), 500); // Wait for UIViewController to be ready - https://github.com/ionic-team/capacitor-plugins/issues/980
		else
			this.loadMapAsync();
	}

	public ionViewWillLeave(): void {
		if (this.moMap)
			this.moMap.destroy();
	}

	/** Ouvre une carte GoogleMaps avec les coordonées passées en paramètre.
	 * @param pnZoom Valeur de zoom pour l'affiche de la carte (20 pour une maison, 15 pour une rue, 10 pour une ville ...).
	 * @param pnViewAngle Valeur optionnelle de l'angle de vue (par défaut 30°).
	 * @param psMarkerSnippet Texte optionnel du marqueur.
	*/
	private async loadMapAsync(pnZoom: number = GoogleMapComponent.C_DEFAULT_ZOOM, psMarkerSnippet = ""): Promise<void> {
		await this.generateMapAsync(pnZoom);
		await this.prepareMapAsync(this.markerTitle, psMarkerSnippet);
		this.detectChanges();
	}

	/** Récupère les coordonnées GPS et l'adresse pour afficher la carte correspondante. */
	private getCoordinatesAndAddressAsync(): Promise<LatLng> {
		if (NumberHelper.isValid(this.latitude) && NumberHelper.isValid(this.longitude)) // Création à partir des coordonnées.
			return this.getCoordinatesAndAddressFromCoordinatesAsync();

		else if (!StringHelper.isBlank(this.address)) // Création à partir d'une adresse.
			return this.getCoordinatesAndAddressFromAddressAsync();

		else { // Impossibilité de déterminer la position.
			this.positionLabel.value = "Impossible de déterminer la position.";
			return undefined;
		}
	}

	/** Récupère les coordonées GPS et l'adresse à partir de coordonnées GPS. */
	private getCoordinatesAndAddressFromCoordinatesAsync(): Promise<LatLng> {
		let loAddress$: Observable<string>;

		if (StringHelper.isBlank(this.address)) {
			loAddress$ = this.isvcNavigation.searchAddressByCoordinates(this.latitude, this.longitude)
				.pipe(map((paResults: IGeolocData[]) => this.ioContactAddressPipe.transform(ArrayHelper.getFirstElement(paResults), false)));
		}
		else
			loAddress$ = of(this.address);

		return loAddress$
			.pipe(
				tap((psAddress: string) => this.positionLabel.value = `${psAddress} ${GoogleMapComponent.C_GPS_POSITION_TEXT}`),
				map(_ => { return { lat: this.latitude, lng: this.longitude } as LatLng; }),
				tap(() => this.detectChanges()),
			).toPromise();
	}

	/** Récupère les coordonées GPS et l'adresse à partir de l'adresse. */
	private async getCoordinatesAndAddressFromAddressAsync(): Promise<LatLng> {
		const { addresses }: { addresses: Adress[] } = await NativeGeocoder.forwardGeocode({ addressString: this.address, apiKey: ConfigData.environment.GOOGLE_MAPS_BROWSER_API_KEY });
		this.positionLabel.value = `${this.address} ${GoogleMapComponent.C_APPOXIMATIVE_POSITION_TEXT}`;

		this.detectChanges();

		const loGeocoderResult: Adress = ArrayHelper.getFirstElement(addresses);

		return { lat: loGeocoderResult.latitude, lng: loGeocoderResult.longitude };
	}

	private async generateMapAsync(pnZoom: number): Promise<void> {
		try {
			this.moPosition = await this.getCoordinatesAndAddressAsync();
			if (this.moPosition) {
				this.moMap = await GoogleMap.create({
					id: this.mapCanvasId,
					apiKey: this.isvcPlatform.isIOS && this.isvcPlatform.isMobileApp ? ConfigData.environment.GOOGLE_MAPS_IOS_API_KEY : ConfigData.environment.GOOGLE_MAPS_BROWSER_API_KEY,
					config: { center: this.moPosition, zoom: pnZoom },
					element: this.moMapRef.nativeElement
				});
			}
			this.detectChanges();
		}
		catch (poError) {
			this.isvcUIMessage.showMessage(
				new ShowMessageParamsPopup({ header: "Erreur Google Maps", message: "Une erreur est survenue pendant l'accès aux données Google Maps." })
			);
			console.error("GM.C:: Erreur génération carte :", poError);
		}
	}

	private async prepareMapAsync(psTitle: string, psSnippet: string, pnViewAngle: number = GoogleMapComponent.C_DEFAULT_VIEW_ANGLE): Promise<void> {
		if (this.moMap) {
			try {
				await this.moMap.enableIndoorMaps(true); // En zoom max on a les numéros d'étages.
			}
			catch (poError) {
				console.warn(`GM.C::Impossible d'utiliser le 'enableIndoorMaps'`, poError);
			}

			await this.moMap.setCamera({ angle: pnViewAngle, animate: true }); // On oriente la caméra.

			const lsTitle: string = StringHelper.isBlank(psTitle) ? "Destination" : psTitle;
			const loMarkerOptions: Marker = {
				title: lsTitle,
				coordinate: this.moPosition,
				iconSize: {
					height: GoogleMapComponent.C_DEFAULT_ICON_SIZE,
					width: GoogleMapComponent.C_DEFAULT_ICON_SIZE
				}
			};

			if (!StringHelper.isBlank(psSnippet)) // On ne met pas la propriété directement dans l'objet car cela agrandi le marqueur même si c'est vide.
				loMarkerOptions.snippet = psSnippet;

			await this.moMap.addMarker(loMarkerOptions);

			this.detectChanges();
		}
	}

	//#endregion
}