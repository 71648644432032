import { Injectable } from "@angular/core";
import { Directory, FileInfo } from "@capacitor/filesystem";
import { from, Observable, of } from 'rxjs';
import { concatMap, finalize, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { StringHelper } from '../../../helpers/stringHelper';
import { LoadingService } from '../../../services/loading.service';
import { PlatformService } from '../../../services/platform.service';
import { FilesystemService } from "../../filesystem/services/filesystem.service";
import { Loader } from '../../loading/Loader';
import { SqlHelper } from '../../sqlite/helpers/sql.helper';
import { PatchBase } from "./PatchBase";

@Injectable()
export class Sqlite2ToSqliteEvcoreExtbuildFreePatch extends PatchBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "SQL2toSQLEEF.PATCH::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcFilesystem: FilesystemService
	) {
		super("Migration des bases de données sqlite");
	}

	public override applyPatch(): Observable<boolean> {
		const lsSqliteMobileAppDatabasesPath: string = SqlHelper.mobileAppDatabasesPath;

		if (!StringHelper.isBlank(lsSqliteMobileAppDatabasesPath)) {
			console.debug(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}apply patch for ${this.isvcPlatform.isAndroid ? "android" : this.isvcPlatform.isIOS ? "iOS" : "another platform"} android`);
			return this.innerApplyPatch("", lsSqliteMobileAppDatabasesPath);
		}
		else {
			console.debug(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}NOT apply patch`);
			return of(true);
		}
	}

	/** Applique le patch de migration des bases de données depuis le plugin sqlite2 vers le plugin sqliteEvcoreExtbuildFree.
	 * @param psOriginalPath Chemin vers les bases de données avec le plugin sqlite2.
	 * @param psNewDatabasesPath Chemin vers les bases de données avec le plugin sqliteEvcoreExtbuildFree.
	 */
	private innerApplyPatch(psOriginalPath: string, psNewDatabasesPath: string): Observable<boolean> {
		let loLoader: Loader;

		return from(this.isvcLoading.create(`${this.patchDescription} ...`))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(_ => loLoader.present()),
				mergeMap(_ => this.isvcFilesystem.createDirectoryAsync(psNewDatabasesPath, SqlHelper.mobileAppDatabasesDirectory, false)),
				mergeMap(_ => this.isvcFilesystem.listDirectoryEntriesAsync(psOriginalPath, SqlHelper.mobileAppDatabasesDirectory).catch(_ => of([]))),
				map((paEntries: FileInfo[]) => paEntries.filter((poEntry: FileInfo) => poEntry.type === "file" && poEntry.name.includes("_core_") && !poEntry.name.endsWith("-journal"))),
				mergeMap((paEntries: FileInfo[]) => this.moveDatabases(paEntries, loLoader, psNewDatabasesPath)),
				finalize(() => loLoader.dismiss())
			);
	}

	/** Déplace les bases de données depuis le dossier utilisé par le plugin sqlite2 vers le dossier utilisé par le plugin sqliteEvcoreExtbuildFree.
	 * @param paEntries Tableau des dossiers et fichiers présents dans le dossier dont il faut déplacer les bases de données.
	 * @param poLoader Loader affiché qu'il faut mettre à jour au fur-et-à-mesure des déplacements.
	 */
	private moveDatabases(paEntries: FileInfo[], poLoader: Loader, psNewDatabasesPath: string): Observable<boolean> {
		let lnIndex = 0;

		return from(paEntries)
			.pipe(
				concatMap((poFileEntry: FileInfo) => {
					poLoader.text = this.getPatchMessage(lnIndex, paEntries.length);
					console.info(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migration base de données '${poFileEntry.name}' en cours ...`);

					return this.innerMoveDatabasesAsync(poFileEntry, psNewDatabasesPath);
				}),
				tap(_ => ++lnIndex),
				toArray(),
				map((paResults: boolean[]) => paResults.every((pbResult: boolean) => pbResult))
			);
	}

	private async innerMoveDatabasesAsync(poFileEntry: FileInfo, psNewDatabasesPath: string): Promise<boolean> {
		try {
			// On copie la base vers son nouveau chemin puis on la supprime
			// => cela évite des possibles corruptions de bases de données dans le cas où l'app crasherait pendant un déplacement.
			await this.isvcFilesystem.copyAsync(poFileEntry.name, `${psNewDatabasesPath}${poFileEntry.name}`, Directory.Data, SqlHelper.mobileAppDatabasesDirectory);
			await this.isvcFilesystem.removeAsync(poFileEntry.name, Directory.Data);
			console.info(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migration base de données '${poFileEntry.name}' terminée.`)
			return true;
		}
		catch (poError) {
			console.error(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migration base de données '${poFileEntry.name}' échouée.`);
			return false;
		}
	}

	private getPatchMessage(pnIndex: number, pnTotalCount: number): string {
		return `${this.patchDescription} ${pnIndex + 1} sur ${pnTotalCount} ...`;
	}

	//#endregion
}
