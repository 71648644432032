import { IContact } from '../model/contacts/IContact';
import { EAvatarSize } from '../model/picture/EAvatarSize';
import { IAvatar } from '../model/picture/IAvatar';
import { IPicture } from '../model/picture/IPicture';
import { ContactHelper } from './contactHelper';
import { FileHelper } from './fileHelper';
import { StringHelper } from './stringHelper';

export abstract class AvatarHelper {

	//#region METHODS

	/** Crée un avatar depuis un objet `IPicture`.
	 * @param poPicture Image dont il faut créer l'avatar.
	 * @param peAvatarSize Taille de l'avatar.
	 */
	public static createAvatarFromPicture(poPicture: IPicture | undefined, peAvatarSize: EAvatarSize): IAvatar {
		let loAvatar: IAvatar;

		if (poPicture) {

			if (!StringHelper.isBlank(poPicture.base64)) // Base64 présente à convertir en blob qu'on convertit ensuite en url.
				loAvatar = this.createAvatarFromImage(FileHelper.base64toBlob(poPicture.base64, poPicture.mimeType), peAvatarSize);

			else if (!StringHelper.isBlank(poPicture.url)) // Url présente.
				loAvatar = this.createAvatarFromImage(poPicture.url, peAvatarSize);

			else if (!StringHelper.isBlank(poPicture.guid)) // Image dans le DMS.
				loAvatar = this.createAvatarFromImage(undefined, peAvatarSize, poPicture.guid);
		}

		return loAvatar;
	}

	/** Crée un avatar depuis une icône.
	 * @param psIcon Nom de l'icône à utiliser.
	 * @param peAvatarSize Taille de l'avatar.
	 */
	public static createAvatarFromIcon(psIcon: string, peAvatarSize: EAvatarSize): IAvatar {
		return {
			icon: psIcon,
			size: peAvatarSize
		};
	}

	/** Crée un avatar depuis la première lettre de chaque mot.
	 * @param psFirstWord Première chaîne de caractères dont on va garder la première lettre.
	 * @param psSecondWord Seconde chaîne de caractères dont on va garder la première lettre.
	 * @param peAvatarSize Taille de l'avatar.
	 */
	public static createAvatarFromText(psFirstWord: string, psSecondWord: string, peAvatarSize: EAvatarSize): IAvatar {
		return {
			text: `${psFirstWord} ${psSecondWord}`,
			size: peAvatarSize
		};
	}

	/** Crée un avatar depuis une image (blob ou base64).
	 * @param poData Donnée de l'image de l'avatar sous forme de Blob ou base64 ou Url.
	 * @param peAvatarSize Taille de l'avatar.
	 * @param psGuid Guid de l'image du DMS correspondant à l'avatar, optionnel.
	 */
	private static createAvatarFromImage(poData: string | Blob, peAvatarSize: EAvatarSize, psGuid?: string): IAvatar {
		return {
			data: poData,
			size: peAvatarSize,
			guid: psGuid
		};
	}

	/** Crée un avatar depuis un contact en utilisant son image en premier ; si cela ne fonctionne pas, le crée depuis le prénom et nom du contact.
	 * @param poContact Contact dont il faut créer un avatar.
	 * @param peAvatarSize Taille de l'avatar.
	 * @param pbUseIconAsDefaultFallback Indique si l'icône doit être la solution de remplacement si l'image n'est pas disponible.
	 */
	public static createAvatarFromContact(peAvatarSize: EAvatarSize, poContact?: IContact, pbUseIconAsDefaultFallback?: boolean): IAvatar {
		const loAvatar: IAvatar = {
			...this.createAvatarFromPicture(poContact?.picture, peAvatarSize),
			text: ContactHelper.getCompleteFormattedName(poContact, true),
			icon: "person",
			useIconAsDefaultFallback: pbUseIconAsDefaultFallback
		};

		return loAvatar;
	}

	/** Compare deux avatars, retourne `true` si les deux sont les mêmes, `false` sinon.
	 * @param poAvatarA Avatar à comparer avec l'autre.
	 * @param poAvatarB Avatar à comparer avec l'un.
	 */
	public static areDifferent(poAvatarA: IAvatar, poAvatarB: IAvatar): boolean {
		return poAvatarA.data !== poAvatarB.data ||
			poAvatarA.guid !== poAvatarB.guid ||
			poAvatarA.icon !== poAvatarB.icon ||
			poAvatarA.mimeType !== poAvatarB.mimeType ||
			poAvatarA.size !== poAvatarB.size ||
			poAvatarA.text !== poAvatarB.text;
	}

	//#endregion
}