<ion-item *ngIf="to.label" lines="none">
	<ion-label>{{to.label}}</ion-label>
</ion-item>

<gallery [cameraButtonVisible]="params.cameraButtonVisible" [cameraOptions]="params.cameraOptions"
	[command$]="galleryCommand$" [filePickerVisible]="params.filePickerVisible" [filePickerIcon]="params.filePickerIcon"
	[imagePickerIcon]="params.imagePickerIcon" [imagePickerVisible]="params.imagePickerVisible" [files]="files"
	[maxSizeKb]="params.maxSizeKb" [readOnly]="params.readOnly"
	[filePickerFilesButtonText]="params.filePickerFilesButtonText"
	[filePickerImagesButtonText]="params.filePickerImagesButtonText" [cameraButtonText]="params.cameraButtonText"
	[guidWithHyphens]="params.guidWithHyphens" [guidUpperCase]="params.guidUpperCase"
	(onFilesChanged)="onFilesChanged($event)" [acceptedFiles]="params.acceptedFiles"
	[acceptedImages]="params.acceptedImages" [limit]="params.limit" [hideNoFileText]="params.hideNoFileText"
	[selectDocumentType]="params.selectDocumentType">
</gallery>