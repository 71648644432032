import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../../pipes/pipes.module';
import { ModalModule } from '../../../modal/modal.module';
import { VirtualScrollModule } from '../../../virtual-scroll/virtual-scroll.module';
import { CreateConversationSelectorModalComponent } from './create-conversation-selector-modal.component';
import { CreateConversationSelectorModalOpenerService } from './services/create-conversation-selector-modal-opener.service';

@NgModule({
	declarations: [CreateConversationSelectorModalComponent],
	imports: [
		CommonModule,
		IonicModule,
		ModalModule,
		PipeModule,
		VirtualScrollModule,
		MatRadioModule,
		MatDividerModule,
		MatListModule,
		FormsModule
	],
	providers: [CreateConversationSelectorModalOpenerService],
	exports: [CreateConversationSelectorModalComponent]
})
export class CreateConversationSelectorModalModule { }