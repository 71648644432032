import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IIndexedObject } from '../../../../model/IIndexedObject';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { FilterBarMainContentComponent } from '../filter-bar-main-content/filter-bar-main-content.component';
import { FilterBarComponent } from '../filter-bar/filter-bar.component';

@Component({
	selector: 'calao-filter-bar-tags-tag',
	templateUrl: './filter-bar-tags-tag.component.html',
	styleUrls: ['./filter-bar-tags-tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarTagsTagComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	/** Clé du filtre associé à ce tag. */
	@Input() public key?: string;
	@ObserveProperty<FilterBarTagsTagComponent>({ sourcePropertyKey: "key" })
	public readonly observableKey = new ObservableProperty<string>("");

	/** Nom de l'icône à afficher. */
	@Input() public icon?: string;
	@ObserveProperty<FilterBarTagsTagComponent>({ sourcePropertyKey: "icon" })
	public readonly observableIcon = new ObservableProperty<string>("");

	/** Libellé du tag. */
	@Input() public label?: string | null;
	@ObserveProperty<FilterBarTagsTagComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>("");

	public readonly value$: Observable<any> = this.getValue$().pipe(secure(this));

	public readonly display$: Observable<boolean> = this.getDisplay$().pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioMainContent: FilterBarMainContentComponent,
		private readonly ioFilterBar: FilterBarComponent
	) {
		super();
	}

	private getValue$(): Observable<any> {
		return combineLatest([this.ioMainContent.observableFilterValues.value$, this.observableKey.value$]).pipe(
			map(([poFilterValues, psKey]: [IIndexedObject, string]) =>
				poFilterValues ? poFilterValues[psKey] : undefined
			)
		);
	}

	private getDisplay$(): Observable<boolean> {
		return combineLatest([this.observableIcon.value$, this.observableLabel.value$]).pipe(
			map(([psIcon, psLabel]: [string, string]) =>
				!StringHelper.isBlank(psIcon) || !StringHelper.isBlank(psLabel)
			)
		);
	}

	public onClick(): void {
		this.ioFilterBar.presentFilterModal();
	}

	//#endregion

}
