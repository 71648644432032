import { Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ILifeCycleEvent } from '../model/lifeCycle/ILifeCycleEvent';
import { ComponentBase } from './ComponentBase';
import { LifeCycleObserverComponentBase } from './LifeCycleObserverComponentBase';

export class LifeCycleObserver {

	//#region FIELDS

	private moLifeCycleSubscription: Subscription;
	private moComponent: LifeCycleObserverComponentBase;

	//#endregion

	//#region METHODS

	public setComponent(poComponent: LifeCycleObserverComponentBase): void {
		this.moComponent = poComponent;
		const loParentPage: DynamicPageComponent<ComponentBase> | undefined = this.moComponent.getParentPage();

		if (loParentPage && !this.moLifeCycleSubscription) {
			this.moLifeCycleSubscription = loParentPage.getLifeCycleObservable()
				.pipe(
					tap((poEvent: ILifeCycleEvent) => this.onLifeCycleEvent(poEvent)),
					takeUntil(this.moComponent.destroyed$)
				)
				.subscribe();
		}
	}

	/** Libère le composant qui est en train de se détruire de ses abonnements au cycle de vie. */
	public unsubscribeLifeCycle(): void {
		if (this.moLifeCycleSubscription)
			this.moLifeCycleSubscription.unsubscribe(); // On libère les abonnements des cycles de vie.
	}

	private onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		this.moComponent.raiseLifeCycleEvent(poEvent);
	}

	//#endregion
}