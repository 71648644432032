import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { WebViewComponent } from './webview.component';

@NgModule({
	imports: [CommonModule, IonicModule, PipeModule],
	exports: [WebViewComponent],
	declarations: [WebViewComponent]
})
export class WebViewModule { }