import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PointGroup } from 'signature_pad';
import { IBase64Data } from '../../../../model/file/IBase64Data';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { IDrawingModalParams } from '../../models/IDrawingModalParams';
import { IDrawingOptions } from '../../models/IDrawingOptions';
import { IDrawingResult } from '../../models/IDrawingResult';
import { DrawingComponent } from '../drawing/drawing.component';

@Component({
	selector: "calao-drawing-modal",
	templateUrl: './drawing-modal.component.html',
	styleUrls: ['./drawing-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawingModalComponent extends ModalComponentBase<IDrawingResult> implements IDrawingModalParams {

	//#region PROPERTIES

	private msTitle: string;
	/** @implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psValue: string) {
		if (psValue !== this.msTitle) {
			this.msTitle = psValue;
			this.detectChanges();
		}
	}

	private msDrawingTitle: string;
	/** @implements */
	public get drawingTitle(): string { return this.msDrawingTitle; }
	@Input() public set drawingTitle(psValue: string) {
		if (psValue !== this.msDrawingTitle) {
			this.msDrawingTitle = psValue;
			this.detectChanges();
		}
	}

	private mbShowUndoButton = true;
	/** @implements */
	public get showUndoButton(): boolean { return this.mbShowUndoButton; }
	@Input() public set showUndoButton(pbValue: boolean) {
		if (pbValue !== this.mbShowUndoButton) {
			this.mbShowUndoButton = pbValue;
			this.detectChanges();
		}
	}

	private mbShowClearButton = true;
	/** @implements */
	public get showClearButton(): boolean { return this.mbShowClearButton; }
	@Input() public set showClearButton(pbValue: boolean) {
		if (pbValue !== this.mbShowClearButton) {
			this.mbShowClearButton = pbValue;
			this.detectChanges();
		}
	}

	private moOptions: IDrawingOptions;
	/** @implements */
	public get options(): IDrawingOptions { return this.moOptions; }
	@Input() public set options(poValue: IDrawingOptions) {
		if (poValue !== this.moOptions) {
			this.moOptions = poValue;
			this.detectChanges();
		}
	}

	private mbReadonly = false;
	/** @implements */
	public get readonly(): boolean { return this.mbReadonly; }
	@Input() public set readonly(pbValue: boolean) {
		if (pbValue !== this.mbReadonly) {
			this.mbReadonly = pbValue;
			this.detectChanges();
		}
	}

	private moBase64Data: IBase64Data;
	/** @implements */
	public get base64Data(): IBase64Data { return this.moBase64Data; }
	@Input() public set base64Data(poValue: IBase64Data) {
		if (poValue !== this.moBase64Data) {
			this.moBase64Data = poValue;
			this.detectChanges();
		}
	}

	private maData: PointGroup[];
	/** @implements */
	public get data(): PointGroup[] { return this.maData; }
	@Input() public set data(paValues: PointGroup[]) {
		if (paValues && paValues !== this.maData) {
			this.maData = paValues;
			this.detectChanges(); // On réinitialise le signaturePad.
		}
	}

	private msMissingDrawingText: string;
	/** @implements */
	public get missingDrawingText(): string { return this.msMissingDrawingText; }
	@Input() public set missingDrawingText(psValue: string) {
		if (psValue !== this.msMissingDrawingText) {
			this.msMissingDrawingText = psValue;
			this.detectChanges();
		}
	}

	private mbCanValidate = false;
	/** Indique si la modale peut être validée. */
	public get canValidate(): boolean { return this.mbCanValidate; }
	public set canValidate(pbValue: boolean) {
		if (pbValue !== this.mbCanValidate) {
			this.mbCanValidate = pbValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService, poChangeDetector: ChangeDetectorRef) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	/** Valide le dessin et le retourne à la page appelante sous la forme d'un Blob. */
	public validate(poDrawingComponent: DrawingComponent): void {
		this.close(poDrawingComponent.getDrawingResult());
	}

	public updateEmptyDrawStatus(pbValue: boolean) {
		this.canValidate = !pbValue;
	}

	//#endregion

}
