import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StringHelper } from '../../../../helpers/stringHelper';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un booléen. */
@Component({
	templateUrl: './boolLabelField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoolLabelFieldComponent extends FieldBase<boolean> implements OnInit { //todo : typer + params.

	//#region PROPERTIES

	/** Texte à afficher en fonction du booléen. */
	public boolText: string;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();

		const lsTrueText: string = !StringHelper.isBlank(this.to.data.trueText) ? this.to.data.trueText : "Oui";
		const lsFalseText: string = !StringHelper.isBlank(this.to.data.falseText) ? this.to.data.falseText : "Non";
		const lsUndefinedText: string = !StringHelper.isBlank(this.to.data.undefinedText) ? this.to.data.undefinedText : "Non déterminé";

		this.boolText = typeof this.fieldValue === "undefined" ?
			lsUndefinedText : this.fieldValue ?
				lsTrueText : lsFalseText;
	}

	//#endregion
}