import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { RoutingHelper } from '../helpers/routing.helper';

@Injectable({
	providedIn: 'root'
})
export class CanGoBackService {

	//#region METHODS

	constructor(private readonly ioRouter: Router) { }

	public canGoBack$(psDefaultHref: string): Observable<boolean> {
		return this.ioRouter.events.pipe(
			filter((poEvent: Event) => poEvent instanceof NavigationEnd),
			startWith(psDefaultHref),
			map(() => this.testRoute(psDefaultHref, this.ioRouter.url))
		);
	}


	protected testRoute(psDefaultHref: string, psRouterUrl: string): boolean {
		return !RoutingHelper.routeEqual(
			psRouterUrl,
			psDefaultHref ?? ""
		);
	}
	//#endregion

}
