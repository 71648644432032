import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DmsModule } from '@calaosoft/osapp/modules/dms/dms.module';
import { MetaComBuilderBase } from './model/MetaComBuilderBase';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		DmsModule.forRoot([MetaComBuilderBase])
	],
	providers: [MetaComBuilderBase]
})
export class ComDmsModule { }
