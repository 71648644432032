import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { EnumHelper } from '../helpers/enumHelper';
import { IdHelper } from '../helpers/idHelper';
import { StringHelper } from '../helpers/stringHelper';
import { DomainsUserSettings } from '../model/application/DomainsUserSettings';
import { IDomainSettings } from '../model/application/IDomainSettings';
import { IUserSettings } from '../model/application/IUserSettings';
import { UserData } from '../model/application/UserData';
import { ConfigData } from '../model/config/ConfigData';
import { EDomainName } from '../model/domain/EDomainName';
import { EPrefix } from '../model/EPrefix';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { ESuffix } from '../model/ESuffix';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { IDataSource } from '../model/store/IDataSource';
import { IWorkspace } from '../model/workspaces/IWorkspace';
import { IWorkspaceInfo } from '../model/workspaces/IWorkspaceInfo';
import { WorkspaceFilter } from '../model/workspaces/WorkspaceFilter';
import { ApplicationService } from './application.service';
import { Store } from './store.service';

@Injectable({ providedIn: "root" })
export class DomainService {

	//#region FIELDS

	private C_DATABASE_ID_APP_STORAGE: string;

	//#endregion

	//#region PROPERTIES

	public static readonly C_DEFAULT_DOMAIN_ID: string = "common";

	private moDomainsUserSettings: DomainsUserSettings;
	public get domainsUserSettings(): DomainsUserSettings {
		return this.moDomainsUserSettings;
	}
	public set domainsUserSettings(poDomainsUserSettings: DomainsUserSettings) {
		this.saveUserSettings(poDomainsUserSettings)
			.subscribe(
				(poResult: DomainsUserSettings) => this.moDomainsUserSettings = poResult
			);
	}

	/** Permet de tracer le domaine actif. */
	private msCurrentDomain: string = EDomainName.common;
	public get currentDomain(): string {
		return this.msCurrentDomain;
	}
	public set currentDomain(psCurrentDomain: string) {
		if (StringHelper.isBlank(psCurrentDomain))
			this.msCurrentDomain = EDomainName.common;
		else
			this.msCurrentDomain = psCurrentDomain;

		if (UserData.current && this.domainsUserSettings)
			UserData.workspaceFilters = this.getWorkspacesFilter(this.currentDomain);
	}

	public domainIds: Array<string> = [];

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en bases de données. */
		private isvcStore: Store,
		psvcApplicationService: ApplicationService) {

		this.init(psvcApplicationService);
		EnumHelper.getValues(EDomainName).forEach((psValue: string) => this.domainIds.push(psValue));
	}

	/** Initialise le service du domaine en récupérant l'id de la base de données correspondant.
	 * @param psvcApplicationService Service d'application permettant de récupérer des flags par exemple.
	 */
	private init(psvcApplicationService: ApplicationService): void {
		psvcApplicationService.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.C_DATABASE_ID_APP_STORAGE = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage))))
			.subscribe();

		psvcApplicationService.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.loadDomainsUserSettings()))
			.subscribe();
	}

	/** Mémorise un paramétrage de filtrage pour une liste de workspaces indiqué sous forme de liste.
	 * @param psDomainId Le domaine pour lequel on souhaite mémoriser des paramètres.
	 * @param paWorkspacesFilter Une liste des domaines dont on veut activer la visibilité, ceux qui sont omis sont considéré comme devant être masqués.
	 */
	public applyWorkspacesFilter(psDomainId: string = EDomainName.common, paWorkspacesFilter: string[]): Observable<IDomainSettings> {
		let loApply$: Observable<IDomainSettings | never>;

		if (UserData.current) {
			// Cherche une section de paramètres pour le domaine.
			let loDomainSettings: IDomainSettings = this.domainsUserSettings[psDomainId] as IDomainSettings;

			if (!loDomainSettings) {
				// Pas de section de paramètres pour ce domaine => on en initialise une nouvelle.
				loDomainSettings = { workspacesFilter: {} };
				this.domainsUserSettings[psDomainId] = loDomainSettings;
			}

			// Active ou désactive la visibilité de chaque espace de travail auquel l'utilisateur a accès.
			UserData.current.workspaceInfos.forEach((poWorkspaceInfo: IWorkspaceInfo) => {
				// Cherche une correspondance dans le tableau en paramètre.
				loDomainSettings.workspacesFilter[poWorkspaceInfo.id] = !!paWorkspacesFilter.find((psItem: string) => psItem === poWorkspaceInfo.id);
			});

			// Persiste la modification.
			loApply$ = this.saveCurrentUserDomainSettings().pipe(mapTo(loDomainSettings));
		}
		else
			loApply$ = EMPTY;

		return loApply$;
	}

	/** Crée le workspace de l'utilisateur.
	 * @param psUserId Id de l'utilisateur.
	 * @param psWorkspaceId Id du workspace.
	 */
	public createUserWorkspace(psUserId: string, psName: string, psWorkspaceId: string): IWorkspace {
		return {
			_id: this.createWorkspaceId(psWorkspaceId),
			name: psName,
			userId: psUserId,
			databases: [
				this.createWorkspaceDatabaseName(psWorkspaceId, `_coll${ESuffix.contacts}`),
				this.createWorkspaceDatabaseName(psWorkspaceId, `_coll${ESuffix.calendars}`),
			],
			permissions: [psUserId]
		};
	}

	/** Permet de créer l'id des bases de workspace en fonction de variables.
	 * @param psWorkspaceId Id de l'utilisateur ou propre au workspace.
	 * @param psDataBaseRole Rôle de la database.
	 */
	public createWorkspaceDatabaseName(psWorkspaceId: string, psDataBaseRole: string): string {
		return `${ConfigData.appInfo.appId}_core_${this.createWorkspaceIdForDb(psWorkspaceId)
			}${psDataBaseRole.indexOf("_") >= 0 ? psDataBaseRole : `_${psDataBaseRole}`}`;
	}

	/** Crée l'identifiant du workspace pour créer la base de données.
	 * @param psWorkspaceId Identifiant du workspace.
	 */
	private createWorkspaceIdForDb(psWorkspaceId: string): string {
		return (psWorkspaceId.indexOf("ws") >= 0 ? psWorkspaceId.replace("_", "") : `ws${psWorkspaceId}`);
	}

	/** Crée l'identifiant du workspace.
	 * @param psWorkspaceId Identifiant du workspace.
	 */
	private createWorkspaceId(psWorkspaceId: string): string {
		return (psWorkspaceId.indexOf(EPrefix.workspace) >= 0 ? psWorkspaceId : IdHelper.buildId(EPrefix.workspace, psWorkspaceId));
	}

	private getUserSettingsDocId(psUserSettingsId: string): string {
		return `${EPrefix.userSettings + ConfigData.authentication.userId}_${psUserSettingsId}`;
	}

	/** Interprête les userSettings pour en déduire les workspaces à afficher dans un domaine.
	 * Pour chaque workspace auquel l'utilisateur, indique s'il est visible, masqué ou indéterminé.
	 */
	public getWorkspacesFilter(psDomainId: string): WorkspaceFilter[] {
		if (!UserData.current)
			throw new NoCurrentUserDataError();

		else if (!UserData.current.workspaceInfos || (UserData.current.workspaceInfos && UserData.current.workspaceInfos.length === 0))
			return [];

		else if (!this.domainsUserSettings)
			throw new Error("Les préférences utilisateurs par domaine n'ont pas été chargées.");

		else {
			return UserData.current.workspaceInfos
				.map((poWorkspace: IWorkspaceInfo) => WorkspaceFilter.fromDomainsUserSettings(psDomainId, poWorkspace.id, this.domainsUserSettings))
				.filter((poFilter: WorkspaceFilter) => poFilter != null);
		}
	}


	/** Charge les settings des domaines. */
	public loadDomainsUserSettings(): Observable<DomainsUserSettings> {
		return this.loadUserSettings(DomainsUserSettings.C_DOMAINS_USER_SETTINGS_ID).pipe(
			map((poResult: IUserSettings) => {
				if (!poResult)
					poResult = new DomainsUserSettings(); // Charge les paramètres par défaut si rien n'existe en BD.

				this.moDomainsUserSettings = poResult;

				return poResult;
			}));
	};

	/** Charge les user settings pour un id donné, retoune le document associé ou `undefined` si pas de document.
	 * @param psUserSettingsId Identifiant des paramètres utilisateur à charger.
	 */
	public loadUserSettings(psUserSettingsId: string): Observable<IUserSettings> {
		const loParams: IDataSource = {
			databaseId: this.C_DATABASE_ID_APP_STORAGE,
			viewParams: {
				key: this.getUserSettingsDocId(psUserSettingsId),
				include_docs: true
			}
		};

		return this.isvcStore.get(loParams).pipe(map((paResults: IUserSettings[]) => ArrayHelper.getFirstElement(paResults)));
	}

	/** Sauvegarde les paramètres utilisateur domaines actifs. */
	public saveCurrentUserDomainSettings(): Observable<DomainsUserSettings> {
		return this.saveUserSettings(this.domainsUserSettings);
	}

	/** Sauvegarde les paramètres utilisateurs
 * @param poUserSettings Params du user.
 */
	public saveUserSettings(poUserSettings: IUserSettings): Observable<IUserSettings> {
		poUserSettings._id = this.getUserSettingsDocId(poUserSettings.userSettingsId);

		return this.isvcStore.put(poUserSettings, this.C_DATABASE_ID_APP_STORAGE)
			.pipe(mapTo(poUserSettings));
	};

	//#endregion
}