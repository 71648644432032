<!-- ZONE DE RECHERCHE -->
<div *ngIf="hasSearchbox" class="search-box txt-center form-list width100pc">
	<search #search class="search" [data]="documents" [options]="searchOptions"
		(filteredEntriesChanged)="onFilteredDocumentsChanged($event)"></search>
	<osapp-selector [displayMode]="selectorDisplayMode.tags" [options]="selectOptions" [display]="displayRoleTags"
		(selectionChanged)="onRoleSelectionChanged($event, search)" [multiple]="true">
	</osapp-selector>
</div>

<!-- Message si aucun groupe. -->
<ion-label *ngIf="!isLoading && filteredDocuments.length === 0" class="ion-margin">
	Aucun groupe.
</ion-label>

<ion-virtual-scroll class="marge-btm-list" [items]="filteredDocuments" [trackBy]="trackById">
	<ion-item-sliding #itemSliding osappAutoCloseSlideItem *virtualItem="let group" data-cy="group-item"
		[disabled]="params.swipeDisabled">

		<ion-item lines="full" class="dispo-group" (click)="onItemClicked(group)">
			<!-- Chaque division de groupe -->
			<calao-conflict-list-marker *ngIf="group._conflicts"></calao-conflict-list-marker>
			<!-- Avatar du groupe -->
			<avatar [src]="getGroupAvatar(group)" [cssClass]="'round'" style="margin-right: 16px; margin-left: 16px;"
				(srcError)="onImgError($event, group)">
			</avatar>
			<ion-grid class="no-margin">
				<ion-row>
					<!-- Nom du groupe -->
					<ion-label class="ion-no-padding ion-text-wrap group-name">{{group.name}}</ion-label>
				</ion-row>
				<ion-row *ngIf="group.roles">
					<!-- Nom du groupe -->
					<ion-note class="ion-text-wrap group-roles">{{getRolesNames(group)}}</ion-note>
				</ion-row>
				<ion-row>
					<!-- Membres du groupe -->
					<ion-note class="ion-text-wrap contacts-group">
						{{ contactsByGroupId.get(group._id) | groupMemberNamesEllipsis }}
					</ion-note>
				</ion-row>
			</ion-grid>
			<!-- Bouton du menu contextuel -->
			<ion-button class="itemb btn-more" fill="clear" slot="end" *ngIf="itemSlidingEnabled"
				(click)="openOrCloseItemSliding(itemSliding, $event)">
				<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
			</ion-button>
		</ion-item>

		<!-- Options des items. -->
		<ion-item-options side="end">
			<div *ngFor="let option of itemOptions">
				<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
					(click)="onItemOptionClicked(group, option.action, itemSliding, option)">
					<ion-icon [name]="option.icon"></ion-icon>
					{{option.label}}
				</ion-button>
			</div>
		</ion-item-options>
	</ion-item-sliding>
</ion-virtual-scroll>