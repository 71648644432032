<div class="field-wrapper" [ngStyle]="{'padding': (observablePadding.value$ | async)}">
	<ng-container [ngTemplateOutlet]="(observableReadonly.value$ | async) ? visuTemplate: editTemplate"></ng-container>
</div>

<ng-template #editTemplate>
	<div class="title-section">
		<ion-label class="label-group">{{ observableFieldLabel.value$ | async }}</ion-label>
		<div class="activation-wrapper">
			<ion-note>{{ observableToggleLabel.value$ | async }}</ion-note>
			<ion-toggle [checked]="observableActivation.value$ | async" (ionChange)="onActivationChanged($event)" mode="ios">
			</ion-toggle>
		</div>
	</div>

	<ng-container *ngIf="observableActivation.value$ | async">
		<div class="date-wrapper">
			<ng-container *ngIf="observableDate.value$ | async as date">
				<osapp-date-time [params]="observableDatePickerParams.value$ | async" [model]="date"
					(modelChange)="onDateChanged($event)"></osapp-date-time>
				<osapp-date-time [params]="observableTimePickerParams.value$ | async" [model]="date"
					(modelChange)="onDateChanged($event)"></osapp-date-time>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-template #visuTemplate>
	<ion-label class="visu-label">{{ observableFieldLabel.value$ | async }} le {{ observableReadonlyDateLabel.value$ |
		async }}</ion-label>
</ng-template>