import { Injectable } from '@angular/core';
import { ModalOptions } from '@ionic/core/dist/types/components/modal/modal-interface';
import { map } from 'rxjs/operators';
import { IdHelper } from '../../../../../helpers/idHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { EPrefix } from '../../../../../model/EPrefix';
import { IConversation } from '../../../../../model/conversation/IConversation';
import { ModalService } from '../../../../modal/services/modal.service';
import { CreateConversationSelectorModalComponent } from '../create-conversation-selector-modal.component';
import { EConversationSelectorModalAction } from '../models/econversation-selector-modal-action';
import { ICreateConversationSelectorModalComponentParams } from '../models/icreate-conversation-selector-modal-component-params';
import { ICreateConversationModalResponse } from '../models/icreate-conversation-selector-modal-response';

@Injectable()
export class CreateConversationSelectorModalOpenerService {

	//#region FIELDS

	private static readonly C_LOG_ID = "CCSMO.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService
	) { }

	/** Ouvre la modale de sélection des conversations.
	 * @param paConversations Tableau des conversations à afficher dans la modal.
	 * @returns Un objet contenant l'id de la conversation à ouvrir et l'action `open`,
	 * `creation` pour créer une nouvelle conversation et `canceled` pour ne rien faire.
	 */
	public openAsync(paConversations: IConversation[]): Promise<ICreateConversationModalResponse | undefined> {
		const lsNewConversationKey = "newConversation";

		const loOptions: ModalOptions = {
			component: CreateConversationSelectorModalComponent,
			componentProps: {
				conversations: paConversations,
				title: "Sélection des conversations",
				newConversationKey: lsNewConversationKey
			} as ICreateConversationSelectorModalComponentParams
		};

		return this.isvcModal.open(loOptions)
			.pipe(map((psResponse?: string) => this.getConversationModalResponse(lsNewConversationKey, paConversations, psResponse)))
			.toPromise();
	}

	/** Retourne un objet contenant l'id de la conversation à ouvrir et l'action `open`,
	 * `creation` pour créer une nouvelle conversation et `canceled` pour ne rien faire.
	 * @param psNewConversationKey Chaîne de caractères pour identifier si on doit créer une nouvelle conversation.
	 * @param paConversation Tableau des conversations afficher dans la modale.
	 * @param psResponse La réponse de la modale.
	 */
	private getConversationModalResponse(psNewConversationKey: string, paConversation: IConversation[], psResponse?: string): ICreateConversationModalResponse {
		// Dans le cas où on quitte la modale (on clique sur la croix ou sur `Annuler`).
		if (StringHelper.isBlank(psResponse))
			return { action: EConversationSelectorModalAction.canceled };
		// Dans le cas où on doit créer une nouvelle conversation.
		else if (psResponse === psNewConversationKey)
			return { action: EConversationSelectorModalAction.creation };
		// Dans le cas où on doit ouvrir une conversation.
		else if (IdHelper.getPrefixFromId(psResponse) === EPrefix.conversation)
			return { conversation: this.getConversationToOpen(paConversation, psResponse), action: EConversationSelectorModalAction.open };
		else { // Dans des cas non gérés.
			console.error(`${CreateConversationSelectorModalOpenerService.C_LOG_ID}Error : One case is not managed in the modal's response.`);
			return { action: EConversationSelectorModalAction.canceled };
		}
	}

	/** Retourne une conversation depuis un tableau de conversations grâce à son id, `undefined` si non trouvé.
	 * @param paConversations Tableau de conversations.
	 * @param psConversationId L'id de la conversation à retourner.
	 */
	private getConversationToOpen(paConversations: IConversation[], psConversationId: string): IConversation | undefined {
		return paConversations.find((poConversation: IConversation) => poConversation._id === psConversationId);
	}

	//#endregion METHODS

}