<div class="widget-area">
	<h3>Widget Calao: </h3>
	<br>
	<ng-container *ngIf="designableDescriptor.selectedDefinitionId">
		<ng-container *ngFor="let item of allFields">
			<ng-container *ngIf="item.type !== 'label'">
				<button class="btn-widget" (click)="onAddFieldToSelectedDefinition(item.type)">{{item.type}}</button>
				<br>
			</ng-container>
		</ng-container>
	</ng-container>
</div>