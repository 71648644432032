import { Type } from "@angular/core";
import { EventParticipantEventBase } from "../models/event-participant-event-base";
import { EventParticipantRemoveEndEvent } from "../models/event-participant-remove-end-event";
import { EventParticipantRemoveEvent } from "../models/event-participant-remove-event";
import { IEventParticipantEventData } from "../models/ievent-participant-event-data";
import { IEventParticipantEventEndedData } from "../models/ievent-participant-event-ended-data";
import { EventParticipantTaskBase } from "./event-participant-task-base";

export class EventParticipantRemoveTask extends EventParticipantTaskBase {

	//#region FIELDS

	protected override startEventType: Type<EventParticipantEventBase<IEventParticipantEventData>> = EventParticipantRemoveEvent;
	protected override endEventType: Type<EventParticipantEventBase<IEventParticipantEventEndedData>> = EventParticipantRemoveEndEvent;

	//#endregion

}