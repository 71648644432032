import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SlidingListModule } from '../sliding-list/sliding-list.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { ColumnListHeaderColumnComponent } from './components/column-list/column-list-header-column/column-list-header-column.component';
import { ColumnListHeaderComponent } from './components/column-list/column-list-header/column-list-header.component';
import { ColumnListRowColumnComponent } from './components/column-list/column-list-row-column/column-list-row-column.component';
import { ColumnListRowComponent } from './components/column-list/column-list-row/column-list-row.component';
import { ColumnListComponent } from './components/column-list/column-list.component';
import { ListItemContentPrefixComponent } from './components/list/list-item-content-prefix/list-item-content-prefix.component';
import { ListItemContentSuffixComponent } from './components/list/list-item-content-suffix/list-item-content-suffix.component';
import { ListItemContentTitleComponent } from './components/list/list-item-content-title/list-item-content-title.component';
import { ListItemContentComponent } from './components/list/list-item-content/list-item-content.component';
import { ListItemEndComponent } from './components/list/list-item-end/list-item-end.component';
import { ListItemStartComponent } from './components/list/list-item-start/list-item-start.component';
import { ListItemComponent } from './components/list/list-item/list-item.component';

const components: Array<Type<any> | any[]> = [
	ListItemComponent,
	ListItemStartComponent,
	ListItemContentComponent,
	ListItemContentSuffixComponent,
	ListItemContentPrefixComponent,
	ListItemContentTitleComponent,
	ColumnListComponent,
	ColumnListHeaderComponent,
	ColumnListHeaderColumnComponent,
	ColumnListRowComponent,
	ColumnListRowColumnComponent,
	ListItemEndComponent
];

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		SlidingListModule,
		VirtualScrollModule
	],
	declarations: components,
	exports: components
})
export class ListsModule { }
