import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigData } from '../model/config/ConfigData';
import { IAuthStatus } from '../model/security/IAuthStatus';
import { ApplicationService } from '../services/application.service';
import { SecurityService } from '../services/security.service';

@Injectable({ providedIn: "root" })
export class NotAuthenticatedGuard implements CanActivate {

	//#region METHODS

	constructor(
		private readonly isvcSecurityService: SecurityService,
		private readonly ioNavController: NavController,
		private readonly ioMenu: MenuController
	) { }

	public canActivate(): Observable<boolean> {
		return this.isvcSecurityService.authenticationStatus$
			.pipe(
				map((poAuthStatus: IAuthStatus) => {
					const lbCanActivate: boolean = !poAuthStatus.isAuthenticated || !!ConfigData.environment.unlockRequired;
					console.log("NOTAUTH.G.S:: canActivate", lbCanActivate);

					if (!lbCanActivate)
						this.ioNavController.navigateRoot(ApplicationService.C_HOME_ROUTE_URL);
					else
						this.ioMenu.enable(false);

					return lbCanActivate;
				})
			);
	}

	//#endregion

}