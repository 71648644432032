import { Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { IDesignableDescriptor } from '../../../model/IDesignableDescriptor';
import { DesignService } from '../../../services/design.service';

/** Permet de tester la définition sélectionnée.
 * Son entrée de formulaire sera affichée dans ce composant.
 */
@Component({
	selector: "form-data-simulator",
	templateUrl: './form-data-simulator.component.html',
	styleUrls: ['./form-data-simulator.component.scss']
})
export class FormDataSimulatorComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public show = false;
	public designableDescriptor: IDesignableDescriptor;

	//#endregion

	//#region METHODS

	constructor(private isvcDesign: DesignService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcDesign.getDesignableDescriptorAsObservable()
			.pipe(
				tap((poDesignableDescriptor: IDesignableDescriptor) => this.designableDescriptor = poDesignableDescriptor),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet d'afficher ou non le contenu du composant. */
	public onShow(): void {
		this.show = !this.show;
	}

	//#endregion

}