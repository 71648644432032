import { Component, OnInit } from '@angular/core';
import { IAppStartPauseLogData } from '@calaosoft/osapp/model/application/iapp-start-pause-log-data';
import { ConfigData } from '@calaosoft/osapp/model/config/ConfigData';
import { IConfig } from '@calaosoft/osapp/model/config/IConfig';
import { DeviceNotAuthorizedError } from '@calaosoft/osapp/model/store/error/device-not-authorized-error';
import { BatteryService } from '@calaosoft/osapp/modules/battery/services/battery.service';
import { EFlag } from '@calaosoft/osapp/modules/flags/models/EFlag';
import { Loader } from '@calaosoft/osapp/modules/loading/Loader';
import { ELogActionId } from '@calaosoft/osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { PatchService } from '@calaosoft/osapp/modules/patch/services/patch.service';
import { PwaUpdateService } from '@calaosoft/osapp/modules/pwa/services/pwa-update.service';
import { PageManagerService } from '@calaosoft/osapp/modules/routing/services/pageManager.service';
import { AppComponentBase } from '@calaosoft/osapp/modules/utils/components/app-component-base';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { ConfigService } from '@calaosoft/osapp/services/config.service';
import { FlagService } from '@calaosoft/osapp/services/flag.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@calaosoft/osapp/services/loading.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { MenuController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import packageJson from '../../package.json';
import * as constants from '../config';
import { ComComponentRegister } from '../model/ComComponentRegister';

@Component({
	selector: "ion-app",
	templateUrl: 'app.component.html'
})
export class AppComponent extends AppComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "APP.C::"

	//#endregion

	//#region PROPERTIES

	/** Clé du menu sur le côté. */
	public readonly menuKey = "sideMenu";
	/** Titre du menu, si vide, pas de toolbar. */
	public readonly menuTitle = "Texcom";

	//#endregion

	//#region METHODS

	constructor(
		private isvcLoading: LoadingService,
		private isvcConfig: ConfigService,
		/** Service de menu angular. */
		private ioMenu: MenuController,
		private isvcUiMessage: UiMessageService,
		private readonly isvcPatch: PatchService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPwaUpdate: PwaUpdateService,
		private readonly isvcLogger: LoggerService,
		psvcBattery: BatteryService,
		psvcApplication: ApplicationService,
		psvcPageManager: PageManagerService,
		psvcPlatform: PlatformService,
	) {
		super(psvcApplication, psvcPlatform, psvcBattery);

		psvcPageManager.addComponents(ComComponentRegister.getRouteComponents());
	}

	public ngOnInit(): void {
		let loLoader: Loader;
		console.log("APP.C::En attente d'initialisation de la plateforme...");

		this.ioMenu.swipeGesture(true);
		this.ioMenu.enable(false);

		constants.environment.version = packageJson.version;
		this.isvcPatch.applyPatches() // Application des patchs.
			.pipe(
				mergeMap(_ => this.isvcLoading.create("Démarrage de l'application ...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConfig.init(constants as IConfig)), // Initialisation des configs de l'app.
				tap(
					_ => {
						this.isvcFlag.setFlagValue(EFlag.appInitialized, true);
						this.logAppInitialized();
					},
					poError => this.initError(poError)
				),
				finalize(() => loLoader.dismiss())
			)
			.subscribe();

		this.isvcPwaUpdate.initUpdateCheck();
	}

	private initError(poError: string | DeviceNotAuthorizedError | any): void {
		console.error(`APP.C::Application initialization error.`, poError);

		//! Nécessaire afin de supprimer le loader : si utilisation de l'instance du loader ou si cette ligne de code est appelée dans le finalize() alors le loader restera.
		this.isvcLoading.dismissAll();

		/** Message d'erreur à afficher à l'utilisateur. */
		let lsMessage: string;

		// Si l'erreur reçu est de type `string`, elle est générée par OSApp, on l'affiche directement à l'utilisateur.
		if (typeof poError === "string")
			lsMessage = poError;
		// Si c'est un type d'erreur géré par OSApp, on l'affiche.
		else if (poError instanceof DeviceNotAuthorizedError)
			lsMessage = poError.message;
		else	// Type d'erreur non géré.
			lsMessage = "Une erreur critique s'est produite. Veuillez contacter le support technique.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Erreur",
				message: lsMessage,
				buttons: [
					{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
					{ text: "Réessayer", handler: () => { ApplicationService.reloadApp(); } } as AlertButton
				]
			})
		);
	}

	private logAppInitialized(): void {
		console.log("APP.C::Application initialized");

		const loAppStartPauseLogData: IAppStartPauseLogData = {
			version: ConfigData.appInfo.appVersion!,
			environment: ConfigData.environment.id
		};

		this.isvcLogger.action(
			AppComponent.C_LOG_ID,
			"Démarrage de l'application.",
			ELogActionId.appStart,
			loAppStartPauseLogData
		);
	}

	//#endregion

}