import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICustomer } from '../../../model/customer/icustomer';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IDataSourceViewParams } from '../../../model/store/IDataSourceViewParams';
import { Store } from '../../../services/store.service';

@Injectable()
export class CustomersService {

	//#region METHODS

	constructor(protected readonly isvcStore: Store) { }

	/** Retourne un tableau de clients.
	 * @param poDataSource Datasource passée au store pour la récupération des clients.
	 */
	public getCustomers<T extends ICustomer>(poDataSource: IDataSource<T>): Observable<T[]> {
		return this.isvcStore.get<T>(poDataSource);
	}

	/** Récupère le client en base données grâce à l'identifiant passé en paramètre.
	 * @param psCustomerId Identifiant du client à récupérer.
	 */
	public getCustomer<T extends ICustomer>(psCustomerId: string): Observable<T> {
		return this.isvcStore.getOne<T>({
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psCustomerId,
				include_docs: true
			} as IDataSourceViewParams
		});
	}

	//#endregion

}