import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { Observable, defer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { EModalSize } from '../model/EModalSize';

@Injectable()
export class ModalService {

	//#region METHODS

	constructor(private readonly ioModalCtrl: ModalController) { }

	public open<T = any>(poOptions: ModalOptions, peModalSize?: EModalSize): Observable<T> {
		if (peModalSize)
			poOptions.cssClass = `${(poOptions.cssClass + " ") ?? ""}${peModalSize}`;

		return defer(() => this.ioModalCtrl.create(poOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				map((poResponse: OverlayEventDetail<T>) => poResponse.data)
			);
	}

	//#endregion

}