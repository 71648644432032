import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Keyboard } from '@capacitor/keyboard';
import { IonContent } from '@ionic/angular';
import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { DateHelper } from '../../helpers/dateHelper';
import { IdHelper } from '../../helpers/idHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EPrefix } from '../../model/EPrefix';
import { IListDefinitionsField } from '../../model/forms/IListDefinitionsField';
import { ENavigationType } from '../../model/navigation/ENavigationType';
import { ISearchOptions } from '../../model/search/ISearchOptions';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { GeolocationHelper } from '../../modules/geolocation/helpers/geolocation.helper';
import { IGeolocationCoordinates } from '../../modules/navigation/models/igeolocation-coordinates';
import { ObservableArray } from '../../modules/observable/models/observable-array';
import { FavoritesService } from '../../modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '../../modules/preferences/model/IPreferences';
import { RecentsService } from '../../modules/preferences/recents/services/recents.service';
import { IAutocompleteOptionClickedEvent } from '../../modules/search/models/iautocomplete-option-clicked-event';
import { EntityLinkService } from '../../services/entityLink.service';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '../../services/store.service';

interface ColumnWithKeyByIndex<T> { [index: number]: { key: keyof T } }
interface IAutoCompleteData<T> { data: T, text: string }
interface IGpsCoordinates {
	key: string;
	value: number;
}
interface IUserGpsCoordinates {
	latitude: IGpsCoordinates;
	longitude: IGpsCoordinates;
}

@Component({
	selector: "search",
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent<T extends IStoreDocument> extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_INPUT_DEBOUNCE_MS = 500;
	private static readonly C_VISIBLE = "visible";

	/** Contenu de l'input du search général. */
	@Output() private readonly searchValueChange = new EventEmitter<string>();
	@Output("autocompleteOptionClicked") private readonly moAutocompleteOptionClickedEvent = new EventEmitter<IAutocompleteOptionClickedEvent<T>>();
	/** Événement permettant au composant père de récupérer le changement de la propriété "filteredEntries". */
	@Output("filteredEntriesChanged") private readonly moFilteredEntriesChangedEvent: EventEmitter<Array<T>>;

	private readonly moPrefixSubject = new Subject<EPrefix>();
	private readonly moMutationObserver = new MutationObserver(() => this.focus());

	private moUserGpsCoordinates: IUserGpsCoordinates;
	private maFilteredEntries: T[];

	//#endregion

	//#region PROPERTIES

	/** Objet contenant les propriétés nécessaires pour fonctionner. */
	private moOptions: ISearchOptions<T> = {};
	public get options(): ISearchOptions<T> { return this.moOptions; }
	@Input() public set options(poValue: ISearchOptions<T>) {
		if (poValue)
			this.moOptions = poValue;
	}

	/** Contient toutes les entrées sur lesquelles effectuer les recherches. */
	private maData: ObservableArray<T>;
	public get data(): Array<T> | ObservableArray<T> { return this.maData; }
	@Input("data") public set data(paValues: Array<T> | ObservableArray<T>) {
		this.setData(paValues);
	}

	private mbAutofocus = false;
	public get autofocus(): boolean { return this.mbAutofocus; }
	@Input("autofocus")
	public set autofocus(pbAutofocus: boolean) {
		if (pbAutofocus !== this.mbAutofocus) {
			this.mbAutofocus = pbAutofocus;
			this.focus();
		}
	}

	public get searchValue(): string { return this.searchFormControl.value; }
	/** Valeur recherchée. */
	@Input() public set searchValue(psValue: string) {
		if (psValue !== this.searchValue)
			this.searchFormControl.setValue(psValue);
	}

	@Input() public customButtonsTemplate: TemplateRef<any>;

	private moSearchbar: ElementRef<HTMLDivElement>;
	/** L'instance ion-searchbar présent dans le HTML. */
	public get searchbar(): ElementRef<HTMLDivElement> { return this.moSearchbar; }
	@ViewChild("searchBarElement") public set searchbar(poSearchbar: ElementRef<HTMLDivElement>) {
		if (poSearchbar)
			this.moSearchbar = poSearchbar;
		this.focus();
	}

	@ViewChild(MatAutocompleteTrigger) public trigger: MatAutocompleteTrigger;
	@ViewChild("autoGroup") public autoGroup: MatAutocomplete;
	@ViewChild("searchDiv") public searchDiv: ElementRef<HTMLDivElement>;

	/** Rayon de la recherche par localisation. */
	public currentLocationRadius: number;
	/** Indique si le panel de recherche avancée est fermé. */
	public isPanelCollapsed = true;
	/** Contenu des champs de recherche du panel. */
	public searchPanelValues: Array<any> = [];
	/** Contrôle de formulaire pour l'input de recherche permettant de rajouter une logique lors du changement de sa valeur. */
	public searchFormControl: UntypedFormControl;
	/** Indique si la recherche GPS est active ou non. */
	public isLocationEnabled: boolean;
	public autoCompleteOptions: Map<string, IAutoCompleteData<T>[]>;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de navigation. */
		private readonly isvcNavigation: NavigationService,
		private readonly isvcFavorites: FavoritesService,
		private readonly isvcRecents: RecentsService,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioElementRef: IonContent,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);

		this.moFilteredEntriesChangedEvent = new EventEmitter();
		this.searchFormControl = new UntypedFormControl();
		this.moMutationObserver.observe(document, { attributeFilter: ["visibility"], subtree: true, childList: true });
	}

	public ngOnInit(): void {
		this.init();
		this.initAutocomplete();

		this.searchFormControl.valueChanges
			.pipe(
				debounceTime(SearchComponent.C_INPUT_DEBOUNCE_MS),
				tap((psNewValue: string) => {
					if (!StringHelper.isBlank(psNewValue))
						this.trigger.closePanel();
					else
						this.trigger.openPanel();

					this.searchValueChange.emit(psNewValue);
					this.emitNewValues(this.search());
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moPrefixSubject.complete();
		this.moMutationObserver.disconnect();
	}

	private setData(paValues: Array<T> | ObservableArray<T>): void {
		if (!paValues)	// Si tableau undefined, alors on considère que c'est un tableau vide.
			paValues = [];

		if (!this.maData) { // Singleton
			if (paValues instanceof ObservableArray)
				this.maData = paValues;
			else
				this.maData = new ObservableArray(paValues);

			this.maData.changes$
				.pipe(
					tap(() => {
						this.moPrefixSubject.next(IdHelper.getPrefixFromId(ArrayHelper.getFirstElement(this.maData)?._id));
						this.initSearchableFields();
						this.emitNewValues(this.searchInDisplayFields());
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}

		if (paValues !== this.maData)
			this.maData.resetArray(paValues);
	}

	private initAutocomplete(): void {
		this.ioElementRef.scrollEvents = true;
		this.ioElementRef.ionScroll
			.pipe(
				filter(() => this.trigger.panelOpen),
				tap(() => this.trigger.closePanel()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.moPrefixSubject.asObservable()
			.pipe(
				distinctUntilChanged(),
				switchMap((pePrefix: EPrefix) => combineLatest([this.isvcFavorites.get(pePrefix, true), this.isvcRecents.get(pePrefix, true)])),
				map((paPreferences: [IPreferences, IPreferences]) => {
					const loAutoCompleteOptions = new Map<string, IAutoCompleteData<T>[]>();

					if (ArrayHelper.hasElements(paPreferences[1]?.ids))
						loAutoCompleteOptions.set("Récents", this.getAutoCompleteData(paPreferences[1]?.ids));
					if (ArrayHelper.hasElements(paPreferences[0]?.ids))
						loAutoCompleteOptions.set("Favoris", this.getAutoCompleteData(paPreferences[0]?.ids));

					return loAutoCompleteOptions;
				}),
				tap((poAutoCompleteOptions: Map<string, IAutoCompleteData<T>[]>) => {
					this.autoCompleteOptions = poAutoCompleteOptions;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	private getAutoCompleteData(paIds?: string[]): IAutoCompleteData<T>[] {
		const laValues: IAutoCompleteData<T>[] = [];

		if (ArrayHelper.hasElements(paIds)) {
			this.data.forEach((poData: T) => {
				if (paIds.includes(poData._id))
					laValues.push({ data: poData, text: this.isvcEntityLink.buildEntity(poData)?.name });
			});
		}

		return laValues;
	}

	private focus(): void {
		if (this.moSearchbar?.nativeElement) {
			this.moSearchbar.nativeElement.autofocus = this.autofocus;
			if (getComputedStyle(this.searchbar.nativeElement).visibility === SearchComponent.C_VISIBLE) {
				this.moMutationObserver.disconnect();

				if (this.autofocus)
					this.searchbar.nativeElement.focus();
			}

			this.detectChanges();
		}
	}

	public openAutocomplete(): void {
		if (StringHelper.isBlank(this.searchValue))
			this.trigger.openPanel();
	}

	/** Ferme le clavier. */
	public closeKeyboard(): void {
		Keyboard.hide().catch(() => { });
	}

	private getRadius(): number {
		if (this.isCurrentLocationRadiusValid())
			return this.currentLocationRadius;
		else if (this.isLocationSearchRadiusValid())
			return this.options.locationSearch.radius;
		else
			return NaN;
	}

	/** Obtient les coordonnées GPS de l'utilisateur et met à jour le texte associé. */
	public getAndFormatLocation(): void {
		this.searchByCoordinates$().pipe(takeUntil(this.destroyed$)).subscribe();
	}

	private searchByCoordinates$(): Observable<void | never> {
		const lnCurrentRadius: number = this.getRadius();

		if (NumberHelper.isValid(lnCurrentRadius)) {
			return this.isvcNavigation.getCurrentPosition()
				.pipe(
					catchError(poError => { console.error("SRCH.C:: Erreur récupération coordonnées GPS :", poError); return EMPTY; }),
					map((poCoordinates: IGeolocationCoordinates) => {
						// Réinitialisation de l'objet contenant les coordonées gps de l'utilisateur.
						this.moUserGpsCoordinates = {
							latitude: { key: this.options.locationSearch.latitudeField, value: poCoordinates.latitude },
							longitude: { key: this.options.locationSearch.longitudeField, value: poCoordinates.longitude }
						};
						// Modification du placeholder.
						this.options.searchboxPlaceholder = `${lnCurrentRadius} kms autour de moi`;
						// Lancement de la recherche.
						this.emitNewValues(this.search());
					}),
					map(() => this.detectChanges()),
					takeUntil(this.destroyed$)
				);
		}
		else
			return EMPTY;
	}

	/** Initialise le composant. */
	private init(): void {
		if (!this.options)
			this.options = { searchableFields: [] };

		if (this.options.hasPreFillData === undefined)
			this.options.hasPreFillData = true;

		if (StringHelper.isBlank(this.options.searchboxPlaceholder))
			this.options.searchboxPlaceholder = "";

		// Si le nombre max d'entrées filtrées n'est pas valide et strictement positif, on met une valeur par défaut.
		if (!(NumberHelper.isValid(this.options.maxFilteredEntries) && this.options.maxFilteredEntries > 0))
			this.options.maxFilteredEntries = Infinity; // On peut mettre un "vrai" nombre si besoin, ce cas fonctionne bien avec des virtual scroll.

		if (this.options.locationSearch)
			this.initLocationSearch();

		// S'il y a des données, on initialise le searchableFields.
		if (this.data)
			this.initSearchableFields();
	}

	/** Initialise les options de géolocalisation de recherche. */
	private initLocationSearch(): void {
		this.isvcNavigation.resetConfig(ENavigationType.waze);

		this.currentLocationRadius = this.options.locationSearch.searchRadiusOptions[0];

		this.isLocationEnabled = true; // Si on a des options de géolocalisation, on l'active.
		this.emitNewValues(this.search()); // Si la géolocalisation est activée, on la lance.
	}

	/** Initialise, si besoin, des options de champs recherchables par défaut (s'il n'y en a pas de renseigné). */
	private initSearchableFields(): void {
		// S'il y a des données et qu'il n'y a pas de champs recherchables d'indiqués ni de fonction de recherche, on ajoute des champs recherchables par défaut (tous).
		if (this.options && !ArrayHelper.hasElements(this.options.searchableFields) && !this.options.searchFunction) {
			console.warn("SRCH.C:: Attention aucun champ ni fonction de recherche, la recherche sera faite sur chaque champs primitifs des objets manipulés !");
			const laSearchableFields: IListDefinitionsField<T>[] = [];
			const loFirstItem: T = ArrayHelper.getFirstElement(this.data);

			for (const lsKey in loFirstItem) {
				if (lsKey !== Store.C_ID_PROPERTY && lsKey !== Store.C_REVISION_PROPERTY && typeof loFirstItem[lsKey] !== "object")
					laSearchableFields.push({ key: lsKey, label: lsKey });
			}

			this.options.searchableFields = laSearchableFields;
		}
	}

	/** Se déclenche lorsque le texte d'un champs du panel change. Effectue la recherche. */
	public onPanelTextChanged(): void {
		const laSearchDisplayFieldsParams: ColumnWithKeyByIndex<T> = {};

		for (let lnIndex = 0; lnIndex < this.searchPanelValues.length; ++lnIndex) {
			if (!StringHelper.isBlank(this.searchPanelValues[lnIndex]))
				laSearchDisplayFieldsParams[lnIndex] = { key: this.options.searchableFields[lnIndex].key };
		}

		this.emitNewValues(this.searchInDisplayFields(laSearchDisplayFieldsParams));
	}

	/** Permet la recherche par les critères de colonnes (en cache dans le scope de l'appli).
	 * Si elle ne prend pas de params elle recherche dans les colonnes qui sont fournies par défaut dans le tableau searchFieldsKeys et sur la valeur tapée dans searchForm.
	 * Sinon elle prend en param la liste des champs où elle doit chercher et les termes qu'elle doit chercher.
	 * @param paCols Tableau indexé par index contenant un objet avec pour seul champ 'key' : colonnes où effecteur la recherche, optionnel.
	 */
	private searchInDisplayFields(paCols?: ColumnWithKeyByIndex<T>): T[] {
		this.maFilteredEntries = []; // On vide le tableau pour éviter les doublons.

		if (paCols && ArrayHelper.hasElements(this.options.searchableFields)) // On est en mode recherche par critère dans le searchPannel.
			this.searchInDisplayFieldsWithColsAndValues();

		else { // On est en mode auto on cherche sur les colonnes qui sont affichées.
			if (!StringHelper.isBlank(this.searchValue)) {

				if (this.searchValue.indexOf(" ") !== -1) {
					const laSearchesForms: Array<string> = this.searchValue.split(" ");
					let laCurrentSearches: Array<any> = this.data;

					for (let lnIndex = 0, lnLength = laSearchesForms.length; lnIndex < lnLength; ++lnIndex) {
						laCurrentSearches = this.searchInFilteredList(laCurrentSearches, laSearchesForms[lnIndex]);
					}
					this.maFilteredEntries = laCurrentSearches;
				}
				else
					this.maFilteredEntries = this.searchInFilteredList(this.data, this.searchValue);
			}
			else
				this.maFilteredEntries = this.data;
		}

		if (this.options)
			return this.verifPreFillDataAndFilledSearchForm(paCols);

		return this.maFilteredEntries;
	}

	/** Mode de recherche par critères dans le searchPannel. */
	private searchInDisplayFieldsWithColsAndValues(): void {
		for (let lnEntriesIndex = 0, lnEntriesLength = this.data.length; lnEntriesIndex < lnEntriesLength; ++lnEntriesIndex) {
			const loEntry: any = this.data[lnEntriesIndex];
			let lbShouldBeDisplayed: boolean;

			for (let lnValuesIndex = 0, lnValuesLength = this.searchPanelValues.length; lnValuesIndex < lnValuesLength; ++lnValuesIndex) {
				const loValue: any = this.searchPanelValues[lnValuesIndex];
				const lsKey: keyof T = this.options.searchableFields[lnValuesIndex].key;

				if (typeof loValue === "string" && !StringHelper.isBlank(loValue)) {

					if (typeof loEntry[lsKey] === "string" && loEntry[lsKey].toLowerCase().indexOf(loValue.toLowerCase()) !== -1)
						lbShouldBeDisplayed = true;
					else {
						lbShouldBeDisplayed = false;
						break;
					}
				}

				if (lbShouldBeDisplayed)
					this.maFilteredEntries.push(loEntry);
			}
		}
	}

	/** Retourne les entrées qui possèdent un champs dont la valeur est celle de psSearchValue.
	 * @param paEntries Entrées où chercher la valeur.
	 * @param psSearchValue Valeur cherchée.
	 */
	private searchInFilteredList(paEntries: T[], psSearchValue: string): T[] {
		const lbSearchBySearchableFields: boolean = ArrayHelper.hasElements(this.options.searchableFields);

		psSearchValue = psSearchValue.toLowerCase(); // On met en minuscule pour la comparaison.

		// On filtre les données à afficher si elles correspondent à une recherche textuelle par champ ou une recherche par fonction.
		return paEntries.filter((poEntry: T) => {
			return (lbSearchBySearchableFields && this.isMatchingFromSearchableFields(poEntry, psSearchValue)) ||
				(this.options.searchFunction && this.options.searchFunction(poEntry, psSearchValue));
		});
	}

	/** Retourne `true` si l'entrée correspond au critère de recherche et doit être affiché, `false` sinon.
	 * @param poEntry Entrée (objet) dans laquelle vérifier si le critère de recherche textuelle est respecté.
	 * @param  psSearchValue Valeur de la recherche textuelle.
	 */
	private isMatchingFromSearchableFields(poEntry: T, psSearchValue: string): boolean {
		return this.options.searchableFields.some((poSearchableField: IListDefinitionsField) => {
			const loSearchableFieldValue: any = poEntry[poSearchableField.key];
			let lbResult = false;

			// Si la valeur courante correspond au critère de la valeur de recherche.
			if (this.isMatchingValue(loSearchableFieldValue, psSearchValue))
				lbResult = true;

			// Si la valeur courante est un tableau dont au moins un élément corespond au critère de la valeur de recherche.
			else if ((loSearchableFieldValue instanceof Array &&
				(this.isMatchingValue(loSearchableFieldValue.join(","), psSearchValue) ||
					loSearchableFieldValue.some((poItem: any) => this.isMatchingValue(poItem, psSearchValue)))
			))
				lbResult = true;

			// Si la valeur est une date valide.
			//! Attention toutefois vu que "ma chaine de caractère 4" est une date valide, on supprime tout espace du champ (une date sérialisée possède '-' et ':' uniquement).
			else if (loSearchableFieldValue instanceof Date ||
				(typeof loSearchableFieldValue === "string" && DateHelper.isDate(loSearchableFieldValue.replace(/ /g, "")))
			) {
				// On stringifie la date en remplaçant tous les caractères qui ne sont pas des chiffres en '/' pour passer au format dd/MM/yyyy/HH/mm/ss.
				const lsSearchableFieldValueDateString: string = new Date(loSearchableFieldValue).toLocaleString().replace(/\D+/g, "/");
				// On vérifie que la valeur cherchée est incluse dans la date stringifiée. On teste aussi en remplaçant tout ce qui n'est pas un chiffre par des '/'.
				lbResult = lsSearchableFieldValueDateString.includes(psSearchValue) || lsSearchableFieldValueDateString.includes(psSearchValue.replace(/[: \-]+/g, "/"));
			}

			return lbResult;
		});
	}

	/** Retourne `true` si la valeur correspond aux critères de la valeur de la recherche, `false` sinon.
	 * @param poValue Valeur dont il faut vérifier la correspondance avec la valeur de la recherche.
	 * @param psSearchValue Valeur recherchée.
	 */
	private isMatchingValue(poValue: any, psSearchValue: string): boolean {
		return typeof poValue !== "object" && (`${poValue}`.toLowerCase()).indexOf(psSearchValue) !== -1;
	}

	/** Lance la recherche. */
	public search(): T[] {
		const lnCurrentRadius: number = this.getRadius();

		// Si le placeholder a changé, c'est que cela concerne une recherche géo, on exécute la recherche par géoloc si le rayon est valide.
		if (this.options.searchboxPlaceholder.indexOf("autour de moi") !== -1 && NumberHelper.isValid(lnCurrentRadius)) {
			const lsLatitudeFieldKey: string = this.moUserGpsCoordinates.latitude.key;
			const lsLongitudeFieldKey: string = this.moUserGpsCoordinates.longitude.key;
			const lnUserLatitude: number = this.moUserGpsCoordinates.latitude.value;
			const lnUserLongitude: number = this.moUserGpsCoordinates.longitude.value;
			const laSearchResults: T[] = [];

			for (let lnIndex = 0; lnIndex < this.data.length; ++lnIndex) {
				const loEntry: T = this.data[lnIndex];
				const lnEntryDistanceFromUser: number =
					GeolocationHelper.calculateDistanceBetweenCoordinatesKm(lnUserLatitude, lnUserLongitude, loEntry[lsLatitudeFieldKey], loEntry[lsLongitudeFieldKey]);

				// Si la distance entre l'utilisateur et la cible est inférieure ou égale au rayon déterminé par l'utilisateur,
				// on ajoute l'entrée aux résultats à afficher.
				if (lnEntryDistanceFromUser <= lnCurrentRadius)
					laSearchResults.push(loEntry);

				if (laSearchResults.length === 50) // Si on atteint 50 résultats, on arrête la recherche (assez de résultats).
					break;
			}

			return this.maFilteredEntries = laSearchResults;
		}
		else
			return this.searchInDisplayFields();
	}

	/** Déclenche le masquage ou l'apparition du panel de recherche par critère. */
	public toggleCollapse(): void {
		this.isPanelCollapsed = !this.isPanelCollapsed;
	}

	/** Vérification du preFillData et du searchForm remplit ou non.
	 * @param paCols Tableau indexé par index contenant un objet avec pour seul champ 'key' : colonnes où effecteur la recherche, optionnel.
	 */
	private verifPreFillDataAndFilledSearchForm(paCols?: ColumnWithKeyByIndex<T>): T[] {
		const lbHasNoColumns: boolean = paCols === undefined || (paCols && Object.keys(paCols).length === 0);

		if (this.options.hasPreFillData && ((this.isPanelCollapsed && StringHelper.isBlank(this.searchValue)) || (!this.isPanelCollapsed && lbHasNoColumns)))
			this.maFilteredEntries = this.data.slice(0, this.options.maxFilteredEntries);

		else if (!this.options.hasPreFillData && StringHelper.isBlank(this.searchValue) && lbHasNoColumns && ArrayHelper.areAllValuesEmpty(this.searchPanelValues))
			this.maFilteredEntries = [];

		else if (this.maFilteredEntries.length > this.options.maxFilteredEntries)
			this.maFilteredEntries.splice(this.options.maxFilteredEntries);

		return this.maFilteredEntries;
	}

	/** Vérifie si le radius courant est valide ou non. */
	private isCurrentLocationRadiusValid(): boolean {
		return NumberHelper.isValid(this.currentLocationRadius);
	}

	/** Vérifie si le radius des options de recherche est valide ou non. */
	private isLocationSearchRadiusValid(): boolean {
		return this.isLocationEnabled && NumberHelper.isValid(this.options.locationSearch.radius);
	}

	/** Émet les nouvelles valeurs via l'input (émission à partir d'un nouveau tableau pour éviter des problèmes de références/rafraîchissements.
		* @param paNewValues Tableau des nouvelles valeurs à émettre.
		*/
	private emitNewValues(paNewValues: T[]): void {
		// Si le tableau des résultats dépasse le seuil, On considère qu'il n'y a pas de résultats (l'utilisateur doit affiner via le moteur de recherche)
		if (NumberHelper.isValid(this.moOptions.prefillThreshold) && paNewValues.length > this.moOptions.prefillThreshold)
			paNewValues = [];

		this.moFilteredEntriesChangedEvent.emit(Array.from(paNewValues));
	}

	public conserveInsertionOrder(): number {
		return 1;
	}

	public onAutocompleteOptionClicked(poEvent: MouseEvent, poValue: IAutoCompleteData<T>): void {
		poEvent.stopPropagation();
		this.moAutocompleteOptionClickedEvent.emit({ event: poEvent, data: poValue.data });
	}

	public onInputBlur(poEvent: UIEvent): void {
		if (!this.searchDiv.nativeElement.contains((poEvent as any).relatedTarget) &&
			(poEvent as any).relatedTarget?.getAttribute?.apply((poEvent as any).relatedTarget, ["instanceId"]) !== this.getInstanceId()) // Corrige la fermeture des options
			this.trigger.closePanel();
	}

	//#endregion

}