import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';

@Component({
	selector: 'calao-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	@Output("onValueChange") private readonly moValueChangedEmitter = new EventEmitter<number>();

	//#endregion

	//#region PROPERTIES

	/** `true` pour un affichage readonly, sinon `false`. */
	@Input() public isReadonly?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "isReadonly" })
	public readonly observableIsReadonly = new ObservableProperty<boolean>();

	/** Valeur minimale du slider. */
	@Input() public minValue?: number;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "minValue" })
	public readonly observableMinValue = new ObservableProperty<number>();

	/** Valeur maximale du slider. */
	@Input() public maxValue?: number;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "maxValue" })
	public readonly observableMaxValue = new ObservableProperty<number>();

	/** Valeur du pas. */
	@Input() public step?: number;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "step" })
	public readonly observableStep = new ObservableProperty<number>();

	/** `true` pour afficher l'épingle, sinon `false`. */
	@Input() public pin?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "pin" })
	public readonly observablePin = new ObservableProperty<boolean>();

	/** Valeur du slider. */
	@Input() public value?: number;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "value" })
	public readonly observableValue = new ObservableProperty<number>();

	/** `true` pour activer le magnétisme vers la graduation la plus proche, sinon `false`. */
	@Input() public snaps?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "snaps" })
	public readonly observableSnaps = new ObservableProperty<boolean>(true);

	/** `true` pour afficher les graduations, sinon `false`. */
	@Input() public ticks?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "ticks" })
	public readonly observableTicks = new ObservableProperty<boolean>();

	/** `true` pour afficher la valeur minimale, sinon `false`. */
	@Input() public showMinValue?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "showMinValue" })
	public readonly observableShowMinValue = new ObservableProperty<boolean>();

	/** `true` pour afficher la valeur maximale, sinon `false`. */
	@Input() public showMaxValue?: boolean;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "showMaxValue" })
	public readonly observableShowMaxValue = new ObservableProperty<boolean>();

	/** `true` pour afficher le libellé de la valeur minimale, sinon `false`. */
	@Input() public minLabel?: string;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "minLabel" })
	public readonly observableMinLabel = new ObservableProperty<string>();

	/** `true` pour afficher le libellé de la valeur maximale, sinon `false`. */
	@Input() public maxLabel?: string;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "maxLabel" })
	public readonly observableMaxLabel = new ObservableProperty<string>();

	/** Valeur par défaut du slider. */
	@Input() public defaultValue?: number;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "defaultValue" })
	public readonly observableDefaultValue = new ObservableProperty<number>();

	/** Couleurs du slider par valeur. */
	@Input() public colors?: Record<string, string>;
	@ObserveProperty<SliderComponent>({ sourcePropertyKey: "colors" })
	public readonly observableColors = new ObservableProperty<Record<string, string>>();

	/** Style à apliquer pour modifier la couleur. */
	public readonly observableStyle = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {
		this.observableValue.bind(this.observableDefaultValue.value$, this);
		this.observableStyle.value = this.getRangeColor(this.observableValue.value);
	}

	public onChanged(poEvent: Event) {
		const lnValue: number = (poEvent as CustomEvent).detail.value;
		this.observableStyle.value = this.getRangeColor(lnValue);
		this.moValueChangedEmitter.emit(lnValue);
	}

	private getRangeColor(pnValue: number): string {
		if (this.observableColors.value) {
			const lsColor: string | undefined = this.observableColors.value[pnValue];
			if (lsColor)
				return `--bar-background-active: var(${lsColor}); --pin-background: var(${lsColor});`;
		}

		return "--bar-background-active: var(--ion-color-primary); --pin-background: var(--ion-color-primary);";
	}

	//#endregion METHODS


}
