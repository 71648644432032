import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '../../components/date/date.module';
import { SelectorModule } from '../../modules/selector/selector.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { FilterBarGeographicalSearchInputComponent } from './components/filter-bar-geographical-search-input/filter-bar-geographical-search-input.component';
import { FilterBarItemSelectorFieldComponent } from './components/filter-bar-item-selector-field/filter-bar-item-selector-field.component';
import { FilterBarItemTitleComponent } from './components/filter-bar-item-title/filter-bar-item-title.component';
import { FilterBarItemComponent } from './components/filter-bar-item/filter-bar-item.component';
import { FilterBarMainContentComponent } from './components/filter-bar-main-content/filter-bar-main-content.component';
import { FilterBarModalContentComponent } from './components/filter-bar-modal-content/filter-bar-modal-content.component';
import { FilterBarSearchInputComponent } from './components/filter-bar-search-input/filter-bar-search-input.component';
import { FilterBarTagsTagComponent } from './components/filter-bar-tags-tag/filter-bar-tags-tag.component';
import { FilterBarTagsComponent } from './components/filter-bar-tags/filter-bar-tags.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule,
	CalaoAvatarModule,
	DateModule,
	PipeModule,
	SelectorModule
];
const components: Type<any>[] = [
	FilterBarComponent,
	FilterBarItemComponent,
	FilterBarMainContentComponent,
	FilterBarModalContentComponent,
	FilterBarSearchInputComponent,
	FilterBarGeographicalSearchInputComponent,
	FilterBarItemTitleComponent,
	FilterBarItemSelectorFieldComponent,
	FilterBarTagsComponent,
	FilterBarTagsTagComponent
];

@NgModule({
	imports: modules,
	declarations: components,
	exports: components
})
export class FilterModule { }