import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
	selector: 'osapp-field-file',
	template: `
    <input type="file" [formControl]="$any(formControl)" [formlyAttributes]="field">
  `,
})
export class FormlyFieldFileComponent extends FieldType<FormlyFieldConfig> { }