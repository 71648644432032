
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICurveOption } from '../../model/curve/ICurveOption';

/** Service de gestion des courbes. */
@Injectable({ providedIn: 'root' })
export class CurveService {

	//#region FIELDS

	private moCurveSubject = new Subject<{ action: string, params: { curveOption: ICurveOption, curveData: Array<any> } }>();

	//#endregion

	//#region METHODS

	constructor() { }

	/** Obtient un abonnement de la courbe. */
	public getCurveEventObservable(): Observable<{ action: string, params: { curveOption: ICurveOption, curveData: Array<any> } }> {
		return this.moCurveSubject.asObservable();
	}

	//#endregion
}