import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SearchModule } from '../../components/search/search.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { ConflictsModule } from '../conflicts/conflicts.module';
import { FilterModule } from '../filter/filter.module';
import { SelectorModule } from '../selector/selector.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { GroupsChecklistComponent } from './components/groups-checklist/groups-checklist.component';
import { GroupsFilterBarComponent } from './components/groups-filter-bar/groups-filter-bar.component';
import { GroupsListComponent } from './components/groups-list/groups-list.component';
import { SectorGroupsListComponent } from './components/sector-groups-list/sector-groups-list.component';

const components: Type<any>[] = [
	SectorGroupsListComponent,
	GroupsListComponent,
	GroupsFilterBarComponent,
	GroupsChecklistComponent
]

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		PipeModule,
		CalaoAvatarModule,
		SearchModule,
		ConflictsModule,
		VirtualScrollModule,
		FilterModule,
		SelectorModule
	],
	exports: components
})
export class GroupsModule { }
