import { Subscription } from 'rxjs';
import { IdHelper } from '../../helpers/idHelper';
import { EPrefix } from '../../model/EPrefix';
import { ITaskParams } from '../../model/backgroundTask/taskParams/ITaskParams';
import { IFlag } from '../../model/flag/IFlag';
import { ITaskDescriptorParams } from './itask-descriptor-params';

export class TaskDescriptor<T extends ITaskParams = ITaskParams> {

	//#region FIELDS

	private static readonly C_INTERVAL_MULTIPLICATOR = 1.3;

	//#endregion

	//#region PROPERTIES

	/** Identifiant du document pour la sérialisation. */
	public _id: string;
	/** Révision du document. */
	public _rev?: string;
	/** Id de la tâche. */
	public id: string;
	/** Nom de la tâche. */
	public name: string;
	/** Type de la tâche à exécuter. */
	public taskType: string;
	/** Paramètre de la tâche à exécuter. */
	public params?: T;
	/** Permet de savoir si on doit persister la tâche. */
	public enableTaskPersistance: boolean;
	/** Intervalle de temps en milliseconde entre deux exécutions de la tâche,
	 * utilisé par défaut pour la gestion de l'application en arrière plan. Optionnel.
	 * */
	public intervalRepetition?: number | number[];
	/** Intervalle de temps en milliseconde entre deux exécutions
	 * de la tâche lorsque l'application est en arrière plan, optionnel.
	 * */
	public backgroundIntervalRepetition?: number | number[];
	/** Multiplie la valeur de RetryInterval à chaque échec si non cyclique. */
	public intervalMultiplicator: number;
	/** Liste des identifiants de flags et valeurs attendues pour exécuter la tâche, optionnel. */
	public execAfter?: Array<IFlag>;
	/** Intervalle pour rejouer la tâche. */
	public replayInterval?: number;
	/** Date de la dernière exécution de la tâche, optionnel. */
	public lastExec?: Date;
	/** Intervalle de temps en ms avant de réessayer d'exécuter une tâche qui a échoué, 30sec par défaut. */
	public retryInterval?: number;
	/** Souscription à l'execution de la tâche. */
	public subscription?: Subscription;

	//#endregion

	//#region METHODS

	constructor(poParams: ITaskDescriptorParams<T>) {
		this.id = poParams.id;
		this._id = IdHelper.buildId(EPrefix.task, this.id);
		this.enableTaskPersistance = poParams.enableTaskPersistance ?? false;
		this.execAfter = poParams.execAfter;
		this.name = poParams.name;
		this.taskType = poParams.taskType;
		this.params = poParams.params;
		this.intervalRepetition = poParams.intervalRepetition;
		this.backgroundIntervalRepetition = poParams.backgroundIntervalRepetition;
		this.intervalMultiplicator = poParams.intervalMultiplicator ?? TaskDescriptor.C_INTERVAL_MULTIPLICATOR;
		this.replayInterval = poParams.replayInterval;
		this.lastExec = poParams.lastExec;
		this.retryInterval = poParams.retryInterval;
		this.subscription = poParams.subscription;
	}

	//#endregion

}