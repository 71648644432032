import { Entity } from '@calaosoft/osapp/model/entities/Entity';
import { EAvatarSize } from '@calaosoft/osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@calaosoft/osapp/services/EntityBuilder';
import { C_PREFIX_REPORT } from '../app/app.constants';
import { IReport } from './IReport';


export class ReportEntityBuilder extends EntityBuilder<IReport>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_REPORT).test(psModelId),
			(poReport: IReport) => new Entity(
				poReport,
				`rapports/${poReport._id}`,
				() => `${poReport.title}`
			),
			() => "Rapports",
			EDatabaseRole.formsEntries,
			C_PREFIX_REPORT,
			() => ({
				size: EAvatarSize.medium,
				icon: "document"
			})
		);
	}
}