import { Exclude, Expose } from "class-transformer";
import { ObservableProperty } from "../models/observable-property";

const C_LOG_ID = "OBSPROP.DECORATOR::";

interface IObservePropertyParams<T> {
	/** Nom de la propriété à observer. */
	sourcePropertyKey: keyof T;
	/** Fonction qui transforme la nouvelle valeur affectée à la propriété source en valeur utilisée par la propriété observable. */
	transformer?: (poNewValue: any, poThis: T) => any;
	/** Exclut la propriété observée. */
	excludeSource?: boolean;
}

/** Observe une propriété afin de réagir à ses changements.
 * @param poParams
 */
export function ObserveProperty<T>(poParams: IObservePropertyParams<T>): PropertyDecorator {
	return function (poTarget: Object, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<ObservableProperty<any>>) {
		Exclude()(poTarget, psPropertyKey); // On exclue la propriété observable.

		const loSourceDescriptor = Object.getOwnPropertyDescriptor(poTarget, poParams.sourcePropertyKey);
		const lfGet = function () {
			return loSourceDescriptor?.get?.apply(this) ?? this[`#obs_${psPropertyKey}`];
		};
		const lfSet = function (poNewVal: any) {
			this[`#obs_${psPropertyKey}`] = poParams.transformer ? poParams.transformer(poNewVal, this) : poNewVal;
			loSourceDescriptor?.set?.apply(this, [poNewVal]);

			const loObservableProperty: ObservableProperty<any> = this[psPropertyKey];
			if (!(loObservableProperty instanceof ObservableProperty))
				// ! A étudier : cette erreur peut être affichée dans le cas où le champ observé est initialisé par défaut dans sa classe !
				console.error(`${C_LOG_ID}The property '${psPropertyKey}' from '${poTarget.constructor.name}' should be an instance of ObservableProperty`);
			else
				loObservableProperty.value = this[`#obs_${psPropertyKey}`];
		};

		// On surcharge la propriété source avec les méthodes `get` et `set` personnalisées.
		Object.defineProperty(poTarget, poParams.sourcePropertyKey, { get: lfGet, set: lfSet });

		if (poParams.excludeSource)
			Exclude()(poTarget, poParams.sourcePropertyKey as string); // On exclut la propriété de base.
		else
			Expose()(poTarget, poParams.sourcePropertyKey as string); // On expose la propriété de base.

		return poDescriptor;
	};
}