import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AutocompleteTypeComponent } from "./autocomplete-type.component";
import { FormlyFieldButtonComponent } from './button-type.component';
import { FormlyFieldFileComponent } from './file-type-component';
import { FileValueAccessorDirective } from './file-value-accessor';
import { ImportComponent } from './import.component';

@NgModule({
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		FormlyMaterialModule,
		IonicModule,
		MatInputModule,
		MatAutocompleteModule,
		FormlyModule.forRoot({
			types: [
				{
					name: 'file',

					component: FormlyFieldFileComponent,
				},
				{
					name: 'button',
					component: FormlyFieldButtonComponent,
					defaultOptions: {
						templateOptions: {
							btnType: 'default',
							type: 'button',
						},
					},
				},
				{
					name: 'autocomplete',
					component: AutocompleteTypeComponent,
				},
			],
			validationMessages: [
				{ name: 'required', message: 'Cette information est requise.' },
			],
		}),
	],
	bootstrap: [ImportComponent],
	declarations: [
		FileValueAccessorDirective,
		ImportComponent,
		FormlyFieldFileComponent,
		FormlyFieldButtonComponent,
		AutocompleteTypeComponent
	]
})
export class ImportModule { }