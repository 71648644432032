import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import SignaturePad, { Options, PointGroup } from 'signature_pad';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EXTENSIONS_AND_MIME_TYPES } from '../../../../helpers/fileHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IBase64Data } from '../../../../model/file/IBase64Data';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { IDrawingOptions } from '../../models/IDrawingOptions';
import { IDrawingParams } from '../../models/IDrawingParams';
import { IDrawingResult } from '../../models/IDrawingResult';

@Component({
	selector: "calao-drawing",
	templateUrl: './drawing.component.html',
	styleUrls: ['./drawing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawingComponent extends ComponentBase implements IDrawingParams, AfterViewInit {

	//#region FIELDS

	/** Événement lors du changement du dessin (`true` = passage à dessin vide, `false` = passage à dessin non vide). */
	@Output("onEmptyChanged") private readonly moEmptyChangedEvent = new EventEmitter<boolean>();

	@ViewChild("signaturePadCanvas", { read: ElementRef }) private moSignaturePadCanvas: ElementRef<HTMLCanvasElement>;

	/** Objet de dessin, est `undefined` tant que l'ui n'est pas initialisée. */
	private moDrawingPad: SignaturePad;

	//#endregion

	//#region PROPERTIES

	private msDrawingTitle: string;
	/** @implements */
	public get drawingTitle(): string { return this.msDrawingTitle; }
	@Input() public set drawingTitle(psValue: string) {
		if (psValue !== this.msDrawingTitle) {
			this.msDrawingTitle = psValue;
			this.detectChanges();
		}
	}

	private mbShowUndoButton = true;
	/** @implements */
	public get showUndoButton(): boolean { return this.mbShowUndoButton; }
	@Input() public set showUndoButton(pbValue: boolean) {
		if (pbValue !== this.mbShowUndoButton) {
			this.mbShowUndoButton = pbValue;
			this.detectChanges();
		}
	}

	private mbShowClearButton = true;
	/** @implements */
	public get showClearButton(): boolean { return this.mbShowClearButton; }
	@Input() public set showClearButton(pbValue: boolean) {
		if (pbValue !== this.mbShowClearButton) {
			this.mbShowClearButton = pbValue;
			this.detectChanges();
		}
	}

	/** @implements */
	@Input() public options?: IDrawingOptions;

	private mbReadonly = false;
	/** @implements */
	public get readonly(): boolean { return this.mbReadonly; }
	@Input() public set readonly(pbValue: boolean) {
		if (pbValue !== this.mbReadonly) {
			this.mbReadonly = pbValue;
			this.initDrawingPad(); // On réinitialise le signaturePad.
		}
	}

	private moBase64Data: IBase64Data;
	/** @implements */
	public get base64Data(): IBase64Data { return this.moBase64Data; }
	@Input() public set base64Data(poValue: IBase64Data) {
		if (poValue !== this.moBase64Data) {
			this.moBase64Data = poValue;
			this.initDrawingPad(); // On réinitialise le signaturePad.
		}
	}

	private maData: PointGroup[];
	/** @implements */
	public get data(): PointGroup[] { return this.maData; }
	@Input() public set data(paValues: PointGroup[]) {
		if (paValues && paValues !== this.maData) {
			this.maData = paValues;
			this.initDrawingPad(); // On réinitialise le signaturePad.
		}
	}

	/** @implements */
	@Input() public missingDrawingText?: string;
	@ObserveProperty<DrawingComponent>({ sourcePropertyKey: "missingDrawingText" })
	public readonly observableMissingDrawingText = new ObservableProperty<string>();

	private mbIsEmptyBeforeTracing = true;
	/** Indique si le dessin est vierge avant le prochain tracé. */
	public get isEmptyBeforeTracing(): boolean { return this.mbIsEmptyBeforeTracing; }

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.initDrawingPad();
	}

	/** Initialisation du dessin. */
	private initDrawingPad(): void {
		this.initDrawingOptions();

		if (this.moSignaturePadCanvas) {
			this.moDrawingPad = new SignaturePad(
				this.moSignaturePadCanvas.nativeElement,
				{ penColor: this.options.penColor, backgroundColor: this.options.backgroundColor } as Options
			);

			this.moDrawingPad.clear();

			if (this.options.onTracingBegun)
				this.moDrawingPad.addEventListener("beginStroke", () => this.options.onTracingBegun());

			this.initOnDrawingPadEndEvent();

			// Désactive l'édition en mode readonly, l'active sinon.
			this.mbReadonly ? this.moDrawingPad.off() : this.moDrawingPad.on();

			if (ArrayHelper.hasElements(this.maData)) { // Si on a des points prégénérés, on les initialise.
				this.moDrawingPad.fromData(this.maData);
				this.mbIsEmptyBeforeTracing = false;
			}
		}
	}

	/** Initialise les options pour dessiner. */
	private initDrawingOptions(): void {
		if (!this.options)
			this.options = {};

		if (StringHelper.isBlank(this.options.penColor))
			this.options.penColor = "black";
	}

	/** Initialise les traitements à faire lorsqu'un tracé se termine. */
	private initOnDrawingPadEndEvent(): void {
		this.moDrawingPad.addEventListener("endStroke", () => {
			if (this.options.onTracingDone)
				this.options.onTracingDone();

			if (this.mbIsEmptyBeforeTracing)
				this.raiseIsNotEmptyEvent();

			this.detectChanges();
		});
	}

	/** Efface le dernier tracé effectué. */
	public undo(): void {
		if (!this.isEmpty()) { // Si le dessin n'est pas vierge on retourne en arrière sinon inutile.
			const laPointGroups: PointGroup[] = this.moDrawingPad.toData();
			laPointGroups.pop(); // Suppression du dernier tracé;

			this.moDrawingPad.fromData(laPointGroups);

			if (laPointGroups.length === 0) // Si le dessin est maintenant vierge, on lève un événement.
				this.raiseIsEmptyEvent();
		}
	}

	/** Efface tous les tracés effectués. */
	public clear(): void {
		if (this.moDrawingPad) {
			this.moDrawingPad.clear();
			this.raiseIsEmptyEvent();
		}
	}

	/** Indique si le dessin est vierge ou non. */
	private isEmpty(): boolean {
		return !this.moDrawingPad || this.moDrawingPad.isEmpty();
	}

	/** Envoie un événement pour indiquer que le dessin est vierge. */
	private raiseIsEmptyEvent(): void {
		if (!this.mbIsEmptyBeforeTracing) // Si le dessin n'était pas vierge avant le dernier tracé, on émet un changement.
			this.moEmptyChangedEvent.emit(this.mbIsEmptyBeforeTracing = true);
	}

	/** Envoie un événement pour indiquer que le dessin n'est plus vierge. */
	private raiseIsNotEmptyEvent(): void {
		if (this.mbIsEmptyBeforeTracing) // Si le dessin était vierge avant le dernier tracé, on émet un changement.
			this.moEmptyChangedEvent.emit(this.mbIsEmptyBeforeTracing = false);
	}

	/** Retourne le résultat du dessin avec le tableau de points générés et la base64 résultat.
	 * @param psMimeType Type mime que doit avoir la base64. Si le type mime n'est pas correcte, non pris en charge ou non renseigné, `image/png` par défaut.
	 * @param pnQuality Qualité de l'image, comprise entre 0 et 1 (0.25 == 25%) ; `1` par défaut.
	 */
	public getDrawingResult(psMimeType: string = EXTENSIONS_AND_MIME_TYPES.png.mimeType, pnQuality: number = 1): IDrawingResult {
		return {
			base64: this.moDrawingPad?.toDataURL(psMimeType, pnQuality),
			data: this.moDrawingPad?.toData()
		};
	}

	//#endregion

}