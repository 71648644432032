import { Component } from '@angular/core';

@Component({
	selector: "editor-page",
	templateUrl: './editor-page.component.html',
	styleUrls: ['./editor-page.component.scss']
})
/** Permet de montrer la page d'édition d'un FormDescriptor,
 * composée de :
 * Widget adder, Widget setting & Form editor.
 */
export class EditorPageComponent {

	//#region METHODS

	constructor() { }

	//#endregion

}