import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SelectorModule } from '../selector/selector.module';
import { RoleComponent } from './components/role/role.component';
import { RolesSelectorComponent } from './components/roles-selector/roles-selector.component';
import { HasPermissionGuard } from './guards/has-permission.guard';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule,
	SelectorModule
];

@NgModule({
	imports: modules,
	providers: [HasPermissionGuard],
	declarations: [RoleComponent, RolesSelectorComponent],
	exports: [RoleComponent, RolesSelectorComponent]
})
export class PermissionsModule { }