import { Document } from "./document";
import { Folder } from "./folder";

export class FolderContent {

	//#region PROPERTIES

	public current: Folder;
	/** Liste des dossiers enfants. */
	public folders: FolderContent[] = [];
	/** Liste des fichiers du dossier courant. */
	public documents: Document[] = [];

	//#endregion

	//#region METHODS

	public getDocumentsRecursively(): Document[] {
		return [...this.documents, ...(this.folders.map((poFolder: FolderContent) => poFolder.getDocumentsRecursively())).flat()];
	}

	//#endregion METHODS

}
