<span *ngIf="observableReadonly.value$ | async; else notReadonly;">{{observableDurationReadonlyLabel.value$ |
	async}}</span>

<ng-template #notReadonly>
	<div class="title-section">
		<ion-label class="label-group">Date et heure</ion-label>
		<div *ngIf="!required" class="activation-wrapper">
			<ion-note>Programmée</ion-note>
			<ion-toggle [checked]="observableActivation.value$ | async" (ionChange)="onActivationChanged($event)" mode="ios">
			</ion-toggle>
		</div>
	</div>

	<ng-container *ngIf="observableActivation.value$ | async">
		<div class="date-wrapper">
			<ng-container *ngIf="observableStartDate.value$ | async as startDate">
				<osapp-date-time [params]="observableStartDateTimePickerParams.value$ | async" [model]="startDate"
					(modelChange)="onStartDateChanged($event)"></osapp-date-time>

				<ng-container *ngIf="!(observableFullDay.value$ | async)">
					<osapp-date-time [params]="observableStartTimePickerParams.value$ | async" [model]="startDate"
						(modelChange)="onStartDateChanged($event)"></osapp-date-time>
				</ng-container>
			</ng-container>
		</div>
		<div class="date-wrapper">
			<ng-container *ngIf="observableEndDate.value$ | async as endDate">
				<osapp-date-time [params]="observableEndDateTimePickerParams.value$ | async" [model]="endDate"
					(modelChange)="onEndDateChanged($event)"></osapp-date-time>

				<ng-container *ngIf="!(observableFullDay.value$ | async)">
					<osapp-date-time [params]="observableEndTimePickerParams.value$ | async" [model]="endDate"
						(modelChange)="onEndDateChanged($event)"></osapp-date-time>
				</ng-container>
			</ng-container>
		</div>

		<ion-item lines="none" class="ion-no-padding">
			<ion-checkbox slot="start" [checked]="observableFullDay.value$ | async" (ionChange)="onFullDayChanged($event)"
				mode="ios">
			</ion-checkbox>
			<ion-label><ion-note>Journée entière</ion-note></ion-label>
		</ion-item>
	</ng-container>
</ng-template>