import { ObjectHelper } from "../../../../helpers/objectHelper";

export class FilesystemRenameError extends Error {

	constructor(psFileRenameFailed: string) {
		super(`Une erreur est survenue lors du renommage du fichier '${psFileRenameFailed}'.`);
		// Nécessaire pour que le 'instanceof FilesystemRenameError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemRenameError);
	}

}