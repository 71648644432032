import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { App, AppState } from '@capacitor/app';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { StringHelper } from '../../helpers/stringHelper';
import { IDestroyable } from '../../modules/utils/lifecycle/models/IDestroyable';

/** Sujet qui permet de déteminer si un onglet est actif ou non (dès que l'oglet est visible il est considéré comme actif). */
const loAppStateChangeSubject = new BehaviorSubject<boolean>(true);
/** Abonnement aux changements d'état de l'application (actif/visible ou non). */
App.addListener("appStateChange", (poAppState: AppState) => loAppStateChangeSubject.next(poAppState.isActive));

export class ActivePageManager {

	//#region FIELDS

	private readonly moIsActivePageSubject = new BehaviorSubject<boolean>(true);
	private readonly msUrl: string;
	private readonly moDestroyed$: Observable<void>;

	//#endregion

	//#region PROPERTIES

	public get isActive$(): Observable<boolean> {
		return this.moIsActivePageSubject.asObservable().pipe(distinctUntilChanged());
	}

	private mbIsActive = true;
	public get isActive(): boolean { return this.mbIsActive; }

	//#endregion

	//#region METHODS

	constructor(
		poComponent: IDestroyable,
		poRouter: Router,
		pfUrlPredicate: (msNewUrl: string, msPageUrl: string) => boolean = (msNewUrl: string, msPageUrl: string) => msNewUrl === msPageUrl
	) {

		this.msUrl = poRouter.url;
		this.moDestroyed$ = poComponent.destroyed$;

		combineLatest([
			poRouter.events.pipe(
				filter((poEvent: RouterEvent) => poEvent instanceof NavigationEnd && !StringHelper.isBlank(poEvent.url)),
				distinctUntilChanged((poOldEvent: NavigationEnd, poNewEvent: NavigationEnd) => poOldEvent.url === poNewEvent.url)
			),
			loAppStateChangeSubject.asObservable()
		])
			.pipe(
				tap(([poRouterEvent, pbIsActive]: [NavigationEnd, boolean]) =>
					this.moIsActivePageSubject.next(this.mbIsActive = pfUrlPredicate(poRouterEvent.url, this.msUrl) && pbIsActive)
				),
				takeUntil(this.moDestroyed$),
				finalize(() => this.moIsActivePageSubject.complete())
			)
			.subscribe();
	}

	//#endregion

}