import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { Contact } from '../../contacts/models/contact';
import { BaseEvent } from '../models/base-event';
import { BaseEventOccurrence } from '../models/base-event-occurrence';
import { EEventParticipationStatus } from '../models/eevent-participation-status';
import { EventDuration } from '../models/event-duration';
import { EventParticipation } from '../models/event-participation';
import { EventParticipationOccurrence } from '../models/event-participation-occurrence';
import { IEventOccurrence } from '../models/ievent-occurrence';
import { IEventParticipantStatus } from '../models/ievent-participant-status';

@Injectable()
export class CalendarEventsParticipationService {

	//#region FIELDS

	private static readonly C_LOG_ID: "CALEVTPART.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcUiMessage: UiMessageService
	) { }

	public getEventsParticipationsIndexedByDate$(paEvents: BaseEvent[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, Map<number, IEventParticipantStatus[]>>> {
		const loEventsById: Map<string, BaseEvent> = ArrayHelper.groupByUnique(paEvents, (poEvent: BaseEvent) => poEvent._id);

		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: EPrefix.eventParticipation,
				endkey: `${EPrefix.eventParticipation}${Store.C_ANYTHING_CODE_ASCII}`,
			},
			filter: (poDoc: IStoreDocument) => loEventsById.has(EventParticipation.extractEventId(poDoc._id)),
			live: pbLive,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager
		}).pipe(
			switchMap((paDocs: IStoreDocument[]) => this.isvcStore.get({
				role: EDatabaseRole.workspace,
				viewParams: {
					keys: paDocs.map((poDoc: IStoreDocument) => poDoc._id),
					include_docs: true
				},
				live: pbLive,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				baseClass: EventParticipation
			})),
			map((paEventParticipations: EventParticipation[]) => MapHelper.map(
				ArrayHelper.groupBy(paEventParticipations, (poEventParticipation: EventParticipation) => poEventParticipation.eventId),
				(paGroupedEventParticipations: EventParticipation[], psEventId: string) => this.indexParticipations(paGroupedEventParticipations, loEventsById.get(psEventId))
			))
		);
	}

	public getEventParticipationsIndexedByDate$(
		poEvent: BaseEvent,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<number, IEventParticipantStatus[]>> {
		const lsStartKey: string = EventParticipation.buildId(poEvent._id, EPrefix.contact);
		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			baseClass: EventParticipation
		}).pipe(
			map((paEventParticipations: EventParticipation[]) => this.indexParticipations(paEventParticipations, poEvent))
		);
	}

	private indexParticipations(paEventParticipations: EventParticipation[], poEvent: BaseEvent): Map<number, IEventParticipantStatus[]> {
		const loParticipantStatusDataByOccurrenceDate = new Map<number, IEventParticipantStatus[]>();
		const lsAuthorContactId: string = UserHelper.getUserContactId(poEvent.authorId);

		paEventParticipations.forEach((poEventParticipation: EventParticipation) => {
			const laParticipantStatusData: IEventParticipantStatus[] = loParticipantStatusDataByOccurrenceDate.get(undefined) ?? [];

			if (!StringHelper.isBlank(poEventParticipation.participationStatus)) {
				laParticipantStatusData.push({
					participantId: poEventParticipation.contactId,
					status: poEventParticipation.participationStatus,
					place: poEventParticipation.place,
					organizer: poEventParticipation.contactId === lsAuthorContactId,
					startDate: poEventParticipation.startDate,
					endDate: poEventParticipation.endDate,
					isBaseParticipation: true
				});
				loParticipantStatusDataByOccurrenceDate.set(undefined, laParticipantStatusData);
			}

			poEventParticipation.occurrences?.forEach((poEventParticipationOccurrence: EventParticipationOccurrence) => {
				const lnOccurrenceTime: number = DateHelper.resetMinutes(poEventParticipationOccurrence.startDate).getTime();
				const laOccurrenceParticipantStatusData: IEventParticipantStatus[] = loParticipantStatusDataByOccurrenceDate.get(lnOccurrenceTime) ?? [];
				laOccurrenceParticipantStatusData.push({
					participantId: poEventParticipation.contactId,
					status: poEventParticipationOccurrence.participationStatus,
					place: poEventParticipationOccurrence.place,
					organizer: poEventParticipation.contactId === lsAuthorContactId,
					startDate: poEventParticipationOccurrence.startDate,
					endDate: poEventParticipationOccurrence.endDate
				});
				loParticipantStatusDataByOccurrenceDate.set(lnOccurrenceTime, laOccurrenceParticipantStatusData);
			});
		});

		return loParticipantStatusDataByOccurrenceDate;
	}


	public getDefaultEventParticipantStatus(poParticipant: Contact, poBaseEventOccurrence: BaseEventOccurrence, pbIsBaseParticipation?: boolean): IEventParticipantStatus {
		return {
			status: EEventParticipationStatus.waiting,
			participantId: poParticipant._id,
			organizer: poParticipant._id === UserHelper.getUserContactId(poBaseEventOccurrence.authorId),
			isBaseParticipation: pbIsBaseParticipation,
			startDate: poBaseEventOccurrence.startDate,
			endDate: poBaseEventOccurrence.observableEndDate.value,
			place: poBaseEventOccurrence.place
		};
	}

	public async acceptAsync(poEventOccurrence: BaseEventOccurrence): Promise<void> {
		return this.showParticipationConfirmationPopup$(true, poEventOccurrence).pipe(
			filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
			map(() => {
				try {
					return this.changeParticipationStateAsync(poEventOccurrence, EEventParticipationStatus.accepted);
				}
				catch (poError: any) {
					console.error(`${CalendarEventsParticipationService.C_LOG_ID}error when accept participation.`, poError);
					this.showParticipationErrorPopup();
					throw poError;
				}
			})
		).toPromise();
	}

	public async rejectAsync(poEventOccurrence: BaseEventOccurrence): Promise<void> {
		return this.showParticipationConfirmationPopup$(false, poEventOccurrence).pipe(
			filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
			map(() => {
				try {
					return this.changeParticipationStateAsync(poEventOccurrence, EEventParticipationStatus.denied);
				}
				catch (poError: any) {
					console.error(`${CalendarEventsParticipationService.C_LOG_ID}error when refuse participation.`, poError);
					this.showParticipationErrorPopup();
					throw poError;
				}
			})
		).toPromise();
	}

	private showParticipationConfirmationPopup$(pbParticipation: boolean, poEvent: BaseEventOccurrence): Observable<IUiResponse<boolean>> {
		return poEvent.periodLabel$.pipe(
			take(1),
			mergeMap((psPeriod: string) => {
				const lsParticpation: string = pbParticipation ? "accepter" : "refuser";
				const lsRecurrence: string = ArrayHelper.hasElements(poEvent.recurrences) ? "la série" : "l'évènement";
				const lsplural: string = ArrayHelper.hasElements(poEvent.recurrences) ? "d'évènements suivante" : "suivant";
				const lsPeriod = `<div class="disflex"><ion-icon name="time"></ion-icon> ${psPeriod}</div>`;
				const lsPlace: string = poEvent.place ? `<div class="disflex"><ion-icon name="location" class="place"></ion-icon> ${poEvent.place}</div>` : "";
				const lsMessage = `Voulez-vous réellement ${lsParticpation} ${lsRecurrence} ${lsplural} ?<br />${lsPeriod}${lsPlace}`;
				const loMessageParamsPopup = new ShowMessageParamsPopup({
					backdropDismiss: true,
					header: "Une réponse va être envoyée à l'organisateur",
					message: lsMessage,
					buttons: [
						{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
						{ text: "Confirmer", handler: () => UiMessageService.getTruthyResponse(), cssClass: "validate-btn" }
					],
					cssClass: "participation-confirmation-popup",
					subHeader: "",
					inputs: []
				});
				return this.isvcUiMessage.showAsyncMessage<boolean>(loMessageParamsPopup);
			})
		);
	}

	private showParticipationErrorPopup(): void {
		const loMessageParamsPopup = new ShowMessageParamsPopup({
			backdropDismiss: true,
			header: "Erreur",
			message: `Impossible d'enregistrer votre participation. Veuillez réessayer ultérieurement et contacter le support technique si le problème persiste.`,
			buttons: [
				{ text: "OK", handler: () => UiMessageService.getTruthyResponse(), cssClass: "validate-btn" }
			],
			cssClass: "",
			subHeader: "",
			inputs: []
		});
		this.isvcUiMessage.showMessage(loMessageParamsPopup);
	}

	private async changeParticipationStateAsync(
		poEventOccurrence: BaseEventOccurrence,
		peStatus: EEventParticipationStatus
	): Promise<void> {
		const loParticipation: EventParticipation = await this.getUserParticipationAsync(poEventOccurrence);

		if (poEventOccurrence.hasToRequestGlobalParticipation) { // Si c'est une occurrence de base
			const loBaseOccurrence: BaseEventOccurrence | undefined = poEventOccurrence.event.getBaseOccurrence();
			loParticipation.participationStatus = peStatus;
			loParticipation.participationStatusDate = new Date;

			if (loBaseOccurrence) {
				loParticipation.startDate = loBaseOccurrence.startDate;
				loParticipation.place = loBaseOccurrence.place;
				loParticipation.endDate = loBaseOccurrence.observableEndDate.value;
			}
		}
		else {
			loParticipation.occurrences.push(new EventParticipationOccurrence({
				startDate: poEventOccurrence.startDate,
				endDate: new EventDuration(poEventOccurrence.duration).addDurationToDate(poEventOccurrence.startDate),
				eventRev: poEventOccurrence.event._rev,
				participationStatus: peStatus,
				place: poEventOccurrence.place,
				participationStatusDate: new Date
			}));
		}

		await this.saveParticipation(loParticipation);
	}

	private async getUserParticipationAsync(poEventOccurrence: IEventOccurrence): Promise<EventParticipation> {
		const lsParticipationId: string = EventParticipation.buildId(poEventOccurrence.eventId, UserHelper.getUserContactId());
		let loUserParticipation: EventParticipation | undefined = await this.isvcStore.getOne({
			role: EDatabaseRole.workspace,
			viewParams: {
				key: lsParticipationId,
				include_docs: true
			},
			baseClass: EventParticipation
		}, false).toPromise();

		if (!loUserParticipation) {
			loUserParticipation = new EventParticipation({
				_id: lsParticipationId
			});
		}

		return loUserParticipation;
	}

	private async saveParticipation(poParticipation: EventParticipation): Promise<void> {
		poParticipation.previousRev = poParticipation._rev;
		await this.isvcStore.put(poParticipation, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))).toPromise();
	}

	public getEventParticipationIdsAsync(poEvent: BaseEvent): Promise<string[]> {
		const lsStartKey: string = EventParticipation.buildId(poEvent._id, EPrefix.contact);

		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			}
		}).pipe(map((paDocs: IStoreDocument[]) => paDocs.map((poDoc: IStoreDocument) => poDoc._id))).toPromise();
	}

	public async deleteParticipationsAsync(poEvent: BaseEvent): Promise<void> {
		await this.isvcStore.deleteMultipleDocuments(
			await this.getEventParticipationIdsAsync(poEvent),
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
		).toPromise();
	}

	//#endregion

}
