import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import * as elementVisible from 'element-visible';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Directive({
	selector: '[calaoAutoResize]'
})
export class AutoResizeDirective implements OnDestroy, OnInit {
	private moVisibleSubject: BehaviorSubject<undefined>;
	private moMutationObserver: MutationObserver;
	private moIntersectionObserver: IntersectionObserver;

	constructor(private moVirtualScroll: CdkVirtualScrollViewport) { }

	public ngOnDestroy(): void {
		this.moIntersectionObserver.disconnect();
		this.moMutationObserver.disconnect();
		this.moVisibleSubject.complete();
	}

	public ngOnInit(): void {
		this.moVisibleSubject = new BehaviorSubject(undefined);
		this.moVisibleSubject.pipe(
			debounceTime(100),
			map(() => this.isViewportVisible()),
			distinctUntilChanged(),
			filter((pbVisible: boolean) => pbVisible),
			tap(() => this.moVirtualScroll.checkViewportSize())
		).subscribe();

		this.moMutationObserver = new MutationObserver(() => this.moVisibleSubject.next(undefined));
		this.moMutationObserver.observe(document, { attributeFilter: ["opacity", "display", "visibility"], childList: true, subtree: true });
		this.moIntersectionObserver = new IntersectionObserver(() => this.moVisibleSubject.next(undefined), { threshold: [0, 0.25, 0.5, 0.75, 1] });
		this.moIntersectionObserver.observe(this.moVirtualScroll.elementRef.nativeElement);
	}


	private isViewportVisible(): boolean {
		return elementVisible(this.moVirtualScroll.elementRef.nativeElement);
	}
}
