import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
	selector: 'formly-autocomplete-type',
	template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [errorStateMatcher]="errorStateMatcher">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let value of filter | async" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class AutocompleteTypeComponent extends FieldType<FormlyFieldConfig> implements OnInit, AfterViewInit {
	@ViewChild(MatInput) public formFieldControl: MatFormFieldControl<any>;
	@ViewChild(MatAutocompleteTrigger) public autocomplete: MatAutocompleteTrigger;

	public filter: Observable<any>;

	public ngOnInit() {
		this.filter = this.formControl.valueChanges
			.pipe(
				startWith(''),
				switchMap(term => this.to.filter(term)),
			);
	}

	public ngAfterViewInit() {
		// temporary fix for https://github.com/angular/material2/issues/6728
		(<any>this.autocomplete)._formField = this.formField;
	}
}