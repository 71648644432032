import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-list-item-content-prefix',
	templateUrl: './list-item-content-prefix.component.html',
	styleUrls: ['./list-item-content-prefix.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'"
	}
})
export class ListItemContentPrefixComponent { }
