import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { IMenuComponentDescriptor } from '../../model/IMenuComponentDescriptor';
import { IFlag } from '../../model/flag/IFlag';
import { LinkInfo } from '../../model/link/LinkInfo';
import { ISectionInfo } from '../../model/menu/ISectionInfo';
import { SectionInfo } from '../../model/menu/SectionInfo';
import { EStoreFlag } from '../../model/store/EStoreFlag';
import { EPermission } from '../../modules/permissions/models/EPermission';
import { IPermission } from '../../modules/permissions/models/ipermission';
import { PermissionsService } from '../../modules/permissions/services/permissions.service';
import { ApplicationService } from '../../services/application.service';
import { MenuService } from './menu.service';

/** Le menu est un composant qui contient une liste de "LinkInfo", celle-ci permet d'afficher une liste de liens. */
@Component({
	selector: 'calao-menu',
	templateUrl: 'menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {

	//#region PROPERTIES

	/** Paramètre du composant identifiant le menu à afficher. */
	@Input() public menuKey: string;
	/** Contient les informations de chaque lien et donc de chaque page accessible par le menu. */
	@Input() public sections: SectionInfo[] = [];

	@Output("onSectionsLoaded") private readonly moOnMenuLoadedEventEmitter = new EventEmitter<SectionInfo[]>;

	/** Indique si tous les enfants sections sont fermés/ouverts. */
	public areSectionsClosed = true;
	/** Template utilisé. */
	public templateId: string;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion du menu. */
		private isvcMenu: MenuService,
		/** Service de gestion du rafraîchissement de la vue. */
		private ioChangeDetectorRef: ChangeDetectorRef,
		private isvcApplication: ApplicationService,
		private isvcPermission: PermissionsService
	) {

	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		this.init().subscribe();
	}

	/** Appellée quand un changement a lieu dans les propriétés bindées. */
	public ngOnChanges(): void {
		this.init().subscribe();
	}

	/** Endroit où se désabonner et se détacher des événements pour éviter des fuites mémoires, juste avant la destruction du composant. */
	public ngOnDestroy(): void {
		this.ioChangeDetectorRef.detach(); // On se détache du changeDetector.
	}

	private init(): Observable<boolean> {
		return this.isvcApplication.observeFlag(EStoreFlag.DBInitialized)
			.pipe(
				distinctUntilChanged(),
				filter((poFlag: IFlag) => poFlag.value),
				mergeMap((poFlag: IFlag) => {
					if (!this.menuKey) {
						console.warn("MNU.C:: Warning : la clé de menu n'est pas précisée. Ajoutez le paramètre menuKey lors de la création du menu.");
						return of(false);
					}
					else
						return this.fillMenuFromDatabase();
				})
			);
	}

	/** Initialise le contenu du menu via la bdd. */
	private fillMenuFromDatabase(): Observable<boolean> {
		ArrayHelper.clear(this.sections);
		return this.isvcMenu.getMenu(this.menuKey) // Récupération du contenu.
			.pipe(
				catchError(poError => { console.error(`MNU.C:: Erreur création et récupèration menu : `, poError); return throwError(poError); }),
				mergeMap((poMenu: IMenuComponentDescriptor) => {
					this.areSectionsClosed = poMenu.areSectionsClosed;

					// On ne fait une requete sur la base de données que lorsque le tableau est vide (remplissage unique de composant).
					if (!ArrayHelper.hasElements(this.sections)) {
						poMenu.sections.forEach((poSection: ISectionInfo) => {
							const loSection = new SectionInfo(poSection);

							// On filtre les liens en fonction des permissions d'accès.
							loSection.links = loSection.links.filter((poLink: LinkInfo) =>
								!ArrayHelper.hasElements(poLink.permissions) ||
								poLink.permissions?.some((poPermission: IPermission) => this.isvcPermission.evaluatePermission(poPermission.permission as EPermission, poPermission.type))
							);

							this.sections.push(loSection);
						});
						if (!StringHelper.isBlank(poMenu.templateId))
							this.templateId = poMenu.templateId;
					}

					this.moOnMenuLoadedEventEmitter.emit([...this.sections]);

					this.ioChangeDetectorRef.detectChanges();
					return of(true);
				})
			);
	}

	//#endregion
}