export enum EJitsiButton {
	camera = "camera",
	closedcaptions = "closedcaptions",
	desktop = "desktop",
	download = "download",
	embedmeeting = "embedmeeting",
	etherpad = "etherpad",
	feedback = "feedback",
	filmstrip = "filmstrip",
	fullscreen = "fullscreen",
	hangup = "hangup",
	help = "help",
	highlight = "highlight",
	invite = "invite",
	linktosalesforce = "linktosalesforce",
	livestreaming = "livestreaming",
	microphone = "microphone",
	noisesuppression = "noisesuppression",
	participantspane = "participants-pane",
	profile = "profile",
	raisehand = "raisehand",
	recording = "recording",
	security = "security",
	selectbackground = "select-background",
	settings = "settings",
	shareaudio = "shareaudio",
	sharedvideo = "sharedvideo",
	shortcuts = "shortcuts",
	stats = "stats",
	tileview = "tileview",
	togglecamera = "toggle-camera",
	videoquality = "videoquality",
	whiteboard = "whiteboard",

}