import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { FormsService } from '../services/forms.service';

@Injectable({
	providedIn: 'root'
})
export class FormEntryResolver implements Resolve<IStoreDocument> {

	//#region METHODS

	constructor(protected isvcForms: FormsService, private ioRouter: Router) { }

	public resolve(poRoute: ActivatedRouteSnapshot): Observable<IStoreDocument> {
		const lsModelId: string = poRoute.params.modelId;

		return this.isvcForms.getEntryFromId(lsModelId)
			.pipe(
				take(1),
				mergeMap((poModel: IStoreDocument) => {
					if (poModel)
						return of(poModel);
					else {
						this.ioRouter.navigate(['..']);
						return EMPTY;
					}
				})
			);
	}

	//#endregion
}