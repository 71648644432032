import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ImageHelper } from '../../helpers/imageHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { BlobUrlManager } from './model/BlobUrlManager';
import { EImageClass } from './model/EImageClass';

@Component({
	selector: "osapp-image",
	templateUrl: './osapp-image.component.html',
	styleUrls: ['./osapp-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsappImageComponent extends ComponentBase implements OnDestroy {

	//#region FIELDS

	@Output("onImageLoaded") private readonly onImageLoadedEventEmitter = new EventEmitter<CustomEvent>();
	@Output("onImageError") private readonly onImageErrorEventEmitter = new EventEmitter<CustomEvent>();

	private readonly moBlobUrlManager = new BlobUrlManager();

	//#endregion

	//#region PROPERTIES

	private msSrc: string;
	public get src(): string | Blob { return this.msSrc; }
	@Input() public set src(poValue: string | Blob) {
		let lsSrc: string;
		if (poValue) {
			const lsValueToSanitize: string = poValue instanceof Blob ? this.moBlobUrlManager.createUrl(poValue) : poValue;
			lsSrc = this.ioSanitizer.bypassSecurityTrustUrl(lsValueToSanitize) as string;
		}

		if (lsSrc !== this.msSrc) {
			this.msSrc = lsSrc;
			this.detectChanges();
		}
	}

	@Input() public alt: string;

	private meImgClass: EImageClass = EImageClass.fullwidthImg;
	/** Classe à appliquer sur l'élément, 'EImageClass.fullwidthImg' par défaut. */
	public get imgClass(): EImageClass { return this.meImgClass; }
	@Input() public set imgClass(peValue: EImageClass) {
		if (peValue !== this.meImgClass) {
			this.meImgClass = peValue;
			this.detectChanges();
		}
	}

	private readonly moBackgroundColorProp = new ObservableProperty<string | undefined>();
	/** Couleur de fond si l'image n'est pas affichée. */
	@Input() public set backgroundColor(poValue: string | ObservableProperty<string>) {
		if (poValue instanceof ObservableProperty)
			this.moBackgroundColorProp.bind(poValue.value$, this);
		else
			this.moBackgroundColorProp.value = poValue;
	}
	/** Flux de la couleur de fond si l'image n'est pas affichée. */
	public readonly backgroundColor$: Observable<string | undefined> = this.moBackgroundColorProp.value$;

	//#endregion

	//#region METHODS

	constructor(private readonly ioRenderer: Renderer2, private readonly ioSanitizer: DomSanitizer, poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBlobUrlManager.releaseUrls();
	}

	public async onImageLoadedAsync(poEvent: Event, poImage: HTMLImageElement): Promise<void> {
		this.onImageLoadedEventEmitter.emit(poEvent as CustomEvent);
		await this.updateOrientationAsync(poImage);
		this.detectChanges();
	}

	private async updateOrientationAsync(poImage: HTMLImageElement): Promise<void> {
		const lsClass: string = await ImageHelper.getCssOrientationAsync(poImage);

		if (!StringHelper.isBlank(lsClass))
			this.ioRenderer.addClass(poImage, lsClass);
	}

	public onImageError(poEvent: Event): void {
		this.onImageErrorEventEmitter.emit(poEvent as CustomEvent);
	}

	//#endregion

}