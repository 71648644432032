import { saveAs } from 'file-saver';
import { bindCallback, defer, fromEvent, Observable, of, Subject, throwError } from 'rxjs';
import { finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { BlobUrlManager } from '../components/image/model/BlobUrlManager';
import { IBase64Data } from '../model/file/IBase64Data';
import { OsappError } from '../modules/errors/model/OsappError';
import { PerformanceManager } from '../modules/performance/PerformanceManager';
import { ArrayHelper } from './arrayHelper';
import { StringHelper } from './stringHelper';

export const EXTENSIONS_AND_MIME_TYPES = {
	"3gp": { key: "3gp", mimeType: "video/3gpp" },
	"7z": { key: "7z", mimeType: "application/x-7z-compressed" },
	aac: { key: "aac", mimeType: "audio/aac" },
	abw: { key: "abw", mimeType: "application/x-abiword" },
	apk: { key: "apk", mimeType: "application/vnd.android.package-archive" },
	arc: { key: "arc", mimeType: "application/octet-stream" },
	asf: { key: "asf", mimeType: "video/x-ms-asf" },
	avi: { key: "avi", mimeType: "video/x-msvideo" },
	azw: { key: "azw", mimeType: "application/vnd.amazon.ebook" },
	bin: { key: "bin", mimeType: "application/octet-stream" },
	bmp: { key: "bmp", mimeType: "image/bmp" },
	bz: { key: "bz", mimeType: "application/x-bzip" },
	bz2: { key: "bz2", mimeType: "application/x-bzip2" },
	c: { key: "c", mimeType: "text/plain" },
	class: { key: "class", mimeType: "application/octet-stream" },
	conf: { key: "conf", mimeType: "text/plain" },
	cpp: { key: "cpp", mimeType: "text/plain" },
	csh: { key: "csh", mimeType: "application/x-csh" },
	css: { key: "css", mimeType: "text/css" },
	csv: { key: "csv", mimeType: "text/csv" },
	db: { key: "db", mimeType: "application/vnd.sqlite3" },
	doc: { key: "doc", mimeType: "application/msword" },
	docx: { key: "docx", mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
	eot: { key: "eot", mimeType: "application/vnd.ms-fontobject" },
	epub: { key: "epub", mimeType: "application/epub+zip" },
	exe: { key: "exe", mimeType: "application/octet-stream" },
	gif: { key: "gif", mimeType: "image/gif" },
	gtar: { key: "gtar", mimeType: "application/x-gtar" },
	gz: { key: "gz", mimeType: "application/x-gzip" },
	h: { key: "h", mimeType: "text/plain" },
	htm: { key: "htm", mimeType: "text/html" },
	html: { key: "html", mimeType: "text/html" },
	ico: { key: "ico", mimeType: "image/x-icon" },
	ics: { key: "ics", mimeType: "text/calendar" },
	jar: { key: "jar", mimeType: "application/java-archive" },
	java: { key: "java", mimeType: "text/plain" },
	jpeg: { key: "jpeg", mimeType: "image/jpeg" },
	jpg: { key: "jpg", mimeType: "image/jpg" },
	js: { key: "js", mimeType: "application/x-javascript" },
	json: { key: "json", mimeType: "application/json" },
	log: { key: "log", mimeType: "text/plain" },
	m3u: { key: "m3u", mimeType: "audio/x-mpegurl" },
	m4a: { key: "m4a", mimeType: "audio/mp4a-latm" },
	m4b: { key: "m4b", mimeType: "audio/mp4a-latm" },
	m4p: { key: "m4p", mimeType: "audio/mp4a-latm" },
	m4u: { key: "m4u", mimeType: "video/vnd.mpegurl" },
	m4v: { key: "m4v", mimeType: "video/x-m4v" },
	mid: { key: "mid", mimeType: "audio/midi" },
	midi: { key: "midi", mimeType: "audio/midi" },
	mov: { key: "mov", mimeType: "video/quicktime" },
	mp2: { key: "mp2", mimeType: "audio/x-mpeg" },
	mp3: { key: "mp3", mimeType: "audio/x-mpeg" },
	mp4: { key: "mp4", mimeType: "video/mp4" },
	mpc: { key: "mpc", mimeType: "application/vnd.mpohun.certificate" },
	mpe: { key: "mpe", mimeType: "video/mpeg" },
	mpeg: { key: "mpeg", mimeType: "video/mpeg" },
	mpg: { key: "mpg", mimeType: "video/mpeg" },
	mpg4: { key: "mpg4", mimeType: "video/mp4" },
	mpga: { key: "mpga", mimeType: "audio/mpeg" },
	mpkg: { key: "mpkg", mimeType: "application/vnd.apple.installer+xml" },
	msg: { key: "msg", mimeType: "application/vnd.ms-outlook" },
	odp: { key: "odp", mimeType: "application/vnd.oasis.opendocument.presentation" },
	ods: { key: "ods", mimeType: "application/vnd.oasis.opendocument.spreadsheet" },
	odt: { key: "odt", mimeType: "application/vnd.oasis.opendocument.text" },
	oga: { key: "oga", mimeType: "audio/ogg" },
	ogg: { key: "ogg", mimeType: "audio/ogg" },
	ogv: { key: "ogv", mimeType: "video/ogg" },
	ogx: { key: "ogx", mimeType: "application/ogg" },
	otf: { key: "otf", mimeType: "font/otf" },
	pdf: { key: "pdf", mimeType: "application/pdf" },
	png: { key: "png", mimeType: "image/png" },
	pps: { key: "pps", mimeType: "application/vnd.ms-powerpoint" },
	ppt: { key: "ppt", mimeType: "application/vnd.ms-powerpoint" },
	pptx: { key: "pptx", mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
	prop: { key: "prop", mimeType: "text/plain" },
	rar: { key: "rar", mimeType: "application/x-rar-compressed" },
	rc: { key: "rc", mimeType: "text/plain" },
	rmvb: { key: "rmvb", mimeType: "audio/x-pn-realaudio" },
	rtf: { key: "rtf", mimeType: "application/rtf" },
	sh: { key: "sh", mimeType: "text/plain" },
	svg: { key: "svg", mimeType: "image/svg+xml" },
	swf: { key: "swf", mimeType: "application/x-shockwave-flash" },
	tar: { key: "tar", mimeType: "application/x-tar" },
	tgz: { key: "tgz", mimeType: "application/x-compressed" },
	tif: { key: "tif", mimeType: "image/tiff" },
	tiff: { key: "tiff", mimeType: "image/tiff" },
	ts: { key: "ts", mimeType: "application/typescript" },
	ttf: { key: "ttf", mimeType: "font/ttf" },
	txt: { key: "txt", mimeType: "text/plain" },
	vsd: { key: "vsd", mimeType: "application/vnd.visio" },
	wav: { key: "wav", mimeType: "audio/x-wav" },
	weba: { key: "weba", mimeType: "audio/webm" },
	webm: { key: "webm", mimeType: "video/webm" },
	webp: { key: "webp", mimeType: "image/webp" },
	wma: { key: "wma", mimeType: "audio/x-ms-wma" },
	wmv: { key: "wmv", mimeType: "audio/x-ms-wmv" },
	woff: { key: "woff", mimeType: "font/woff" },
	woff2: { key: "woff2", mimeType: "font/woff2" },
	wps: { key: "wps", mimeType: "application/vnd.ms-works" },
	xhtml: { key: "xhtml", mimeType: "application/xhtml+xml" },
	xls: { key: "xls", mimeType: "application/vnd.ms-excel" },
	xlsx: { key: "xlsx", mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
	xml: { key: "xml", mimeType: "text/plain" },
	xul: { key: "xul", mimeType: "application/vnd.mozilla.xul+xml" },
	z: { key: "z", mimeType: "application/x-compress" },
	zip: { key: "zip", mimeType: "application/zip" }
};

export abstract class FileHelper {

	//#region FIELDS

	private static readonly C_MAX_CANVAS_AREA_PX = 268435456;
	private static readonly C_MAX_CANVAS_WIDTH_PX = 32767;
	private static readonly C_MAX_CANVAS_HEIGHT_PX = 32767;
	private static readonly C_LOG_ID = "FILE.H::";

	//#endregion

	//#region PROPERTIES

	/** Regex pour trouver le type de contenu de la base64. */
	public static readonly C_BASE64_TYPE_REGEX = /^data:.+;base64,/;

	//#endregion

	//#region METHODS

	/** @deprecated Convertit une chaîne de caractères (base64) en objet Blob en prenant compte des données et du type de contenu.\
	 * Utiliser l'autre surcharge.
	 * @param psBase64 base64 sous forme de chaîne de caractères (avec ou sans le "data:contentType").
	 * @param psContentType Type de contenu de la base64 (image/png ...) qui doit être renseigné si la base64 ne le contient pas.
	 * @param pnSliceSize Taille de chaque paquets en byte, optionnel.
	 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
	 */
	public static base64toBlob(psBase64: string, psContentType?: string, pnSliceSize?: number): Blob;
	/** Convertit une donnée base64 en objet Blob en prenant compte des données et du type de contenu.
	 * @param poData Donnée base64 à transformer (avec ou sans le "data:contentType").
	 * @param psContentType Type de contenu de la base64 (image/png ...) qui doit être renseigné si la base64 ne le contient pas.
	 * @param pnSliceSize Taille de chaque paquets en byte, optionnel.
	 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
	 */
	public static base64toBlob(poData: IBase64Data, psContentType?: string, pnSliceSize?: number): Blob;
	public static base64toBlob(poData: string | IBase64Data, psContentType?: string, pnSliceSize: number = 512): Blob {
		const loData: IBase64Data = typeof poData === "string" ? { base64: poData } : poData;

		if (StringHelper.isBlank(psContentType))
			psContentType = this.extractContentTypeFromBase64(loData);

		this.crushBase64DataPrefix(loData);

		const lsByteCharacters: string = atob(loData.base64);
		const laBlobParts: Array<Uint8Array> = []; // Ne pas mettre le type Array<BlobPart> car ne compile pas.

		for (let lnOffset = 0; lnOffset < lsByteCharacters.length; lnOffset += pnSliceSize) {
			const lsSlice: string = lsByteCharacters.slice(lnOffset, lnOffset + pnSliceSize);

			const laByteNumbers: Array<number> = new Array(lsSlice.length);
			for (let lnIndex = 0; lnIndex < lsSlice.length; ++lnIndex) {
				laByteNumbers[lnIndex] = lsSlice.charCodeAt(lnIndex);
			}

			laBlobParts.push(new Uint8Array(laByteNumbers));
		}

		const loBlobContentType: BlobPropertyBag = { type: psContentType };

		return new Blob(laBlobParts, loBlobContentType);
	}

	/** Récupère un Blob et permet d'obtenir sa base64.
	 * @param poBlob Blob que l'on souhaite transformer.
	 */
	public static blobToBase64Data(poBlob: Blob): Observable<IBase64Data> {
		const loFileReader = new FileReader();
		const loSubject = new Subject<IBase64Data>();

		loFileReader.onloadend = (_: ProgressEvent) => {
			loSubject.next({ base64: loFileReader.result } as IBase64Data);
			loSubject.complete();
		};

		loFileReader.readAsDataURL(poBlob);

		return loSubject;
	}

	/** Permet de transformer un blob en fichier.
	 * @param poData Blob à transformer.
	 * @param psName Nom du fichier, optionnel.
	 */
	public static blobToFile(poData: Blob | File, psName?: string): File {
		if (StringHelper.isBlank((poData as File).name))
			return <File>Object.assign(poData, { name: psName }); //todo : tester mais très certainement mieux -> return new File([poData], psName ?? "file", { type: poData.type });
		else
			return poData as File;
	}

	public static downloadBlob(blob: string | Blob, fileName: string): void {
		saveAs(blob, fileName);
	}

	/** Écrase le préfixe d'une base64 ("data:...;base64,") pour ne garder que la donnée nécessaire.
	 * @param poData Donnée de la base64 dont il faut écraser le préfixe.
	 */
	public static crushBase64DataPrefix(poData: IBase64Data): IBase64Data {
		if (poData.base64 && this.C_BASE64_TYPE_REGEX.test(poData.base64))
			poData.base64 = poData.base64.replace(this.C_BASE64_TYPE_REGEX, "");

		return poData;
	}

	/** @deprecated Extrait le type de contenu d'une base64 à partir de celle-ci.\
	 * Utiliser l'autre surcharge.
	 * @param psBase64 Chaîne de caractères correspondant à la base64 dont il faut extraire le type.
	 */
	public static extractContentTypeFromBase64(psBase64: string): string;
	/** Extrait le type de contenu d'une données base64 à partir de celle-ci.
	 * @param psBase64 Chaîne de caractères correspondant à la base64 dont il faut extraire le type.
	 */
	public static extractContentTypeFromBase64(poData: IBase64Data): string;
	public static extractContentTypeFromBase64(poData: string | IBase64Data): string {
		const loData: IBase64Data = typeof poData === "string" ? { base64: poData } : poData;

		return loData.base64.indexOf("data:") >= 0 ? loData.base64.split(/[:;]/)[1] : "";
	}

	/** Réduit la taille d'une image depuis son Blob si nécessaire et renvoie un fichier de l'image réduite.
	 * @param poOriginalBlob Blob original qui correspond à l'image à réduire.
	 * @param pnMaxSizeKb Taille maximale de l'image en sortie en Kb.
	 * @param psMimeType Type mime pour l'image de sortie.
	 */
	public static reduceImage(poOriginalBlob: Blob, pnMaxSizeKb: number, psMimeType: string = EXTENSIONS_AND_MIME_TYPES.jpeg.mimeType): Observable<File> {
		const lnMaxSizeBytes: number = pnMaxSizeKb * 1024; // On convertit la taille max de l'image de kilo-octets vers octets.

		if (lnMaxSizeBytes >= poOriginalBlob.size) // Si la taille max est supérieure à la taille de l'image, pas besoin de la réduire.
			return of(this.blobToFile(poOriginalBlob));

		else { // Sinon réduction.
			const loPerformance = new PerformanceManager;
			return defer(() => {
				loPerformance.markStart();
				return this.processReducingImage(poOriginalBlob, lnMaxSizeBytes, psMimeType);
			})
				.pipe(tap(
					(poReducedFile: File) => console.debug(`FILE.H::Image reducing duration (ms) : ${loPerformance.markEnd().measure()} ; ${poOriginalBlob.size} Bytes -> ${poReducedFile.size} Bytes`),
					poError => {
						loPerformance.clear();
						console.error("FILE.H:: Error when reducing image :", poError);
					}
				));
		}
	}

	/** Traitement de réduction de la taille d'une image depuis son Blob renvoie un fichier de l'image réduite.
	 * @param poOriginalBlob Blob original qui correspond à l'image à réduire.
	 * @param pnMaxSizeKb Taille maximale de l'image en sortie en Kb.
	 * @param psMimeType Type mime pour l'image de sortie.
	 */
	private static processReducingImage(poOriginalBlob: Blob, pnMaxSizeBytes: number, psMimeType: string): Observable<File> {
		const loCanvas: HTMLCanvasElement = document.createElement("canvas"); // On passe par un canvas qu'on n'affiche pas afin de manipuler l'image.
		const loImage: HTMLImageElement = document.createElement("img");
		const loBlobUrlManager = new BlobUrlManager;
		loImage.src = loBlobUrlManager.createUrl(poOriginalBlob);

		// On transforme l'événement "loImage.addEventListener('load')" en observable.
		return fromEvent(loImage, "load").pipe(
			take(1),
			mergeMap(
				(poEvent: Event) => {
					const lnImageArea: number = loImage.width * loImage.height;

					if (lnImageArea > FileHelper.C_MAX_CANVAS_AREA_PX) // Si l'image est trop grande pour le canvas on commence par réduire sa taille.
						FileHelper.reduceImageDimensionsByRatio(loImage, FileHelper.C_MAX_CANVAS_AREA_PX / lnImageArea);

					if (loImage.width > FileHelper.C_MAX_CANVAS_WIDTH_PX) // Si l'image est trop large pour le canvas on commence par réduire sa taille.
						FileHelper.reduceImageDimensionsByRatio(loImage, FileHelper.C_MAX_CANVAS_WIDTH_PX / loImage.width);

					if (loImage.height > FileHelper.C_MAX_CANVAS_HEIGHT_PX) // Si l'image est trop haute pour le canvas on commence par réduire sa taille.
						FileHelper.reduceImageDimensionsByRatio(loImage, FileHelper.C_MAX_CANVAS_HEIGHT_PX / loImage.height);

					loCanvas.width = loImage.width;
					loCanvas.height = loImage.height;
					loCanvas.getContext("2d").drawImage(loImage, loImage.x, loImage.y, loImage.width, loImage.height);

					// On encapsule la méthode de génération d'un blob de taille réduite dans une méthode car elle est asynchrone mais retourne void.
					// On place les paramètres avant la callback car l'opérateur 'bindCallback' le nécessite.
					const lfToBlob: (psType: string, pnQuality: number, pfCallback: BlobCallback) => void =
						(psType: string, pnQuality: number, pfCallback: BlobCallback) => loCanvas.toBlob(pfCallback, psType, pnQuality);
					// On crée une fonction qui renvoie un observable lié à la callback de la méthode précédente.
					const lfToBlobAsObservable: (psType: string, pnQuality: number) => Observable<Blob> = bindCallback(lfToBlob);

					return lfToBlobAsObservable(psMimeType, pnMaxSizeBytes / poOriginalBlob.size).pipe(
						mergeMap((poReducedBlob: Blob) => {
							if (poReducedBlob)
								return of(poReducedBlob);

							return throwError(new OsappError("Erreur lors de la compression de l'image"));
						}),
						tap(
							() => { },
							(poError: any) => console.error(`${FileHelper.C_LOG_ID}Error while reducing image.`, poError)
						),
						map((poReducedBlob: Blob) => this.blobToFile(poReducedBlob, (poOriginalBlob as File).name)),
						finalize(() => loBlobUrlManager.releaseUrls())
					);
				}
			)
		);
	}

	private static reduceImageDimensionsByRatio(loImage: HTMLImageElement, lnRatio: number): void {
		loImage.width *= lnRatio;
		loImage.height *= lnRatio;
	}

	/** @deprecated Retourne la taille de l'image correspondante à la base 64 passée en paramètre, en octets. Retourne 0 si la base 64 n'est pas valide.\
	 * Utiliser l'autre surcharge.
	 * @param psBase64 Chaîne de caractères représentant la base 64 dont il faut calculer la taille.
	 */
	public static getBase64BytesSize(psBase64: string): number;
	/** Retourne la taille de l'image correspondante à la base64 passée en paramètre, en octets. Retourne 0 si la base64 n'est pas valide.
	 * @param poData Donnée de la base64 dont il faut calculer la taille.
	 */
	public static getBase64BytesSize(poData: IBase64Data): number;
	public static getBase64BytesSize(poData: string | IBase64Data): number {
		const loData: IBase64Data = typeof poData === "string" ? { base64: poData } : poData;

		return loData.base64 ? Math.round(loData.base64.replace(/data:.+;base64,/, "").length * 0.75) : 0;
	}

	/** Télécharge le blob
	 * @param poBlob Le blob.
	 * @param psName Nom du fichier.
	 */
	public static saveBlob(poBlob: Blob, psName: string): void {
		const loLink: HTMLAnchorElement = document.createElement("a");
		document.body.appendChild(loLink);
		loLink.style.display = "none";
		const lsUrl: string = window.URL.createObjectURL(poBlob);
		loLink.href = lsUrl;
		loLink.download = psName;
		loLink.click();
		window.URL.revokeObjectURL(lsUrl);
	};

	/** Récupère l'extension du nom du fichier spécifié, `undefined` si non trouvée.
	 * @param psFileName Nom du fichier avec extension.
	 */
	public static getFileExtensionFromFileName(psFileName: string): string | undefined {
		if (StringHelper.isBlank(psFileName))
			return undefined;
		else
			return psFileName.indexOf(".") > 0 ? ArrayHelper.getLastElement(psFileName.split(".")) : undefined;
	}

	/** Récupère le nom du fichier spécifié sans son extension, chaîne vide par défaut.
	 * @param psFileName Nom du fichier avec extension.
	 */
	public static getFileNameWithoutExtension(psFileName: string): string {
		if (StringHelper.isBlank(psFileName))
			return "";
		else {
			const lsFileExtension: string | undefined = this.getFileExtensionFromFileName(psFileName);
			return lsFileExtension ? psFileName.replace(`.${lsFileExtension}`, "") : psFileName;
		}
	}

	/** Extrait le mime type de l'extension dans une url, retourne 'undefined' si l'extension n'est pas reconnue.
	 * @param psFileNameWithExtension Nom du fichier avec extension dont il faut récupérer le mimeType.
	 */
	public static extractMimeTypeFromFileNameWithExtension(psFileNameWithExtension: string): string | undefined {
		if (StringHelper.isBlank(psFileNameWithExtension))
			return undefined;
		else {
			const lsFileExtension: string | undefined = this.getFileExtensionFromFileName(psFileNameWithExtension);
			return lsFileExtension ? EXTENSIONS_AND_MIME_TYPES[lsFileExtension.toLocaleLowerCase()]?.mimeType : undefined;
		}
	}

	/** Récupère l'extension associée à un type mime, `undefined` si non trouvée.
	 * @param psMimeType Type mime dont on veut récupérer l'extension associée.
	 */
	public static getExtensionFromMimeType(psMimeType: string): string | undefined {
		if (StringHelper.isBlank(psMimeType))
			return undefined;
		else {
			return Object.values(EXTENSIONS_AND_MIME_TYPES)
				.find((poValue: { key: string, mimeType: string }) => poValue.mimeType === psMimeType)
				?.key;
		}
	}

	/** @deprecated Retourne `true` si la donnée est bien une base64, `false` sinon.\
	 * Utiliser l'autre surcharge.
	 * @param psData Donnée de la base64 dont il faut vérifier si elle est bien une base64 ou non.
	 */
	public static isBase64(psData: string): boolean;
	/** Retourne `true` si la donnée est bien une base64, `false` sinon.
	 * @param psData Donnée de la base64 dont il faut vérifier si elle est bien une base64 ou non.
	 */
	public static isBase64(poData: IBase64Data): boolean;
	public static isBase64(poData: string | IBase64Data): boolean {
		const loData: IBase64Data = typeof poData === "string" ? { base64: poData } : poData;

		return loData.base64.endsWith("=") &&
			/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(this.crushBase64DataPrefix(loData).base64);
	}

	/** Retourne une copie d'un Blob qui peut avoir un nouveau type mime.
	 * @param poData Données à cloner.
	 * @param psMimeType Type mime du nouveau fichier, identique à l'original si non renseigné.
	 */
	public static cloneBlob(poData: Blob, psMimeType?: string): Blob {
		return new Blob([poData], { type: StringHelper.isBlank(psMimeType) ? poData.type : psMimeType });
	}

	/** Retourne une copie d'un File qui peut avoir un nouveau type mime.
	 * @param poData Données à cloner.
	 * @param psMimeType Type mime du nouveau fichier, identique à l'original si non renseigné.
	 */
	public static cloneFile(poData: File, psMimeType?: string): File {
		return new File([poData], poData.name, { type: StringHelper.isBlank(psMimeType) ? poData.type : psMimeType });
	}

	/** Récupère l'extension d'un fichier en fonction de sa signature, `undefined` pas de correspondance.
	 * @param psMagicNumber Nombre correspondant à la signature du type de fichier.
	 */
	public static getExtensionFromMagicNumber(psMagicNumber: string): string {
		switch (psMagicNumber.toUpperCase()) {
			case "504B0304": // zip.
				return EXTENSIONS_AND_MIME_TYPES.zip.key;

			case "53514C69": // BDD sqlite.
				return EXTENSIONS_AND_MIME_TYPES.db.key;

			case "89504E47":
				return EXTENSIONS_AND_MIME_TYPES.png.key;

			case "47494638":
				return EXTENSIONS_AND_MIME_TYPES.gif.key;

			case "25504446":
				return EXTENSIONS_AND_MIME_TYPES.pdf.key;

			case "FFD8FFDB":
			case "FFD8FFE0":
			case "FFD8FFE1":
				return EXTENSIONS_AND_MIME_TYPES.jpeg.key;

			default:
				console.error(`${FileHelper.C_LOG_ID}can not getting extension from magic number '${psMagicNumber}'`);
				return undefined;
		}
	}

	//#endregion

}