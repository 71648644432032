<form [formGroup]="form" class="import-form">
  <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>

</form>

<ion-grid>
  <ion-row>
    <ion-col id="preview-field">
      <p>Preview</p>
      <pre>{{ response | json }}</pre>

    </ion-col>
    <ion-col>
      <p>Visualisation du document :</p>
      <table *ngIf='File'>
        <tr>
          <th *ngFor="let title of File.split('\n')[0].split(';')">{{title}}</th>
        </tr>
        <tr *ngFor="let item of File.split('\n')| slice:1 ; let i=index ">
          <td *ngFor="let elem of item.split(';')">{{elem}}</td>
        </tr>
      </table>
    </ion-col>
  </ion-row>
</ion-grid>