import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { StringHelper } from '../../../helpers/stringHelper';
import { IAppointment } from '../../../model/booking/IAppointment';
import { IBookingSession } from '../../../model/booking/IBookingSession';
import { ICall } from '../../../model/booking/ICall';
import { IInvitation } from '../../../model/booking/IInvitation';
import { IStream } from '../../../model/booking/IStream';
import { IUserAgent } from '../../../model/booking/IUserAgent';
import { IContact } from '../../../model/contacts/IContact';
import { LoadingService } from '../../../services/loading.service';
import { BookingService } from '../../booking/booking.service';
import { Loader } from '../../loading/Loader';
import { LiveService } from '../live.service';

declare const apiRTC; // Indispensable pour utiliser l'apiRTC importé via script js.

interface Counter {
	min: number;
	sec: number;
}

@Component({
	selector: "calao-called",
	templateUrl: '../call/call.component.html',
	styleUrls: ['../call/call.component.scss'],
})
export class CalledComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private moUserAgent: IUserAgent;
	private moConnectedSession: IBookingSession;
	private moCall: ICall;
	private moLocalStream: IStream;
	private moDefaultCounter: Counter;
	private mnCallDuration: number;
	private moInvitationSubject = new Subject<IInvitation>();

	//#endregion

	//#region PROPERTIES

	public appointmentId: string;

	public counter: Counter;
	public isCustomerView: boolean;
	public isCallStarted: boolean;

	@Input() public apiKey: string;
	@Input() public connectedContact: IContact;
	@Input() public isVideoMuted: boolean;
	@Input() public isAudioMuted: boolean;
	@Input() public defaultRemoteImageSrc: string;
	@Input() public waitingMessage: string;
	@Output("callerLeft") private readonly callerLeftEventEmitter = new EventEmitter<void>();

	//#endregion

	//#region METHODS

	constructor(
		private isvcLive: LiveService,
		private ioActivatedRoute: ActivatedRoute,
		private isvcBooking: BookingService,
		private isvcLoading: LoadingService
	) {
		super();
	}

	public async ngOnInit(): Promise<void> {
		this.moUserAgent = new apiRTC.UserAgent({
			uri: `apzkey:${this.apiKey}`
		});

		this.appointmentId = this.ioActivatedRoute.snapshot.params.appointmentId;
		this.isCustomerView = true;

		const loLoader: Loader = await this.isvcLoading.create("Préparation de l'appel ...");

		await loLoader.present();

		try {
			this.moConnectedSession = await this.moUserAgent.register({
				id: `${this.connectedContact._id}|${this.appointmentId}`,
				userData: { "username": this.connectedContact.firstName }
			});
			this.moConnectedSession.on("incomingCall", (poInvitation: IInvitation) => this.moInvitationSubject.next(poInvitation));
			this.handleIncomingCall();
		}
		finally {
			loLoader.dismiss();
		}
	}

	/** Initialise les événements liés à l'appel en cours @param poCall. */
	public setCallListeners(poCall: ICall): void {
		poCall
			.on("localStreamAvailable", (poStream: IStream) => this.onLocalStreamAvailable(poStream))
			.on("streamAdded", (poStream: IStream) => this.onStreamAdded(poStream))
			.on("streamRemoved", (poStream: IStream) => this.onStreamRemoved(poStream))
			.on("userMediaError", (poError: any) => this.onUserMediaError(poError))
			.on("hangup", () => this.onHangup());
	}

	private handleIncomingCall(): void {
		this.moInvitationSubject.asObservable()
			.pipe(
				tap(_ => console.debug("CALLED.C:: incomingCall")),
				switchMap((poInvitation: IInvitation) => this.isvcBooking.getAppointment(this.appointmentId)
					.pipe(
						tap((poAppointment: IAppointment) => {
							this.mnCallDuration = poAppointment.durationInMinutes;
							if (!StringHelper.isBlank(poAppointment.callStartDate))
								this.moDefaultCounter = this.isvcLive.generateCounter(this.mnCallDuration, new Date(poAppointment.callStartDate));
						}),
						mergeMap(_ => poInvitation.accept())
					)
				),
				switchMap((poCall: ICall) => {
					this.moCall = poCall;
					this.setCallListeners(this.moCall);
					this.isCallStarted = true;
					return this.isvcLive.startTimer(this.moDefaultCounter, this.mnCallDuration);
				}),
				tap((poCounter: Counter) => {
					this.counter = poCounter;
					if (poCounter.min === 0 && poCounter.sec === 0)
						this.hangupCall();
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	private onLocalStreamAvailable(poStream: IStream): void {
		this.moLocalStream = poStream;

		if (this.isAudioMuted)
			this.moLocalStream.muteAudio();
		if (this.isVideoMuted)
			this.moLocalStream.muteVideo();

		this.isvcLive.addStreamInDiv(this.moLocalStream, "localStream", `local-media-${this.moLocalStream.getId()}`, { width: "100%" }, true);

		this.moLocalStream
			.on("stopped", () => this.onStopped());
	}

	private onStopped(): void {
		console.warn("Stream stopped");
		document.getElementById(`local-media-${this.moLocalStream.getId()}`).remove();
	}

	private onStreamAdded(poStream: IStream): void {
		this.isvcLive.addStreamInDiv(poStream, "remoteStream", `remote-media-${poStream.getId()}`, { width: "100%" }, false);
	}

	private onStreamRemoved(poStream: IStream): void {
		this.callerLeftEventEmitter.emit();
		document.getElementById(`remote-media-${poStream.getId()}`).remove();
	}

	private onUserMediaError(poError: any): void {
		console.error("CALLED.C:: userMediaError detected : ", poError);
		console.error("CALLED.C:: userMediaError detected with error : ", poError.error);
	}

	private onHangup(): void {
		this.moCall = null;
	}

	/** Raccrocher un appel. */
	public hangupCall(pbIsUserAction?: boolean): void {
		this.isvcLive.hangupCall(this.moCall);
	}

	public unregister(): Promise<void> {
		this.callerLeftEventEmitter.complete();
		this.moInvitationSubject.complete();
		this.isvcLive.hangupCall(this.moCall);
		return this.moUserAgent.unregister();
	}

	/** Active ou coupe le micro. */
	public toggleMuteAudio(): void {
		if (this.isAudioMuted) {
			this.isAudioMuted = false;

			if (this.moCall)
				this.moLocalStream.unmuteAudio();
		}
		else {
			this.isAudioMuted = true;

			if (this.moCall)
				this.moLocalStream.muteAudio();
		}
	}

	/** Active ou coupe la vidéo. */
	public toggleMuteVideo(): void {
		if (this.isVideoMuted) {
			this.isVideoMuted = false;

			if (this.moCall)
				this.moLocalStream.unmuteVideo();
		}
		else {
			this.isVideoMuted = true;

			if (this.moCall)
				this.moLocalStream.muteVideo();
		}
	}

	//#endregion

}