import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Document } from '../../models/document';
import { EListItemOption } from '../../models/elist-item-option';
import { IListItemOption } from '../../models/ilist-item-option';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';

@Component({
	selector: 'calao-document-item',
	templateUrl: './document-item.component.html',
	styleUrls: ['./document-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentItemComponent extends DestroyableComponentBase {

	//#region FIELDS

	@Output("onOpenClicked") private readonly moOpenClickedEvent = new EventEmitter<Document>();
	@Output("onOptionClicked") private readonly moOptionClickedEvent = new EventEmitter<IListItemOptionClickEvent>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Document. */
	@Input() public document: Document;
	@ObserveProperty<DocumentItemComponent>({ sourcePropertyKey: "document" })
	public readonly observableDocument = new ObservableProperty<Document>();

	/** Indique s'il faut afficher l'arbre de navigation. */
	@Input() public displayNavigationTree: boolean;
	@ObserveProperty<DocumentItemComponent>({ sourcePropertyKey: "displayNavigationTree" })
	public readonly observableDisplayNavigationTree = new ObservableProperty<boolean>(false);

	/** Liste des options de l'item. */
	@Input() public options: IListItemOption[] | undefined;
	@ObserveArray<DocumentItemComponent>("options")
	public readonly observableOptions = new ObservableArray<IListItemOption>();

	/** Indique si le document est lu. */
	@Input() public documentRead: boolean | undefined;
	@ObserveProperty<DocumentItemComponent>({ sourcePropertyKey: "documentRead" })
	public readonly observableDocumentRead = new ObservableProperty<boolean>();

	//#endregion

	//#region METHODS

	public onOptionClicked(peOptionKey: EListItemOption, poDocument: Document): void {
		this.moOptionClickedEvent.emit({ key: peOptionKey, document: poDocument });
	}

	public raiseOpenDocument(poDocument: Document): void {
		this.moOpenClickedEvent.next(poDocument);
	}

	//#endregion

}
