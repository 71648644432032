import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { IAppStartPauseLogData } from '../model/application/iapp-start-pause-log-data';
import { ConfigData } from '../model/config/ConfigData';
import { EConfigFlag } from '../model/config/EConfigFlag';
import { IDatabaseDocumentEvent } from '../model/databaseDocument/IDatabaseDocumentEvent';
import { IFlag } from '../model/flag/IFlag';
import { EventsService } from '../modules/events/events.service';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { FlagService } from './flag.service';
import { GlobalDataService } from './global-data.service';
import { PlatformService } from './platform.service';

@Injectable({ providedIn: "root" })
export class ApplicationService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "APP.S::";

	/** Texte pour le loader d'une récupération de données. */
	public static readonly C_LOAD_DATA_LOADER_TEXT = "Récupération des données ...";
	public static readonly C_HOME_ROUTE_URL = "home";
	public static readonly C_APP_START_TIME_KEY = "appStart";
	public static readonly C_APP_AVAILABLE_TIME_KEY = "appAvailable";
	/** Clé du temps que l'utilisateur à mis pour s'authentifier dans le globalDataService. */
	public static readonly C_USER_LOGIN_INTERACTION_DURATION_KEY = "userLoginInteractionDuration";
	public static readonly C_AUTHENTICATION_ROUTE_URL = "/authenticator";
	public static readonly C_LOADING_ROUTE_URL = "/loading";
	public static readonly C_ROOT_ROUTE_URL = "/";

	private static readonly C_NOT_AVAILABLE_APP_URLS = [ApplicationService.C_LOADING_ROUTE_URL, ApplicationService.C_AUTHENTICATION_ROUTE_URL, ApplicationService.C_ROOT_ROUTE_URL];

	private readonly moApplicationEventSubject = new Subject<IApplicationEvent>();
	private readonly applicationReadySubject = new ReplaySubject<void>(1);

	/** Sujet pour envoyer des événements de création de document. */
	protected readonly moDatabaseDocumentEventSubject = new Subject<IDatabaseDocumentEvent>();

	private moScheldulerEventSubject = new Subject<IApplicationEvent>();

	//#endregion

	//#region PROPERTIES

	/** Permet de s'abonner à l'événement indiquant que la configuration de l'app est terminée. */
	public get applicationReady$(): Observable<void> { return this.applicationReadySubject.asObservable(); }
	/** Permet de s'abonner aux événements de l'application. */
	public get appEvent$(): Observable<IApplicationEvent> { return this.moApplicationEventSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor(
		private isvcFlag: FlagService,
		private isvcEvents: EventsService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcGlobalData: GlobalDataService,
		private readonly ioRouter: Router
	) {
		super();
		this.isvcFlag.waitForFlag(EConfigFlag.ConfigReady, true).subscribe(_ => this.applicationReadySubject.next());

		this.ioRouter.events.pipe(
			filter((poEvent: RouterEvent) => poEvent instanceof NavigationEnd && !ApplicationService.C_NOT_AVAILABLE_APP_URLS.includes(ArrayHelper.getFirstElement(poEvent.url.split("?")))),
			take(1),
			tap(() => this.markAppAvailable()),
			takeUntil(this.destroyed$)
		).subscribe();

		if (!isvcPlatform.isMobileApp) {
			// Permet de montrer la popup native du navigateur si un rechargement de la page va avoir lieu, seulement si on n'est pas sur l'app mobile.
			window.addEventListener(
				"beforeunload",
				(poEvent: BeforeUnloadEvent) => {
					poEvent.preventDefault();
					return poEvent.returnValue = "";
				}
			);
		}
	}

	/** Permet de faire passer le sujet de l'app service au scheduler.
	 * @param poApplicationSubject Sujet de l'app service.
	 */
	public setApplicationSubject(poApplicationSubject: Subject<IApplicationEvent>): void {
		this.moScheldulerEventSubject = poApplicationSubject;
		this.moScheldulerEventSubject.asObservable().subscribe(
			(poResult: IApplicationEvent) => {
				this.isvcEvents.raiseEvent(poResult);
				this.moApplicationEventSubject.next(poResult);
			},
			poError => this.moApplicationEventSubject.error(poError)
		);
	}

	/** Permet de s'abonner aux événements du service d'application.
	 * @param pfNext Fonction appelée lors du next => function(poResult: StoreEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 * @param pfComplete Fonction appelée lors du complete.
	 */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moDatabaseDocumentEventSubject.asObservable().subscribe(
			(poResult: IApplicationEvent) => pfNext(poResult),
			poError => pfError(poError),
			() => pfComplete()
		);
	}

	/** Permet d'attendre qu'un flag passe à l'état voulu.
	 * @param psFlagName Nom du flag.
	 * @param pbFlagValue Valeur du flag souhaité.
	 */
	public waitForFlag(psFlagName: string, pbFlagValue: boolean): Observable<IFlag> {
		return this.observeFlag(psFlagName)
			.pipe(
				filter((poFlag: IFlag) => poFlag.value === pbFlagValue),
				take(1)
			);
	}

	/** Permet d'observer un flag en continu.
	 * @param psFlagName Nom du flag.
	 */
	public observeFlag(psFlagName: string): Observable<IFlag> {
		return this.appEvent$
			.pipe(
				filter((poEvent: IApplicationEvent) => poEvent.alteredFlags && poEvent.alteredFlags.some((poFlag: IFlag) => poFlag.key === psFlagName)),
				map((poEvent: IApplicationEvent) => ({ key: psFlagName, value: poEvent.alteredFlags.find((poFlag: IFlag) => poFlag.key === psFlagName).value })),
				startWith(this.isvcFlag.getFlag(psFlagName))
			);
	}

	/** ### ATTENTION : DOIT ÊTRE RÉIMPLÉMENTÉE DANS LES FILLES !
	 * Attend la fin de l'initialisation des bases de données pour demander la création des design documents.
	 * @param psvcApplication Service d'application permettant de savoir quand les bases de données sont initialisées.
	 */
	protected waitInitDatabasesToCreateDatabaseDocuments(psvcApplication: ApplicationService): void { }

	/** Recharge l'app. */
	public static reloadApp(): void {
		location.reload();
	}

	/** Quitte l'app. */
	public static exitApp(): void {
		navigator["app"].exitApp();
	}

	/** Détécte la mise en pause de l'application et log l'action `calao-app-suspended` en indiquant le niveau de batterie si disponible. */
	public logOnPause(): void {
		this.isvcPlatform.onPause$
			.pipe(
				tap(_ => this.logAppStatus(ApplicationService.C_LOG_ID, false))
			).subscribe();
	}

	/** Détécte la reprise d'activité de l'application et log l'action `calao-app-resumed` en indiquant le niveau de batterie si disponible. */
	public logOnResume(): void {
		this.isvcPlatform.onResume$
			.pipe(
				tap(_ => this.logAppStatus(ApplicationService.C_LOG_ID, true))
			).subscribe();
	}

	/** Log l'action correspondant à une mise en pause ou à une reprise d'activité de l'application en précisant le niveau de batterie si fournit.
	 * @param psLogId Identifiant du composant.
	 * @param pbIsResume Indique s'il s'agit d'une reprise d'activité de l'application.
	 * @param pnBatteryLevel Le niveau de batterie en pourcentage.
	 */
	private logAppStatus(psLogId: string, pbIsResume?: boolean): void {
		const loAppStartPauseLogData: IAppStartPauseLogData = {
			version: ConfigData.appInfo.appVersion,
			environment: ConfigData.environment.id
		};

		this.isvcLogger.action(
			psLogId,
			pbIsResume ? "Reprise d'activité de l'application." : "Mise en pause de l'application.",
			pbIsResume ? ELogActionId.appResumed : ELogActionId.appSuspended,
			loAppStartPauseLogData
		);
	}

	public markAppStart(): void {
		this.isvcGlobalData.setData(ApplicationService.C_APP_START_TIME_KEY, Date.now());
	}

	public markAppAvailable(): void {
		const lnTime: number = Date.now();
		this.isvcGlobalData.setData(ApplicationService.C_APP_AVAILABLE_TIME_KEY, lnTime);
		this.isvcLogger.action(
			ApplicationService.C_LOG_ID,
			"Application initialisée.",
			ELogActionId.appAvailable,
			{
				startTimeMs: lnTime - this.isvcGlobalData.getCurrentData(ApplicationService.C_APP_START_TIME_KEY) -
					this.isvcGlobalData.getCurrentData(ApplicationService.C_USER_LOGIN_INTERACTION_DURATION_KEY),
				userLoginInteractionTimeMs: this.isvcGlobalData.getCurrentData(ApplicationService.C_USER_LOGIN_INTERACTION_DURATION_KEY)
			}
		);
	}

	//#endregion
}