import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevicesSecurityService } from './services/devices-security.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [DevicesSecurityService]
})
export class DevicesModule { }