import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, defer, of, throwError } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { Entity } from '../../../../model/entities/Entity';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { GalleryService } from '../../../../services/gallery.service';
import { EntitiesService } from '../../../entities/services/entities.service';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Document } from '../../models/document';
import { EExplorerDisplayMode } from '../../models/eexplorer-display-mode';
import { EListItemOption } from '../../models/elist-item-option';
import { FolderContent } from '../../models/folder-content';
import { FormDocument } from '../../models/form-document';
import { IAddDocumentParams } from '../../models/iadd-document-modal-params';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';
import { DocExplorerService } from '../../services/doc-explorer.service';
import { DocumentStatusService } from '../../services/document-status.service';
import { AddDocumentModalComponent } from '../add-document-modal/add-document-modal.component';
import { FolderListComponent } from '../folder-list/folder-list.component';

@Component({
	selector: 'calao-doc-explorer',
	templateUrl: './doc-explorer.component.html',
	styleUrls: ['./doc-explorer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocExplorerComponent extends ComponentBase implements OnDestroy {

	//#region FIELDS

	@ViewChild(FolderListComponent, { static: false }) private moFolderListComponent: FolderListComponent;

	/** Événement lors du changement de chemin. */
	@Output("onPathChanged") private readonly moPathChangedEvent = new EventEmitter<string>();
	@Output("onDisplayModeChanged") private readonly moDisplayModeChangedEvent = new EventEmitter<EExplorerDisplayMode>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Chemin du dossier parent sur lequel limiter la récupération des documents. */
	@Input() public rootPath?: string | null;
	@ObserveProperty<DocExplorerComponent>({ sourcePropertyKey: "rootPath" })
	public readonly observableRootPath = new ObservableProperty<string | undefined>(undefined);

	/** Mode d'affichage. */
	@Input() public displayMode: EExplorerDisplayMode;
	@ObserveProperty<DocExplorerComponent>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<EExplorerDisplayMode>();

	/** Route courante. */
	@Input() public currentPath?: string;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "currentPath" })
	public readonly observableCurrentPath = new ObservableProperty<string>();

	/** Flux indiquant si on peut ajouter un document. */
	public readonly observableCanCreate = new ObservableProperty<boolean>(false);
	/** Flux indiquant si on affiche le chemin de navigation. */
	public readonly observableDisplayNavigationTree = new ObservableProperty<boolean>(false);

	/** Enum mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;
	/** Enum mode d'affichage. */
	public readonly explorerDisplayMode = EExplorerDisplayMode;
	/** Liste des options d'affichage. */
	public readonly displayModeOptions: ReadonlyArray<ISelectOption<EExplorerDisplayMode>> = [{ label: "Dossiers", value: EExplorerDisplayMode.folders }, { label: "Date", value: EExplorerDisplayMode.date }];

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcDocExplorer: DocExplorerService,
		protected readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		protected readonly isvcDocumentStatus: DocumentStatusService,
		protected readonly isvcModal: ModalService,
		protected readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcGallery: GalleryService,
		private readonly ioRoute: ActivatedRoute,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.observableCurrentPath.value$.pipe(
			map((psPath: string) => psPath ? this.isvcDocExplorerDocuments.checkFolderPermissions(psPath, "create", false) : false),
			tap((pbCanCreate: boolean) => this.observableCanCreate.value = pbCanCreate),
			secure(this)
		).subscribe();

		this.ioRoute.queryParams.pipe(
			tap((poQueryParams: { displayMode?: EExplorerDisplayMode }) => {
				this.observableDisplayMode.value = poQueryParams.displayMode ?? EExplorerDisplayMode.folders;
			}),
			secure(this)
		).subscribe();
	}

	public onAddDocumentClickedAsync(): Promise<boolean> {
		return combineLatest([this.observableCurrentPath.value$, this.isvcEntityLink.currentEntity ? this.isvcDocExplorerDocuments.getEntity$(this.isvcEntityLink.currentEntity.id) : of(undefined)]).pipe(
			take(1),
			mergeMap(([psPath, poEntity]: [string, Entity<IStoreDocument> | undefined]) => {
				const loParams: IAddDocumentParams = {
					path: psPath,
					parentEntity: poEntity,
					activatedRoute: this.ioRoute
				};

				return this.isvcModal.open<boolean>({
					component: AddDocumentModalComponent,
					componentProps: loParams
				});
			}),
			tap((pbDocAdded: boolean) => {
				if (pbDocAdded)
					this.moFolderListComponent?.reload();
			}),
		).toPromise();
	}

	public onPathChanged(psPath: string): void {
		this.moPathChangedEvent.emit(this.observableCurrentPath.value = psPath);
	}

	public async openDocumentAsync(poDocument: Document): Promise<void> {
		await this.openDocument$(poDocument).pipe(secure(this)).toPromise();
	}

	private openDocument$(poDocument: Document): Observable<boolean> {
		return defer(() => {
			if (poDocument instanceof FormDocument) {
				return this.isvcDocExplorerDocuments.getFolderContent$(ArrayHelper.getFirstElement(poDocument.paths)).pipe(
					take(1),
					mergeMap((poFolder: FolderContent) => {
						return this.isvcEntities.navigateToEntityViewAsync(
							GuidHelper.extractGuid(poDocument._id),
							ArrayHelper.getFirstElement(poFolder.current.forms).descriptor,
							this.ioRoute
						);
					})
				);
			}
			else
				return this.isvcGallery.openFile(poDocument);
		}).pipe(
			mergeMap(() => this.markAsRead$(poDocument))
		);
	}

	private editDocument$(poDocument: FormDocument): Observable<boolean> {
		return this.isvcDocExplorerDocuments.getFolderContent$(ArrayHelper.getFirstElement(poDocument.paths)).pipe(
			take(1),
			mergeMap((poFolder: FolderContent) => {
				return this.isvcEntities.navigateToEntityEditAsync(
					GuidHelper.extractGuid(poDocument._id),
					ArrayHelper.getFirstElement(poFolder.current.forms).descriptor,
					this.ioRoute
				);
			})
		);
	}

	public onDisplayModeChanged(peSelectedDisplayMode: EExplorerDisplayMode): void {
		this.moDisplayModeChangedEvent.emit(this.observableDisplayMode.value = peSelectedDisplayMode);
	}

	private moveToTrash$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.moveToTrash$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	private restore$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.restore$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	private delete$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.delete$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	/** Marque un document comme lu.
	 * @param poDocument
	 */
	public markAsRead$(poDocument: Document): Observable<boolean> {
		return this.isvcDocumentStatus.changeReadSatus$(poDocument, "read");
	}

	/** Marque un document comme non lu.
	 * @param poDocument
	 */
	public markAsUnread$(poDocument: Document): Observable<boolean> {
		return this.isvcDocumentStatus.changeReadSatus$(poDocument, "notRead");
	}

	public async onOptionClickedAsync(poEvent: IListItemOptionClickEvent): Promise<boolean> {
		return this.onOptionClicked$(poEvent).toPromise();
	}

	public openAsync(poDocument: Document): Promise<boolean> {
		return this.onOptionClicked$({ key: EListItemOption.read, document: poDocument }).toPromise();
	}

	@Queue<
		DocExplorerComponent,
		Parameters<DocExplorerComponent["onOptionClicked$"]>,
		ReturnType<DocExplorerComponent["onOptionClicked$"]>
	>({
		excludePendings: true
	})
	private onOptionClicked$(poEvent: IListItemOptionClickEvent): Observable<boolean> {
		switch (poEvent.key) {
			case EListItemOption.delete:
				return this.delete$(poEvent.document);
			case EListItemOption.read:
				return this.openDocument$(poEvent.document);
			case EListItemOption.restore:
				return this.restore$(poEvent.document);
			case EListItemOption.trash:
				return this.moveToTrash$(poEvent.document);
			case EListItemOption.unread:
				return this.markAsUnread$(poEvent.document);
			default:
				if (poEvent.key === EListItemOption.edit && poEvent.document instanceof FormDocument)
					return this.editDocument$(poEvent.document);
				return throwError(new Error(`Event type ${poEvent.key} not supported for document ${poEvent.document._id}.`));
		}
	}

	//#endregion METHODS

}
