<div class="marg-container">
	<div class="flex-row width-contain">
		<div class="flex-align first">
			<calao-form [formDescriptor]="formDescriptor" [formDefinitionId]="designableDescriptor.selectedDefinitionId"
				[model]="designableDescriptor.fakeEntry" [readOnly]="true">
			</calao-form>
		</div>
		<div class="flex-align actions">
			<div class="vertical-align">
				<form-data-simulator></form-data-simulator>
			</div>
			<div class="vertical-align">
				<form-preview-json></form-preview-json>
			</div>
		</div>
	</div>
</div>