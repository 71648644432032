import { ObjectHelper } from "../../../helpers/objectHelper";

export class InvalidPatternError extends Error {

	constructor(psPattern: string) {
		super(`Pattern '${psPattern}' invalide.`);
		// Nécessaire pour que le 'instanceof InvalidPatternError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InvalidPatternError);
	}

}