import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IIndexedObject } from '../../../../model/IIndexedObject';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { FilterBarItemComponent } from '../filter-bar-item/filter-bar-item.component';

interface IKeyValue {
	key?: string;
	value: any;
}

@Component({
	selector: 'calao-filter-bar-main-content',
	templateUrl: './filter-bar-main-content.component.html',
	styleUrls: ['./filter-bar-main-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class FilterBarMainContentComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChildren(FilterBarItemComponent, { descendants: true }) private moFilterBarItems: QueryList<FilterBarItemComponent>;

	//#endregion

	//#region PROPERTIES

	public readonly observableFilterValues = new ObservableProperty<IIndexedObject>();

	//#endregion

	//#region METHODS

	public ngAfterContentInit(): void {
		this.observableFilterValues.value$.pipe(
			tap((poNewFilterValues?: IIndexedObject) => {
				if (poNewFilterValues) {
					this.moFilterBarItems.forEach((poFilterBarItem: FilterBarItemComponent) =>
						poFilterBarItem.observableValue.value = poNewFilterValues[poFilterBarItem.key] ?? poFilterBarItem.defaultValue
					);
				}
			}),
			takeUntil(this.destroyed$)
		).subscribe();

		this.moFilterBarItems.changes.pipe(
			startWith({}),
			switchMap(() =>
				combineLatest(this.moFilterBarItems.map((poFilterBarItem: FilterBarItemComponent) =>
					poFilterBarItem.observableValue.value$.pipe(
						startWith(poFilterBarItem.observableValue.value ?? poFilterBarItem.defaultValue),
						map((poValue: any) => ({ key: poFilterBarItem.key, value: poValue } as IKeyValue)),
						takeUntil(poFilterBarItem.destroyed$)
					)
				))
			),
			map((paKeyValues: IKeyValue[]) => {
				const loFilterValues: IIndexedObject = { ...this.observableFilterValues.value };

				paKeyValues.forEach((poKeyValue: IKeyValue) => {
					if (!StringHelper.isBlank(poKeyValue.key))
						loFilterValues[poKeyValue.key] = poKeyValue.value;
				});

				return loFilterValues;
			}),
			distinctUntilChanged(ObjectHelper.areEqual),
			tap((poFilterValues: IIndexedObject) => this.observableFilterValues.value = poFilterValues),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	//#endregion

}
