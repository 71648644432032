<ng-container *ngIf="!!linkInfo">
	<div *ngIf="!linkInfo.hidden" [ngSwitch]="linkInfo.templateId" [ngClass]="linkInfo.cssClass">

		<ng-template [ngSwitchCase]="'default'">
			<ion-button (click)="action()">
				<ion-label>{{ linkInfo.label }}</ion-label>
			</ion-button>
		</ng-template>

		<ng-template [ngSwitchCase]="'full'">
			<ion-item [ngClass]="linkInfo.cssClass" button [color]="linkInfo.color" class="ion-text-start" (click)="action()"
				class="expandable-separation">
				<ion-icon *ngIf="linkInfo.icon" class="item-icon" slot="start" [name]="linkInfo.icon"></ion-icon>
				<ion-label>{{ linkInfo.label }}</ion-label>
			</ion-item>
		</ng-template>

		<ng-template [ngSwitchCase]="'item'">
			<ion-item [ngClass]="linkInfo.cssClass" (click)="action()" class="standard-link padtopLink">
				<ion-icon *ngIf="linkInfo.icon" class="item-icon" slot="start" [name]="linkInfo.icon"></ion-icon>
				<ion-label>{{ linkInfo.label }}</ion-label>
			</ion-item>
		</ng-template>

		<ng-template [ngSwitchCase]="'item-no-line'">
			<ion-item [ngClass]="linkInfo.cssClass" (click)="action()" lines="none" class="standard-link padtopLink">
				<ion-icon *ngIf="linkInfo.icon" class="item-icon" slot="start" [name]="linkInfo.icon"></ion-icon>
				<ion-label>{{ linkInfo.label }}</ion-label>
			</ion-item>
		</ng-template>

		<ng-template [ngSwitchCase]="'image'">
			<a (click)="action()">
				<img [src]="linkInfo.params.src" [alt]="linkInfo.label" [height]="linkInfo.params.height || 50"
					[width]="linkInfo.params.width || 50" />
			</a>
		</ng-template>

		<ng-template [ngSwitchCase]="'icon'">
			<ion-button (click)="action()" class="width-links">
				<span class="button-md2 pad1">
					<div class="links">
						<ion-icon [name]="linkInfo.params.icon"></ion-icon>
						<ion-note>
							<ng-container *ngIf='linkInfo.label != "0"'>{{linkInfo.label}}</ng-container>
						</ion-note>
					</div>
				</span>
			</ion-button>
		</ng-template>

		<ng-template [ngSwitchCase]="'avatar'">
			<ion-item (click)="action()">
				<ion-avatar slot="start">
					<img [src]="linkInfo.params.src">
				</ion-avatar>
				<h2>{{ linkInfo.label }}</h2>
				<p>{{ linkInfo.params.subtitle }}</p>
			</ion-item>
		</ng-template>

		<ng-template [ngSwitchCase]="'subtitle'">
			<ion-item (click)="action()">
				<ion-label>
					<h2>{{ linkInfo.label }}</h2>
					<p>{{ linkInfo.params.subtitle }}</p>
				</ion-label>
			</ion-item>
		</ng-template>

		<ng-template [ngSwitchCase]="'tile'">
			<osapp-date-time #dateTimePicker class="hidden" [model]="date" [params]="datePickerParams"
				(dateSelected)="onDateSelected($event)">
			</osapp-date-time>
			<div (click)="action(dateTimePicker)" class="marker-position button-link-tile gradient-back" data-cy="tile">
				<div class="full-width">
					<span *ngIf="marker" class="marker-link">{{marker}}
					</span>
					<ion-icon color="tile-icon" position="stacked" *ngIf="linkInfo.params.icon" class="cicon"
						[ngClass]="linkInfo.params.icon" [name]="linkInfo.params.icon">
					</ion-icon>
					<ion-label #label [ngClass]="label.mode === 'ios' ? 'label-ios' : '' " class="p-link-tile">
						{{ linkInfo.label }}</ion-label>
				</div>
			</div>
		</ng-template>

		<ng-template *ngSwitchDefault>Erreur : Pas de template nommée {{linkInfo.templateId}}</ng-template>

	</div>
</ng-container>