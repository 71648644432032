import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SliderComponent } from './components/slider/slider.component';

@NgModule({
	declarations: [SliderComponent],
	exports: [SliderComponent],
	imports: [
		CommonModule,
		IonicModule
	]
})
export class SliderModule { }
