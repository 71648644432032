import { Component, OnDestroy } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { IEntityTypeSelectorParams } from '../../../model/entities/IEntityTypeSelectorParams';
import { ISelectorValue } from '../../../model/selector/ISelectorValue';
import { SelectorWrapperComponentBase } from '../../../model/selector/SelectorWrapperComponentBase';
import { EntityBuilder } from '../../../services/EntityBuilder';

@Component({
	selector: "osapp-entity-type-selector",
	templateUrl: './entityTypeSelector.component.html',
	styleUrls: ['./entityTypeSelector.component.scss']
})
export class EntityTypeSelectorComponent extends SelectorWrapperComponentBase<EntityBuilder, IEntityTypeSelectorParams> implements OnDestroy {

	//#region METHODS

	constructor() {
		super();
	}

	protected override initSearchOptions(): void { }

	protected override initValues(): void {
		const laEntityBuilder: EntityBuilder[] = this.params && this.params.entityBuilders ? this.params.entityBuilders : ConfigData.entityBuilders;
		this.values = laEntityBuilder.map((poEntityBuilder: EntityBuilder) => ({
			value: poEntityBuilder,
			displayValue: this.getDisplayValue(poEntityBuilder),
			isSelected: false
		} as ISelectorValue<EntityBuilder>));
	}

	public override ngOnDestroy(): void {
		if (this.params && this.params.entityTypeSelectionSubject)
			this.params.entityTypeSelectionSubject.complete();
	}

	protected getDisplayValue(poValue: EntityBuilder): string {
		return poValue.category();
	}

	public onSelectionValidated(paSelectedValues: ISelectorValue<EntityBuilder>[]): void {
		if (this.params && this.params.entityTypeSelectionSubject)
			this.params.entityTypeSelectionSubject.next(ArrayHelper.getFirstElement(this.getValuesFromSelectorValues(paSelectedValues)));
	}

	//#endregion

}