import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '../../helpers/stringHelper';
import { ICustomer } from '../../model/booking/ICustomer';
import { ContactNamePipe } from '../../pipes/contactName.pipe';
import { TelephoneHelper } from '../telephone/telephone-helper';

@Pipe({ name: "customerName" })
export class CustomerNamePipe implements PipeTransform {

	//#region METHODS

	constructor(private ioContactNamePipe: ContactNamePipe) { }

	/** Transforme le prénom et le nom d'un client sous la forme `NOM Prénom` (ou numéro de téléphone si pas de nom/prénom).
	 * ou affiche le numéro de téléphone si les informations ne sont pas disponibles hormis le numéro de téléphone.
	 * @param poCustomer Client dont on veut faire une transformation sur son nom.
	 */
	public transform(poCustomer?: ICustomer): string {
		return !StringHelper.isBlank(poCustomer?.phone) && StringHelper.isBlank(poCustomer?.firstName) && StringHelper.isBlank(poCustomer?.lastName) ?
			TelephoneHelper.formatPhoneNumber10Digits(poCustomer.phone) :
			this.ioContactNamePipe.transform(poCustomer);
	}

	//#endregion

}