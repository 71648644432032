export abstract class AlertMessage {
	public static readonly errorOccured = "An error occured.";

	public static readonly notFoundDB = "Database not found.";
	public static readonly notFoundDesc = "Descriptor not found.";
	public static readonly notFoundDef = "Definition not found.";

	public static readonly unselectDB = "No database selected.";
	public static readonly unselectDesc = "No Descriptor selected.";
	public static readonly unselectDef = "No Definition selected.";
	public static readonly unselectListDef = "No ListDefinition selected.";

	public static readonly emptyName = "Name not filled.";
	public static readonly emptyDescriptor = "Descriptor has no definition.";

	public static readonly resetDesc = "Descriptor reseted.";
	public static readonly resetDef = "Definition reseted.";
	public static readonly deleteDef = "Definition deleted.";
	public static readonly deleteListDef = "ListDefinition deleted.";

	public static readonly editField = "Field edited.";
	public static readonly saveDesc = "Descriptor saved.";
	public static readonly notSaveDesc = "Descriptor not saved.";
	public static readonly isReadOnly = "Definition is readonly now.";
	public static readonly notReadOnly = "Definition is not readonly now.";
	public static readonly updateListDef = "ListDefinition updated.";
	public static readonly createListDef = "ListDefinition created.";

	public static readonly wrongFile = "The selected file is not a Descriptor.";
}