import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IRecentsConfig } from './model/IRecentsConfig';
import { RECENTS_CONFIG, RecentsService } from './services/recents.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [RecentsService]
})
export class RecentsModule {

	public static forRoot(poFavoritesConfig: IRecentsConfig): ModuleWithProviders<RecentsModule> {
		return {
			ngModule: RecentsModule,
			providers: [
				{ provide: RECENTS_CONFIG, useValue: poFavoritesConfig }
			]
		};
	}

}
