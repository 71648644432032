import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FlagService } from '../../../../services/flag.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { EDatabaseSynchroFlag } from '../../model/EDatabaseSynchroFlag';

@Component({
	selector: 'calao-database-sync-animated-icon',
	templateUrl: './database-sync-animated-icon.component.html',
	styleUrls: ['./database-sync-animated-icon.component.scss'],
})
export class DatabaseSyncAnimatedIconComponent extends DestroyableComponentBase implements OnInit {

	//#region PROPERTIES

	@Input() public className: string;
	@ObserveProperty<DatabaseSyncAnimatedIconComponent>({ sourcePropertyKey: "className" })
	public readonly observableAnimationClass = new ObservableProperty<string>();

	//#endregion

	constructor(private readonly isvcFlag: FlagService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcFlag.observeFlagValue(EDatabaseSynchroFlag.localToServer)
			.pipe(
				tap((pbIsActive: boolean) => {
					return this.setAnimationClass(pbIsActive, "local-to-server")
				}),
				secure(this)
			).subscribe();

		this.isvcFlag.observeFlagValue(EDatabaseSynchroFlag.serverToLocal)
			.pipe(
				tap((pbIsActive: boolean) => {
					return this.setAnimationClass(pbIsActive, "server-to-local")
				}),
				secure(this)
			).subscribe();
	}

	private setAnimationClass(pbIsActive: boolean, psClassName: string) :void {
		if (pbIsActive)
			this.observableAnimationClass.value = this.observableAnimationClass.value ? `${this.observableAnimationClass.value} ${psClassName}` : psClassName;
		else
			this.observableAnimationClass.value = this.observableAnimationClass.value.replace(psClassName, "");
	}

}