<calao-virtual-scroll [items]="observableGroups.changes$ | async" [itemSize]="70" autosize margeLastItem transparent>
	<ng-template let-group="$implicit">
		<div class="content">
			<ion-item-sliding #slidingItem osappAutoCloseSlideItem>
				<ion-item lines="none" (click)="onGroupClicked(group)">
					<calao-conflict-list-marker *ngIf="group._conflicts"></calao-conflict-list-marker>
					<!-- Avatar -->
					<div class="start">
						<ion-avatar class="avatar" calaoBadgeIcon="trade" calaoBadgeIconPosition="below after">
							<avatar [src]="getGroupAvatar(group)" cssClass="icon-area opacity60">
							</avatar>
						</ion-avatar>
					</div>
					<!-- Affichage des textes. -->
					<ion-text class="text-container">
						<ion-label>{{ group.name }}</ion-label>
						<ion-note class="no-margin contact-groups">{{ getMemberslabel$(group) | async }}</ion-note>
					</ion-text>
					<div slot="end" class="no-margin-start">
						<ion-button class="itemb btn-more" fill="clear" (click)="openOrCloseItemSliding(slidingItem, $event)">
							<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
						</ion-button>
					</div>
				</ion-item>
				<ion-item-options side="end">
					<ion-item-option *ngIf="canEditGroups" color="primary" (click)="onEditGroupClicked(group)">
						<ion-icon name="create"></ion-icon>
						<ion-label>Editer</ion-label>
					</ion-item-option>
					<ion-item-option *ngIf="canDeleteGroups" color="danger" (click)="onDeleteGroupClicked(group)">
						<ion-icon name="trash"></ion-icon>
						<ion-label>Supprimer</ion-label>
					</ion-item-option>
				</ion-item-options>
			</ion-item-sliding>
		</div>
	</ng-template>
</calao-virtual-scroll>