import { defer, EMPTY, Observable } from 'rxjs';
import { defaultIfEmpty, expand, filter, last, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IFlushLogTaskParams } from '../../model/backgroundTask/taskParams/IFlushLogTaskParams';
import { ConfigData } from '../../model/config/ConfigData';
import { IStoreDataResponse } from '../../model/store/IStoreDataResponse';
import { ILogEntry } from '../../modules/logger/models/ILogEntry';
import { LoggerService } from '../../modules/logger/services/logger.service';
import { afterSubscribe } from '../../modules/utils/rxjs/operators/after-subscribe';
import { ApplicationService } from '../application.service';
import { InjectorService } from '../injector.service';
import { Store } from '../store.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class FlushLogTask<T extends IFlushLogTaskParams = IFlushLogTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcStore: Store;
	protected msvcLogger: LoggerService;
	protected msvcApplication: ApplicationService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);

		this.msvcStore = InjectorService.instance.get(Store);
		this.msvcLogger = InjectorService.instance.get(LoggerService);
		this.msvcApplication = InjectorService.instance.get(ApplicationService);
	}

	public override execTask(): Observable<boolean> {
		let lsStartId: string;
		let lnLastResultLength = 0;

		return this.msvcApplication.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				expand((_, pnIndex: number) => {
					if (lnLastResultLength < LoggerService.C_LOGS_DEFAULT_BUFFER_SIZE && pnIndex > 0)
						return EMPTY;

					return defer(() => this.msvcLogger.getLogsToFlush$(lsStartId).pipe(afterSubscribe(() => lsStartId = undefined)))
						.pipe(
							tap((paLogsToFlush: ILogEntry[]) => {
								lsStartId = ArrayHelper.getLastElement(paLogsToFlush)?._id;
								lnLastResultLength = paLogsToFlush?.length ?? 0;
							}),
							filter((paLogsToFlush: ILogEntry[]) => ArrayHelper.hasElements(paLogsToFlush)),
							mergeMap((paLogsToFlush: ILogEntry[]) => this.msvcStore.bulkDocs(ConfigData.logger.databaseId, paLogsToFlush, true, true)),
							mergeMap((paResponses: IStoreDataResponse[]) => this.msvcLogger.deleteLogs$(paResponses.map((poResponse: IStoreDataResponse) => poResponse.id)))
						);
				}),
				defaultIfEmpty(true),
				last()
			);
	}

	//#endregion

}