import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SecurityService } from '../../../services/security.service';

@Injectable()
export class RemoveCredentialsGuard implements CanActivate {

	//#region FIELDS

	private static readonly C_LOG_ID = "RMV.CRDTL.G::";

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly isvcSecurity: SecurityService) { }

	/** @implements Retourne `true` si on peut entrer sur le composant, `false` sinon. */
	public canActivate(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): Promise<boolean> {
		return this.isvcSecurity.clearTokenAsync()
			.then(_ => true)
			.catch(poError => {
				console.error(`${RemoveCredentialsGuard.C_LOG_ID}error when clear token :`, poError);
				return true;
			});
	}

	//#endregion METHODS

}