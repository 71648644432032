import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { EntityHelper } from '@calaosoft/osapp/helpers/entityHelper';
import { NumberHelper } from '@calaosoft/osapp/helpers/numberHelper';
import { StoreHelper } from '@calaosoft/osapp/helpers/storeHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { IConversationFormConfig } from '@calaosoft/osapp/model/config/IConversationFormConfig';
import { IConversationFormConfigResult } from '@calaosoft/osapp/model/config/iconversation-form-config-result';
import { IConversation } from '@calaosoft/osapp/model/conversation/IConversation';
import { IEntity } from '@calaosoft/osapp/model/entities/IEntity';
import { IEntityLink } from '@calaosoft/osapp/model/entities/IEntityLink';
import { ISelectorParams } from '@calaosoft/osapp/model/selector/ISelectorParams';
import { ISelectorValue } from '@calaosoft/osapp/model/selector/ISelectorValue';
import { EInput } from '@calaosoft/osapp/model/uiMessage/EInput';
import { ESelectionResult } from '@calaosoft/osapp/modules/selector/models/eselection-result';
import { EntityBuilder } from '@calaosoft/osapp/services/EntityBuilder';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { InjectorService } from '@calaosoft/osapp/services/injector.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { AlertButton, AlertInput, OverlayEventDetail } from '@ionic/core';
import { entityBuilders } from '../../config';
import { JoinReportSelectorModalService } from '../../modules/report/join-report-selector-modal/services/join-report-selector-modal.service';
import { IComGalleryFile } from '../IComGalleryFile';
import { IComSelectFieldValue } from '../IComSelectFieldValue';
import { IReport } from '../IReport';
import { CustomerEntityBuilder } from '../customer-entity-builder';
import { ICustomer } from '../icustomer';
import { EReportSelection } from './ereport-selection';
import { IJoinReportSelectorResult } from './join-report-selector-result';

interface IComGalleryFileRetroCompat extends IComGalleryFile {
	/** Pour rétrocompat. */
	dmsId?: string;
}

export class JoinReportConversationFormConfig {

	//#region METHODS

	constructor() { }

	/** Récupère la configuration pour le formulaire de rapport dans les conversations. */
	public getConfig(): IConversationFormConfig {
		return {
			icon: "filetext2",
			label: "Rapport",
			getFormDescId: () => "formDesc_customer_report",
			getEditFormDefId: () => "report_edit",
			getEntityBuilders: () => entityBuilders.filter((poItem: EntityBuilder) => poItem.category() === CustomerEntityBuilder.C_CUSTOMER_CATEGORY),
			getSelectorParams: () => this.getSelectorParams(),
			getLinkedParentEntityAsync: (poConversation: IConversation, poModel?: IReport) => this.getLinkedParentEntityAsync(poConversation, poModel),
			getFormEditionMessage: (poOldModel: IReport | undefined, poNewModel: IReport, psDefaultMessage?: string) => this.getFormEditionMessage(poOldModel, poNewModel, psDefaultMessage),
			getFormEntryAsync: (poConversation: IConversation, psParentEntityId: string | undefined) => this.getFormEntryAsync(poConversation, psParentEntityId)
		};
	}

	private getSelectorParams(): ISelectorParams<IEntityLink> {
		return {
			selectionLimit: 1,
			selectionMinimum: 1,
			hasSearchbox: true,
			searchOptions: {
				hasPreFillData: false,
				searchboxPlaceholder: "Rechercher un client",
				searchFunction: (poValue: ISelectorValue<IEntity>, psSearchValue: string) => {
					const loCustomer: ICustomer = poValue.value.model as ICustomer;
					psSearchValue = psSearchValue.toLowerCase();
					return loCustomer.tradingName.toLowerCase().indexOf(psSearchValue) >= 0 || loCustomer.city.toLowerCase().indexOf(psSearchValue) >= 0 || loCustomer.id.indexOf(psSearchValue) >= 0;
				}
			}
		};
	}

	private buildResult<T>(peAction: ESelectionResult, poData?: T): IConversationFormConfigResult<T> {
		return { result: poData, selectionState: peAction };
	}

	//#region getLinkedParentEntityAsync

	private getLinkedParentEntityAsync(poConversation: IConversation, poModel?: IReport): Promise<IConversationFormConfigResult<IEntity | undefined>> {
		const lsvcEntityLink: EntityLinkService = InjectorService.instance.get(EntityLinkService);

		if (poModel) { // On tente d'ouvrir un rapport déjà existant.
			return Promise.resolve(
				this.buildResult(ESelectionResult.selected, this.getParentEntityFromExistingReport(poModel, lsvcEntityLink))
			);
		}
		else {
			// Si le poModel n'est pas renseigné, c'est qu'on créait un nouveau rapport.
			return lsvcEntityLink.getEntityLinks(poConversation._id).toPromise()
				.then((paResults: IEntityLink[]) => {
					// On filtre pour ne récupérer que les clients (les entités avec un chiffre comme id).
					const laCustomerResults: IEntityLink[] = paResults.filter((poItem: IEntityLink) =>
						NumberHelper.isStringNumber(EntityHelper.getEntityLinkPartFromPrefix(poItem).entityId)
					);

					if (laCustomerResults.length === 0) // Si aucun client lié, on renvoie l'état "selected" pour signaler qu'on veut continuer le traitement.
						return this.buildResult(ESelectionResult.selected);
					else if (laCustomerResults.length === 1) { // Si un seul client associé, le rapport sera pour ce client.
						// Construction de l'entité à renvoyer.
						return lsvcEntityLink.buildEntityFromIdAndDatabaseId<IReport>(EntityHelper.getEntityLinkPartFromPrefix(laCustomerResults[0]))
							.toPromise()
							.then((poEntity: IEntity<IReport>) => this.buildResult(ESelectionResult.selected, poEntity));
					}
					else // Si plusieurs clients associés, on affiche un sélecteur et on retourne le client choisi.
						return this.getParentEntityFromCustomerSelectionAsync(lsvcEntityLink, laCustomerResults);
				});
		}
	}

	private getParentEntityFromExistingReport(poModel: IReport, psvcEntityLink: EntityLinkService): IEntity<IReport> {
		//! Ne fonctionne que si les clients et les rapports sont dans la même base !
		const lsDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(poModel, "", false);

		if (StringHelper.isBlank(lsDatabaseId))
			throw new Error("Texcom:: Récupération de la base de données depuis les caches data impossible.");
		else // L'entité parent du rapport existe déjà: c'est le Client.
			return psvcEntityLink.buildEntity<IReport>(poModel);
	}

	private async getParentEntityFromCustomerSelectionAsync(psvcEntityLink: EntityLinkService, paCustomerResults: IEntityLink[])
		: Promise<IConversationFormConfigResult<IEntity | undefined>> {

		const lsvcUiMessage: UiMessageService = InjectorService.instance.get(UiMessageService);
		const loBtnCancel: AlertButton = { text: "Annuler", cssClass: "", role: undefined, handler: (psResult?: string) => true };
		const loBtnOk: AlertButton = { text: "OK", cssClass: "", role: undefined, handler: ((psResult: string) => !StringHelper.isBlank(psResult)) };
		const loPopup: ShowMessageParamsPopup = new ShowMessageParamsPopup({
			header: "Sélectionnez le client à associer",
			message: "",
			buttons: [loBtnCancel, loBtnOk],
			inputs: paCustomerResults.map((poClient: IEntityLink): AlertInput => {
				return { type: EInput.radio, name: poClient.name2, value: poClient._id, label: poClient.name2, placeholder: "", id: "", checked: false };
			})
		});

		const loAlert: HTMLIonAlertElement = await lsvcUiMessage.ioAlertController.create(loPopup);
		await loAlert.present();
		const loResult: OverlayEventDetail<{ values?: string }> = await loAlert.onDidDismiss();

		if (loResult.data?.values) {
			// Index du tableau `laCustomerResults` de la valeur sélectionnée.
			const loChosenIndex: number = paCustomerResults.findIndex((poCustomer: IEntityLink) => poCustomer._id === loResult.data!.values);
			// On retourne l'Entité choisie.
			return this.buildResult(
				ESelectionResult.selected,
				await psvcEntityLink.buildEntityFromIdAndDatabaseId(EntityHelper.getEntityLinkPartFromPrefix(paCustomerResults[loChosenIndex]))
					.toPromise()
			);
		}
		else // L'utilisateur a cliqué en dehors du sélecteur (pour quitter).
			return this.buildResult(ESelectionResult.canceled);
	}

	//#endregion getLinkedParentEntityAsync

	//#region getFormEditionMessage

	private getFormEditionMessage(poOldModel: IReport | undefined, poNewModel: IReport, psDefaultMessage: string = "Un rapport a été modifié."): string {
		let lsMessage = "";
		let lnChangesCount = 0;
		const lfChanges: (psMessage: string) => void = (psMessage: string) => {
			lsMessage = psMessage;
			++lnChangesCount;
		};

		if (!poOldModel)
			return "Un rapport vient d'être créé.";
		else if (!poNewModel)
			return "Un rapport vient d'être supprimé.";
		else {
			if (poOldModel.body !== poNewModel.body)
				lfChanges("Le contenu du rapport a été modifié.");

			if (!this.areSameJoinedFiles(poOldModel, poNewModel))
				lfChanges("Une modification a eu lieu dans les fichiers joints au rapport.");

			if (poOldModel.reportType.value !== poNewModel.reportType.value)
				lfChanges("Le type du rapport a été modifié.");

			if (poOldModel.status.value !== poNewModel.status.value)
				lfChanges("Le statut du rapport a été modifié.");

			if (!ArrayHelper.areArraysEqual(poOldModel.tags, poNewModel.tags,
				(poOldTag: IComSelectFieldValue, poNewTag: IComSelectFieldValue) => poOldTag.value === poNewTag.value)) {
				lfChanges("Les tags du rapport ont été modifiés.");
			}

			if (poOldModel.title !== poNewModel.title)
				lfChanges("Le titre du rapport a été modifié.");
		}

		return lnChangesCount === 1 ? lsMessage : psDefaultMessage;
	}

	private areSameJoinedFiles(poOldModel: IReport, poNewModel: IReport): boolean {
		return ArrayHelper.areArraysEqual(
			poOldModel.gallery,
			poNewModel.gallery,
			(poOldFile: IComGalleryFileRetroCompat, poNewFile: IComGalleryFileRetroCompat) => poOldFile.guid === poNewFile.guid || poOldFile.dmsId === poNewFile.dmsId
		);
	}

	//#endregion getFormEditionMessage

	//#region getFormEntryAsync

	private getFormEntryAsync(poConversation: IConversation, psCustomerId: string | undefined): Promise<IConversationFormConfigResult<IReport | undefined>> {
		// Modale d'affichage des rapports ; si résultat alors ok, sinon, afficher la création de rapport.
		return InjectorService.instance.get(JoinReportSelectorModalService)
			.getReportSelectionResultAsync(poConversation, psCustomerId ?? "")
			.then((poResult: IJoinReportSelectorResult): IConversationFormConfigResult<IReport | undefined> => {

				if (poResult.state === EReportSelection.selection || poResult.state === EReportSelection.creation)
					return { selectionState: ESelectionResult.selected, result: poResult.report };
				else if (poResult.state === EReportSelection.cancelation)
					return { selectionState: ESelectionResult.canceled };
				else
					return { selectionState: ESelectionResult.other };
			});
	}

	//#endregion getFormEntryAsync

	//#endregion METHODS

}