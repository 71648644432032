<ng-container *ngIf="designableDescriptor && designableDefinition as designableDefinition">
	<ng-container *ngIf=" designableDefinition.fields as fields">
		<div class="marg-container">
			<h3>FormDefintion being edited: {{ designableDescriptor.selectedDefinitionId }}</h3>
			<div>
				<br>
				<button (click)="onSelectedDefintionTo(actionEnum.test)">Test Definition</button>
				<button (click)="onSelectedDefintionTo(actionEnum.delete)">Delete Definition</button>
				<button (click)="onSelectedDefintionTo(actionEnum.clear)">Clear Definition</button>
			</div>
			<ng-container *ngFor="let field of fields; let index = index;">
				<ng-container *ngIf="designableDescriptor.selectedField">
					<ng-container *ngIf="designableDescriptor.selectedField.key === field.key">
						<div class="editor-definitions-edit">
							<ng-container *ngTemplateOutlet="fieldTemplate"></ng-container>
						</div>
					</ng-container>
					<ng-container *ngIf="designableDescriptor.selectedField.key!==field.key">
						<div class="editor-definitions">
							<ng-container *ngTemplateOutlet="fieldTemplate"></ng-container>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="designableDescriptor.selectedField === undefined">
					<div class="editor-definitions">
						<ng-container *ngTemplateOutlet="fieldTemplate"></ng-container>
					</div>
				</ng-container>

				<ng-template #fieldTemplate>
					<table>
						<tr>
							<td class="first">
								<div (click)="onSelectedFieldTo(actionEnum.edit, field.key.toString())">
									<h6>Position n°:{{ index + 1 }}</h6>
									<br>
									<h4>{{ field.key }}</h4>
								</div>
							</td>
							<td class="actions">
								<br>
								<div>
									<button (click)="onSelectedFieldTo(actionEnum.move, field.key.toString(), index, true)">Up</button>
									<br>
									<button class="btn-alert"
										(click)="onSelectedFieldTo(actionEnum.edit, field.key.toString())">Modify</button>
									<br>
									<button class="btn-delete"
										(click)="onSelectedFieldTo(actionEnum.delete, field.key.toString())">Delete</button>
									<br>
									<button (click)="onSelectedFieldTo(actionEnum.move, field.key.toString(), index, false)">Down</button>
								</div>
							</td>
						</tr>
					</table>
					<br>
				</ng-template>

			</ng-container>
		</div>
	</ng-container>
</ng-container>