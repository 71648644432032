import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	// tslint:disable-next-line
	selector: 'input[type=file]',
	// tslint:disable-next-line: no-host-metadata-property
	host: {
		'(change)': 'onChange($event.target.files)',
		'(blur)': 'onTouched()',
	},
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessorDirective, multi: true },
	],
})
export class FileValueAccessorDirective implements ControlValueAccessor {
	public value: any;
	public onChange = (_) => { };
	public onTouched = () => { };

	public writeValue(value) { }
	public registerOnChange(fn: any) { this.onChange = fn; }
	public registerOnTouched(fn: any) { this.onTouched = fn; }
}

