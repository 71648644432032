import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { IAppointment } from '../../../model/booking/IAppointment';
import { ICustomer } from '../../../model/booking/ICustomer';

export abstract class AppointmentHelper {

	//#region METHODS

	public static extractResourceId(psAppointmentId: string): string | undefined;
	public static extractResourceId(poAppointment: IAppointment): string | undefined;
	public static extractResourceId(poData: string | IAppointment): string | undefined {
		const lsAppointmentId: string = typeof poData === "string" ? poData : poData._id;

		return ArrayHelper.getLastElement(/apt_\d*_(cont_.*$)/.exec(lsAppointmentId) ?? []);
	}

	public static extractTimestamp(psAppointmentId: string): string | undefined;
	public static extractTimestamp(poAppointment: IAppointment): string | undefined;
	public static extractTimestamp(poData: string | IAppointment): string | undefined {
		const lsAppointmentId: string = typeof poData === "string" ? poData : poData._id;

		return ArrayHelper.getFirstElement(/apt_\d*_(cont_.*$)/.exec(lsAppointmentId) ?? []).split("_")[1];
	}

	public static isPrebook(poAppointment: IAppointment): boolean {
		if (poAppointment.prebookDate) {
			return new Date(poAppointment.prebookDate) > DateHelper.addMinutes(new Date(), -15);
		};
		return false;
	}

	public static getStatusName(poAppointment: IAppointment): string {
		switch (poAppointment.status) {
			case 'booked':
				return 'RESERVE';

			case 'unavailable':
				return 'INDISPONIBLE';

			case 'done':
				return 'EFFECTUE';

			case 'holiday':
				return 'CONGES';

			case 'available':
				if (AppointmentHelper.isPrebook(poAppointment)) {
					return 'EN COURS DE RESERVATION';
				}
				else {
					return 'DISPONIBLE';
				}

			default:
				return 'INCONNU';
		}
	}

	public static getStatusColor(poAppointment: IAppointment): string {
		switch (poAppointment.status) {
			case 'booked':
				return 'tertiary';

			case 'unavailable':
				return 'danger';

			case 'done':
				return 'tertiary';

			case 'holiday':
				return 'medium';

			case 'available':
				if (AppointmentHelper.isPrebook(poAppointment)) {
					return 'secondary';
				}
				else {
					return 'success';
				}

			default:
				return '';
		}
	}

	public static getStatusIcon(poAppointment: IAppointment): string {
		switch (poAppointment.status) {
			case 'booked':
				return 'time-outline';

			case 'unavailable':
				return 'close-outline';

			case 'holiday':
				return 'close-outline';

			case 'available':
				if (AppointmentHelper.isPrebook(poAppointment)) {
					return 'time-outline';
				}
				else {
					return 'checkmark-outline';
				}

			default:
				return '';
		}
	}

	public static canEditStatus(poAppointment: IAppointment): boolean {
		switch (poAppointment.status) {
			case 'available':
				return !AppointmentHelper.isPrebook(poAppointment);

			case 'unavailable':
				return true;

			case 'holiday':
				return true;

			case 'booked':
				return true;

			default:
				return false;
		}
	}

	public static canBookAppointment(poAppointment: IAppointment): boolean {
		if (poAppointment.status === "available") {
			if (AppointmentHelper.isPrebook(poAppointment)) {
				return false;
			}
			else {
				return true;
			}
		}
		else {
			return false;
		}
	}

	public static isFinished(poAppointment: IAppointment): boolean {
		return !!poAppointment.callEndDate || DateHelper.compareTwoDates(new Date(), poAppointment.endDate) > 0;
	}

	public static getNextAppointmentId(psAppointmentId: string): string {
		const lnNextTimestamp: number = +(AppointmentHelper.extractTimestamp(psAppointmentId) ?? 0) + 1800;

		return `apt_${lnNextTimestamp}_${AppointmentHelper.extractResourceId(psAppointmentId)}`;
	}

	/**
	 * Retourne les rendez-vous pouvant être booké sur deux créneaux.
	 * @param paAppointments la liste de rendez-vous à filtrer.
	 */
	public static filter2SlotsAppointment(paAppointments: IAppointment[]): IAppointment[] {
		return paAppointments.filter((poAppointment: IAppointment, i: number) => {
			if (paAppointments[i + 1]) {
				return paAppointments[i + 1]._id === AppointmentHelper.getNextAppointmentId(poAppointment._id);
			}
			else return false;
		});
	}

	public static filterPrebookAppointment(paAppointments: IAppointment[], poCustomer?: ICustomer): IAppointment[] {
		return paAppointments.filter((poAppointment: IAppointment) => {
			if (AppointmentHelper.isPrebook(poAppointment)) {
				if (poCustomer) {
					return poAppointment.prebookUserId === poCustomer._id;
				}
				else {
					return false;
				}
			}
			return true;
		});
	}

	//#endregion
}