import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@calaosoft/osapp/components/components.module';
import { SharedComponentsModule } from '@calaosoft/osapp/components/sharedComponents.module';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { NgCalendarModule } from 'ionic2-calendar';
import { comFormlyConfig } from '../config';
import { ActionButtonFieldReportComponent } from './report/actionButton/actionButtonFieldReport.component';
import { SupportComponent } from './support/support.component';

const dependencies: Array<Type<any>> = [
	SupportComponent,
	ActionButtonFieldReportComponent
];

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		NgCalendarModule,
		ComponentsModule,
		SharedComponentsModule,
		FormsModule,
		FormlyModule.forChild(comFormlyConfig)
	],
	exports: [],
	declarations: dependencies,
	providers: [
		{ provide: ErrorHandler, useClass: ErrorHandler }
	]
})
export class CustomModule { }