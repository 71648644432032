import { EPrefix } from "@calaosoft/osapp/model/EPrefix";

//#region PRÉFIXES

/** Préfixe d'identifiant d'un rapport : "report_". */
export const C_PREFIX_REPORT: EPrefix = "report_" as EPrefix;
/** Préfixe d'identifiant d'un client : "" (nombre sous forme de chaîne de caractères). */
export const C_PREFIX_CUSTOMER: EPrefix = "" as EPrefix;

//#endregion

//#region RÔLES

export const MERCHANDISEUR_ROLE_ID = "merchandiseur";
export const CONVOYEUR_ROLE_ID = "convoyeur";
export const COMMERCIAL_ROLE_ID = "commercial";

//#endregion