import { HttpErrorResponse } from '@angular/common/http';
import { Observable, defer, of, throwError } from 'rxjs';
import { catchError, mapTo, mergeMap, tap } from 'rxjs/operators';
import { IHeartbeatTaskParams } from '../../model/backgroundTask/taskParams/IHeartbeatTaskParams';
import { ICredentialsDocument } from '../../model/security/ICredentialsDocument';
import { InjectorService } from '../injector.service';
import { ShowMessageParamsPopup } from '../interfaces/ShowMessageParamsPopup';
import { NetworkService } from '../network.service';
import { SecurityService } from '../security.service';
import { UiMessageService } from '../uiMessage.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class HeartbeatTask<T extends IHeartbeatTaskParams = IHeartbeatTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "HRTBT.T::";

	private readonly msvcSecurity: SecurityService;
	private readonly msvcNetwork: NetworkService;
	private readonly msvcUiMessage: UiMessageService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcSecurity = InjectorService.instance.get(SecurityService);
		this.msvcNetwork = InjectorService.instance.get(NetworkService);
		this.msvcUiMessage = InjectorService.instance.get(UiMessageService);
		console.debug(`${HeartbeatTask.C_LOG_ID}HeartbeatTask.name = ${HeartbeatTask.name}`);
	}

	/** Vérifie régulièrement la durée de validité du token.
	 * Si le token est valide, que l'on a du réseau et pas d'écouteur sur le Dom, on crée un écouteur pour envoyer un heartbeat pendant l'activité de l'application
	 * Si le token est expiré et que l'on a du réseau, on affiche l'authenticator
	 * Si le token est expiré et que l'on à pas de réseau, on enlève le mode déverrouillage, on affiche un message et l'authenticator
	 */
	public override execTask(): Observable<void> {
		return this.msvcSecurity.checkTokenExpiration()
			.pipe(
				mergeMap((pbIsTokenExpired: boolean) => pbIsTokenExpired ? this.handleTokenExpired() : of(true)),
				mergeMap((pbResult: boolean) => this.defineTouchHandler(pbResult)),
				catchError((poError: HttpErrorResponse) => {
					console.warn(`${HeartbeatTask.C_LOG_ID}Server rejected token renewal.`, poError);
					return poError.status === 401 ? this.handleTokenExpired() : throwError(poError);
				}),
				mapTo(undefined)
			);
	}

	/** Appelé si le token est expiré.\
	 * Si l'utilisateur n'a pas de réseau, affiche un message d'erreur et le redirige vers la page de connexion.
	 */
	private handleTokenExpired(): Observable<boolean> {
		console.warn(`${HeartbeatTask.C_LOG_ID}Expired token! Redirecting to authentication page.`);

		return defer(() => this.msvcSecurity.clearTokenAsync()).pipe(
			mergeMap(() => this.msvcSecurity.logOutAsync()),
			tap(_ =>
				this.msvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Votre session a expiré. Merci de vous reconnecter.", header: "Authentification" }))
			),
			mapTo(true)
		);
	}

	private defineTouchHandler(pbResult: boolean): Observable<ICredentialsDocument> {
		return this.msvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => pbResult && pbHasNetwork && !this.msvcSecurity.isDomListening ?
					this.msvcSecurity.defineTouchHandler() : of(null)
				)
			);
	}

	//#endregion

}