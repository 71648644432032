import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-list-item-content-suffix',
	templateUrl: './list-item-content-suffix.component.html',
	styleUrls: ['./list-item-content-suffix.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'"
	}
})
export class ListItemContentSuffixComponent { }
